/**

Template Name: Mash Able Admin Template
Author: Codedthemes
Email: codedthemes@gmail.com
File: style.css
**/


/* ============= == Table of Contents==

	- Custom Css
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Icons, Accordion, Button, Redial, floating actions, FAB, tabs, typography, buttons, box shadow, slider page, error page, Breadcrumb, pagination, Basic card, Login card
	- General Elements
		-	Modal, Nestable, Lable & Badges, List, Portlates, Preloader, Calender, To-Do, Materialize general elements, Lables
	- Advance Form Elements
	- Pages
		-	Loader page, Invoice, Tasklist, Task detail, Range-slider, Counter, Maintenance, Color, FAQ, Progress, Panels & wells, Contact card, Auth pages, Flag
		-	Gallery, Products pages, Widget, Email, Social timeline, Datatable, Chat, Blog, Wizard, CRM dashboard, Task board, Issue list, Chartlist, X-editable
		-	Home chat, Dashboard 1-2-3-4
	- Responsive media class

============= */


/**  ===================== Custom css start
==========================  **/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;800&display=swap");
@import 'assets/themify-icons/themify-icons';
body {
    background-color: #f3f3f3;
    font-size: 0.875em;
    overflow-x: hidden !important;
    font-family: 'Mada', sans-serif;

a {
  text-decoration: none;
}
}

ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

* a {
    font-size: 14px;
    &:focus,
    &:hover {
        text-decoration: none;
    }
}

p {
    font-size: 14px;
}

.media-left {
    padding-right: 20px;
}

.file-card {
  border: solid 1px #57575a;
  width: 12rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  user-select: none;
  i {
    font-size: large;
  }
}

.link {
  color: black;
}

.link:hover {
  color: #0073aa;
  cursor: pointer;
}

/**====== Custom css end ======**/


/**  ===================== Main-body css start
==========================  **/

.main-body .page-wrapper {
    padding: 1.8rem;
    transition: all ease-in 0.3s;
    .page-header {
        margin-bottom: 20px;
    }
    .page-header-title {
        display: inline-block;
        h4 {
            display: inline-block;
            margin-bottom: 0;
            font-weight: 600;
            color: #4a6076;
            font-size: 20px;
        }
        span {
            font-size: 13px;
            color: #9f9f9f;
            display: block;
            margin-top: 5px;
        }
    }
    .page-header-breadcrumb {
        float: right;
    }
}


/**====== Main-body css end ======**/


/**  ===================== Card-Layout css start
==========================  **/

.card {
    border-radius: 2px;
    border-top: 4px solid rgba(0, 115, 170, 0.5);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin-bottom: 30px;
    .card-footer {
        background-color: #fff;
    }
    .card-header {
        background-color: transparent;
        padding: 15px 20px;
        .card-header-left {
            display: inline-block;
        }
        .card-header-right {
            border-radius: 0 0 0 5px;
            right: 0;
            top: 0;
            background: #f6f7f7;
            display: inline-block;
            float: right;
            padding: 7px 0;
            position: absolute;
            i {
                margin: 0 20px;
                cursor: pointer;
            }
        }
        span {
            color: #9f9f9f;
            display: block;
            font-size: 13px;
            margin-top: 5px;
        }
    }
    h5 {
        margin-bottom: 0;
        color: #757575;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 10px;
    }
    .card-body {
        table tr {
            padding-bottom: 20px;
        }
        .sub-title {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
        }
        code {
            background-color: #eee;
        }
        .dropdown-menu {
            top: 38px;
        }
        p {
            line-height: 25px;
        }
        a.dropdown-item {
            margin-bottom: 0;
            font-size: 14px;
            transition: 0.25s;
            &:active,
            .active {
                background-color: #0073aa;
            }
        }
        &.remove-label i {
            margin: 0;
            padding: 0;
        }
        &.button-list span.badge {
            margin-left: 5px;
        }
        .dropdown-menu {
            background-color: #fff;
            padding: 0;
            .dropdown-divider {
                background-color: #ddd;
                margin: 3px 0;
            }
            > {
                a {
                    padding: 10px 16px;
                    line-height: 1.429;
                }
                li {
                    >a {
                        &:focus,
                        &:hover {
                            background-color: rgba(202, 206, 209, 0.5);
                        }
                    }
                    &:first-child>a:first-child {
                        border-top-right-radius: 4px;
                        border-top-left-radius: 4px;
                    }
                }
            }
        }
        .badge-box {
            border: 1px solid #ccc;
            padding: 10px;
            margin: 12px 0;
        }
    }
    .inner-card-body {
        border: 1px solid #ddd;
    }
}

.card-header-text {
    margin-bottom: 0;
    font-size: 1rem;
    color: rgba(51, 51, 51, 0.85);
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
}

.icofont-rounded-down {
    display: inline-block;
    transition: all ease-in 0.3s;
}

.icon-up {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rotate-refresh {
    -webkit-animation: mymove 0.8s infinite linear;
    animation: mymove 0.8s infinite linear;
    display: inline-block;
}

@-webkit-keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.breadcrumb-title {
    a {
        font-size: 14px;
        color: #4a6076;
    }
    li:last-child a {
        color: #7e7e7e;
    }
}

.sub-title {
    border-bottom: 1px solid rgba(204, 204, 204, 0.35);
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: #2c3e50;
}


/**======  Card-Layout css end ======**/


/*======= Typography css start ========*/

.typography {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 20px;
        font-weight: 600;
    }
    small {
        margin-left: 10px;
        font-weight: 600;
        color: #777;
    }
}

.card-body.list-tag {
    ul li {
        display: block;
        float: none;
        margin-bottom: 5px;
    }
    ol li {
        margin-bottom: 5px;
    }
}

.inline-order-list {
    margin-top: 50px;
    h4,
    p {
        margin-bottom: 0;
    }
}

.card-body ul li.list-inline-item {
    display: inline-block;
    float: left;
}


/*======= Typography css end ========*/


/**  ===================== Modal css start
==========================  **/

.modal {
    z-index: 99999999999;
}

.bd-example .modal {
    display: block;
    position: inherit;
    background-color: #2C3E50;
    margin-bottom: 20px;
}

.card .overflow-container h5 {
    margin-bottom: 5px;
}

.button-page .card-body a.nav-link {
    margin-bottom: 0;
}

.sweet-alert {
    button.confirm {
        background-color: #0073aa !important;
    }
    .sa-input-error {
        top: 23px;
    }
}


/**====== Modal css end ======**/


/**  ===================== Notification css start
==========================  **/

.location-selector {
    width: 100%;
    height: 250px;
    background-color: #fff;
    border: 2px dashed #e5e9ec;
    position: relative;
    margin-bottom: 20px;
    .bit {
        background-color: #e5e9ec;
        cursor: pointer;
        position: absolute;
        &:hover {
            background-color: #ddd;
        }
        &.bottom {
            height: 25%;
            width: 40%;
            margin: 0 30%;
        }
        &.top {
            height: 25%;
            width: 40%;
            margin: 0 30%;
            top: 0;
        }
        &.bottom {
            bottom: 0;
        }
        &.left {
            height: 20%;
            width: 20%;
            margin-left: 0;
            margin-right: 0;
        }
        &.right {
            height: 20%;
            width: 20%;
            margin-left: 0;
            margin-right: 0;
            right: 0;
        }
        &.left {
            left: 0;
        }
    }
}

button.close {
    margin-top: 7px;
    margin-bottom: 0;
}


/**====== Notification css end ======**/


/**  ===================== Tooltip css start
==========================  **/

.mytooltip {
    display: inline;
    position: relative;
    z-index: 9999;
    .tooltip-item {
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        display: inline-block;
        font-weight: 500;
        padding: 0 10px;
    }
    .tooltip-content {
        position: absolute;
        z-index: 9999;
        width: 360px;
        left: 50%;
        margin: 0 0 20px -180px;
        bottom: 100%;
        text-align: left;
        font-size: 14px;
        line-height: 30px;
        box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
        background: #2b2b2b;
        opacity: 0;
        cursor: default;
        pointer-events: none;
        &::after {
            content: '';
            top: 100%;
            left: 50%;
            border: solid transparent;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: #2a3035 transparent transparent;
            border-width: 10px;
            margin-left: -10px;
        }
        img {
            position: relative;
            height: 140px;
            display: block;
            float: left;
            margin-right: 1em;
        }
    }
    .tooltip-item::after {
        content: '';
        position: absolute;
        width: 360px;
        height: 20px;
        bottom: 100%;
        left: 50%;
        pointer-events: none;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    &:hover {
        .tooltip-item::after {
            pointer-events: auto;
        }
        .tooltip-content {
            pointer-events: auto;
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
            transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
        }
        .tooltip-content2 {
            opacity: 1;
            font-size: 18px;
            i {
                opacity: 1;
                font-size: 18px;
                opacity: 1;
                font-size: 18px;
                pointer-events: auto;
                -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
                transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            }
            opacity: 1;
            font-size: 18px;
            pointer-events: auto;
            -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        }
        .tooltip-item2 {
            color: #fff;
            -webkit-transform: translate3d(0, -0.9em, 0);
            transform: translate3d(0, -0.9em, 0);
        }
        .tooltip-text3 {
            transition-delay: 0s;
            transform: scale3d(1, 1, 1);
        }
        .tooltip-content3 {
            opacity: 1;
            pointer-events: auto;
            transform: scale3d(1, 1, 1);
        }
        .tooltip-content4,
        .tooltip-text2 {
            pointer-events: auto;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
        .tooltip-inner2 {
            transition-delay: 0.3s;
            transform: translate3d(0, 0, 0);
        }
        .tooltip-content5 {
            opacity: 1;
            pointer-events: auto;
            transition-delay: 0s;
        }
    }
    .tooltip-text {
        font-size: 14px;
        line-height: 24px;
        display: block;
        padding: 1.31em 1.21em 1.21em 0;
        color: #fff;
    }
    .tooltip-item2 {
        color: #0073aa;
        cursor: pointer;
        z-index: 100;
        position: relative;
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
    }
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
    transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

.tooltip-effect-5 .tooltip-text {
    padding: 1.4em;
}

.tooltip-effect-1 .tooltip-content {
    transform: translate3d(0, -10px, 0);
    transition: opacity 0.3s, transform 0.3s;
    color: #fff;
}

.tooltip-effect-2 .tooltip-content {
    -webkit-transform-origin: 50% calc(110%);
    transform-origin: 50% calc(110%);
    -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
    -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
    transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale3d(0.7, 0.3, 1);
    transform: scale3d(0.7, 0.3, 1);
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-5 .tooltip-content {
    width: 180px;
    margin-left: -90px;
    -webkit-transform-origin: 50% calc(106%);
    transform-origin: 50% calc(106%);
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s;
    -webkit-transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
    transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.tooltip-effect-6 .tooltip-content2 {
    -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
    transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    i {
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
        -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
    }
}

.tooltip-effect-7 .tooltip-content2 {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    i {
        -webkit-transform: translate3d(0, 15px, 0);
        transform: translate3d(0, 15px, 0);
        -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
    }
}

.tooltip-effect-8 .tooltip-content2 {
    -webkit-transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
    transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    i {
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
        -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
    }
}

.tooltip-effect-9 .tooltip-content2 {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    i {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
        -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
    }
}

.tooltip-effect-6:hover .tooltip-content2 i {
    -webkit-transform: rotate3d(1, 1, 1, 0);
    transform: rotate3d(1, 1, 1, 0);
}

.tooltip-content2 {
    position: absolute;
    z-index: 9999;
    width: 80px;
    height: 80px;
    padding-top: 25px;
    left: 50%;
    margin-left: -40px;
    bottom: 100%;
    border-radius: 50%;
    text-align: center;
    background: #0073aa;
    color: #fff;
    opacity: 0;
    margin-bottom: 20px;
    cursor: default;
    pointer-events: none;
    i {
        opacity: 0;
    }
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin: -7px 0 0 -15px;
        width: 30px;
        height: 20px;
        background: url("assets/images/tooltip/tooltip1.svg") center center no-repeat;
        background-size: 100%;
    }
}

.tooltip-content3 {
    position: absolute;
    background: url("assets/images/tooltip/shape1.svg") center bottom no-repeat;
    background-size: 100% 100%;
    z-index: 9999;
    width: 200px;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
    padding: 50px 30px;
    text-align: center;
    color: #fff;
    opacity: 0;
    cursor: default;
    font-size: 14px;
    line-height: 27px;
    pointer-events: none;
    -webkit-transform: scale3d(0.1, 0.2, 1);
    transform: scale3d(0.1, 0.2, 1);
    -webkit-transform-origin: 50% 120%;
    transform-origin: 50% 120%;
    -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: opacity 0.4s, transform 0.4s;
    -webkit-transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
    transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
    &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: 50%;
        margin-left: -8px;
        top: 100%;
        background: #00AEEF;
        -webkit-transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
        transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
    }
}

.tooltip-content4 {
    position: absolute;
    z-index: 99;
    width: 360px;
    left: 50%;
    margin-left: -180px;
    bottom: -5px;
    text-align: left;
    background: #0073aa;
    opacity: 0;
    font-size: 14px;
    line-height: 27px;
    padding: 1.5em;
    color: #fff;
    border-bottom: 55px solid #34495e;
    cursor: default;
    pointer-events: none;
    border-radius: 5px;
    -webkit-transform: translate3d(0, -0.5em, 0);
    transform: translate3d(0, -0.5em, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    a {
        color: #2b2b2b;
    }
    .tooltip-text2 {
        opacity: 0;
        -webkit-transform: translate3d(0, 1.5em, 0);
        transform: translate3d(0, 1.5em, 0);
        -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
    }
}

.tooltip-content5 {
    position: absolute;
    z-index: 9999;
    width: 300px;
    left: 50%;
    bottom: 100%;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    font-weight: 400;
    color: #fff;
    background: 0 0;
    opacity: 0;
    margin: 0 0 20px -150px;
    cursor: default;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: opacity 0.3s 0.3s;
    transition: opacity 0.3s 0.3s;
    span {
        display: block;
    }
    &::after {
        content: '';
        bottom: -20px;
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: #0073aa transparent transparent;
        border-width: 10px;
        margin-left: -10px;
    }
    .tooltip-text3 {
        border-bottom: 10px solid #0073aa;
        overflow: hidden;
        -webkit-transform: scale3d(0, 1, 1);
        transform: scale3d(0, 1, 1);
        -webkit-transition: -webkit-transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s;
    }
    .tooltip-inner2 {
        background: #2b2b2b;
        padding: 40px;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
    }
}

a.mytooltip {
    font-weight: 700;
    color: #0073aa;
    z-index: 9;
}

.tooltip-link a {
    margin-left: 10px;
    color: #0073aa;
}

.tooltip-icon button i {
    margin-right: 0;
}


/**====== Tooltip css end ======**/


/**  ===================== Slider css start
==========================  **/


/*========= Owl-carousel css start ============*/

.owl-theme {
    .owl-nav [class*='owl-']:hover {
        background: #0073aa;
    }
    .owl-dots .owl-dot {
        &.active span,
        &:hover span {
            background: #0073aa;
        }
    }
}


/*========= Owl-carousel css end ============*/


/**  ===================== Animation css start
==========================  **/

.animation-image img {
    margin: 10px auto 20px;
    cursor: pointer;
}


/**====== Animation css end ======**/


/**  ===================== Tree-view css start
==========================  **/

.card-body.tree-view ul {
    display: inherit;
    li {
        display: inherit;
        float: none;
    }
}


/*
.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
    background: url("assets/pages/treeview/throbber.gif") center center no-repeat;
}*/


/**====== Tree-view css end ======**/


/**  ===================== File Upload css start
==========================  **/

.jFiler-theme-default {
    .jFiler-input-button {
        background-color: #0073aa;
        background-image: none;
        color: #fff;
    }
    .jFiler-input {
        width: 100%;
    }
}

.jFiler-input-dragDrop {
    width: 100%;
    background-color: #fafafa;
}

.jFiler-items-grid .jFiler-item .jFiler-item-container .jFiler-item-info {
    position: absolute;
    bottom: -10%;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 6px 10px;
    z-index: 9;
    opacity: 0;
    -webkit-transition: all 0.12s;
    -moz-transition: all 0.12s;
    transition: all 0.12s;
}

.jFiler-input-choose-btn {
    border: 1px solid #0073aa;
    padding: 10px 25px;
    background: #0073aa;
    color: #fff !important;
    &:hover {
        background: #fff;
        border: 1px solid #0073aa;
        color: #fff !important;
    }
}

.card .jFiler-item-assets ul li {
    margin-right: 0;
}


/**====== File Upload css end ======**/


/**  ===================== Cropper css start
==========================  **/

.img-container,
.img-preview {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center;
}

.img-container {
    min-height: 200px;
    max-height: 516px;
    margin-bottom: 20px;
    >img {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .img-container {
        min-height: 516px;
    }
}

.docs-preview {
    margin-right: -15px;
}

.img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    >img {
        max-width: 100%;
    }
}

.preview-lg {
    width: 100% !important;
}

.preview-md {
    width: 139px;
    height: 78px;
}

.preview-sm {
    width: 69px;
    height: 39px;
}

.preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0;
}

.docs-data>.input-group {
    margin-bottom: 10px;
    > {
        label {
            min-width: 80px;
        }
        span {
            min-width: 50px;
        }
    }
}

.docs-buttons> {
    .btn-group,
    .form-control,
    .btn {
        margin-right: 5px;
    }
}

.docs-toggles> {
    .btn-group,
    .dropdown,
    .btn {
        margin-bottom: 10px;
    }
}

.docs-tooltip {
    display: block;
    margin: -6px -12px;
    padding: 6px 12px;
    >.icon {
        margin: 0 -3px;
        vertical-align: top;
    }
}

.tooltip-inner {
    white-space: normal;
}

.btn-upload .tooltip-inner {
    white-space: nowrap;
}

@media (max-width: 400px) {
    .btn-group-crop {
        margin-right: -15px !important;
        >.btn {
            padding-left: 5px;
            padding-right: 5px;
        }
        .docs-tooltip {
            margin-left: -5px;
            margin-right: -5px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.docs-options .dropdown-menu {
    width: 100%;
    >li {
        padding: 3px 20px;
        font-size: 14px;
        &:hover {
            background-color: #f7f7f7;
        }
        >label {
            display: block;
        }
    }
}

.docs-cropped .modal-body {
    text-align: center;
    > {
        canvas,
        img {
            max-width: 100%;
        }
    }
}

.card-body .docs-options .dropdown-menu {
    top: inherit;
}

label.btn-upload {
    height: 40px;
}


/**====== Cropper css end ======**/


/**  ===================== Accordion css start
==========================  **/

.accordion-msg {
    display: block;
    color: #222222;
    padding: 14px 20px;
    border-top: 1px solid #ddd;
    font-weight: 600;
    cursor: pointer;
    &:focus,
    &:hover {
        text-decoration: none;
        outline: none;
    }
}

.faq-accordion .accordion-desc {
    padding: 20px;
}

.accordion-desc {
    color: #222222;
    padding: 0 20px 20px;
}

#color-accordion .accordion-desc {
    margin-top: 14px;
}

.ui-accordion-header-icon {
    float: right;
    font-size: 20px;
}

.accordion-title {
    margin-bottom: 0;
}

.accordion-block {
    padding: 0;
    p {
        margin-bottom: 0;
    }
}

.color-accordion-block a {
    &:focus,
    &:hover {
        color: #fff;
    }
}

a {
    &.bg-default {
        &:focus,
        &:hover {
            background-color: #d9dcde !important;
            color: #fff;
        }
    }
    &.bg-primary {
        &:focus,
        &:hover {
            background-color: #0096dd !important;
            color: #fff;
        }
    }
    &.bg-success {
        &:focus,
        &:hover {
            background-color: #54d98c !important;
            color: #fff;
        }
    }
    &.bg-info {
        &:focus,
        &:hover {
            background-color: #5faee3 !important;
            color: #fff;
        }
    }
    &.bg-warning {
        &:focus,
        &:hover {
            background-color: #f4d03f !important;
            color: #fff;
        }
    }
    &.bg-danger {
        &:focus,
        &:hover {
            background-color: #ed7669 !important;
            color: #fff;
        }
    }
}


/**====== Accordion css end ======**/


/**  ===================== Tabs css start
==========================  **/

.tab-list p {
    padding: 10px;
}

.tab-with-img i {
    position: absolute;
    padding: 5px;
}

.tab-icon {
    margin-bottom: 30px;
    i {
        padding-right: 10px;
    }
}

.tab-below {
    border-top: 1px solid #ddd;
    border-bottom: none;
    &.nav-tabs .nav-link.active {
        border-color: transparent #ddd #ddd #ddd;
    }
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        margin-top: -2px;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .tab-below a,
.tab-below .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .tab-below a,
.tab-below .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .tab-below a,
.tab-below .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .tab-below a {
    margin-top: -2px;
}

.tab-below.nav-tabs .nav-link {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-header~.tab-icon .tab-with-img .sub-title i {
    right: 10px;
}

.tab-with-img .nav-link {
    position: relative;
}

.tabs-left,
.tabs-right {
    min-width: 120px;
    vertical-align: top;
    width: 150px;
}

.tabs-left,
.tabs-left-content,
.tabs-right,
.tabs-right-content {
    display: table-cell;
}

.nav-tabs {
    &.tabs-left .slide {
        height: 35px;
        width: 4px;
        bottom: 15px;
    }
    &.tabs-right .slide {
        height: 35px;
        width: 4px;
        bottom: 15px;
        right: 0;
    }
}

.product-edit .md-tabs {
    .nav-item a,
    .main-menu .main-menu-content .nav-item .tree-1 a a {
        padding: 0 0 20px !important;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .product-edit .md-tabs a a,
.product-edit .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 .product-edit .md-tabs a a,
.product-edit .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 .product-edit .md-tabs a a,
.product-edit .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 .product-edit .md-tabs a a {
    padding: 0 0 20px !important;
}

.md-tabs.tabs-left {
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        width: 100%;
        position: relative;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs.tabs-left a,
.md-tabs.tabs-left .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs.tabs-left a,
.md-tabs.tabs-left .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs.tabs-left a,
.md-tabs.tabs-left .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs.tabs-left a {
    width: 100%;
    position: relative;
}

.md-tabs.tabs-right {
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        width: 100%;
        position: relative;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs.tabs-right a,
.md-tabs.tabs-right .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs.tabs-right a,
.md-tabs.tabs-right .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs.tabs-right a,
.md-tabs.tabs-right .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs.tabs-right a {
    width: 100%;
    position: relative;
}

.tabs-left {
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        width: 100%;
        position: relative;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .tabs-left a,
.tabs-left .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .tabs-left a,
.tabs-left .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .tabs-left a,
.tabs-left .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .tabs-left a {
    width: 100%;
    position: relative;
}

.tabs-right {
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        width: 100%;
        position: relative;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .tabs-right a,
.tabs-right .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .tabs-right a,
.tabs-right .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .tabs-right a,
.tabs-right .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .tabs-right a {
    width: 100%;
    position: relative;
}

.md-tabs {
    position: relative;
    .nav-item+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a+.nav-item {
        margin: 0;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs .nav-item+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+a {
    margin: 0;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 .md-tabs a+a,
    .tree-2 .md-tabs .tree-1 a+a,
    .tree-3 .md-tabs .tree-1 a+a,
    .tree-4 .md-tabs .tree-1 a+a {
        margin: 0;
    }
}

.md-tabs .main-menu .main-menu-content .nav-item .tree-2 .nav-item+a {
    margin: 0;
}

.main-menu .main-menu-content .nav-item {
    .tree-2 .md-tabs .nav-item+a,
    .tree-1 .md-tabs .tree-2 a+a {
        margin: 0;
    }
}

.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+a {
    margin: 0;
}

.main-menu .main-menu-content .nav-item {
    .tree-2 .md-tabs a+a,
    .tree-3 .md-tabs .tree-2 a+a,
    .tree-4 .md-tabs .tree-2 a+a {
        margin: 0;
    }
}

.md-tabs .main-menu .main-menu-content .nav-item .tree-3 .nav-item+a {
    margin: 0;
}

.main-menu .main-menu-content .nav-item {
    .tree-3 .md-tabs .nav-item+a,
    .tree-1 .md-tabs .tree-3 a+a,
    .tree-2 .md-tabs .tree-3 a+a {
        margin: 0;
    }
}

.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+a {
    margin: 0;
}

.main-menu .main-menu-content .nav-item {
    .tree-3 .md-tabs a+a,
    .tree-4 .md-tabs .tree-3 a+a {
        margin: 0;
    }
}

.md-tabs .main-menu .main-menu-content .nav-item .tree-4 .nav-item+a {
    margin: 0;
}

.main-menu .main-menu-content .nav-item {
    .tree-4 .md-tabs .nav-item+a,
    .tree-1 .md-tabs .tree-4 a+a,
    .tree-2 .md-tabs .tree-4 a+a,
    .tree-3 .md-tabs .tree-4 a+a {
        margin: 0;
    }
}

.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a+a {
    margin: 0;
}

.md-tabs {
    .nav-link {
        border: none;
        color: #1b8bf9;
    }
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        background-color: #fff;
        width: calc(100% / 4);
        text-align: center;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a {
    background-color: #fff;
    width: calc(100% / 4);
    text-align: center;
}


/*.md-tabs .nav-link:focus, .md-tabs .nav-link:hover {
    border: none;
}*/

.md-tabs {
    .nav-item .nav-link.active~.slide,
    .main-menu .main-menu-content .nav-item .tree-1 a .nav-link.active~.slide {
        opacity: 1;
        transition: all 0.3s ease-out;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a .nav-link.active~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a .nav-link.active~.slide,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a .nav-link.active~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a .nav-link.active~.slide,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a .nav-link.active~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a .nav-link.active~.slide,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a .nav-link.active~.slide {
    opacity: 1;
    transition: all 0.3s ease-out;
}

.md-tabs {
    .nav-item .nav-link~.slide,
    .main-menu .main-menu-content .nav-item .tree-1 a .nav-link~.slide {
        opacity: 0;
        transition: all 0.3s ease-out;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a .nav-link~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a .nav-link~.slide,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a .nav-link~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a .nav-link~.slide,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a .nav-link~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a .nav-link~.slide,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a .nav-link~.slide {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.md-tabs {
    .nav-item.open .nav-link,
    .main-menu .main-menu-content .nav-item .tree-1 a.open .nav-link {
        color: #0073aa;
        border: none;
        background-color: transparent;
        border-radius: 0;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a.open .nav-link,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a.open .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a.open .nav-link,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a.open .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a.open .nav-link,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a.open .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a.open .nav-link {
    color: #0073aa;
    border: none;
    background-color: transparent;
    border-radius: 0;
}

.md-tabs {
    .nav-item.open .nav-link:focus,
    .main-menu .main-menu-content .nav-item .tree-1 a.open .nav-link:focus {
        color: #0073aa;
        border: none;
        background-color: transparent;
        border-radius: 0;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a.open .nav-link:focus,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a.open .nav-link:focus,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a.open .nav-link:focus,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a.open .nav-link:focus,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a.open .nav-link:focus,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a.open .nav-link:focus,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a.open .nav-link:focus {
    color: #0073aa;
    border: none;
    background-color: transparent;
    border-radius: 0;
}

.md-tabs {
    .nav-item.open .nav-link:hover,
    .main-menu .main-menu-content .nav-item .tree-1 a.open .nav-link:hover {
        color: #0073aa;
        border: none;
        background-color: transparent;
        border-radius: 0;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a.open .nav-link:hover,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a.open .nav-link:hover,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a.open .nav-link:hover,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a.open .nav-link:hover,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a.open .nav-link:hover,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a.open .nav-link:hover,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a.open .nav-link:hover {
    color: #0073aa;
    border: none;
    background-color: transparent;
    border-radius: 0;
}

.md-tabs {
    .nav-link.active {
        color: #0073aa;
        border: none;
        background-color: transparent;
        border-radius: 0;
        &:focus,
        &:hover {
            color: #0073aa;
            border: none;
            background-color: transparent;
            border-radius: 0;
        }
    }
    .nav-item:first-child,
    .main-menu .main-menu-content .nav-item .tree-1 a:first-child {
        border-bottom: 1px solid #ddd;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a:first-child,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a:first-child,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a:first-child,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a:first-child,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a:first-child,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a:first-child,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a:first-child {
    border-bottom: 1px solid #ddd;
}

.md-tabs {
    .nav-item a,
    .main-menu .main-menu-content .nav-item .tree-1 a a {
        padding: 20px 0 !important;
        color: #0073aa;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a {
    padding: 20px 0 !important;
    color: #0073aa;
}

.nav-tabs .slide {
    background: #0073aa;
    width: calc(100% / 4);
    height: 4px;
    position: absolute;
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
    bottom: 0;
    .nav-item.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link {
        color: #0073aa;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .nav-link {
    color: #0073aa;
}

.img-tabs {
    img {
        width: 100px;
        margin: 0 auto;
    }
    a {
        opacity: 0.5;
        transition: all ease-in-out 0.3s;
        span i {
            height: 25px;
            width: 25px;
            border-radius: 100%;
            bottom: 10px;
            right: 70px;
        }
        img {
            border: 3px solid;
        }
        &.active {
            opacity: 1;
            transition: all ease-in-out 0.3s;
        }
    }
    .nav-item:first-child,
    .main-menu .main-menu-content .nav-item .tree-1 a:first-child {
        border-bottom: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .img-tabs a:first-child,
.img-tabs .main-menu .main-menu-content .nav-item .tree-2 a:first-child,
.main-menu .main-menu-content .nav-item .tree-2 .img-tabs a:first-child,
.img-tabs .main-menu .main-menu-content .nav-item .tree-3 a:first-child,
.main-menu .main-menu-content .nav-item .tree-3 .img-tabs a:first-child,
.img-tabs .main-menu .main-menu-content .nav-item .tree-4 a:first-child,
.main-menu .main-menu-content .nav-item .tree-4 .img-tabs a:first-child {
    border-bottom: none;
}

#pc-left-panel-menu {
    margin-bottom: 20px;
}

.h-active a {
    color: #1b8bf9 !important;
    font-weight: 600;
}

.img-circle {
    border-radius: 50%;
}

.b-none {
    border: none !important;
}


/**====== Tabs css end ======**/


/**  ===================== Table css start
==========================  **/

.table {
    &.table-xl {
        td,
        th {
            padding: 1.25rem 2rem;
        }
    }
    &.table-lg {
        td,
        th {
            padding: 0.9rem 2rem;
        }
    }
    &.table-de {
        td,
        th {
            padding: 0.75rem 2rem;
        }
    }
    &.table-sm {
        td,
        th {
            padding: 0.6rem 2rem;
        }
    }
    &.table-xs {
        td,
        th {
            padding: 0.4rem 2rem;
        }
    }
}

.table-columned> {
    tbody>tr> {
        th:first-child,
        td:first-child {
            border-left: 0;
        }
    }
    tfoot>tr> {
        th:first-child,
        td:first-child {
            border-left: 0;
        }
    }
    tbody>tr> {
        th,
        td {
            border: 0;
            border-left: 1px solid #ddd;
        }
    }
    tfoot>tr> {
        th,
        td {
            border: 0;
            border-left: 1px solid #ddd;
        }
    }
}

.table-border-style {
    padding: 0;
    .table {
        margin-bottom: 0;
    }
}

.table>thead>tr>th {
    border-bottom-color: #ccc;
}

.table-borderless tbody tr {
    td,
    th {
        border: 0;
    }
}

.table-bordered>thead>tr {
    &.border-solid {
        > {
            th,
            td {
                border-bottom-width: 2px;
            }
        }
        &:first-child {
            >td,
            th {
                border-bottom-width: 2px;
            }
        }
    }
    &.border-double {
        > {
            th,
            td {
                border-bottom-width: 3px;
                border-bottom-style: double;
            }
        }
        &:first-child {
            >td,
            th {
                border-bottom-width: 3px;
                border-bottom-style: double;
            }
        }
    }
}


/**====== Table css end ======**/


/**  ===================== User-Profile css start
==========================  **/

.rounded-card img {
    margin: 0 auto;
    display: block;
    width: 100%;
}

.user-img img {
    margin: 0 auto;
    display: block;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.user-about ul li {
    border-top: 1px solid rgba(204, 204, 204, 0.28);
    padding: 10px 0 10px 10px;
    text-align: left;
    a {
        font-size: 16px;
        color: #666;
    }
    &.active {
        a {
            color: #fff;
        }
        font-weight: 600;
        background: #1b8bf9;
    }
}

.card-title,
h5 {
    margin: 0;
}

.card-header.followers a {
    font-weight: 500;
    color: rgba(51, 51, 51, 0.85);
}

.user-profile {
    padding: 20px 0;
}

.follow-btn button:first-child,
.user-about i {
    margin-right: 10px;
}

.btn-inline {
    i {
        color: #fff;
    }
    .fb-btn {
        background-color: #3b5998;
    }
    .twitter-btn {
        background-color: #55acee;
    }
    .pinterest-btn {
        background-color: #cb2027;
    }
    .linkedin-btn {
        background-color: #007bb5;
    }
    .dribbble-btn {
        background-color: #ea4c89;
    }
}

.user-post {
    font-style: italic;
}

.connection-list img {
    display: inline-block;
}

.contact-user {
    h4,
    img {
        display: inline-block;
    }
}

.earn-heading {
    display: inline-block;
}

.list-inline {
    display: inline-block;
    li {
        display: inline-block;
    }
}

.service-header {
    display: inline-block;
}

.connection-list img {
    width: 55px;
    height: 55px;
    margin: 5px;
}

.border-post {
    border: 1px solid #ccc;
}

.earn-sub-header {
    font-size: 15px;
    color: #ccc;
}

.btn-inline {
    margin-top: 20px;
}

.order-summary .progress {
    margin-bottom: 32px;
}

.services .service-btn::after {
    top: 20px;
    right: 15px;
}

.services-list {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
    top: 46px;
    right: 0;
}

.contact-menu i,
.fa-cog,
.services-list i {
    margin-right: 10px;
}

.contact-details table {
    .fa-star,
    .fa-star-o {
        color: #0073aa;
    }
}

.review-star i {
    color: #0073aa;
    &:last-child {
        color: #ccc;
    }
}

.card-body.user-info {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.profile-bg-img {
    width: 100%;
}

.user-title {
    position: relative;
    bottom: 20px;
    h2 {
        color: #fff;
        text-shadow: 1px 1px 4px #373a3c;
        font-size: 20px;
    }
}

.profile-image img {
    border: 4px solid #fff;
}

.user-info {
    .media-body,
    .media-left {
        display: table-cell;
        vertical-align: middle;
    }
}

.cover-btn {
    bottom: 38px;
    right: 35px;
    position: absolute;
}

.cover-profile .profile-bg-img {
    margin-bottom: 25px;
}

.contact-user h4 {
    font-size: 20px;
    padding-left: 10px;
}

.groups-contact span {
    float: right;
    h4 {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

.contact-menu {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
    top: 15%;
    right: 10%;
    .dropdown-item {
        padding: 8px 20px;
    }
}

.tab-header {
    margin-bottom: 20px;
}


/*====== User-Profile End ======*/


/**  ===================== User-card css start
==========================  **/

.card.user-card {
    border-top: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
    transition: all 150ms linear;
    margin-bottom: 0;
    &:hover {
        box-shadow: 0 0 25px -5px #9e9c9e;
    }
}

.card-header-img~.btn-group i {
    margin-right: 0;
}

.card.business-info {
    border-top: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
    transition: all 150ms linear;
    &:hover {
        box-shadow: 0 0 25px -5px #9e9c9e;
    }
}

.top-cap-text {
    padding: 20px;
    p {
        padding: 10px 0;
    }
}

.user-content {
    text-align: center;
    margin-top: 20px;
    h4 {
        font-size: 16px;
        font-weight: 600;
    }
    h5 {
        font-size: 14px;
    }
}

.img-overlay {
    bottom: 0;
    color: #fff;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    position: absolute;
    transform: scale(0);
    margin: 0 auto;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
    i {
        margin-right: 0;
    }
}

.img-hover-main {
    padding: 0 40px;
}

.img-hover {
    position: relative;
    width: 80%;
    margin: 0 auto;
    &:hover .img-overlay {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        transform: scale(1);
        margin: 0 auto;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 2;
        -webkit-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }
}

.card-header-img {
    img {
        margin: 0 auto;
        display: block;
    }
    h4 {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    h5,
    h6 {
        margin-top: 15px;
        font-size: 15px;
        color: #222;
        font-weight: 500;
        text-align: center;
    }
}

.simple-cards {
    .btn-group {
        margin: 20px auto 0;
        button {
            margin: 0;
        }
    }
    .user-card {
        padding: 20px 0;
        text-align: center;
    }
}

.card-icon {
    display: block;
    margin-bottom: 5px;
}

.btn-outline-primary {
    border-color: #0073aa;
    font-weight: 500;
    padding: 10px 16px;
    font-size: 15px;
    &:hover {
        background-color: #0073aa;
        border-color: #0073aa;
    }
}

.simple-cards p {
    margin: 20px;
    font-size: 15px;
}

.user-profile {
    #edit-btn,
    #edit-info-btn {
        margin-bottom: 0;
    }
}

.card-body {
    ul.list-contacts,
    &.groups-contact ul,
    ul.list-contacts li,
    &.groups-contact ul li {
        display: block;
        float: none;
    }
}

ul.list-contacts .list-group-item {
    a {
        color: #292b2c;
    }
    &.active a {
        color: #fff;
    }
}

.card-body {
    &.groups-contact {
        margin-bottom: 0;
    }
    .connection-list {
        margin-bottom: 20px;
    }
    .pagination li {
        margin-right: 0 !important;
    }
}

.table button {
    margin-bottom: 0;
}

#crm-contact .img-circle,
img.comment-img {
    width: 40px;
    height: 40px;
}

.page-link {
    color: #0073aa;
}

.page-item.active .page-link {
    background-color: #0073aa;
    border-color: #0073aa;
}

#main {
    margin-bottom: 20px;
}


/*====== User-card End ======*/


/**  ===================== Offline css start
==========================  **/

.offline-box iframe {
    width: 100%;
    border: 1px solid #ddd;
}


/*==== Offline page css end ====*/


/**  ===================== Blog css start
==========================  **/

.blog-page {
    border-top: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
    .blog-box {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        h5 {
            border-bottom: 1px solid #ccc;
            color: #0073aa;
            margin-top: 0;
            padding-bottom: 10px;
            margin-bottom: 15px;
            font-size: 18px;
            display: block;
        }
        .option-font {
            background-color: #0073aa;
            border-radius: 50%;
            bottom: 320px;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            line-height: 35px;
            text-align: center;
            width: 35px;
        }
        .blog-detail {
            padding: 10px;
        }
    }
    .blog-img {
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        margin-bottom: -20px;
        &:hover {
            opacity: 0.8;
            transition: all 0.3s ease-in-out;
        }
        .sharing {
            position: relative;
            bottom: 50px;
            left: 15px;
        }
        .share {
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            bottom: 3px;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            left: 50px;
            padding: 3px 5px;
            position: absolute;
            &::before {
                border-bottom: 6px solid transparent;
                border-left: 6px solid transparent;
                border-right: 6px solid rgba(0, 0, 0, 0.5);
                border-top: 6px solid rgba(0, 0, 0, 0.5);
                bottom: 8px;
                content: "";
                height: 42%;
                left: -6px;
                position: absolute;
                transform: rotate(-135deg);
                width: 12px;
            }
            i {
                color: #fff;
                font-size: 15px;
                border-right: 1px solid #fff;
                padding: 0 5px;
                &:last-child {
                    border: none;
                }
                a {
                    padding: 0 5px;
                    color: #fff;
                }
            }
        }
    }
}

.author {
    display: inline-block;
    float: right;
    a {
        font-size: 13px;
    }
}

.blog_post_img h5 {
    display: block;
}

.blog-date {
    font-size: 18px;
    padding-bottom: 10px;
    display: block;
    i {
        margin-left: 5px;
        font-size: 14px;
        color: #0073aa;
    }
    .icon-calendar {
        font-size: 16px;
        margin-top: 5px;
    }
}

.day {
    color: #bbb;
    padding-left: 10px;
    font-size: 14px;
}

.btn-blog a {
    color: #fff !important;
}

.blog-single {
    h4 {
        margin-top: 20px;
        color: #0073aa;
        font-weight: 400;
    }
    p {
        margin: 20px 0;
        &:last-child {
            color: #777;
        }
    }
    .qutoe-text {
        font-size: 15px;
        color: #0073aa;
        border-left: 3px solid #0073aa;
        padding-left: 25px;
        font-weight: 400;
    }
    img:first-child {
        width: 100%;
    }
}

.blog-article .articles img {
    width: 100%;
}

.blog-big-user {
    h6,
    p {
        color: #757575;
    }
}

.blog-s-reply h6 {
    color: #0073aa;
}

.shares-like li {
    &:first-child,
    i {
        color: #777;
    }
}

.blog-tag li {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #ccc;
    margin-right: 15px;
    a,
    i {
        color: #777;
    }
    &:first-child {
        border: none;
        padding: 5px 0;
    }
    i {
        font-size: 20px;
        vertical-align: middle;
    }
}

.shares-like {
    li {
        display: inline-block;
        margin-right: 15px;
        font-size: 20px;
        &:first-child {
            font-size: 17px;
        }
        i {
            color: #fff;
        }
    }
    .btn-facebook,
    .btn-google-plus,
    .btn-linkedin,
    .btn-pinterest,
    .btn-twitter {
        color: #fff;
        padding: 10px 15px;
        display: inline-block;
    }
}

.btn-dribbble i,
.btn-dropbox i,
.btn-facebook i,
.btn-flickr i,
.btn-github i,
.btn-google-plus i,
.btn-instagram i,
.btn-linkedin i,
.btn-pinterest i,
.btn-skype i,
.btn-tumblr i,
.btn-twitter i,
.btn-youtube i {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 3px 0 0 3px;
    margin: -7px 0 -7px -13px;
}

.blog-article .articles {
    h6 {
        padding-top: 20px;
        font-weight: 400;
    }
    a {
        font-weight: 400;
        font-size: 15px;
        color: #0073aa;
        margin: 20px 0;
        display: block;
    }
}

.blog-s-reply h6 span {
    font-size: 12px;
    color: #777;
    margin-left: 5px;
}

.blog-u-comment {
    span {
        font-size: 14px;
    }
    .blog-edit a,
    .blog-reply a {
        margin-right: 10px;
        font-size: 12px;
    }
}

.system_font_color {
    font-size: 18px;
    color: #0073aa;
    a {
        color: #0073aa;
        margin-left: 5px;
    }
}

.port_detail_next_search {
    h5 {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #cccccc;
        display: block;
        font-weight: lighter;
    }
    a {
        color: #333;
        transition: all 0.3s 0s;
        i {
            float: right;
            margin-top: 6px;
        }
    }
}

.blog_detail_social_icon span {
    font-size: 18px;
    padding: 10px;
    border: 1px solid #999;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 10px;
    transition: all 0.5s ease;
    display: inline-block;
    margin-bottom: 10px;
    &:hover {
        background-color: #0073aa;
        color: #fff;
    }
}

.latest_blog h5 a {
    color: #333;
    font-weight: 600;
}


/*====== Blog css ends =======*/


/**  ===================== Gallery css start
==========================  **/

.gallery-page .card-body {
    margin-bottom: -20px;
}

.thumbnail {
    margin-bottom: 20px;
    .thumb {
        position: relative;
        display: block;
    }
}

.card.gallery-desc {
    box-shadow: 0 0 1px 2px rgba(128, 128, 128, 0.16);
}

.masonry-media {
    overflow: hidden;
    transition: all 0.5s;
    img:hover {
        transform: scale(1.1);
        overflow: hidden;
        transition: all 0.5s;
    }
}

.masonry-image .masonry-media {
    margin-bottom: 20px;
}

figure.effect-goliath {
    background: #0073aa;
}


/**====== Gallery css end ======**/


/**  ===================== List-chart css start
==========================  **/

.ct-series-a .ct-line {
    stroke: #1ce3bb;
}

.ct-series-b .ct-line {
    stroke: rgba(255, 157, 136, 0.62);
}

.ct-series-c .ct-line {
    stroke: rgba(79, 84, 103, 0.45);
}

.ct-series-d .ct-line {
    stroke: rgba(129, 142, 219, 0.61);
}

.ct-series-a .ct-point,
.ct-series-b .ct-point,
.ct-series-c .ct-point,
.ct-series-d .ct-point {
    stroke: rgba(52, 54, 70, 0.47);
    stroke-width: 8px;
    stroke-linecap: round;
}

.ct-series-a .ct-slice-donut {
    stroke: #01C0C8;
}

.ct-series-b .ct-slice-donut {
    stroke: #83D6DE;
}

.ct-series-c .ct-slice-donut {
    stroke: #1ABC9C;
}

.ct-series-d .ct-slice-donut {
    stroke: #4F5467;
}


/*===== List-chart Css End ====*/


/**  ===================== Task-list css start
==========================  **/

.task-list {
    select {
        width: 92%;
        .task-list img {
            margin-right: 5px;
            display: inline-block;
        }
    }
    input {
        width: 92%;
    }
}

.task-list-table img {
    width: 40px;
    i {
        color: #333;
        margin-right: 5px;
    }
}

.task-page {
    td:last-child {
        position: relative;
    }
    a {
        cursor: pointer;
    }
    tr td:last-child i {
        margin-right: 10px;
    }
}


/*===== Task-list css end ====*/


/**  ===================== Task-detiails css start
==========================  **/

.thumb-img {
    position: relative;
    display: block;
    &:hover .caption-hover {
        background-color: rgba(0, 0, 0, 0.7);
        visibility: visible;
        opacity: 1;
        filter: alpha(opacity=100);
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.caption-hover {
    top: 0;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    >span {
        top: 38%;
        width: 100%;
        position: absolute;
        text-align: center;
    }
}

.media .b-2-primary {
    border: 2px solid #0073aa;
}

.thumb-block {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    padding: 3px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    -webkit-transition: border 0.2s ease-in-out;
    -o-transition: border 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
    a {
        font-size: 12px;
    }
    i {
        margin-right: 0;
    }
}

.comment-block i {
    margin-right: 0;
}

.dropdown-item {
    color: #666;
    padding: 6px 20px;
    &.active,
    &:active {
        background-color: #0073aa;
    }
}

.task-detail-right .counter {
    text-align: center;
    color: #777;
}

.task-details .table.table-xs {
    td,
    th {
        padding: 1rem 0.3rem;
    }
}

.assign-user .media-left img {
    margin-bottom: 0;
}

.photo-table img {
    display: inline-block;
    width: 40px;
    margin-bottom: 5px;
}

.v-middle {
    vertical-align: middle;
}

.revision-block {
    .form-group {
        margin-bottom: 0;
    }
    i {
        margin-right: 0;
    }
}

.task-setting {
    .switchery {
        display: block !important;
        float: right;
    }
    .form-group {
        margin-bottom: 0;
    }
}

.task-attachment i {
    cursor: pointer;
}


/**====== Task-details css end ======**/


/**  ===================== Task-board css start
==========================  **/

.filter-bar {
    .nav,
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        display: inline-block;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .filter-bar a,
.filter-bar .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .filter-bar a,
.filter-bar .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .filter-bar a,
.filter-bar .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .filter-bar a {
    display: inline-block;
}

.filter-bar>.navbar {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
    padding: 0.5rem 1rem;
}

.navbar-nav {
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        float: left;
        line-height: 26px;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .navbar-nav a,
.navbar-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .navbar-nav a,
.navbar-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .navbar-nav a,
.navbar-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .navbar-nav a {
    float: left;
    line-height: 26px;
}

.nav-item button i {
    margin-right: 0;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 a button i,
    .tree-2 a button i,
    .tree-3 a button i,
    .tree-4 a button i {
        margin-right: 0;
    }
}

.filter-bar .navbar-light .navbar-nav .nav-link {
    margin-right: 10px;
}

.card-footer .task-list-table {
    display: inline-block;
    a img {
        display: inline-block;
    }
}

.task-board {
    margin-top: 10px;
    float: right;
    .dropdown {
        display: inline-block;
    }
}

p {
    &.task-detail {
        margin-bottom: 5px;
    }
    &.task-due {
        margin-bottom: 0;
    }
}

.task-right-header-revision,
.task-right-header-status,
.task-right-header-users {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #ccc;
}

.taskboard-right-progress,
.taskboard-right-revision,
.taskboard-right-users {
    margin-top: 10px;
}

.task-right {
    h6 {
        font-size: 12px;
    }
    .icofont {
        margin-top: 5px;
    }
}

.taskboard-right-revision .media .media-body .chat-header {
    font-size: 13px;
}

.media-left i {
    margin-right: 0;
}

.nav-item.nav-grid {
    float: right;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 a.nav-grid,
    .tree-2 a.nav-grid,
    .tree-3 a.nav-grid,
    .tree-4 a.nav-grid {
        float: right;
    }
}

.faq-progress .progress {
    position: relative;
    background-color: #eeeded;
    height: 10px;
    .faq-text1,
    .faq-text2,
    .faq-text3,
    .faq-text4,
    .faq-text5 {
        font-weight: 600;
        margin-right: -37px;
    }
    .faq-bar1,
    .faq-bar2,
    .faq-bar3,
    .faq-bar4,
    .faq-bar5 {
        background: #29aecc;
        height: 10px;
        border-radius: 0;
        position: absolute;
        top: 0;
    }
    .faq-bar1 {
        background-color: #f1c40f;
    }
    .faq-text1 {
        color: #2196F3;
    }
    .faq-bar2,
    .faq-bar5 {
        background-color: #2ecc71;
    }
    .faq-text2,
    .faq-text5 {
        color: #4CAF50;
    }
    .faq-bar3 {
        background-color: #e74c3c;
    }
    .faq-text3 {
        color: #ff5252;
    }
    .faq-bar4 {
        background-color: #0073aa;
    }
    .faq-text4 {
        color: #f57c00;
    }
}

.card-faq h4 {
    color: #2196F3;
}

.faq-progress .progress {
    margin-bottom: 10px;
}


/**====== Tsak-board css end ======**/


/**  ===================== Issue-list css start
==========================  **/

.issue-list-progress {
    border-bottom: 1px solid #ccc;
    .progress {
        position: relative;
        background-color: #eeeded;
        height: 9px;
        width: 100%;
        margin: 20px 0;
        overflow: visible;
    }
}

.issue-progress .progress {
    .issue-text1 {
        font-weight: 600;
        position: absolute;
    }
    .issue-bar1 {
        background: #0073aa;
        height: 10px;
        border-radius: 0;
        position: absolute;
        top: 0;
    }
}

.matrics-issue .sub-title {
    padding-top: 20px;
    padding-bottom: 10px;
    display: block;
}

.bg-white {
    background-color: #fff !important;
}

.matrics-issue div h6 {
    padding-top: 10px;
    color: #777;
}

table.matrics-table tr:first-child td {
    border-top: none !important;
}

#issue-list-table>thead>tr>th {
    border-bottom: none;
}


/**====== Issue-list css end ======**/


/**  ===================== Product css start
==========================  **/

.prod-img {
    position: relative;
}

.prod-item .prod-img .option-hover {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    left: 0;
}

.hvr-shrink {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.prod-img .p-new a {
    position: absolute;
    top: 15px;
    right: 0;
    padding: 8px 13px;
    line-height: 1;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 2px 0 0 2px;
    background: #3498DB;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
}

.prod-info {
    a {
        font-size: 18px;
    }
    .br-wrapper {
        margin: 0 auto 20px;
    }
    .br-widget {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
    }
    h6 {
        font-size: 18px;
        font-weight: 600;
    }
}

.prod-view:hover .option-hover {
    display: inline-block;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 1;
}

.prod-item {
    .prod-info {
        background: #fff;
        padding: 30px 0 20px;
    }
    .br-widget {
        min-height: inherit;
    }
}

.prod-img .p-sale {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 50%;
    background-color: #ff5252;
    color: #fff;
    font-weight: 800;
    letter-spacing: 1px;
    padding: 11px 4px;
}

.prod-info .br-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

.option-icon i {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 36px;
    margin: 30px 0;
    padding: 17px 5px;
}


/**====== Product css end ======**/


/**  ===================== Product-details css start
==========================  **/

.product-detail-page {
    border-top: none;
}

.product-detail {
    .br-wrapper {
        margin: 10px 0;
    }
    .product-detail .btn i {
        margin-right: 0;
    }
    .br-widget {
        min-height: 35px;
        margin-top: 0;
        display: block;
    }
    .btn-number {
        background-color: #e0e0e0;
        border-color: #d4d4d4;
        border-radius: 0;
        color: #000;
    }
    .product-price {
        display: inline-block;
        margin-right: 50px;
        font-size: 24px;
    }
    .pro-desc,
    hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

#small_banner {
    .slick-slide {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.5;
    }
    img {
        cursor: pointer;
    }
    .slick-center {
        filter: grayscale(0);
        opacity: 1;
    }
    &:hover {
        .slick-prev {
            left: 0;
            transition: all ease-in 0.3s;
        }
        .slick-next {
            right: 0;
            transition: all ease-in 0.3s;
        }
    }
}

.port_details_all_img {
    overflow: hidden;
}

.slick-prev {
    left: -45px;
    z-index: 1;
    height: 100%;
    background: rgba(0, 0, 0, 0.62);
    width: 4%;
    transition: all ease-in 0.3s;
    &:focus,
    &:hover {
        color: transparent;
        outline: none;
        background: rgba(0, 0, 0, 0.8);
    }
}

.slick-next {
    right: -45px;
    z-index: 1;
    height: 100%;
    background: rgba(0, 0, 0, 0.62);
    width: 4%;
    transition: all ease-in 0.3s;
    &:focus,
    &:hover {
        color: transparent;
        outline: none;
        background: rgba(0, 0, 0, 0.8);
    }
}

.counter-input .input-group {
    width: 20%;
}

.pro-det-tab .tab-content {
    border: 1px solid #ccc;
    border-top: 0;
}

.big_banner .port_big_img {
    margin-bottom: 15px;
}

.cd-price .cd-price-month {
    font-size: 64px;
}

.product-edit .br-wrapper {
    margin: 10px 0 30px;
}

.addcontact .md-content>div ul {
    padding-left: 0;
}


/**====== Product-details css end ======**/


/* ====================== Panels and wells css start
========================= */

.panel {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body p {
    overflow: hidden;
    margin-bottom: 0;
}

.panels-wells .panel {
    margin-bottom: 20px;
    .panel-body {
        padding: 15px;
    }
}

.panel-heading {
    padding: 10px 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-footer {
    padding: 10px 15px;
    background-color: #fafafa;
    border-top: 1px solid #eeeeee;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-sm {
    padding: 9px;
    border-radius: 3px;
}

.well-lg {
    padding: 24px;
    border-radius: 6px;
}


/**====== Panels and wells css end ======**/


/**  ===================== Search css start
==========================  **/

.search-result .card {
    border-top: none;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
}

.seacrh-header {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.search-content {
    img {
        width: 120px;
    }
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.search-result i {
    float: right;
}

.search2 .card-body:first-child {
    padding-top: 0;
}


/**====== Search css end ======**/


/**  ===================== Wizard css start
==========================  **/

.cart-page .wizard>.content>.body {
    width: 100%;
    padding: 0;
}

.wizard>.content>.body {
    width: 100%;
}

.payment-card {
    border: 1px solid #ccc;
    border-radius: 0;
    margin-bottom: 15px;
    padding: 20px;
    table {
        margin-bottom: 0;
    }
}

.confirmation {
    text-align: center;
    font-size: 80px;
}

.confirmation-icon {
    color: #e74c3c;
}

.width-100 {
    width: 100%;
}

.post-input {
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    resize: none;
}

.file-upload-lbl {
    max-width: 15px;
    padding: 5px 0 0;
}


/**====== Wizard css end ======**/


/**  ===================== Wall css start
==========================  **/

.post-new-footer {
    border-top: 1px solid #ccc;
    i {
        font-size: 18px;
        margin-right: 15px;
        cursor: pointer;
    }
}

.input-group {
    span {
        cursor: pointer;
    }
    a {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
    }
}

.msg-send {
    background-color: #f3f3f3;
    &:focus {
        background-color: #f3f3f3;
    }
}

.wall-dropdown:after {
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
}

.wall-img-preview {
    display: inline-block;
    text-align: center;
    .wall-item {
        display: block;
        float: left;
        position: relative;
        overflow: hidden;
        border: 2px solid #fff;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: center;
        &:first-child {
            &:nth-last-child(2) {
                width: 50%;
                ~div,
                ~div {
                    width: 50%;
                }
                width: 50%;
            }
            &:nth-last-child(10) {
                width: 33.333333%;
                ~div:not(:last-child) {
                    width: 33.333333%;
                }
            }
            &:nth-last-child(11)~div:nth-last-of-type(-n+3) {
                width: 33.333333%;
            }
            &:nth-last-child(3) {
                width: 33.333333%;
                ~div {
                    width: 33.333333%;
                }
            }
            &:nth-last-child(4) {
                width: 33.333333%;
                ~div:not(:last-child) {
                    width: 33.333333%;
                }
            }
            &:nth-last-child(5) {
                width: 33.333333%;
                ~div:not(:nth-last-of-type(-n+2)) {
                    width: 33.333333%;
                }
            }
            &:nth-last-child(6) {
                width: 33.333333%;
                ~div {
                    width: 33.333333%;
                }
            }
            &:nth-last-child(7)~div:nth-last-of-type(-n+3) {
                width: 33.333333%;
            }
            &:nth-last-child(9) {
                width: 33.333333%;
                ~div {
                    width: 33.333333%;
                }
            }
            &:nth-last-child(5)~div:nth-last-of-type(-n+2) {
                width: 50%;
            }
            &:nth-last-child(11) {
                width: 25%;
                ~div:not(:nth-last-of-type(-n+3)) {
                    width: 25%;
                }
            }
            &:nth-last-child(12) {
                width: 25%;
                ~div {
                    width: 25%;
                }
            }
            &:nth-last-child(7) {
                width: 25%;
                ~div:not(:nth-last-of-type(-n+3)) {
                    width: 25%;
                }
            }
            &:nth-last-child(8) {
                width: 25%;
                ~div {
                    width: 25%;
                }
            }
            &:nth-last-child(10)~div:nth-child(10),
            &:nth-last-child(4)~div:nth-child(4) {
                width: 100%;
            }
        }
        >img {
            display: none;
        }
        &:only-child {
            width: 100%;
        }
    }
}

.fb-timeliner {
    h2 {
        background: #0073aa;
        color: #fff;
        margin-top: 0;
        padding: 15px;
        font-size: 16px;
        border-radius: 2px;
        -webkit-border-radius: 4px;
        font-weight: 300;
    }
    ul {
        margin-left: 15px;
        margin-bottom: 20px;
        li {
            margin-bottom: 3px;
            a {
                color: #999797;
                border-left: 4px solid #d3d7dd;
                padding-left: 10px;
                padding-top: 3px;
                padding-bottom: 3px;
                display: block;
                &:hover {
                    color: #999797;
                    border-left: 4px solid #b1b1b1;
                    padding-left: 10px;
                }
            }
            &.active a {
                color: #7a7a7a;
                border-left: 4px solid #7a7a7a;
                padding-left: 10px;
            }
        }
    }
}


/*====== Wall css End ======*/


/**  ===================== X-editable css start
==========================  **/

.dotted-line-theme {
    .no_edit {
        width: 100% !important;
        .i_text {
            font-size: 13px;
        }
        padding: 15px 2px;
    }
    .just_edit input[type="radio"] {
        opacity: 0;
    }
    .ibtn_container {
        padding-left: 0;
        margin-top: 2px;
        position: absolute;
        top: 16px;
        z-index: 999;
        i {
            color: #fff;
        }
    }
}

#msform #progressbar li.active {
    color: #0073aa;
    &:before {
        background: #0073aa;
    }
}

#progressbar li.active:after {
    background: #0073aa;
}

#msform a {
    color: #0073aa;
    font-weight: 600;
}


/*====== X-editable css End ======*/


/**  ===================== Invoice css start
==========================  **/

.invoice-contact {
    display: flex;
    margin-bottom: 30px;
    padding-top: 30px;
    align-items: center;
}

.invoice-table {
    border-left: 1px solid #fff;
    padding-left: 20px;
    td,
    th {
        border-top: none;
        padding: 3px 0;
    }
    > {
        tbody>tr:last-child>td,
        tfoot>tr:last-child>td {
            padding-bottom: 0;
        }
    }
}

.invoice-box h1 {
    font-size: 7rem;
}

.invoice-order {
    padding-left: 0;
    th:first-child {
        padding-left: 0;
        width: 80px;
    }
}

.invoice-detail-table {
    th:first-child {
        width: 450px;
        text-align: left;
    }
    thead th {
        text-align: center;
    }
    td {
        vertical-align: middle;
        text-align: center;
        &:first-child {
            text-align: left;
        }
    }
}

.invoice-total {
    background: #f3f3f3;
    padding: 30px 0;
    td,
    th {
        text-align: right;
    }
    td {
        padding-left: 30px;
    }
}

.invoive-info {
    display: flex;
    margin-bottom: 30px;
    h6 {
        margin-bottom: 20px;
        text-transform: uppercase;
        font-weight: 600;
        color: #757575;
    }
}

.invoice-total tbody {
    padding-right: 20px;
    float: right;
}

.dropdown-menu {
    left: initial;
    right: 0;
    i {
        margin-right: 5px;
    }
}


/**====== Invoice css end ======**/

.grid-layout span {
    display: block;
    border: 1px solid #ddd;
    padding: 10px;
}

.vertical-alignment .row {
    padding: 15px 0;
    min-height: 10rem;
    border: 1px solid #ddd;
}

.inte-benifits {
    border: 1px solid #ddd;
    ul {
        list-style: square;
        padding-left: 20px;
    }
}


/**  ===================== Version css start
==========================  **/

.version .card-body {
    padding: 0;
}

.card.version .card-header .card-header-right {
    background-color: #fff;
    top: 8px;
}

.version {
    .nav {
        display: block;
        li {
            display: block;
            padding: 10px 20px;
            cursor: pointer;
            &:hover {
                background-color: #f6f7f7;
            }
            a {
                color: #666;
            }
            span {
                position: absolute;
                right: 20px;
            }
        }
    }
    .navigation-header a {
        color: #999;
    }
}

.support-btn {
    padding: 20px;
}

.version .nav li:first-child {
    border-top: 1px solid #ddd;
    color: #666;
}


/**====== Version css end ======**/


/**====== Date-dropper css ======**/

.dd-w,
.sp-container {
    z-index: 99;
}


/**  ===================== Theme-preloader css start
==========================  **/

.theme-loader {
    height: 100%;
    width: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 999999;
}

@keyframes ball-scale {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ball-scale {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.ball-scale {
    left: 50%;
    top: 50%;
    position: absolute;
    height: 100px;
    width: 100px;
    margin: -50px 0 0 -50px;
    >div {
        -webkit-animation: ball-scale 2s linear infinite;
        animation: ball-scale 2s linear infinite;
        border: 5px solid #0073aa;
        border-top-color: transparent;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        position: relative;
        &:before,
        &:after {
            -webkit-animation: ball-scale 1s linear infinite;
            animation: ball-scale 1s linear infinite;
            content: "";
            position: absolute;
            border: 5px solid #0073aa;
            border-top-color: transparent;
            border-radius: 50%;
        }
        &:before {
            height: 80%;
            width: 80%;
            top: 10%;
            left: 10%;
        }
        &:after {
            -webkit-animation: ball-scale 0.5s linear infinite;
            animation: ball-scale 0.5s linear infinite;
            height: 50%;
            width: 50%;
            top: 25%;
            left: 25%;
        }
    }
}


/**====== Theme-preloader css end ======**/


/**  ===================== Color css start
==========================  **/

.color-1 {
    background: #2C3E50;
}

.color-2 {
    background: #3498DB;
}

.color-3 {
    background: #0C7096;
}

.color-4 {
    background: #36C7C7;
}

.color-5 {
    background: #0F9DA5;
}

.color-6 {
    background: #FB6868;
}

.color-picker {
    position: fixed;
    right: 0;
    top: 150px;
    width: 239px;
    background: #fff;
    z-index: 999999;
    -webkit-transition: all 0.3s ease;
    a.handle {
        border: 1px solid #2C3E50;
        border-right-color: transparent;
        border-left-color: transparent;
        position: absolute;
        top: 0;
        right: 239px;
        width: 50px;
        height: 50px;
        text-align: center;
        background: #fff;
        z-index: 9999;
        transition: all ease-in 0.3s;
        &:hover {
            background: #4A6076;
            transition: all ease-in 0.3s;
            i {
                color: #fff;
                transition: all ease-in 0.3s;
            }
        }
        i {
            color: #2C3E50;
            font-size: 25px;
            line-height: 50px;
        }
    }
    .settings-header {
        background: #2C3E50;
        height: 50px;
        padding: 15px 34px 0;
        h3 {
            color: #fff;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 0;
            padding: 0 0 5px;
            margin-top: 0;
        }
    }
    .section {
        &:last-child {
            border-bottom: 1px solid #2c3e50;
        }
        padding: 20px 14px;
        border-bottom: 1px solid #2c3e50;
        border-left: 1px solid #2c3e50;
        overflow: hidden;
        h3 {
            font-size: 16px;
            text-transform: none;
            color: #3c3c3c;
            line-height: 16px;
            padding: 0;
            margin-bottom: 20px;
            text-align: left;
        }
        i {
            font-size: 16px;
            margin-right: 10px;
        }
    }
    span {
        font-size: 13px;
        color: #9a9a9a;
    }
    .colors a {
        display: block;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        margin-left: 0;
        margin-bottom: 10px;
        margin-right: 5px;
        float: left;
        transition: all ease-in 0.3s;
        &:hover {
            box-shadow: 0 0 2px 1px rgba(247, 54, 121, 0.58);
            transition: all ease-in 0.3s;
        }
    }
    .skin a {
        display: block;
        width: 70px;
        height: 30px;
        margin-left: 0;
        margin-top: 0;
        margin-right: 5px;
        float: left;
        text-align: center;
        line-height: 30px;
        border: 2px transparent solid;
        &.actt {
            border: 2px #FFF solid;
        }
    }
}


/*====== Color css ends ======= */


/**  ===================== Session Timeout css start
==========================  **/

#sessionTimeout-dialog .close {
    display: none;
}


/*====== Session Timeout css ends ======= */


/**  ===================== Generic-class css start
========================== **/

.generic-card-body code {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.generic-image-body {
    .tab-content li {
        padding: 0 20px;
        display: inline-block;
    }
    code {
        display: block;
    }
    img {
        display: block;
        margin: 10px auto 0;
    }
}


/*====== Padding css starts ======*/

.p-0 {
    padding: 0px;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.p-30 {
    padding: 30px;
}

.p-35 {
    padding: 35px;
}

.p-40 {
    padding: 40px;
}

.p-45 {
    padding: 45px;
}

.p-50 {
    padding: 50px;
}


/*====== Padding css ends ======*/


/*====== Padding-top css starts ======*/

.p-t-0 {
    padding-top: 0px !important;
}

.p-t-5 {
    padding-top: 5px !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-15 {
    padding-top: 15px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-25 {
    padding-top: 25px !important;
}

.p-t-30 {
    padding-top: 30px !important;
}

.p-t-35 {
    padding-top: 35px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-t-45 {
    padding-top: 45px !important;
}

.p-t-50 {
    padding-top: 50px !important;
}


/*====== Padding-top css ends ======*/


/*====== Padding-bottom css starts ======*/

.p-b-0 {
    padding-bottom: 0px !important;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-b-25 {
    padding-bottom: 25px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-b-35 {
    padding-bottom: 35px !important;
}

.p-b-40 {
    padding-bottom: 40px !important;
}

.p-b-45 {
    padding-bottom: 45px !important;
}

.p-b-50 {
    padding-bottom: 50px !important;
}


/*====== Padding-bottom css ends ======*/


/*====== Padding-left css starts ======*/

.p-l-0 {
    padding-left: 0px;
}

.p-l-5 {
    padding-left: 5px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-l-15 {
    padding-left: 15px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-25 {
    padding-left: 25px;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-35 {
    padding-left: 35px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-l-45 {
    padding-left: 45px;
}

.p-l-50 {
    padding-left: 50px;
}


/*====== Padding-left css ends ======*/


/*====== Padding-right css starts ======*/

.p-r-0 {
    padding-right: 0px;
}

.p-r-5 {
    padding-right: 5px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-15 {
    padding-right: 15px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-r-25 {
    padding-right: 25px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-35 {
    padding-right: 35px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-r-45 {
    padding-right: 45px;
}

.p-r-50 {
    padding-right: 50px;
}


/*====== Padding-right css ends ======*/


/*====== Margin css starts ======*/

.m-0 {
    margin: 0px;
}

.m-5 {
    margin: 5px;
}

.m-10 {
    margin: 10px;
}

.m-15 {
    margin: 15px;
}

.m-20 {
    margin: 20px;
}

.m-25 {
    margin: 25px;
}

.m-30 {
    margin: 30px;
}

.m-35 {
    margin: 35px;
}

.m-40 {
    margin: 40px;
}

.m-45 {
    margin: 45px;
}

.m-50 {
    margin: 50px;
}


/*====== Margin css ends ======*/


/*====== Margin-top css starts ======*/

.m-t-0 {
    margin-top: 0px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-35 {
    margin-top: 35px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-t-45 {
    margin-top: 45px;
}

.m-t-50 {
    margin-top: 50px;
}


/*====== Margin-top css ends ======*/


/*====== Margin-Bottom css starts ======*/

.m-b-0 {
    margin-bottom: 0px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-25 {
    margin-bottom: 25px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-35 {
    margin-bottom: 35px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-b-45 {
    margin-bottom: 45px;
}

.m-b-50 {
    margin-bottom: 50px;
}


/*====== Margin-Bottom css ends ======*/


/*====== Margin-left css starts ======*/

.m-l-0 {
    margin-left: 0px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-25 {
    margin-left: 25px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-35 {
    margin-left: 35px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-l-45 {
    margin-left: 45px;
}

.m-l-50 {
    margin-left: 50px;
}


/*====== Margin-left css ends ======*/


/*====== Margin-right css starts ======*/

.m-r-0 {
    margin-right: 0px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-25 {
    margin-right: 25px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-35 {
    margin-right: 35px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-r-45 {
    margin-right: 45px;
}

.m-r-50 {
    margin-right: 50px;
}


/*====== Margin-right css ends ======*/


/*====== Border-radius css starts ======*/

.b-radius-0 {
    border-radius: 0px !important;
}

.b-radius-5 {
    border-radius: 5px !important;
}

.b-radius-10 {
    border-radius: 10px !important;
}


/*====== Border-radius css ends ======*/


/*====== Font-size css starts ======*/

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;
}

.f-24 {
    font-size: 24px;
}

.f-26 {
    font-size: 26px;
}

.f-28 {
    font-size: 28px;
}

.f-30 {
    font-size: 30px;
}

.f-32 {
    font-size: 32px;
}

.f-34 {
    font-size: 34px;
}

.f-36 {
    font-size: 36px;
}

.f-38 {
    font-size: 38px;
}

.f-40 {
    font-size: 40px;
}

.f-42 {
    font-size: 42px;
}

.f-44 {
    font-size: 44px;
}

.f-46 {
    font-size: 46px;
}

.f-48 {
    font-size: 48px;
}

.f-50 {
    font-size: 50px;
}

.f-52 {
    font-size: 52px;
}

.f-54 {
    font-size: 54px;
}

.f-56 {
    font-size: 56px;
}

.f-58 {
    font-size: 58px;
}

.f-60 {
    font-size: 60px;
}

.f-62 {
    font-size: 62px;
}

.f-64 {
    font-size: 64px;
}

.f-66 {
    font-size: 66px;
}

.f-68 {
    font-size: 68px;
}

.f-70 {
    font-size: 70px;
}

.f-72 {
    font-size: 72px;
}

.f-74 {
    font-size: 74px;
}

.f-76 {
    font-size: 76px;
}

.f-78 {
    font-size: 78px;
}

.f-80 {
    font-size: 80px;
}


/*====== Font-size css ends ======*/


/*====== Font-weight css starts ======*/

.f-w-100 {
    font-weight: 100;
}

.f-w-300 {
    font-weight: 300;
}

.f-w-400 {
    font-weight: 400;
}

.f-w-600 {
    font-weight: 600;
}

.f-w-700 {
    font-weight: 700;
}

.f-w-900 {
    font-weight: 900;
}


/*====== Font-weight css ends ======*/


/*====== Font-style css starts ======*/

.f-s-normal {
    font-style: normal;
}

.f-s-italic {
    font-style: italic;
}

.f-s-oblique {
    font-style: oblique;
}

.f-s-initial {
    font-style: initial;
}

.f-s-inherit {
    font-style: inherit;
}


/*====== Font-style css ends ======*/


/*====== Text-align css starts ======*/

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}


/*====== Text-align css ends ======*/


/*====== Text-Transform css starts ======*/

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}


/*====== Text-Transform css ends ======*/


/*====== Text-Decoration css starts ======*/

.text-overline {
    text-decoration: overline;
}

.text-line-through {
    text-decoration: line-through;
}

.text-underline {
    text-decoration: underline;
}


/*====== Text-Decoration css ends ======*/


/*====== Vertical-Align css starts ======*/

.baseline {
    vertical-align: baseline;
}

.sub {
    vertical-align: sub;
}

.super {
    vertical-align: super;
}

.top {
    vertical-align: top;
}

.text-top {
    vertical-align: text-top;
}

.middle {
    vertical-align: middle;
}

.bottom {
    vertical-align: bottom;
}

.text-bottom {
    vertical-align: text-bottom;
}

.initial {
    vertical-align: initial;
}

.inherit {
    vertical-align: inherit;
}


/*====== Vertical-Align css ends ======*/


/*====== Position css starts ======*/

.pos-static {
    position: static;
}

.pos-absolute {
    position: absolute;
}

.pos-fixed {
    position: fixed;
}

.pos-relative {
    position: relative;
}

.pos-initial {
    position: initial;
}

.pos-inherit {
    position: inherit;
}


/*====== Position css ends ======*/


/*====== Float css starts ======*/

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.f-none {
    float: none;
}


/*====== Float css ends ======*/


/*====== Overflow css starts ======*/

.o-hidden {
    overflow: hidden;
}

.o-visible {
    overflow: visible;
}

.o-auto {
    overflow: auto;
}


/*====== Overflow css ends ======*/


/*====== Image-sizes css starts ======*/

.img-20 {
    width: 20px;
}

.img-30 {
    width: 30px;
}

.img-40 {
    width: 40px;
}

.img-50 {
    width: 50px;
}

.img-60 {
    width: 60px;
}

.img-70 {
    width: 70px;
}

.img-80 {
    width: 80px;
}

.img-90 {
    width: 90px;
}

.img-100 {
    width: 100px;
}


/*====== Image-sizes css ends ======*/


/*======= Text css starts ===========*/

.text-primary {
    color: #0073aa !important;
}

.text-warning {
    color: #f1c40f !important;
}

.text-default {
    color: #bdc3c7 !important;
}

.text-danger {
    color: #e74c3c !important;
}

.text-success {
    color: #2ecc71 !important;
}

.text-inverse {
    color: #34495e !important;
}

.text-info {
    color: #3498DB !important;
}

.text-custom {
    color: #64b0f2 !important;
}

.text-pink {
    color: #ff7aa3 !important;
}

.text-muted {
    color: #777 !important;
}

.text-dark {
    color: #2b3d51 !important;
}

.text-purple {
    color: #9261c6 !important;
}

.text-muted {
    color: #777 !important;
}


/*======= Text css ends ===========*/


/*======= Label-color css starts  ======= */

.label {
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize;
}

.label-primary {
    background-color: #0073aa;
}

.label-warning {
    background-color: #f1c40f;
}

.label-default {
    background-color: #bdc3c7;
}

.label-danger {
    background-color: #e74c3c;
}

.label-success {
    background-color: #2ecc71;
}

.label-inverse {
    background-color: #34495e;
}

.label-info {
    background-color: #3498DB;
}


/*======= Label-color css starts  ======= */


/*======= Badge-color css starts  ======= */

.badge-primary {
    background-color: #0073aa;
}

.badge-warning {
    background-color: #f1c40f;
}

.badge-default {
    background-color: #bdc3c7;
}

.badge-danger {
    background-color: #e74c3c;
}

.badge-success {
    background-color: #2ecc71;
}

.badge-inverse {
    background-color: #34495e;
}

.badge-info {
    background-color: #3498DB;
}


/*======= Badge-color css end  ======= */


/*======= Background-color css starts  ======= */

.bg-color-box {
    text-align: center;
    display: inline-block;
    padding: 10px 20px;
    box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
    font-weight: 600;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    span {
        color: #fff;
    }
}

.bg-primary {
    background-color: #0073aa !important;
    color: #fff;
}

.bg-warning {
    background-color: #f1c40f !important;
    color: #fff;
}

.bg-default {
    background-color: #bdc3c7 !important;
    color: #fff;
}

.bg-danger {
    background-color: #e74c3c !important;
    color: #fff;
}

.bg-success {
    background-color: #2ecc71 !important;
    color: #fff;
}

.bg-inverse {
    background-color: #34495e !important;
    color: #fff;
}

.bg-info {
    background-color: #3498DB !important;
    color: #fff;
}


/*======= Background-color css end  ======= */


/*======= Button-color css starts  ======= */

.color-primary {
    background-color: #0073aa;
}

.color-warning {
    background-color: #f1c40f;
}

.color-default {
    background-color: #bdc3c7;
}

.color-danger {
    background-color: #e74c3c;
}

.color-success {
    background-color: #2ecc71;
}

.color-inverse {
    background-color: #34495e;
}

.color-info {
    background-color: #3498DB;
}


/*======= Button-color css ends  ======= */


/*======= Loader-color css starts  ======= */

.loader-primary {
    background-color: #0073aa !important;
}

.loader-warning {
    background-color: #f1c40f !important;
}

.loader-default {
    background-color: #bdc3c7 !important;
}

.loader-danger {
    background-color: #e74c3c !important;
}

.loader-success {
    background-color: #2ecc71 !important;
}

.loader-inverse {
    background-color: #34495e !important;
}

.loader-info {
    background-color: #3498DB !important;
}


/*======= Loader-color css ends  ======= */


/*======= Nestable-color css starts  ======= */

.nestable-primary {
    background-color: #0073aa !important;
    border-color: #0073aa;
    color: #fff !important;
}

.nestable-warning {
    background-color: #f1c40f !important;
    border-color: #f1c40f;
    color: #fff !important;
}

.nestable-default {
    background-color: #bdc3c7 !important;
    border-color: #bdc3c7;
    color: #fff !important;
}

.nestable-danger {
    background-color: #e74c3c !important;
    border-color: #e74c3c;
    color: #fff !important;
}

.nestable-success {
    background-color: #2ecc71 !important;
    border-color: #2ecc71;
    color: #fff !important;
}

.nestable-inverse {
    background-color: #34495e !important;
    border-color: #34495e;
    color: #fff !important;
}

.nestable-info {
    background-color: #3498DB !important;
    border-color: #3498DB;
    color: #fff !important;
}


/*======= Nestable-color css ends  ======= */


/*======= Table-Border-Bottom-color css starts  ======= */

table {
    thead .border-bottom-primary th {
        border-bottom: 1px solid #0073aa;
    }
    tbody .border-bottom-primary {
        th,
        td {
            border-bottom: 1px solid #0073aa;
        }
    }
    thead .border-bottom-warning th {
        border-bottom: 1px solid #f1c40f;
    }
    tbody .border-bottom-warning {
        th,
        td {
            border-bottom: 1px solid #f1c40f;
        }
    }
    thead .border-bottom-default th {
        border-bottom: 1px solid #bdc3c7;
    }
    tbody .border-bottom-default {
        th,
        td {
            border-bottom: 1px solid #bdc3c7;
        }
    }
    thead .border-bottom-danger th {
        border-bottom: 1px solid #e74c3c;
    }
    tbody .border-bottom-danger {
        th,
        td {
            border-bottom: 1px solid #e74c3c;
        }
    }
    thead .border-bottom-success th {
        border-bottom: 1px solid #2ecc71;
    }
    tbody .border-bottom-success {
        th,
        td {
            border-bottom: 1px solid #2ecc71;
        }
    }
    thead .border-bottom-inverse th {
        border-bottom: 1px solid #34495e;
    }
    tbody .border-bottom-inverse {
        th,
        td {
            border-bottom: 1px solid #34495e;
        }
    }
    thead .border-bottom-info th {
        border-bottom: 1px solid #3498DB;
    }
    tbody .border-bottom-info {
        th,
        td {
            border-bottom: 1px solid #3498DB;
        }
    }
}


/*======= Table-Border-Bottom-color css ends  ======= */


/*======= Table styling css starts  ======= */

.table-styling {
    .table-primary,
    &.table-primary {
        background-color: #0073aa;
        color: #fff;
        border: 3px solid #0073aa;
    }
    .table-primary thead,
    &.table-primary thead {
        background-color: #005177;
        border: 3px solid #005177;
    }
    .table-warning,
    &.table-warning {
        background-color: #f1c40f;
        color: #fff;
        border: 3px solid #f1c40f;
    }
    .table-warning thead,
    &.table-warning thead {
        background-color: #c29d0b;
        border: 3px solid #c29d0b;
    }
    .table-default,
    &.table-default {
        background-color: #bdc3c7;
        color: #fff;
        border: 3px solid #bdc3c7;
    }
    .table-default thead,
    &.table-default thead {
        background-color: #a1aab0;
        border: 3px solid #a1aab0;
    }
    .table-danger,
    &.table-danger {
        background-color: #e74c3c;
        color: #fff;
        border: 3px solid #e74c3c;
    }
    .table-danger thead,
    &.table-danger thead {
        background-color: #d62c1a;
        border: 3px solid #d62c1a;
    }
    .table-success,
    &.table-success {
        background-color: #2ecc71;
        color: #fff;
        border: 3px solid #2ecc71;
    }
    .table-success thead,
    &.table-success thead {
        background-color: #25a25a;
        border: 3px solid #25a25a;
    }
    .table-inverse,
    &.table-inverse {
        background-color: #34495e;
        color: #fff;
        border: 3px solid #34495e;
    }
    .table-inverse thead,
    &.table-inverse thead {
        background-color: #22303d;
        border: 3px solid #22303d;
    }
    .table-info,
    &.table-info {
        background-color: #3498DB;
        color: #fff;
        border-top: 3px solid #3498DB;
        border-left: 3px solid #3498DB;
    }
    .table-info thead,
    &.table-info thead {
        background-color: #217dbb;
        border: 3px solid #217dbb;
    }
}


/*======= Table styling css ends  ======= */


/*======= Toolbar css starts  ======= */

.toolbar-primary {
    .tool-item {
        background: #0073aa !important;
        &.selected,
        &:hover {
            background: #005177 !important;
        }
    }
    &.tool-top .arrow {
        border-color: #0073aa transparent transparent;
    }
    &.tool-bottom .arrow {
        border-color: transparent transparent #0073aa;
    }
    &.tool-left .arrow {
        border-color: transparent transparent transparent #0073aa;
    }
    &.tool-right .arrow {
        border-color: transparent #0073aa transparent transparent;
    }
}

.btn-toolbar-primary.pressed {
    background-color: #0073aa;
}

.toolbar-warning {
    .tool-item {
        background: #f1c40f !important;
        &.selected,
        &:hover {
            background: #c29d0b !important;
        }
    }
    &.tool-top .arrow {
        border-color: #f1c40f transparent transparent;
    }
    &.tool-bottom .arrow {
        border-color: transparent transparent #f1c40f;
    }
    &.tool-left .arrow {
        border-color: transparent transparent transparent #f1c40f;
    }
    &.tool-right .arrow {
        border-color: transparent #f1c40f transparent transparent;
    }
}

.btn-toolbar-warning.pressed {
    background-color: #f1c40f;
}

.toolbar-light {
    .tool-item {
        background: #bdc3c7 !important;
        &.selected,
        &:hover {
            background: #a1aab0 !important;
        }
    }
    &.tool-top .arrow {
        border-color: #bdc3c7 transparent transparent;
    }
    &.tool-bottom .arrow {
        border-color: transparent transparent #bdc3c7;
    }
    &.tool-left .arrow {
        border-color: transparent transparent transparent #bdc3c7;
    }
    &.tool-right .arrow {
        border-color: transparent #bdc3c7 transparent transparent;
    }
}

.btn-toolbar-light.pressed {
    background-color: #bdc3c7;
}

.toolbar-danger {
    .tool-item {
        background: #e74c3c !important;
        &.selected,
        &:hover {
            background: #d62c1a !important;
        }
    }
    &.tool-top .arrow {
        border-color: #e74c3c transparent transparent;
    }
    &.tool-bottom .arrow {
        border-color: transparent transparent #e74c3c;
    }
    &.tool-left .arrow {
        border-color: transparent transparent transparent #e74c3c;
    }
    &.tool-right .arrow {
        border-color: transparent #e74c3c transparent transparent;
    }
}

.btn-toolbar-danger.pressed {
    background-color: #e74c3c;
}

.toolbar-success {
    .tool-item {
        background: #2ecc71 !important;
        &.selected,
        &:hover {
            background: #25a25a !important;
        }
    }
    &.tool-top .arrow {
        border-color: #2ecc71 transparent transparent;
    }
    &.tool-bottom .arrow {
        border-color: transparent transparent #2ecc71;
    }
    &.tool-left .arrow {
        border-color: transparent transparent transparent #2ecc71;
    }
    &.tool-right .arrow {
        border-color: transparent #2ecc71 transparent transparent;
    }
}

.btn-toolbar-success.pressed {
    background-color: #2ecc71;
}

.toolbar-dark {
    .tool-item {
        background: #34495e !important;
        &.selected,
        &:hover {
            background: #22303d !important;
        }
    }
    &.tool-top .arrow {
        border-color: #34495e transparent transparent;
    }
    &.tool-bottom .arrow {
        border-color: transparent transparent #34495e;
    }
    &.tool-left .arrow {
        border-color: transparent transparent transparent #34495e;
    }
    &.tool-right .arrow {
        border-color: transparent #34495e transparent transparent;
    }
}

.btn-toolbar-dark.pressed {
    background-color: #34495e;
}

.toolbar-info {
    .tool-item {
        background: #3498DB !important;
        &.selected,
        &:hover {
            background: #217dbb !important;
        }
    }
    &.tool-top .arrow {
        border-color: #3498DB transparent transparent;
    }
    &.tool-bottom .arrow {
        border-color: transparent transparent #3498DB;
    }
    &.tool-left .arrow {
        border-color: transparent transparent transparent #3498DB;
    }
    &.tool-right .arrow {
        border-color: transparent #3498DB transparent transparent;
    }
}

.btn-toolbar-info.pressed {
    background-color: #3498DB;
}


/*======= Toolbar css ends  ======= */


/*======= Card-Border-Top-color css starts  ======= */

.card-border-primary {
    border-top: 4px solid #0073aa;
}

.card-border-warning {
    border-top: 4px solid #f1c40f;
}

.card-border-default {
    border-top: 4px solid #bdc3c7;
}

.card-border-danger {
    border-top: 4px solid #e74c3c;
}

.card-border-success {
    border-top: 4px solid #2ecc71;
}

.card-border-inverse {
    border-top: 4px solid #34495e;
}

.card-border-info {
    border-top: 4px solid #3498DB;
}


/*======= Card-Border-Top-color css ends  ======= */


/*======= Panels-wells-color css starts  ======= */

.panels-wells {
    .panel-primary {
        border-color: #0073aa;
    }
    .panel-warning {
        border-color: #f1c40f;
    }
    .panel-default {
        border-color: #bdc3c7;
    }
    .panel-danger {
        border-color: #e74c3c;
    }
    .panel-success {
        border-color: #2ecc71;
    }
    .panel-inverse {
        border-color: #34495e;
    }
    .panel-info {
        border-color: #3498DB;
    }
}


/*======= Panels-wells-color css ends  ======= */


/*======= All-Borders-color css starts  ======= */

.b-t-primary {
    border-top: 1px solid #0073aa;
}

.b-b-primary {
    border-bottom: 1px solid #0073aa;
}

.b-l-primary {
    border-left: 1px solid #0073aa;
}

.b-r-primary {
    border-right: 1px solid #0073aa;
}

.b-t-warning {
    border-top: 1px solid #f1c40f;
}

.b-b-warning {
    border-bottom: 1px solid #f1c40f;
}

.b-l-warning {
    border-left: 1px solid #f1c40f;
}

.b-r-warning {
    border-right: 1px solid #f1c40f;
}

.b-t-default {
    border-top: 1px solid #bdc3c7;
}

.b-b-default {
    border-bottom: 1px solid #bdc3c7;
}

.b-l-default {
    border-left: 1px solid #bdc3c7;
}

.b-r-default {
    border-right: 1px solid #bdc3c7;
}

.b-t-danger {
    border-top: 1px solid #e74c3c;
}

.b-b-danger {
    border-bottom: 1px solid #e74c3c;
}

.b-l-danger {
    border-left: 1px solid #e74c3c;
}

.b-r-danger {
    border-right: 1px solid #e74c3c;
}

.b-t-success {
    border-top: 1px solid #2ecc71;
}

.b-b-success {
    border-bottom: 1px solid #2ecc71;
}

.b-l-success {
    border-left: 1px solid #2ecc71;
}

.b-r-success {
    border-right: 1px solid #2ecc71;
}

.b-t-inverse {
    border-top: 1px solid #34495e;
}

.b-b-inverse {
    border-bottom: 1px solid #34495e;
}

.b-l-inverse {
    border-left: 1px solid #34495e;
}

.b-r-inverse {
    border-right: 1px solid #34495e;
}

.b-t-info {
    border-top: 1px solid #3498DB;
}

.b-b-info {
    border-bottom: 1px solid #3498DB;
}

.b-l-info {
    border-left: 1px solid #3498DB;
}

.b-r-info {
    border-right: 1px solid #3498DB;
}

.b-t-theme {
    border-top: 1px solid #ddd;
}

.b-b-theme {
    border-bottom: 1px solid #ddd;
}

.b-l-theme {
    border-left: 1px solid #ddd;
}

.b-r-theme {
    border-right: 1px solid #ddd;
}


/*======= All-Borders-color css ends  ======= */


/**====== Generic-class css end ======**/


/**  ===================== Main-header css start
==========================  **/

.main-header {
    margin-bottom: 20px;
    padding: 20px 0 20px 20px;
    span {
        color: #9f9f9f;
        display: block;
        font-size: 13px;
        margin-top: 5px;
    }
}

.header-navbar {
    min-height: 4em;
    padding: 0;
    .navbar-wrapper {
        background-color: #2C3E50;
        .navbar-logo {
            align-items: center;
            display: flex;
            float: left;
            height: 3.5rem;
            justify-content: center;
            text-align: center;
            text-transform: uppercase;
            width: 235px;
            h5 {
                margin-bottom: 0;
            }
            a {
                color: #fff;
            }
            .mobile-menu,
            .mobile-search,
            .mobile-options {
                display: none;
            }
        }
        .navbar-container {
            margin-left: 240px;
            .nav-left,
            .nav-right {
                float: left;
                margin-bottom: 0;
            }
            .nav-left li,
            .nav-right li {
                float: left;
                line-height: 4;
                padding: 0 10px;
                position: relative;
            }
            .nav-left a,
            .nav-right a {
                color: #000;
                padding: 1.5rem .6rem;
            }
            .nav-left .mega-menu-top i {
                font-size: 10px;
                margin-left: 10px;
            }
            .nav-right {
                .mega-menu-top i {
                    font-size: 10px;
                    margin-left: 10px;
                }
                float: right;
                .user-profile {
                    img {
                        margin-right: 10px;
                        width: 40px;
                    }
                    i {
                        font-size: 10px;
                        margin-left: 10px;
                    }
                }
            }
            .header-notification,
            .mega-menu-top {
                perspective: 1000px;
                z-index: 99;
            }
            .header-notification .badge,
            .mega-menu-top .badge {
                background: red;
                border-radius: 100px;
                left: 27px;
                position: absolute;
                top: 10px;
            }
            .header-notification .show-notification,
            .mega-menu-top .show-notification,
            .header-notification .profile-notification,
            .mega-menu-top .profile-notification {
                -webkit-transform: translateY(30px);
                background: #fff;
                box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.03), 0 -2px 1px -2px rgba(0, 0, 0, 0.02), 0 0 0 -1px rgba(0, 0, 0, 0.03);
                opacity: 0;
                position: absolute;
                right: 0;
                transform: translateY(30px);
                transition: all linear 0.3s;
                visibility: hidden;
                width: 24rem;
            }
            .header-notification .show-notification a,
            .mega-menu-top .show-notification a,
            .header-notification .profile-notification a,
            .mega-menu-top .profile-notification a {
                color: #666;
            }
            .header-notification .show-notification li,
            .mega-menu-top .show-notification li,
            .header-notification .profile-notification li,
            .mega-menu-top .profile-notification li {
                border-top: 1px solid #fff;
                line-height: initial;
                padding: 1em 20px;
                width: 100%;
            }
            .header-notification .show-notification li:first-child,
            .mega-menu-top .show-notification li:first-child,
            .header-notification .profile-notification li:first-child,
            .mega-menu-top .profile-notification li:first-child {
                padding: 10px 20px;
            }
            .header-notification .show-notification li:first-child h6,
            .mega-menu-top .show-notification li:first-child h6,
            .header-notification .profile-notification li:first-child h6,
            .mega-menu-top .profile-notification li:first-child h6 {
                display: inline-block;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
                text-transform: uppercase;
            }
            .header-notification .show-notification li:first-child label,
            .mega-menu-top .show-notification li:first-child label,
            .header-notification .profile-notification li:first-child label,
            .mega-menu-top .profile-notification li:first-child label {
                float: right;
            }
            .header-notification .show-notification li img,
            .mega-menu-top .show-notification li img,
            .header-notification .profile-notification li img,
            .mega-menu-top .profile-notification li img {
                width: 40px;
                margin-right: 10px;
            }
            .header-notification .show-notification .notification-user,
            .mega-menu-top .show-notification .notification-user,
            .header-notification .profile-notification .notification-user,
            .mega-menu-top .profile-notification .notification-user {
                font-size: 15px;
                font-weight: bold;
            }
            .header-notification .show-notification .notification-msg,
            .mega-menu-top .show-notification .notification-msg,
            .header-notification .profile-notification .notification-msg,
            .mega-menu-top .profile-notification .notification-msg {
                margin-bottom: 5px;
            }
            .header-notification .show-notification .notification-time,
            .mega-menu-top .show-notification .notification-time,
            .header-notification .profile-notification .notification-time,
            .mega-menu-top .profile-notification .notification-time {
                font-size: 12px;
            }
            .header-notification .profile-notification,
            .mega-menu-top .profile-notification {
                width: 17em;
            }
            .header-notification .profile-notification a,
            .mega-menu-top .profile-notification a {
                font-size: 15px;
                padding-left: 0;
            }
            .header-notification .profile-notification i,
            .mega-menu-top .profile-notification i {
                font-size: 15px;
                margin-left: 0;
                margin-right: 5px;
            }
            .header-notification .profile-notification li,
            .mega-menu-top .profile-notification li {
                border-top: none;
                padding: .7em 20px;
            }
            .header-notification .profile-notification li:first-child,
            .mega-menu-top .profile-notification li:first-child {
                padding: .7em 20px;
            }
            .header-notification:hover .show-notification,
            .mega-menu-top:hover .show-notification,
            .header-notification:hover .profile-notification,
            .mega-menu-top:hover .profile-notification {
                -webkit-transform: translateY(0px);
                opacity: 1;
                transform: translateY(0px);
                visibility: visible;
            }
            .lng-dropdown {
                .show-notification,
                .header-notification .profile-notification {
                    width: 14em;
                }
            }
            .header-notification .lng-dropdown .profile-notification,
            .lng-dropdown .mega-menu-top .profile-notification {
                width: 14em;
            }
            .mega-menu-top {
                .lng-dropdown .profile-notification {
                    width: 14em;
                }
                perspective: inherit;
                position: static !important;
                .show-notification,
                .profile-notification {
                    left: 0;
                    margin: 0 25px;
                    width: calc(100% - 50px);
                    z-index: 9999;
                }
                .show-notification li,
                .profile-notification li,
                .show-notification li:first-child,
                .profile-notification li:first-child {
                    padding: 20px;
                }
                .show-notification li:first-child h6,
                .profile-notification li:first-child h6 {
                    border-bottom: 1px solid #0073aa, #f1c40f, #bdc3c7, #e74c3c, #2ecc71, #34495e, #3498DB;
                    display: block;
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                }
                .show-notification li h6,
                .profile-notification li h6 {
                    border-bottom: 1px solid #ddd;
                    display: block;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                    text-transform: uppercase;
                }
                .show-notification li img,
                .profile-notification li img {
                    border-radius: 0;
                    transition: all ease-in-out .3s;
                    width: auto;
                }
                .show-notification li img:hover,
                .profile-notification li img:hover {
                    box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.37);
                }
                .show-notification .mega-mailbox li,
                .profile-notification .mega-mailbox li {
                    border-top: none;
                    padding-left: 0;
                    padding-top: 0;
                }
                .show-notification .mega-mailbox li a,
                .profile-notification .mega-mailbox li a {
                    padding: 0;
                }
                .show-notification .mega-mailbox li a i,
                .profile-notification .mega-mailbox li a i {
                    font-size: 20px;
                    margin-left: 0;
                    margin-right: 15px;
                }
                .show-notification .mega-mailbox li h5,
                .profile-notification .mega-mailbox li h5 {
                    font-size: 15px;
                    font-weight: 600;
                }
                .mega-menu-links {
                    li {
                        border-top: none;
                        padding: 10px 0;
                        &:first-child {
                            padding-top: 0;
                            padding: 10px 0;
                        }
                    }
                    a {
                        display: block;
                        font-size: 14px;
                        padding: 0;
                        text-transform: capitalize;
                    }
                }
                .mega-menu-contact {
                    label,
                    .form-control {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


/*======= Main-header css end ======*/


/**  ===================== Left-side-menu css start
==========================  **/

.main-menu {
    background: #2c3e50;
    float: left;
    width: 235px;
    z-index: 99;
    .main-menu-header {
        align-items: center;
        background: #4a6076;
        display: flex;
        padding: 20px;
        .user-details {
            display: inline-block;
            margin-left: 10px;
            #more-details {
                cursor: pointer;
            }
            span {
                color: #fff;
                display: block;
                font-size: 14px;
                text-transform: capitalize;
                i {
                    font-size: 10px;
                    margin-left: 10px;
                }
                &:first-child {
                    margin-bottom: 5px;
                }
            }
        }
    }
    .main-menu-content {
        .more-details {
            display: none;
            a {
                padding: 10px 15px;
                display: block;
                color: #fff;
                transition: all ease-in .3s;
                &:hover {
                    color: #0073aa;
                }
                i {
                    margin-right: 10px;
                }
            }
        }
        .menu-search {
            padding: 20px;
            padding-bottom: 10px;
        }
        li {
            position: relative;
        }
        .nav-title {
            color: #2ed8b6;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 1px;
            padding: 10px 5px;
            text-transform: uppercase;
            i {
                font-weight: 800;
            }
            .menu-caption {
                right: 10px;
            }
        }
        .nav-item {
            &.has-class>a,
            .tree-1 a.has-class>a,
            .tree-2 a.has-class>a,
            .tree-3 a.has-class>a,
            .tree-4 a.has-class>a {
                background: #1ABC9C;
            }
            &.has-class>a:hover,
            .tree-1 a.has-class>a:hover,
            .tree-2 a.has-class>a:hover,
            .tree-3 a.has-class>a:hover,
            .tree-4 a.has-class>a:hover {
                color: #fff;
            }
        }
        .tree-1.open li .menu-caption {
            right: 10px;
        }
        .nav-item .open {
            &.tree-2 li .menu-caption,
            &.tree-3 li .menu-caption,
            &.tree-4 li .menu-caption {
                right: 10px;
            }
        }
        .tree-1.open .nav-sub-item>a {
            background: #34495E;
        }
        .nav-item .open {
            &.tree-2 .nav-sub-item>a,
            &.tree-3 .nav-sub-item>a,
            &.tree-4 .nav-sub-item>a {
                background: #34495E;
            }
        }
        .tree-1.open .has-class>a {
            color: #1ABC9C;
            font-weight: 600;
        }
        .nav-item .open {
            &.tree-2 .has-class>a,
            &.tree-3 .has-class>a,
            &.tree-4 .has-class>a {
                color: #1ABC9C;
                font-weight: 600;
            }
        }
        .tree-2.open .has-class>a,
        .tree-3.open .has-class>a,
        .tree-4.open .has-class>a {
            color: #1ABC9C;
            font-weight: 600;
        }
        .nav-item {
            color: #fff;
            .tree-1 a,
            .tree-2 a,
            .tree-3 a,
            .tree-4 a {
                color: #fff;
            }
            a,
            .tree-1 a a,
            .tree-2 a a,
            .tree-3 a a,
            .tree-4 a a {
                color: #fff;
                display: block;
                font-size: 14px;
                padding: 10px 15px;
                transition: all ease-in .2s;
            }
            a:hover {
                color: #1ABC9C;
            }
            i,
            .tree-1 a i,
            .tree-2 a i,
            .tree-3 a i,
            .tree-4 a i {
                margin-right: 10px;
                font-size: 15px;
                vertical-align: middle;
            }
            .icon-arrow-down,
            .tree-1 a .icon-arrow-down,
            .tree-2 a .icon-arrow-down,
            .tree-3 a .icon-arrow-down,
            .tree-4 a .icon-arrow-down {
                display: none;
            }
            .tree-1 a,
            .tree-2 a,
            .tree-3 a,
            .tree-4 a {
                padding-left: 50px;
                position: relative;
            }
            .tree-1 a:before,
            .tree-2 a:before,
            .tree-3 a:before,
            .tree-4 a:before {
                content: '\e649';
                font-family: 'themify';
                position: absolute;
                left: 30px;
                font-size: 10px;
                top: 13px;
            }
            .tree-1 .icon-arrow-right,
            .tree-2 .icon-arrow-right,
            .tree-3 .icon-arrow-right,
            .tree-4 .icon-arrow-right {
                display: none;
            }
            .tree-2 a {
                padding-left: 60px;
                &:before {
                    left: 40px;
                }
            }
            .tree-3 a {
                padding-left: 80px;
                &:before {
                    left: 60px;
                }
            }
            .tree-4 a {
                padding-left: 100px;
                &:before {
                    left: 80px;
                }
            }
        }
    }
}

.main-navigation {
    a.nav-link.disabled {
        color: #a2a2a2 !important;
    }
    .nav-title span {
        padding-left: 5px;
    }
    .nav-item.single-item>a:after,
    .main-menu .main-menu-content .nav-item .tree-1 a.single-item>a:after {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.single-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.single-item>a:after,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.single-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.single-item>a:after,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.single-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.single-item>a:after,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.single-item>a:after {
    display: none;
}

.main-navigation {
    .nav-item.single-item .menu-caption,
    .main-menu .main-menu-content .nav-item .tree-1 a.single-item .menu-caption {
        right: 10px;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.single-item .menu-caption,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.single-item .menu-caption,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.single-item .menu-caption,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.single-item .menu-caption {
    right: 10px;
}

.main-navigation {
    .menu-caption {
        position: absolute;
        right: 40px;
        top: 14px;
    }
    .nav-item>a,
    .main-menu .main-menu-content .nav-item .tree-1 a>a {
        position: relative;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a>a,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a>a,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a>a,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a>a {
    position: relative;
}

.main-navigation {
    .nav-sub-item>a,
    .nav-sub-item-3>a,
    .nav-sub-item-4>a {
        position: relative;
    }
    .nav-item>a:after,
    .main-menu .main-menu-content .nav-item .tree-1 a>a:after {
        content: '\e61a';
        font-family: 'themify';
        font-size: 14px;
        position: absolute;
        right: 20px;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a>a:after {
    content: '\e61a';
    font-family: 'themify';
    font-size: 14px;
    position: absolute;
    right: 20px;
}

.main-navigation {
    .nav-sub-item>a:after,
    .nav-sub-item-3>a:after,
    .nav-sub-item-4>a:after {
        content: '\e61a';
        font-family: 'themify';
        font-size: 14px;
        position: absolute;
        right: 20px;
    }
    .nav-item.has-class>a:after,
    .main-menu .main-menu-content .nav-item .tree-1 a.has-class>a:after {
        content: '\e622';
        font-family: 'themify';
        font-size: 14px;
        position: absolute;
        right: 20px;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class>a:after,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class>a:after,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class>a:after,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class>a:after {
    content: '\e622';
    font-family: 'themify';
    font-size: 14px;
    position: absolute;
    right: 20px;
}

.main-navigation {
    .nav-sub-item.has-class>a:after,
    .nav-sub-item-3.has-class>a:after,
    .nav-sub-item-4.has-class>a:after {
        content: '\e622';
        font-family: 'themify';
        font-size: 14px;
        position: absolute;
        right: 20px;
    }
    .nav-item .tree-1,
    .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 {
    display: none;
}

.main-menu .main-menu-content {
    .main-navigation .nav-item .tree-2 {
        display: none;
    }
    .nav-item {
        .tree-2 .main-navigation a .tree-2,
        .tree-3 .main-navigation a .tree-2,
        .tree-4 .main-navigation a .tree-2,
        .tree-1 .main-navigation a .tree-2 {
            display: none;
        }
    }
}

.main-navigation .main-menu .main-menu-content .nav-item .tree-3 {
    display: none;
}

.main-menu .main-menu-content {
    .main-navigation .nav-item .tree-3 {
        display: none;
    }
    .nav-item {
        .tree-2 .main-navigation a .tree-3,
        .tree-3 .main-navigation a .tree-3,
        .tree-4 .main-navigation a .tree-3,
        .tree-1 .main-navigation a .tree-3 {
            display: none;
        }
    }
}

.main-navigation .main-menu .main-menu-content .nav-item .tree-4 {
    display: none;
}

.main-menu .main-menu-content {
    .main-navigation .nav-item .tree-4 {
        display: none;
    }
    .nav-item {
        .tree-2 .main-navigation a .tree-4,
        .tree-3 .main-navigation a .tree-4,
        .tree-4 .main-navigation a .tree-4,
        .tree-1 .main-navigation a .tree-4 {
            display: none;
        }
    }
}

.main-navigation {
    .nav-item .tree-2,
    .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-2 {
    display: none;
}

.main-navigation {
    .nav-item .tree-3,
    .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-3 {
    display: none;
}

.main-navigation {
    .nav-item .tree-4,
    .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-4 {
    display: none;
}

.main-navigation .nav-sub-item {
    .tree-1,
    .main-menu .main-menu-content .nav-item .tree-2 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item .tree-2,
.main-navigation .nav-sub-item .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item .tree-3,
.main-navigation .nav-sub-item .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item .tree-4 {
    display: none;
}

.main-navigation {
    .nav-sub-item {
        .tree-2,
        .tree-3,
        .tree-4 {
            display: none;
        }
    }
    .nav-sub-item-3 {
        .tree-1,
        .main-menu .main-menu-content .nav-item .tree-2 {
            display: none;
        }
    }
}

.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3 .tree-2,
.main-navigation .nav-sub-item-3 .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3 .tree-3,
.main-navigation .nav-sub-item-3 .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3 .tree-4 {
    display: none;
}

.main-navigation {
    .nav-sub-item-3 {
        .tree-2,
        .tree-3,
        .tree-4 {
            display: none;
        }
    }
    .nav-sub-item-4 {
        .tree-1,
        .main-menu .main-menu-content .nav-item .tree-2 {
            display: none;
        }
    }
}

.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4 .tree-2,
.main-navigation .nav-sub-item-4 .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4 .tree-3,
.main-navigation .nav-sub-item-4 .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4 .tree-4 {
    display: none;
}

.main-navigation {
    .nav-sub-item-4 {
        .tree-2,
        .tree-3,
        .tree-4 {
            display: none;
        }
    }
    .nav-item.has-class .tree-1.open,
    .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-4 {
    display: block;
}

.main-navigation {
    .nav-item.has-class .tree-2.open,
    .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-2.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-2.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-2.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-2.open {
    display: block;
}

.main-navigation {
    .nav-item.has-class .tree-3.open,
    .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-3.open {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-3.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-3.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-3.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-3.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-3.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-3.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-3.open {
    display: block;
}

.main-navigation {
    .nav-item.has-class .tree-4.open,
    .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-4.open {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-4.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-4.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-4.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-4.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-4.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-4.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-4.open {
    display: block;
}

.main-navigation .nav-sub-item.has-class {
    .tree-1.open,
    .main-menu .main-menu-content .nav-item .open.tree-2 {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item.has-class .open.tree-2,
.main-navigation .nav-sub-item.has-class .main-menu .main-menu-content .nav-item .open.tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item.has-class .open.tree-3,
.main-navigation .nav-sub-item.has-class .main-menu .main-menu-content .nav-item .open.tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item.has-class .open.tree-4 {
    display: block;
}

.main-navigation {
    .nav-sub-item.has-class {
        .tree-2.open,
        .tree-3.open,
        .tree-4.open {
            display: block;
        }
    }
    .nav-sub-item-3.has-class {
        .tree-1.open,
        .main-menu .main-menu-content .nav-item .open.tree-2 {
            display: block;
        }
    }
}

.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3.has-class .open.tree-2,
.main-navigation .nav-sub-item-3.has-class .main-menu .main-menu-content .nav-item .open.tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3.has-class .open.tree-3,
.main-navigation .nav-sub-item-3.has-class .main-menu .main-menu-content .nav-item .open.tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3.has-class .open.tree-4 {
    display: block;
}

.main-navigation {
    .nav-sub-item-3.has-class {
        .tree-2.open,
        .tree-3.open,
        .tree-4.open {
            display: block;
        }
    }
    .nav-sub-item-4.has-class {
        .tree-1.open,
        .main-menu .main-menu-content .nav-item .open.tree-2 {
            display: block;
        }
    }
}

.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4.has-class .open.tree-2,
.main-navigation .nav-sub-item-4.has-class .main-menu .main-menu-content .nav-item .open.tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4.has-class .open.tree-3,
.main-navigation .nav-sub-item-4.has-class .main-menu .main-menu-content .nav-item .open.tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4.has-class .open.tree-4 {
    display: block;
}

.main-navigation .nav-sub-item-4.has-class {
    .tree-2.open,
    .tree-3.open,
    .tree-4.open {
        display: block;
    }
}


/**====== Collapse-menu css start ======**/

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] {
    .main-menu-header,
    .main-menu-content {
        display: none;
    }
    .main-menu {
        width: 45px;
    }
}

.sidebar_toggle a {
    margin: -5px;
    line-height: 1;
}

body.menu-collapsed {
    label {
        display: none;
    }
    .slimScrollDiv,
    .main-menu-content {
        overflow: visible !important;
    }
    .main-menu-header,
    .main-navigation .nav-item .tree-1 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-collapsed .main-navigation a .tree-1,
    .tree-2 body.menu-collapsed .main-navigation a .tree-1,
    .tree-3 body.menu-collapsed .main-navigation a .tree-1,
    .tree-4 body.menu-collapsed .main-navigation a .tree-1 {
        display: none;
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 {
    display: none;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-2 {
        display: none;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-2,
        .tree-3 body.menu-collapsed .main-navigation a .tree-2,
        .tree-4 body.menu-collapsed .main-navigation a .tree-2,
        .tree-1 body.menu-collapsed .main-navigation a .tree-2 {
            display: none;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 {
    display: none;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-3 {
        display: none;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-3,
        .tree-3 body.menu-collapsed .main-navigation a .tree-3,
        .tree-4 body.menu-collapsed .main-navigation a .tree-3,
        .tree-1 body.menu-collapsed .main-navigation a .tree-3 {
            display: none;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 {
    display: none;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-4 {
        display: none;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-4,
        .tree-3 body.menu-collapsed .main-navigation a .tree-4,
        .tree-4 body.menu-collapsed .main-navigation a .tree-4,
        .tree-1 body.menu-collapsed .main-navigation a .tree-4 {
            display: none;
        }
    }
}

body.menu-collapsed {
    .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-2 {
            display: none;
        }
        .nav-item {
            .tree-1 .main-navigation a .tree-2,
            .tree-2 .main-navigation a .tree-2,
            .tree-3 .main-navigation a .tree-2,
            .tree-4 .main-navigation a .tree-2 {
                display: none;
            }
        }
    }
    .main-navigation .nav-item .tree-2 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-collapsed .main-navigation a .tree-2,
    .tree-2 body.menu-collapsed .main-navigation a .tree-2,
    .tree-3 body.menu-collapsed .main-navigation a .tree-2,
    .tree-4 body.menu-collapsed .main-navigation a .tree-2 {
        display: none;
    }
}

body.menu-collapsed {
    .nav-title span,
    .nav-item span,
    .main-menu .main-menu-content .nav-item .tree-1 a span {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed a span,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-2 a span,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed a span,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-3 a span,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed a span,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-4 a span,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed a span {
    display: none;
}

body.menu-collapsed {
    .nav-item>a i.icon-arrow-down,
    .main-menu .main-menu-content .nav-item .tree-1 a>a i.icon-arrow-down {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed a>a i.icon-arrow-down,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-2 a>a i.icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed a>a i.icon-arrow-down,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-3 a>a i.icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed a>a i.icon-arrow-down,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-4 a>a i.icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed a>a i.icon-arrow-down {
    display: none;
}

body.menu-collapsed {
    .menu-search {
        display: none;
    }
    .main-menu {
        width: 70px;
        .main-menu-content .nav-title {
            text-align: center;
        }
    }
    .main-navigation {
        transition: all ease-in .3s;
        .nav-item,
        .main-menu .main-menu-content .nav-item .tree-1 a {
            text-align: center;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a {
    text-align: center;
}

body.menu-collapsed .main-navigation {
    .nav-item>a,
    .main-menu .main-menu-content .nav-item .tree-1 a>a {
        position: relative;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a>a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a>a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a>a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a>a {
    position: relative;
}

body.menu-collapsed .main-navigation {
    .nav-item>a:after,
    .main-menu .main-menu-content .nav-item .tree-1 a>a:after {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a>a:after,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a>a:after,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a>a:after,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a>a:after {
    display: none;
}

body.menu-collapsed .main-navigation {
    .nav-item>a i,
    .main-menu .main-menu-content .nav-item .tree-1 a>a i {
        margin-right: 0;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a>a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a>a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a>a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a>a i {
    margin-right: 0;
}

body.menu-collapsed .main-navigation {
    .nav-item i,
    .main-menu .main-menu-content .nav-item .tree-1 a i {
        margin-right: 5px;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a i {
    margin-right: 5px;
}

body.menu-collapsed .main-navigation .nav-item .tree-1 {
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 70px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
    z-index: 99;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-collapsed .main-navigation a .tree-1,
    .tree-2 body.menu-collapsed .main-navigation a .tree-1,
    .tree-3 body.menu-collapsed .main-navigation a .tree-1,
    .tree-4 body.menu-collapsed .main-navigation a .tree-1 {
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 70px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
        z-index: 99;
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 {
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 70px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
    z-index: 99;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-2 {
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 70px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
        z-index: 99;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-2,
        .tree-3 body.menu-collapsed .main-navigation a .tree-2,
        .tree-4 body.menu-collapsed .main-navigation a .tree-2,
        .tree-1 body.menu-collapsed .main-navigation a .tree-2 {
            -webkit-transform: translateY(30px);
            background: #34495e;
            display: block;
            left: 70px;
            opacity: 0;
            position: absolute;
            top: 0;
            transform: translateY(30px);
            transition: all linear 0.3s;
            visibility: hidden;
            width: 15em;
            z-index: 99;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 {
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 70px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
    z-index: 99;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-3 {
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 70px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
        z-index: 99;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-3,
        .tree-3 body.menu-collapsed .main-navigation a .tree-3,
        .tree-4 body.menu-collapsed .main-navigation a .tree-3,
        .tree-1 body.menu-collapsed .main-navigation a .tree-3 {
            -webkit-transform: translateY(30px);
            background: #34495e;
            display: block;
            left: 70px;
            opacity: 0;
            position: absolute;
            top: 0;
            transform: translateY(30px);
            transition: all linear 0.3s;
            visibility: hidden;
            width: 15em;
            z-index: 99;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 {
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 70px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
    z-index: 99;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-4 {
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 70px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
        z-index: 99;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-4,
        .tree-3 body.menu-collapsed .main-navigation a .tree-4,
        .tree-4 body.menu-collapsed .main-navigation a .tree-4,
        .tree-1 body.menu-collapsed .main-navigation a .tree-4 {
            -webkit-transform: translateY(30px);
            background: #34495e;
            display: block;
            left: 70px;
            opacity: 0;
            position: absolute;
            top: 0;
            transform: translateY(30px);
            transition: all linear 0.3s;
            visibility: hidden;
            width: 15em;
            z-index: 99;
        }
    }
}

body.menu-collapsed .main-navigation {
    .nav-item:hover .tree-1,
    .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear 0.3s;
    visibility: visible;
}

body.menu-collapsed .main-navigation .nav-item .tree-1 li {
    text-align: left !important;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-collapsed .main-navigation a .tree-1 li,
    .tree-2 body.menu-collapsed .main-navigation a .tree-1 li,
    .tree-3 body.menu-collapsed .main-navigation a .tree-1 li,
    .tree-4 body.menu-collapsed .main-navigation a .tree-1 li {
        text-align: left !important;
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-2 li {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-2 li,
        .tree-3 body.menu-collapsed .main-navigation a .tree-2 li,
        .tree-4 body.menu-collapsed .main-navigation a .tree-2 li,
        .tree-1 body.menu-collapsed .main-navigation a .tree-2 li {
            text-align: left !important;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-3 li {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-3 li,
        .tree-3 body.menu-collapsed .main-navigation a .tree-3 li,
        .tree-4 body.menu-collapsed .main-navigation a .tree-3 li,
        .tree-1 body.menu-collapsed .main-navigation a .tree-3 li {
            text-align: left !important;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-4 li {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-4 li,
        .tree-3 body.menu-collapsed .main-navigation a .tree-4 li,
        .tree-4 body.menu-collapsed .main-navigation a .tree-4 li,
        .tree-1 body.menu-collapsed .main-navigation a .tree-4 li {
            text-align: left !important;
        }
    }
}

body.menu-collapsed .main-navigation .nav-item .tree-1 a {
    text-align: left !important;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-collapsed .main-navigation a .tree-1 a,
    .tree-2 body.menu-collapsed .main-navigation a .tree-1 a,
    .tree-3 body.menu-collapsed .main-navigation a .tree-1 a,
    .tree-4 body.menu-collapsed .main-navigation a .tree-1 a {
        text-align: left !important;
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-2 a {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-2 a,
        .tree-3 body.menu-collapsed .main-navigation a .tree-2 a,
        .tree-4 body.menu-collapsed .main-navigation a .tree-2 a,
        .tree-1 body.menu-collapsed .main-navigation a .tree-2 a {
            text-align: left !important;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-3 a {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-3 a,
        .tree-3 body.menu-collapsed .main-navigation a .tree-3 a,
        .tree-4 body.menu-collapsed .main-navigation a .tree-3 a,
        .tree-1 body.menu-collapsed .main-navigation a .tree-3 a {
            text-align: left !important;
        }
    }
}

body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-collapsed .main-navigation .nav-item .tree-4 a {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-collapsed .main-navigation a .tree-4 a,
        .tree-3 body.menu-collapsed .main-navigation a .tree-4 a,
        .tree-4 body.menu-collapsed .main-navigation a .tree-4 a,
        .tree-1 body.menu-collapsed .main-navigation a .tree-4 a {
            text-align: left !important;
        }
    }
}

body {
    &.menu-collapsed {
        .main-navigation {
            .nav-sub-item {
                >a {
                    position: relative;
                    &:after {
                        content: '\e65d';
                        font-family: 'themify';
                        font-size: 14px;
                        position: absolute;
                        right: 20px;
                    }
                }
                .tree-2 {
                    -webkit-transform: translateY(30px);
                    background-color: #34495E;
                    display: block !important;
                    left: 210px;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    transform: translateY(30px);
                    transition: all linear 0.3s;
                    visibility: hidden;
                    width: 15em;
                    z-index: 99;
                    a {
                        padding-left: 30px;
                        &:before {
                            left: 10px;
                        }
                    }
                }
                &:hover .tree-2 {
                    -webkit-transform: translateY(0px);
                    opacity: 1;
                    transform: translateY(0px);
                    transition: all linear 0.3s;
                    visibility: visible;
                }
            }
            .nav-sub-item-3 {
                >a {
                    position: relative;
                    &:after {
                        content: '\e65d';
                        font-family: 'themify';
                        font-size: 14px;
                        position: absolute;
                        right: 20px;
                    }
                }
                .tree-3 {
                    -webkit-transform: translateY(30px);
                    background-color: #34495E;
                    display: block !important;
                    left: 210px;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    transform: translateY(30px);
                    transition: all linear 0.3s;
                    visibility: hidden;
                    width: 15em;
                    z-index: 99;
                    a {
                        padding-left: 30px;
                        &:before {
                            left: 10px;
                        }
                    }
                }
                &:hover .tree-3 {
                    -webkit-transform: translateY(0px);
                    opacity: 1;
                    transform: translateY(0px);
                    transition: all linear 0.3s;
                    visibility: visible;
                }
            }
            .nav-sub-item-4 {
                >a {
                    position: relative;
                    &:after {
                        content: '\e65d';
                        font-family: 'themify';
                        font-size: 14px;
                        position: absolute;
                        right: 20px;
                    }
                }
                .tree-4 {
                    -webkit-transform: translateY(30px);
                    background-color: #34495E;
                    display: block !important;
                    left: 210px;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    transform: translateY(30px);
                    transition: all linear 0.3s;
                    visibility: hidden;
                    width: 15em;
                    z-index: 99;
                    a {
                        padding-left: 30px;
                        &:before {
                            left: 10px;
                        }
                    }
                }
                &:hover .tree-4 {
                    -webkit-transform: translateY(0px);
                    opacity: 1;
                    transform: translateY(0px);
                    transition: all linear 0.3s;
                    visibility: visible;
                }
            }
        }
        .main-body .page-wrapper {
            transition: all ease-in .3s;
        }
    }
    &.fix-menu .navbar-wrapper,
    &.menu-compact .navbar-wrapper,
    &.menu-rtl .navbar-wrapper,
    &.menu-sidebar .navbar-wrapper {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }
    &.fix-menu .main-menu,
    &.menu-compact .main-menu,
    &.menu-rtl .main-menu,
    &.menu-sidebar .main-menu {
        left: 0;
        position: fixed;
    }
    &.menu-static {
        .navbar-wrapper,
        .main-menu {
            position: initial;
        }
    }
    &.header-fixed {
        .navbar-wrapper {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 9999;
        }
        .main-menu {
            position: fixed;
        }
        .main-body .page-wrapper {
            margin-left: 0;
        }
        &.menu-hide .main-menu {
            opacity: 0;
            transform: translateX(-240px);
            transition: all linear 300ms;
        }
        &.menu-show .main-menu {
            opacity: 1;
            transform: translateX(0px);
            transition: all linear 300ms;
        }
    }
    &.menu-compact {
        .slimScrollDiv,
        .main-menu-content {
            overflow: visible !important;
        }
        #collapse-menu,
        .menu-caption {
            display: none;
        }
        .main-menu {
            width: 150px;
        }
        .nav-item>a,
        .main-menu .main-menu-content .nav-item .tree-1 a>a {
            padding: 20px 15px !important;
            text-align: center;
        }
    }
}


/**====== Left-side-menu css end ======**/


/**  ===================== Menu-layouts css start
==========================  **/


/*===== Fix-menu layout ===== */


/*===== Static-menu layout ===== */


/*===== Header-fix-menu layout ===== */


/*===== Compact-menu layout ===== */

.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a {
    padding: 20px 15px !important;
    text-align: center;
}

body.menu-compact {
    .nav-item>a i,
    .main-menu .main-menu-content .nav-item .tree-1 a>a i {
        display: block;
        font-size: 30px;
        margin-bottom: 10px;
        margin-right: 0 !important;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a i,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a i,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a i,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a i {
    display: block;
    font-size: 30px;
    margin-bottom: 10px;
    margin-right: 0 !important;
}

body.menu-compact {
    .nav-item>a .icon-arrow-down,
    .main-menu .main-menu-content .nav-item .tree-1 a>a .icon-arrow-down {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a .icon-arrow-down,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a .icon-arrow-down,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a .icon-arrow-down,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a .icon-arrow-down {
    display: none;
}

body.menu-compact {
    .nav-item>a:after,
    .main-menu .main-menu-content .nav-item .tree-1 a>a:after {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a:after,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a:after,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a:after,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a:after {
    display: none;
}

body.menu-compact {
    .main-navigation .nav-item .tree-1 {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-compact .main-navigation a .tree-1,
    .tree-2 body.menu-compact .main-navigation a .tree-1,
    .tree-3 body.menu-compact .main-navigation a .tree-1,
    .tree-4 body.menu-compact .main-navigation a .tree-1 {
        display: none;
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 {
    display: none;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-2 {
        display: none;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-2,
        .tree-3 body.menu-compact .main-navigation a .tree-2,
        .tree-4 body.menu-compact .main-navigation a .tree-2,
        .tree-1 body.menu-compact .main-navigation a .tree-2 {
            display: none;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 {
    display: none;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-3 {
        display: none;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-3,
        .tree-3 body.menu-compact .main-navigation a .tree-3,
        .tree-4 body.menu-compact .main-navigation a .tree-3,
        .tree-1 body.menu-compact .main-navigation a .tree-3 {
            display: none;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 {
    display: none;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-4 {
        display: none;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-4,
        .tree-3 body.menu-compact .main-navigation a .tree-4,
        .tree-4 body.menu-compact .main-navigation a .tree-4,
        .tree-1 body.menu-compact .main-navigation a .tree-4 {
            display: none;
        }
    }
}

body.menu-compact {
    .main-menu-header,
    .nav-title {
        display: none;
    }
    .main-navigation {
        transition: all ease-in .3s;
        .nav-item,
        .main-menu .main-menu-content .nav-item .tree-1 a {
            text-align: center;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a {
    text-align: center;
}

body.menu-compact .main-navigation {
    .nav-item i,
    .main-menu .main-menu-content .nav-item .tree-1 a i {
        margin-right: 5px;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a i,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a i,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a i,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a i {
    margin-right: 5px;
}

body.menu-compact .main-navigation .nav-item .tree-1 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    /* left: 150px; */
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-compact .main-navigation a .tree-1,
    .tree-2 body.menu-compact .main-navigation a .tree-1,
    .tree-3 body.menu-compact .main-navigation a .tree-1,
    .tree-4 body.menu-compact .main-navigation a .tree-1 {
        z-index: 999;
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 150px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 150px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-2 {
        z-index: 999;
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 150px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-2,
        .tree-3 body.menu-compact .main-navigation a .tree-2,
        .tree-4 body.menu-compact .main-navigation a .tree-2,
        .tree-1 body.menu-compact .main-navigation a .tree-2 {
            z-index: 999;
            -webkit-transform: translateY(30px);
            background: #34495e;
            display: block;
            left: 150px;
            opacity: 0;
            position: absolute;
            top: 0;
            transform: translateY(30px);
            transition: all linear 0.3s;
            visibility: hidden;
            width: 15em;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 150px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-3 {
        z-index: 999;
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 150px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-3,
        .tree-3 body.menu-compact .main-navigation a .tree-3,
        .tree-4 body.menu-compact .main-navigation a .tree-3,
        .tree-1 body.menu-compact .main-navigation a .tree-3 {
            z-index: 999;
            -webkit-transform: translateY(30px);
            background: #34495e;
            display: block;
            left: 150px;
            opacity: 0;
            position: absolute;
            top: 0;
            transform: translateY(30px);
            transition: all linear 0.3s;
            visibility: hidden;
            width: 15em;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 150px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 15em;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-4 {
        z-index: 999;
        -webkit-transform: translateY(30px);
        background: #34495e;
        display: block;
        left: 150px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 15em;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-4,
        .tree-3 body.menu-compact .main-navigation a .tree-4,
        .tree-4 body.menu-compact .main-navigation a .tree-4,
        .tree-1 body.menu-compact .main-navigation a .tree-4 {
            z-index: 999;
            -webkit-transform: translateY(30px);
            background: #34495e;
            display: block;
            left: 150px;
            opacity: 0;
            position: absolute;
            top: 0;
            transform: translateY(30px);
            transition: all linear 0.3s;
            visibility: hidden;
            width: 15em;
        }
    }
}

body.menu-compact .main-navigation {
    .nav-item:hover .tree-1,
    .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear 0.3s;
    visibility: visible;
}

body.menu-compact .main-navigation .nav-item .tree-1 li {
    text-align: left !important;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-compact .main-navigation a .tree-1 li,
    .tree-2 body.menu-compact .main-navigation a .tree-1 li,
    .tree-3 body.menu-compact .main-navigation a .tree-1 li,
    .tree-4 body.menu-compact .main-navigation a .tree-1 li {
        text-align: left !important;
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-2 li {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-2 li,
        .tree-3 body.menu-compact .main-navigation a .tree-2 li,
        .tree-4 body.menu-compact .main-navigation a .tree-2 li,
        .tree-1 body.menu-compact .main-navigation a .tree-2 li {
            text-align: left !important;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-3 li {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-3 li,
        .tree-3 body.menu-compact .main-navigation a .tree-3 li,
        .tree-4 body.menu-compact .main-navigation a .tree-3 li,
        .tree-1 body.menu-compact .main-navigation a .tree-3 li {
            text-align: left !important;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-4 li {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-4 li,
        .tree-3 body.menu-compact .main-navigation a .tree-4 li,
        .tree-4 body.menu-compact .main-navigation a .tree-4 li,
        .tree-1 body.menu-compact .main-navigation a .tree-4 li {
            text-align: left !important;
        }
    }
}

body.menu-compact .main-navigation .nav-item .tree-1 a {
    text-align: left !important;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 body.menu-compact .main-navigation a .tree-1 a,
    .tree-2 body.menu-compact .main-navigation a .tree-1 a,
    .tree-3 body.menu-compact .main-navigation a .tree-1 a,
    .tree-4 body.menu-compact .main-navigation a .tree-1 a {
        text-align: left !important;
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-2 a {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-2 a,
        .tree-3 body.menu-compact .main-navigation a .tree-2 a,
        .tree-4 body.menu-compact .main-navigation a .tree-2 a,
        .tree-1 body.menu-compact .main-navigation a .tree-2 a {
            text-align: left !important;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-3 a {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-3 a,
        .tree-3 body.menu-compact .main-navigation a .tree-3 a,
        .tree-4 body.menu-compact .main-navigation a .tree-3 a,
        .tree-1 body.menu-compact .main-navigation a .tree-3 a {
            text-align: left !important;
        }
    }
}

body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a {
    text-align: left !important;
}

.main-menu .main-menu-content {
    body.menu-compact .main-navigation .nav-item .tree-4 a {
        text-align: left !important;
    }
    .nav-item {
        .tree-2 body.menu-compact .main-navigation a .tree-4 a,
        .tree-3 body.menu-compact .main-navigation a .tree-4 a,
        .tree-4 body.menu-compact .main-navigation a .tree-4 a,
        .tree-1 body.menu-compact .main-navigation a .tree-4 a {
            text-align: left !important;
        }
    }
}

body {
    &.menu-compact .main-navigation {
        .nav-sub-item {
            >a {
                position: relative;
                &:after {
                    content: '\e65d';
                    font-family: 'themify';
                    font-size: 14px;
                    position: absolute;
                    right: 20px;
                }
            }
            .tree-2 {
                z-index: 999;
                -webkit-transform: translateY(30px);
                background-color: #34495E;
                display: block !important;
                left: 210px;
                opacity: 0;
                position: absolute;
                top: 0;
                transform: translateY(30px);
                transition: all linear 0.3s;
                visibility: hidden;
                width: 15em;
                z-index: 9999;
                a {
                    padding-left: 30px;
                    &:before {
                        left: 10px;
                    }
                }
            }
            &:hover .tree-2 {
                -webkit-transform: translateY(0px);
                opacity: 1;
                transform: translateY(0px);
                transition: all linear 0.3s;
                visibility: visible;
            }
        }
        .nav-sub-item-3 {
            >a {
                position: relative;
                &:after {
                    content: '\e65d';
                    font-family: 'themify';
                    font-size: 14px;
                    position: absolute;
                    right: 20px;
                }
            }
            .tree-3 {
                z-index: 999;
                -webkit-transform: translateY(30px);
                background-color: #34495E;
                display: block !important;
                left: 210px;
                opacity: 0;
                position: absolute;
                top: 0;
                transform: translateY(30px);
                transition: all linear 0.3s;
                visibility: hidden;
                width: 15em;
                z-index: 9999;
                a {
                    padding-left: 30px;
                    &:before {
                        left: 10px;
                    }
                }
            }
            &:hover .tree-3 {
                -webkit-transform: translateY(0px);
                opacity: 1;
                transform: translateY(0px);
                transition: all linear 0.3s;
                visibility: visible;
            }
        }
        .nav-sub-item-4 {
            >a {
                position: relative;
                &:after {
                    content: '\e65d';
                    font-family: 'themify';
                    font-size: 14px;
                    position: absolute;
                    right: 20px;
                }
            }
            .tree-4 {
                z-index: 999;
                -webkit-transform: translateY(30px);
                background-color: #34495E;
                display: block !important;
                left: 210px;
                opacity: 0;
                position: absolute;
                top: 0;
                transform: translateY(30px);
                transition: all linear 0.3s;
                visibility: hidden;
                width: 15em;
                z-index: 9999;
                a {
                    padding-left: 30px;
                    &:before {
                        left: 10px;
                    }
                }
            }
            &:hover .tree-4 {
                -webkit-transform: translateY(0px);
                opacity: 1;
                transform: translateY(0px);
                transition: all linear 0.3s;
                visibility: visible;
            }
        }
    }
    &.menu-bottom {
        .header-navbar {
            min-height: auto;
            position: fixed !important;
            bottom: 0;
            z-index: 9999 !important;
            -webkit-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
        }
        .navbar-wrapper {
            bottom: 0;
            top: auto;
        }
        .mega-menu-top {
            .show-notification,
            .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
                bottom: 0 !important;
            }
        }
        .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
            bottom: 0 !important;
        }
    }
}


/*===== Bottom-menu layout ===== */

.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
    bottom: 0 !important;
}

body.menu-bottom {
    .header-notification .show-notification,
    .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification {
        bottom: 65px;
    }
}

.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .show-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .header-notification .profile-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
    bottom: 65px;
}

body {
    &.menu-bottom #collapse-menu {
        display: none;
    }
    &.menu-rtl {
        direction: rtl;
        ul {
            padding-right: 0;
        }
        .main-menu {
            right: 0;
        }
        .media-left {
            padding-right: 0;
        }
        .header-navbar .navbar-wrapper {
            .navbar-container .mega-menu-top {
                .show-notification .mega-mailbox li a i,
                .profile-notification .mega-mailbox li a i {
                    margin-left: 15px;
                    margin-right: 0;
                }
                .show-notification .mega-mailbox li,
                .profile-notification .mega-mailbox li {
                    padding-right: 0;
                }
            }
            .navbar-logo {
                float: right;
            }
        }
        .breadcrumb-item {
            float: right;
        }
        .header-navbar .navbar-wrapper .navbar-container {
            margin-left: 0;
        }
        .main-body .page-wrapper {
            margin-left: 0;
            margin-right: 240px;
            .page-header-breadcrumb {
                float: left;
            }
        }
        .header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile img {
            margin-left: 10px;
        }
        .main-navigation {
            .menu-caption {
                left: 40px;
                right: auto;
            }
            .nav-item.single-item .menu-caption,
            .main-menu .main-menu-content .nav-item .tree-1 a.single-item .menu-caption {
                right: auto;
            }
        }
    }
}


/*===== RTL-menu layout ===== */

.main-menu .main-menu-content .nav-item .tree-1 body.menu-rtl .main-navigation a.single-item .menu-caption,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-rtl .main-navigation a.single-item .menu-caption,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-rtl .main-navigation a.single-item .menu-caption,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-rtl .main-navigation a.single-item .menu-caption {
    right: auto;
}

body.menu-rtl .main-navigation {
    .nav-item>a:after,
    .main-menu .main-menu-content .nav-item .tree-1 a>a:after {
        left: 20px;
        right: auto;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.menu-rtl .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-rtl .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-rtl .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-rtl .main-navigation a>a:after {
    left: 20px;
    right: auto;
}

body {
    &.menu-rtl {
        .main-navigation .main-menu .main-menu-content .nav-item {
            .tree-1 a {
                >a:after,
                .tree-1 a>a:after {
                    left: 20px;
                    right: auto;
                }
            }
            .tree-2 a {
                >a:after,
                .tree-2 a>a:after {
                    left: 20px;
                    right: auto;
                }
            }
            .tree-3 a {
                >a:after,
                .tree-3 a>a:after {
                    left: 20px;
                    right: auto;
                }
            }
            .tree-4 a {
                >a:after,
                .tree-4 a>a:after {
                    left: 20px;
                    right: auto;
                }
            }
        }
        .main-menu .main-menu-content .nav-item {
            .tree-1 .main-navigation a>a:after,
            .tree-2 .main-navigation a>a:after,
            .tree-3 .main-navigation a>a:after,
            .tree-4 .main-navigation a>a:after {
                left: 20px;
                right: auto;
            }
        }
        .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a {
            >a:after,
            .tree-2 a>a:after {
                left: 20px;
                right: auto;
            }
        }
        .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a>a:after {
            left: 20px;
            right: auto;
        }
        .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a {
            >a:after,
            .tree-3 a>a:after {
                left: 20px;
                right: auto;
            }
        }
        .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a>a:after {
            left: 20px;
            right: auto;
        }
        .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a {
            >a:after,
            .tree-4 a>a:after {
                left: 20px;
                right: auto;
            }
        }
        .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a>a:after {
            left: 20px;
            right: auto;
        }
        .main-navigation {
            .nav-sub-item>a:after,
            .nav-sub-item-3>a:after,
            .nav-sub-item-4>a:after {
                left: 20px;
                right: auto;
            }
        }
        .main-menu .main-menu-header .user-details {
            margin-right: 10px;
        }
        .header-navbar .navbar-wrapper .navbar-container {
            .header-notification .show-notification li img,
            .mega-menu-top .show-notification li img,
            .header-notification .profile-notification li img {
                margin-left: 10px;
            }
            .mega-menu-top {
                .profile-notification li img,
                .show-notification li img,
                .profile-notification li img {
                    margin-left: 10px;
                }
            }
            .header-notification .profile-notification li img,
            .mega-menu-top .profile-notification li img {
                margin-left: 10px;
            }
        }
        .live-status {
            right: auto;
            left: 27px;
        }
        .userlist-box .media-left {
            padding-left: 10px;
        }
        .header-navbar .navbar-wrapper .navbar-container {
            .header-notification .show-notification li:first-child label,
            .mega-menu-top .show-notification li:first-child label,
            .header-notification .profile-notification li:first-child label {
                float: left;
            }
            .mega-menu-top {
                .profile-notification li:first-child label,
                .show-notification li:first-child label,
                .profile-notification li:first-child label {
                    float: left;
                }
            }
            .header-notification .profile-notification li:first-child label,
            .mega-menu-top .profile-notification li:first-child label {
                float: left;
            }
        }
        .rotate-refresh {
            display: initial;
        }
    }
    &.horizontal-fixed .header-navbar,
    &.horizontal-static .header-navbar,
    &.horizontal-icon .header-navbar,
    &.horizontal-icon-fixed .header-navbar {
        position: fixed;
        width: 100%;
        z-index: 9999;
    }
    &.horizontal-fixed .page-header,
    &.horizontal-static .page-header,
    &.horizontal-icon .page-header,
    &.horizontal-icon-fixed .page-header {
        margin-top: 110px;
    }
    &.horizontal-fixed .main-menu-header,
    &.horizontal-static .main-menu-header,
    &.horizontal-icon .main-menu-header,
    &.horizontal-icon-fixed .main-menu-header,
    &.horizontal-fixed #collapse-menu,
    &.horizontal-static #collapse-menu,
    &.horizontal-icon #collapse-menu,
    &.horizontal-icon-fixed #collapse-menu,
    &.horizontal-fixed .main-menu .main-menu-content li.nav-title,
    &.horizontal-static .main-menu .main-menu-content li.nav-title,
    &.horizontal-icon .main-menu .main-menu-content li.nav-title,
    &.horizontal-icon-fixed .main-menu .main-menu-content li.nav-title,
    &.horizontal-fixed .main-navigation .nav-item.has-class .tree-1.open,
    &.horizontal-static .main-navigation .nav-item.has-class .tree-1.open,
    &.horizontal-icon .main-navigation .nav-item.has-class .tree-1.open,
    &.horizontal-icon-fixed .main-navigation .nav-item.has-class .tree-1.open,
    &.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open {
        display: none;
    }
}


/*===== Horizontal-fix-menu layout ===== */

.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4 {
    display: none;
}

body {
    &.horizontal-fixed .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
    &.horizontal-static .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
    &.horizontal-icon .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
    &.horizontal-icon-fixed .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2 {
        display: none;
    }
    &.horizontal-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
        .nav-item .tree-1 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-static .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
        .nav-item .tree-1 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
        .nav-item .tree-1 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
        .nav-item .tree-1 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
        .nav-item .tree-2 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-static .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
        .nav-item .tree-2 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
        .nav-item .tree-2 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
        .nav-item .tree-2 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
        .nav-item .tree-3 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-static .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
        .nav-item .tree-3 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
        .nav-item .tree-3 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
        .nav-item .tree-3 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
        .nav-item .tree-4 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-static .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
        .nav-item .tree-4 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
        .nav-item .tree-4 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-icon-fixed .main-menu .main-menu-content {
        .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
        .nav-item .tree-4 .main-navigation a.has-class .open.tree-2 {
            display: none;
        }
    }
    &.horizontal-fixed .main-navigation .nav-item.has-class .tree-2.open,
    &.horizontal-static .main-navigation .nav-item.has-class .tree-2.open,
    &.horizontal-icon .main-navigation .nav-item.has-class .tree-2.open,
    &.horizontal-icon-fixed .main-navigation .nav-item.has-class .tree-2.open,
    &.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open {
        display: none;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open {
    display: none;
}

body {
    &.horizontal-fixed .main-menu,
    &.horizontal-static .main-menu,
    &.horizontal-icon .main-menu,
    &.horizontal-icon-fixed .main-menu {
        position: fixed;
        width: 100%;
        top: 55px;
        z-index: 98;
        background: #34475a;
    }
    &.horizontal-fixed .main-menu .main-menu-content .nav-item>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a {
        padding: 20px 15px;
    }
    &.horizontal-fixed .main-navigation,
    &.horizontal-static .main-navigation,
    &.horizontal-icon .main-navigation,
    &.horizontal-icon-fixed .main-navigation {
        margin-bottom: 0;
    }
    &.horizontal-fixed .main-navigation .nav-item .tree-1,
    &.horizontal-static .main-navigation .nav-item .tree-1,
    &.horizontal-icon .main-navigation .nav-item .tree-1,
    &.horizontal-icon-fixed .main-navigation .nav-item .tree-1,
    &.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2 {
    -webkit-transform: translateY(30px);
    background-color: #34495E;
    display: block !important;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 60px;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 18em;
    z-index: 9999;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2,
    .tree-1 .tree-2 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2,
    .tree-1 .tree-2 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2,
    .tree-1 .tree-2 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2,
    .tree-1 .tree-2 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2,
    .tree-1 .tree-3 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2,
    .tree-1 .tree-3 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2,
    .tree-1 .tree-3 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2,
    .tree-1 .tree-3 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2,
    .tree-1 .tree-4 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2,
    .tree-1 .tree-4 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2,
    .tree-1 .tree-4 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2,
    .tree-1 .tree-4 a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3 {
    -webkit-transform: translateY(30px);
    background-color: #34495E;
    display: block !important;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 60px;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 18em;
    z-index: 9999;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3,
    .tree-1 .tree-2 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3,
    .tree-1 .tree-2 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3,
    .tree-1 .tree-2 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3,
    .tree-1 .tree-2 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3,
    .tree-1 .tree-3 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3,
    .tree-1 .tree-3 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3,
    .tree-1 .tree-3 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3,
    .tree-1 .tree-3 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3,
    .tree-1 .tree-4 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3,
    .tree-1 .tree-4 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3,
    .tree-1 .tree-4 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3,
    .tree-1 .tree-4 a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4 {
    -webkit-transform: translateY(30px);
    background-color: #34495E;
    display: block !important;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 60px;
    transform: translateY(30px);
    transition: all linear 0.3s;
    visibility: hidden;
    width: 18em;
    z-index: 9999;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4,
    .tree-1 .tree-2 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4,
    .tree-1 .tree-2 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4,
    .tree-1 .tree-2 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4,
    .tree-1 .tree-2 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4,
    .tree-1 .tree-3 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4,
    .tree-1 .tree-3 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4,
    .tree-1 .tree-3 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4,
    .tree-1 .tree-3 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4,
    .tree-1 .tree-4 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4,
    .tree-1 .tree-4 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4,
    .tree-1 .tree-4 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4,
    .tree-1 .tree-4 a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #34495E;
        display: block !important;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 60px;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
}

body {
    &.horizontal-fixed .main-navigation .nav-item .tree-1 li,
    &.horizontal-static .main-navigation .nav-item .tree-1 li,
    &.horizontal-icon .main-navigation .nav-item .tree-1 li,
    &.horizontal-icon-fixed .main-navigation .nav-item .tree-1 li,
    &.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2 li {
    display: block;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 li,
    .tree-1 .tree-2 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 li,
    .tree-1 .tree-2 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 li,
    .tree-1 .tree-2 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 li,
    .tree-1 .tree-2 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 li,
    .tree-1 .tree-3 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 li,
    .tree-1 .tree-3 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 li,
    .tree-1 .tree-3 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 li,
    .tree-1 .tree-3 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 li,
    .tree-1 .tree-4 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 li,
    .tree-1 .tree-4 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 li,
    .tree-1 .tree-4 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 li,
    .tree-1 .tree-4 a .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2 li,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3 li {
    display: block;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 li,
    .tree-1 .tree-2 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 li,
    .tree-1 .tree-2 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 li,
    .tree-1 .tree-2 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 li,
    .tree-1 .tree-2 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 li,
    .tree-1 .tree-3 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 li,
    .tree-1 .tree-3 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 li,
    .tree-1 .tree-3 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 li,
    .tree-1 .tree-3 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 li,
    .tree-1 .tree-4 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 li,
    .tree-1 .tree-4 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 li,
    .tree-1 .tree-4 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 li,
    .tree-1 .tree-4 a .tree-3 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3 li,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4 li {
    display: block;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 li,
    .tree-1 .tree-2 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 li,
    .tree-1 .tree-2 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 li,
    .tree-1 .tree-2 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 li,
    .tree-1 .tree-2 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 li,
    .tree-1 .tree-3 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 li,
    .tree-1 .tree-3 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 li,
    .tree-1 .tree-3 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 li,
    .tree-1 .tree-3 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 li,
    .tree-1 .tree-4 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 li,
    .tree-1 .tree-4 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 li,
    .tree-1 .tree-4 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-4 li {
        display: block;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 li,
    .tree-1 .tree-4 a .tree-4 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4 li,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4 li {
        display: block;
    }
}

body {
    &.horizontal-fixed .main-navigation .nav-item:hover .tree-1,
    &.horizontal-static .main-navigation .nav-item:hover .tree-1,
    &.horizontal-icon .main-navigation .nav-item:hover .tree-1,
    &.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1,
    &.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-2 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear 0.3s;
    visibility: visible;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-2,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-3 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear 0.3s;
    visibility: visible;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-3,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear 0.3s;
    visibility: visible;
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-static .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 body.horizontal-static .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 body.horizontal-icon .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-static .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 body.horizontal-static .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 body.horizontal-icon .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-static .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 body.horizontal-static .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 body.horizontal-icon .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-4,
    .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
}

body {
    &.horizontal-fixed .main-navigation .nav-sub-item>a,
    &.horizontal-static .main-navigation .nav-sub-item>a,
    &.horizontal-icon .main-navigation .nav-sub-item>a,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item>a {
        position: relative;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item>a:after,
    &.horizontal-static .main-navigation .nav-sub-item>a:after,
    &.horizontal-icon .main-navigation .nav-sub-item>a:after,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item>a:after {
        content: '\e65d';
        font-family: 'themify';
        font-size: 14px;
        position: absolute;
        right: 20px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item .tree-2,
    &.horizontal-static .main-navigation .nav-sub-item .tree-2,
    &.horizontal-icon .main-navigation .nav-sub-item .tree-2,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 {
        -webkit-transform: translateY(30px);
        background-color: #2C3E50;
        display: block !important;
        left: 250px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item .tree-2 li,
    &.horizontal-static .main-navigation .nav-sub-item .tree-2 li,
    &.horizontal-icon .main-navigation .nav-sub-item .tree-2 li,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 li {
        display: block;
        padding-left: 15px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item .tree-2 a,
    &.horizontal-static .main-navigation .nav-sub-item .tree-2 a,
    &.horizontal-icon .main-navigation .nav-sub-item .tree-2 a,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 a {
        padding-left: 30px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item .tree-2 a:before,
    &.horizontal-static .main-navigation .nav-sub-item .tree-2 a:before,
    &.horizontal-icon .main-navigation .nav-sub-item .tree-2 a:before,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 a:before {
        left: 10px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item:hover .tree-2,
    &.horizontal-static .main-navigation .nav-sub-item:hover .tree-2,
    &.horizontal-icon .main-navigation .nav-sub-item:hover .tree-2,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item:hover .tree-2 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-3>a,
    &.horizontal-static .main-navigation .nav-sub-item-3>a,
    &.horizontal-icon .main-navigation .nav-sub-item-3>a,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-3>a {
        position: relative;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-3>a:after,
    &.horizontal-static .main-navigation .nav-sub-item-3>a:after,
    &.horizontal-icon .main-navigation .nav-sub-item-3>a:after,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-3>a:after {
        content: '\e65d';
        font-family: 'themify';
        font-size: 14px;
        position: absolute;
        right: 20px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3,
    &.horizontal-static .main-navigation .nav-sub-item-3 .tree-3,
    &.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 {
        -webkit-transform: translateY(30px);
        background-color: #2C3E50;
        display: block !important;
        left: 250px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3 li,
    &.horizontal-static .main-navigation .nav-sub-item-3 .tree-3 li,
    &.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3 li,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 li {
        display: block;
        padding-left: 15px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3 a,
    &.horizontal-static .main-navigation .nav-sub-item-3 .tree-3 a,
    &.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3 a,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 a {
        padding-left: 30px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3 a:before,
    &.horizontal-static .main-navigation .nav-sub-item-3 .tree-3 a:before,
    &.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3 a:before,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 a:before {
        left: 10px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-3:hover .tree-3,
    &.horizontal-static .main-navigation .nav-sub-item-3:hover .tree-3,
    &.horizontal-icon .main-navigation .nav-sub-item-3:hover .tree-3,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-3:hover .tree-3 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-4>a,
    &.horizontal-static .main-navigation .nav-sub-item-4>a,
    &.horizontal-icon .main-navigation .nav-sub-item-4>a,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-4>a {
        position: relative;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-4>a:after,
    &.horizontal-static .main-navigation .nav-sub-item-4>a:after,
    &.horizontal-icon .main-navigation .nav-sub-item-4>a:after,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-4>a:after {
        content: '\e65d';
        font-family: 'themify';
        font-size: 14px;
        position: absolute;
        right: 20px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4,
    &.horizontal-static .main-navigation .nav-sub-item-4 .tree-4,
    &.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 {
        -webkit-transform: translateY(30px);
        background-color: #2C3E50;
        display: block !important;
        left: 250px;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translateY(30px);
        transition: all linear 0.3s;
        visibility: hidden;
        width: 18em;
        z-index: 9999;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4 li,
    &.horizontal-static .main-navigation .nav-sub-item-4 .tree-4 li,
    &.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4 li,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 li {
        display: block;
        padding-left: 15px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4 a,
    &.horizontal-static .main-navigation .nav-sub-item-4 .tree-4 a,
    &.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4 a,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 a {
        padding-left: 30px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4 a:before,
    &.horizontal-static .main-navigation .nav-sub-item-4 .tree-4 a:before,
    &.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4 a:before,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 a:before {
        left: 10px;
    }
    &.horizontal-fixed .main-navigation .nav-sub-item-4:hover .tree-4,
    &.horizontal-static .main-navigation .nav-sub-item-4:hover .tree-4,
    &.horizontal-icon .main-navigation .nav-sub-item-4:hover .tree-4,
    &.horizontal-icon-fixed .main-navigation .nav-sub-item-4:hover .tree-4 {
        -webkit-transform: translateY(0px);
        opacity: 1;
        transform: translateY(0px);
        transition: all linear 0.3s;
        visibility: visible;
    }
    &.horizontal-fixed .main-body .page-wrapper,
    &.horizontal-static .main-body .page-wrapper,
    &.horizontal-icon .main-body .page-wrapper,
    &.horizontal-icon-fixed .main-body .page-wrapper {
        margin-left: 0;
    }
    &.horizontal-fixed .main-menu .main-menu-content .nav-item>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a {
        display: inline-block;
        position: relative;
    }
    &.horizontal-fixed .main-menu .main-menu-content .nav-item>a:after,
    &.horizontal-static .main-menu .main-menu-content .nav-item>a:after,
    &.horizontal-icon .main-menu .main-menu-content .nav-item>a:after,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a:after,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
    &.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a:after {
        display: none;
    }
    &.horizontal-fixed .main-menu .main-menu-content li,
    &.horizontal-static .main-menu .main-menu-content li,
    &.horizontal-icon .main-menu .main-menu-content li,
    &.horizontal-icon-fixed .main-menu .main-menu-content li {
        border-top: none;
        display: inline-block;
        margin-right: 0;
    }
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .icon-arrow-down,
    &.horizontal-static .main-menu .main-menu-content .nav-item .icon-arrow-down,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .icon-arrow-down,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .icon-arrow-down {
        margin-right: 20px;
        position: relative;
        right: -20px;
        top: 0;
    }
    &.horizontal-fixed .tree-1 li,
    &.horizontal-static .tree-1 li,
    &.horizontal-icon .tree-1 li,
    &.horizontal-icon-fixed .tree-1 li,
    &.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 li {
        display: block;
    }
}

.main-menu .main-menu-content .nav-item body.horizontal-fixed .tree-2 li,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item body.horizontal-static .tree-2 li,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon .tree-2 li,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon-fixed .tree-2 li,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-fixed .tree-3 li,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-static .tree-3 li,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon .tree-3 li,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon-fixed .tree-3 li,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-fixed .tree-4 li,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-static .tree-4 li,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon .tree-4 li,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon-fixed .tree-4 li {
    display: block;
}


/*===== Horizontal-static layout ===== */

body {
    &.horizontal-static {
        .header-navbar,
        .main-menu {
            position: initial;
        }
        .page-header {
            margin-top: 60px;
        }
    }
    &.horizontal-icon .header-navbar,
    &.horizontal-icon-fixed .header-navbar,
    &.horizontal-icon .main-menu,
    &.horizontal-icon-fixed .main-menu {
        position: inherit;
    }
    &.horizontal-icon .main-menu .main-menu-content .nav-item>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a {
        text-align: center;
    }
    &.horizontal-icon .main-menu .main-menu-content .nav-item>a i,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a i,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a i,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a i,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a i,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a i,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a i,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a i,
    &.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a i,
    &.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a i {
        display: block;
        margin-bottom: 5px;
        margin-right: 0px;
    }
    &.horizontal-icon .main-navigation .nav-item .tree-1,
    &.horizontal-icon-fixed .main-navigation .nav-item .tree-1 {
        top: 80px;
    }
}


/*===== Horizontal-icon-menu layout ===== */

.main-menu .main-menu-content .nav-item {
    .tree-1 body {
        &.horizontal-icon .main-navigation a .tree-1,
        &.horizontal-icon-fixed .main-navigation a .tree-1 {
            top: 80px;
        }
    }
    .tree-2 body {
        &.horizontal-icon .main-navigation a .tree-1,
        &.horizontal-icon-fixed .main-navigation a .tree-1 {
            top: 80px;
        }
    }
    .tree-3 body {
        &.horizontal-icon .main-navigation a .tree-1,
        &.horizontal-icon-fixed .main-navigation a .tree-1 {
            top: 80px;
        }
    }
    .tree-4 body {
        &.horizontal-icon .main-navigation a .tree-1,
        &.horizontal-icon-fixed .main-navigation a .tree-1 {
            top: 80px;
        }
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 {
    top: 80px;
}

.main-menu .main-menu-content {
    body.horizontal-icon-fixed .main-navigation .nav-item .tree-2 {
        top: 80px;
    }
    .nav-item {
        .tree-2 body {
            &.horizontal-icon .main-navigation a .tree-2,
            &.horizontal-icon-fixed .main-navigation a .tree-2 {
                top: 80px;
            }
        }
        .tree-3 body {
            &.horizontal-icon .main-navigation a .tree-2,
            &.horizontal-icon-fixed .main-navigation a .tree-2 {
                top: 80px;
            }
        }
        .tree-4 body {
            &.horizontal-icon .main-navigation a .tree-2,
            &.horizontal-icon-fixed .main-navigation a .tree-2 {
                top: 80px;
            }
        }
        .tree-1 body {
            &.horizontal-icon .main-navigation a .tree-2,
            &.horizontal-icon-fixed .main-navigation a .tree-2 {
                top: 80px;
            }
        }
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 {
    top: 80px;
}

.main-menu .main-menu-content {
    body.horizontal-icon-fixed .main-navigation .nav-item .tree-3 {
        top: 80px;
    }
    .nav-item {
        .tree-2 body {
            &.horizontal-icon .main-navigation a .tree-3,
            &.horizontal-icon-fixed .main-navigation a .tree-3 {
                top: 80px;
            }
        }
        .tree-3 body {
            &.horizontal-icon .main-navigation a .tree-3,
            &.horizontal-icon-fixed .main-navigation a .tree-3 {
                top: 80px;
            }
        }
        .tree-4 body {
            &.horizontal-icon .main-navigation a .tree-3,
            &.horizontal-icon-fixed .main-navigation a .tree-3 {
                top: 80px;
            }
        }
        .tree-1 body {
            &.horizontal-icon .main-navigation a .tree-3,
            &.horizontal-icon-fixed .main-navigation a .tree-3 {
                top: 80px;
            }
        }
    }
}

body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 {
    top: 80px;
}

.main-menu .main-menu-content {
    body.horizontal-icon-fixed .main-navigation .nav-item .tree-4 {
        top: 80px;
    }
    .nav-item {
        .tree-2 body {
            &.horizontal-icon .main-navigation a .tree-4,
            &.horizontal-icon-fixed .main-navigation a .tree-4 {
                top: 80px;
            }
        }
        .tree-3 body {
            &.horizontal-icon .main-navigation a .tree-4,
            &.horizontal-icon-fixed .main-navigation a .tree-4 {
                top: 80px;
            }
        }
        .tree-4 body {
            &.horizontal-icon .main-navigation a .tree-4,
            &.horizontal-icon-fixed .main-navigation a .tree-4 {
                top: 80px;
            }
        }
        .tree-1 body {
            &.horizontal-icon .main-navigation a .tree-4,
            &.horizontal-icon-fixed .main-navigation a .tree-4 {
                top: 80px;
            }
        }
    }
}

body {
    &.horizontal-icon .page-header {
        margin-top: 80px;
    }
    &.horizontal-icon-fixed {
        .page-header {
            margin-top: 80px;
        }
        .main-menu {
            position: fixed;
            .main-menu-content .nav-item {
                >a i.icon-arrow-down,
                .tree-1 a>a i.icon-arrow-down,
                .tree-2 a>a i.icon-arrow-down,
                .tree-3 a>a i.icon-arrow-down,
                .tree-4 a>a i.icon-arrow-down {
                    display: none;
                }
            }
        }
        .header-navbar {
            position: initial;
        }
        .page-header {
            margin-top: 80px;
        }
    }
    &.menu-sidebar .navbar-wrapper {
        position: initial;
    }
}


/*===== Horizontal-icon-fix-menu layout ===== */


/*===== Sidebar-menu layout ===== */


/*===== Menu-layouts css end ===== */


/**  ===================== Theme-Buttons css start
==========================  **/

.btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
}

.button-page {
    .card-body {
        margin-bottom: -20px;
        ul {
            margin-bottom: 0;
            display: inline-block;
        }
        a {
            margin-bottom: 20px;
        }
        ul li {
            display: inline-block;
            margin-right: 20px;
            float: left;
        }
    }
    .btn {
        margin-bottom: 20px;
        margin-right: 10px;
    }
}

.btn {
    &.btn-round {
        border-radius: 2rem;
    }
    &.btn-square {
        border-radius: 0;
    }
    &.btn-skew {
        transform: skew(-15deg);
    }
    i {
        margin-right: 5px;
    }
    &.btn-icon {
        border-radius: 50%;
        width: 40px;
        line-height: 30px;
        height: 40px;
        padding: 3px;
        text-align: center;
    }
}

.btn-group,
.btn-group-vertical {
    display: inline-block;
}

.btn-group .btn {
    float: left;
}

.icon-btn i {
    margin-right: 0 !important;
}

.button-page .btn-group {
    margin-right: 10px;
}

.show>.dropdown-menu {
    overflow: hidden;
}

.dropdown-danger,
.dropdown-success,
.dropdown-warning,
.dropdown-info,
.dropdown-warning,
.dropdown-primary,
.dropdown-default,
.dropdown-inverse,
.dropdown-disabled {
    display: inline-block;
    vertical-align: center;
    position: relative;
}

.dropdown-split [class*=btn-],
.btn-group [class*=btn-] {
    margin-right: 0;
}

.list-group-item.active {
    background-color: #0073aa;
    border-color: #0073aa;
}

.show>.btn-primary.dropdown-toggle,
.sweet-alert .show>button.dropdown-toggle.confirm,
.wizard>.actions .show>a.dropdown-toggle {
    background-color: #004a6d !important;
    border-color: #004a6d;
    box-shadow: none;
    color: #fff;
}

.btn-disabled,
.dropdown-disabled,
.dropdown-split-disabled {
    cursor: not-allowed;
}

#Note-list li:hover .Note-delete {
    opacity: 1;
    transform: translateX(0px);
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
}

.button-list .btn {
    margin-bottom: 10px;
    margin-right: 30px;
}

.Note-delete {
    margin-bottom: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    position: absolute;
    right: 20px;
    color: #fff;
    transition: all ease-in .3s;
    border-radius: 2px;
    cursor: pointer;
    transform: translateX(10px);
}

.popover-content {
    .color-code {
        margin-bottom: 10px;
    }
    .display-color {
        width: 75px;
        height: 2.50rem;
        border-radius: .25rem;
        margin-right: 30px;
        background-color: rgba(0, 115, 170, 0.5);
        border-color: rgba(0, 115, 170, 0.5);
    }
    span.block {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 14px;
    }
}

.popover-title {
    text-align: center;
}

.nav-pills .nav-link.active {
    &.active {
        background: transparent;
        color: #0275d8;
        text-align: center;
        border-bottom: 1px solid #0275d8;
        &:focus,
        &:hover {
            background: transparent;
            color: #0275d8;
            text-align: center;
            border-bottom: 1px solid #0275d8;
        }
    }
    background: transparent;
    color: #0275d8;
    text-align: center;
    border-bottom: 1px solid #0275d8;
    &:focus,
    &:hover,
    &:focus,
    &:hover {
        background: transparent;
        color: #0275d8;
        text-align: center;
        border-bottom: 1px solid #0275d8;
    }
}


/**====== Basic-Buttons css Start ======**/

.btn-primary,
.sweet-alert button.confirm,
.wizard>.actions a {
    background-color: #0073aa;
    border-color: #0073aa;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.btn-primary:hover,
.sweet-alert button.confirm:hover,
.wizard>.actions a:hover {
    background-color: #0096dd;
    border-color: #0096dd;
}

.btn-primary:active,
.sweet-alert button.confirm:active,
.wizard>.actions a:active {
    background-color: #004a6d !important;
    border-color: #004a6d;
    box-shadow: none;
    color: #fff;
}

.btn-primary:focus,
.sweet-alert button.confirm:focus,
.wizard>.actions a:focus {
    box-shadow: none;
    color: #fff;
}

.btn-primary.disabled,
.sweet-alert button.disabled.confirm,
.wizard>.actions a.disabled {
    background-color: rgba(0, 115, 170, 0.5);
    border-color: rgba(0, 115, 170, 0.5);
}

.btn-warning {
    background-color: #f1c40f;
    border-color: #f1c40f;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #f4d03f;
        border-color: #f4d03f;
    }
    &:active {
        background-color: #b8960b !important;
        border-color: #b8960b;
        box-shadow: none;
        color: #fff;
    }
    &:focus {
        box-shadow: none;
        color: #fff;
    }
    &.disabled {
        background-color: rgba(241, 196, 15, 0.5);
        border-color: rgba(241, 196, 15, 0.5);
    }
}

.btn-default {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #d9dcde;
        border-color: #d9dcde;
    }
    &:active {
        background-color: #9ca5ab !important;
        border-color: #9ca5ab;
        box-shadow: none;
        color: #fff;
    }
    &:focus {
        box-shadow: none;
        color: #fff;
    }
    &.disabled {
        background-color: rgba(189, 195, 199, 0.5);
        border-color: rgba(189, 195, 199, 0.5);
    }
}

.btn-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #ed7669;
        border-color: #ed7669;
    }
    &:active {
        background-color: #cd2a19 !important;
        border-color: #cd2a19;
        box-shadow: none;
        color: #fff;
    }
    &:focus {
        box-shadow: none;
        color: #fff;
    }
    &.disabled {
        background-color: rgba(231, 76, 60, 0.5);
        border-color: rgba(231, 76, 60, 0.5);
    }
}

.btn-success {
    background-color: #2ecc71;
    border-color: #2ecc71;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #54d98c;
        border-color: #54d98c;
    }
    &:active {
        background-color: #239a55 !important;
        border-color: #239a55;
        box-shadow: none;
        color: #fff;
    }
    &:focus {
        box-shadow: none;
        color: #fff;
    }
    &.disabled {
        background-color: rgba(46, 204, 113, 0.5);
        border-color: rgba(46, 204, 113, 0.5);
    }
}

.btn-inverse {
    background-color: #34495e;
    border-color: #34495e;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #46637f;
        border-color: #46637f;
    }
    &:active {
        background-color: #1e2a37 !important;
        border-color: #1e2a37;
        box-shadow: none;
        color: #fff;
    }
    &:focus {
        box-shadow: none;
        color: #fff;
    }
    &.disabled {
        background-color: rgba(52, 73, 94, 0.5);
        border-color: rgba(52, 73, 94, 0.5);
    }
}

.btn-info {
    background-color: #3498DB;
    border-color: #3498DB;
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #5faee3;
        border-color: #5faee3;
    }
    &:active {
        background-color: #2077b2 !important;
        border-color: #2077b2;
        box-shadow: none;
        color: #fff;
    }
    &:focus {
        box-shadow: none;
        color: #fff;
    }
    &.disabled {
        background-color: rgba(52, 152, 219, 0.5);
        border-color: rgba(52, 152, 219, 0.5);
    }
}

.btn-disabled {
    background-color: rgba(26, 188, 156, 0.5);
    border-color: rgba(26, 188, 156, 0.5);
    color: #fff;
    cursor: pointer;
    transition: all ease-in 0.3s;
}


/**====== Basic-Buttons css end ======**/


/**====== Outline-Buttons css Start ======**/

.btn-outline-primary {
    color: #004a6d;
    background-color: #fff;
    background-color: transparent;
}

.btn-outline-warning {
    color: #b8960b;
    background-color: #fff;
    background-color: transparent;
}

.btn-outline-default {
    color: #9ca5ab;
    background-color: #fff;
    background-color: transparent;
    &:hover {
        color: #fff;
    }
}

.btn-outline-danger {
    color: #cd2a19;
    background-color: #fff;
    background-color: transparent;
}

.btn-outline-success {
    color: #239a55;
    background-color: #fff;
    background-color: transparent;
}

.btn-outline-inverse {
    color: #1e2a37;
    background-color: #fff;
    background-color: transparent;
    &:hover {
        color: #fff;
    }
}

.btn-outline-warning {
    color: #b8960b;
    background-color: #fff;
    background-color: transparent;
}

.btn-outline-info {
    color: #2077b2;
    background-color: #fff;
    background-color: transparent;
}

.btn-outline-disabled {
    color: #13866f;
    background-color: #fff;
}


/**====== Outline-Buttons css end ======**/


/**====== Button-Size css start ======**/

.btn-xlg {
    padding: 14px 20px;
    font-size: 21px;
    line-height: 40px;
}

.btn-lg {
    padding: 12px 18px;
    font-size: 18px;
    line-height: 32px;
}

.btn-md {
    padding: 10px 16px;
    font-size: 15px;
    line-height: 23px;
}

.btn-sm {
    padding: 8px 14px;
    line-height: 16px;
    font-size: 11px;
}

.btn-mini {
    padding: 5px 10px;
    line-height: 14px;
    font-size: 10px;
}


/**====== Button-Size css end ======**/


/**====== Badges-button css start ======**/

.btn-default .badge {
    background-color: #34495e;
    color: #fff;
}

.btn-primary .badge,
.sweet-alert button.confirm .badge,
.wizard>.actions a .badge {
    color: #0073aa;
    background-color: #fff;
}

.btn-success .badge {
    color: #2ecc71;
    background-color: #fff;
}

.btn-info .badge {
    color: #3498DB;
    background-color: #fff;
}

.btn-warning .badge {
    color: #f1c40f;
    background-color: #fff;
}

.btn-danger .badge {
    color: #e74c3c;
    background-color: #fff;
}


/**====== Badges-button css end ======**/


/**====== Social-Buttons css start ======**/

.btn-facebook,
.btn-twitter,
.btn-linkedin,
.btn-dribbble,
.btn-google-plus,
.btn-instagram,
.btn-pinterest,
.btn-dropbox,
.btn-flickr,
.btn-tumblr,
.btn-skype,
.btn-youtube,
.btn-github {
    color: #fff;
}

.btn-facebook {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-twitter {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-linkedin {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-dribbble {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-google-plus {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-instagram {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-pinterest {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-dropbox {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-flickr {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-tumblr {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-skype {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-youtube {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-github {
    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
    }
}

.btn-facebook {
    background-color: #3b5998;
}

.btn-twitter {
    background-color: #00ACED;
}

.btn-linkedin {
    background-color: #007BB6;
}

.btn-dribbble {
    background-color: #EA4C89;
}

.btn-google-plus {
    background-color: #DD4B39;
}

.btn-instagram {
    background-color: #517FA4;
}

.btn-pinterest {
    background-color: #CB2027;
}

.btn-dropbox {
    background-color: #32506D;
}

.btn-tumblr {
    background-color: #00ACED;
}


/**====== Social-Buttons css end ======**/


/**====== Drop-down-Buttons css start ======**/

.dropdown-default .dropdown-item:hover,
.dropdown-split-default .dropdown-item:hover {
    background-color: #bdc3c7;
    color: #fff;
}

.dropdown-primary .dropdown-menu a:hover,
.dropdown-split-primary .dropdown-menu a:hover {
    background-color: #0073aa;
    color: #fff;
}

.dropdown-success .dropdown-menu a:hover,
.dropdown-split-success .dropdown-menu a:hover {
    background-color: #2ecc71;
    color: #fff;
}

.dropdown-info .dropdown-menu a:hover,
.dropdown-split-info .dropdown-menu a:hover {
    background-color: #3498DB;
    color: #fff;
}

.dropdown-warning .dropdown-menu a:hover,
.dropdown-split-warning .dropdown-menu a:hover {
    background-color: #f1c40f;
    color: #fff;
}

.dropdown-danger .dropdown-menu a:hover,
.dropdown-split-danger .dropdown-menu a:hover {
    background-color: #e74c3c;
    color: #fff;
}

.dropdown-inverse .dropdown-item,
.dropdown-split-inverse .dropdown-item {
    transition: all 0.3s ease-in;
}

.dropdown-inverse .dropdown-item:hover,
.dropdown-split-inverse .dropdown-item:hover {
    background-color: #34495e;
    color: #fff;
}


/**====== Drop-down-Buttons css end ======**/


/**====== Theme-Buttons css end ======**/


/**  ===================== Basic form elements css start
==========================  **/

.col-form-label {
    font-size: 14px;
}

.select2-container {
    width: 100% !important;
}

.form-group {
    margin-bottom: 1.25em;
    .form-control-lg {
        font-size: 1.25em;
    }
    .form-control-round {
        border-radius: 50px;
    }
    .form-control-static:focus {
        outline: none;
    }
}

.form-control {
    font-size: 14px;
    border-radius: 2px;
    border-width: 2px;
    &:focus {
        border-color: #0073aa !important;
    }
    &.form-control-normal {
        font-weight: 400;
    }
    &.form-control-bold {
        font-weight: 600;
    }
    &.form-control-capitalize {
        text-transform: capitalize;
    }
    &.form-control-uppercase {
        text-transform: uppercase;
    }
    &.form-control-lowercase {
        text-transform: lowercase;
    }
    &.form-control-variant {
        font-variant: small-caps;
    }
    &.form-control-left {
        text-align: left;
    }
    &.form-control-center {
        text-align: center;
    }
    &.form-control-right {
        text-align: right;
    }
    &.form-control-rtl {
        direction: rtl;
    }
}


/*===== Border Inputs start =====*/

.form-control-primary {
    border-color: #0073aa;
    &:focus {
        border-color: #0073aa;
    }
}

.input-group-primary {
    .input-group-addon {
        background-color: #0073aa;
    }
    .form-control {
        border-color: #0073aa;
    }
}

.form-control-warning {
    border-color: #f1c40f;
    &:focus {
        border-color: #f1c40f;
    }
}

.input-group-warning {
    .input-group-addon {
        background-color: #f1c40f;
    }
    .form-control {
        border-color: #f1c40f;
    }
}

.form-control-default {
    border-color: #bdc3c7;
    &:focus {
        border-color: #bdc3c7;
    }
}

.input-group-default {
    .input-group-addon {
        background-color: #bdc3c7;
    }
    .form-control {
        border-color: #bdc3c7;
    }
}

.form-control-danger {
    border-color: #e74c3c;
    &:focus {
        border-color: #e74c3c;
    }
}

.input-group-danger {
    .input-group-addon {
        background-color: #e74c3c;
    }
    .form-control {
        border-color: #e74c3c;
    }
}

.form-control-success {
    border-color: #2ecc71;
    &:focus {
        border-color: #2ecc71;
    }
}

.input-group-success {
    .input-group-addon {
        background-color: #2ecc71;
    }
    .form-control {
        border-color: #2ecc71;
    }
}

.form-control-inverse {
    border-color: #34495e;
    &:focus {
        border-color: #34495e;
    }
}

.input-group-inverse {
    .input-group-addon {
        background-color: #34495e;
    }
    .form-control {
        border-color: #34495e;
    }
}

.form-control-info {
    border-color: #3498DB;
    &:focus {
        border-color: #3498DB;
    }
}

.input-group-info {
    .input-group-addon {
        background-color: #3498DB;
    }
    .form-control {
        border-color: #3498DB;
    }
}


/*===== Form Input-Text start =====*/

.form-txt-primary {
    color: #0073aa;
    &:focus {
        color: #0073aa;
    }
    &::-moz-placeholder {
        color: #0073aa;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #0073aa;
    }
}

.form-txt-warning {
    color: #f1c40f;
    &:focus {
        color: #f1c40f;
    }
    &::-moz-placeholder {
        color: #f1c40f;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #f1c40f;
    }
}

.form-txt-default {
    color: #bdc3c7;
    &:focus {
        color: #bdc3c7;
    }
    &::-moz-placeholder {
        color: #bdc3c7;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #bdc3c7;
    }
}

.form-txt-danger {
    color: #e74c3c;
    &:focus {
        color: #e74c3c;
    }
    &::-moz-placeholder {
        color: #e74c3c;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #e74c3c;
    }
}

.form-txt-success {
    color: #2ecc71;
    &:focus {
        color: #2ecc71;
    }
    &::-moz-placeholder {
        color: #2ecc71;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #2ecc71;
    }
}

.form-txt-inverse {
    color: #34495e;
    &:focus {
        color: #34495e;
    }
    &::-moz-placeholder {
        color: #34495e;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #34495e;
    }
}

.form-txt-info {
    color: #3498DB;
    &:focus {
        color: #3498DB;
    }
    &::-moz-placeholder {
        color: #3498DB;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #3498DB;
    }
}


/*===== Form Background-inputs start =====*/

.form-bg-primary {
    background-color: #0073aa;
    border-color: #0073aa;
    color: #fff;
    &:focus {
        background-color: #0073aa;
        border-color: #0073aa;
        color: #fff;
    }
    &::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #fff;
    }
}

.form-bg-warning {
    background-color: #f1c40f;
    border-color: #f1c40f;
    color: #fff;
    &:focus {
        background-color: #f1c40f;
        border-color: #f1c40f;
        color: #fff;
    }
    &::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #fff;
    }
}

.form-bg-default {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #fff;
    &:focus {
        background-color: #bdc3c7;
        border-color: #bdc3c7;
        color: #fff;
    }
    &::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #fff;
    }
}

.form-bg-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
    &:focus {
        background-color: #e74c3c;
        border-color: #e74c3c;
        color: #fff;
    }
    &::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #fff;
    }
}

.form-bg-success {
    background-color: #2ecc71;
    border-color: #2ecc71;
    color: #fff;
    &:focus {
        background-color: #2ecc71;
        border-color: #2ecc71;
        color: #fff;
    }
    &::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #fff;
    }
}

.form-bg-inverse {
    background-color: #34495e;
    border-color: #34495e;
    color: #fff;
    &:focus {
        background-color: #34495e;
        border-color: #34495e;
        color: #fff;
    }
    &::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #fff;
    }
}

.form-bg-info {
    background-color: #3498DB;
    border-color: #3498DB;
    color: #fff;
    &:focus {
        background-color: #3498DB;
        border-color: #3498DB;
        color: #fff;
    }
    &::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: #fff;
    }
}


/*===== Form Validation states start =====*/

.has-success {
    .col-form-label {
        color: #2ecc71;
    }
    .form-control-success {
        border-color: #2ecc71;
    }
}

.has-warning {
    .col-form-label {
        color: #f1c40f;
    }
    .form-control-success {
        border-color: #f1c40f;
    }
}

.has-danger {
    .col-form-label {
        color: #e74c3c;
    }
    .form-control-success {
        border-color: #e74c3c;
    }
}


/*===== Form-icon css starts =====*/

.left-icon-control {
    position: relative;
    input {
        padding-left: 50px;
    }
    .form-icon {
        position: absolute;
        top: 50%;
        left: 17px;
        margin-top: -12px;
    }
}

.right-icon-control {
    position: relative;
    input {
        padding-right: 50px;
    }
    .form-icon {
        position: absolute;
        top: 7px;
        right: 17px;
    }
}


/*===== Form-icon css ends =====*/


/*===== Basic form elements ends =====*/


/**  ===================== Form group-add-on css start
==========================  **/

.input-group {
    margin-bottom: 1.25em;
}

.input-group-addon {
    background-color: #0073aa;
    color: #fff;
}

.input-group-button {
    [class*=btn-] {
        padding: 0;
        margin-bottom: 0;
    }
    .input-group-addon {
        transition: all ease-in .3s;
        &:hover {
            background-color: #0096dd;
            border-color: #0096dd;
            .btn {
                background-color: #0096dd;
                border-color: #0096dd;
            }
        }
    }
}

.input-group-dropdown [class*=btn-] {
    margin-bottom: 0;
}


/*===== Form group-add-on ends =====*/


/**  ===================== Switches css start
==========================  **/

.js-dynamic-disable,
.js-dynamic-enable {
    vertical-align: inherit;
}


/*===== Switches css ends =====*/


/**  ===================== Radio-button css start
==========================  **/

.form-radio {
    position: relative;
    .form-help {
        position: absolute;
        width: 100%;
    }
    label {
        position: relative;
        padding-left: 1.5rem;
        text-align: left;
        color: #333;
        display: block;
    }
    input {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
    }
}

.radio {
    .helper {
        position: absolute;
        top: -0.15rem;
        left: -0.25rem;
        cursor: pointer;
        display: block;
        font-size: 1rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #999;
        &::after {
            transform: scale(0);
            background-color: #0073aa;
            border-color: #0073aa;
            content: '';
            position: absolute;
            left: 0;
            top: 3px;
            margin: 0.25rem;
            width: 1rem;
            height: 1rem;
            -webkit-transition: -webkit-transform 0.28s ease;
            transition: -webkit-transform 0.28s ease;
            transition: transform 0.28s ease, -webkit-transform 0.28s ease;
            border-radius: 50%;
            border: 0.125rem solid #0073aa;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 3px;
            margin: 0.25rem;
            width: 1rem;
            height: 1rem;
            -webkit-transition: -webkit-transform 0.28s ease;
            transition: -webkit-transform 0.28s ease;
            transition: transform 0.28s ease, -webkit-transform 0.28s ease;
            border-radius: 50%;
            border: 0.125rem solid #0073aa;
        }
    }
    label:hover .helper {
        color: #0073aa;
    }
    input:checked~.helper {
        &::after {
            transform: scale(0.5);
        }
        &::before {
            color: #0073aa;
        }
    }
    &.radiofill {
        input:checked~.helper::after {
            transform: scale(1);
        }
        .helper::after {
            background-color: #0073aa;
        }
    }
    &.radio-disable {
        opacity: 0.7;
        label {
            cursor: not-allowed;
        }
    }
}

.radio-inline {
    display: inline-block;
    margin-right: 20px;
}

.radio.radiofill {
    &.radio-primary .helper {
        &::after {
            background-color: #0073aa;
            border-color: #0073aa;
        }
        &::before {
            border-color: #0073aa;
        }
    }
    &.radio-warning .helper {
        &::after {
            background-color: #f1c40f;
            border-color: #f1c40f;
        }
        &::before {
            border-color: #f1c40f;
        }
    }
    &.radio-default .helper {
        &::after {
            background-color: #bdc3c7;
            border-color: #bdc3c7;
        }
        &::before {
            border-color: #bdc3c7;
        }
    }
    &.radio-danger .helper {
        &::after {
            background-color: #e74c3c;
            border-color: #e74c3c;
        }
        &::before {
            border-color: #e74c3c;
        }
    }
    &.radio-success .helper {
        &::after {
            background-color: #2ecc71;
            border-color: #2ecc71;
        }
        &::before {
            border-color: #2ecc71;
        }
    }
    &.radio-inverse .helper {
        &::after {
            background-color: #34495e;
            border-color: #34495e;
        }
        &::before {
            border-color: #34495e;
        }
    }
    &.radio-info .helper {
        &::after {
            background-color: #3498DB;
            border-color: #3498DB;
        }
        &::before {
            border-color: #3498DB;
        }
    }
}


/*===== Radio Button css ends =====*/


/*===== Border-checkbox css start =====*/

.border-checkbox-section {
    .border-checkbox-group {
        display: inline-block;
        .border-checkbox:checked+.border-checkbox-label:after {
            -webkit-animation: check linear 0.5s;
            animation: check linear 0.5s;
            opacity: 1;
            border-color: #0073aa;
            .border-checkbox-label:before {
                border-color: #eee;
            }
        }
        .border-checkbox-label {
            position: relative;
            display: inline-block;
            cursor: pointer;
            height: 25px;
            line-height: 25px;
            padding-left: 30px;
            margin-right: 15px;
            &:after {
                content: "";
                display: block;
                width: 5px;
                height: 15px;
                opacity: .9;
                border-right: 2px solid #eee;
                border-top: 2px solid #eee;
                position: absolute;
                left: 5px;
                top: 15px;
                -webkit-transform: scaleX(-1) rotate(135deg);
                transform: scaleX(-1) rotate(135deg);
                -webkit-transform-origin: left top;
                transform-origin: left top;
            }
            &:before {
                content: "";
                display: block;
                border: 2px solid #0073aa;
                width: 25px;
                height: 25px;
                position: absolute;
                left: 0;
            }
        }
    }
    .border-checkbox {
        display: none;
        &:disabled~.border-checkbox-label {
            cursor: no-drop;
            color: #ccc;
        }
    }
    .border-checkbox-group-primary {
        .border-checkbox-label:before {
            border: 2px solid #0073aa;
        }
        .border-checkbox:checked+.border-checkbox-label:after {
            border-color: #0073aa;
        }
    }
    .border-checkbox-group-warning {
        .border-checkbox-label:before {
            border: 2px solid #f1c40f;
        }
        .border-checkbox:checked+.border-checkbox-label:after {
            border-color: #f1c40f;
        }
    }
    .border-checkbox-group-default {
        .border-checkbox-label:before {
            border: 2px solid #bdc3c7;
        }
        .border-checkbox:checked+.border-checkbox-label:after {
            border-color: #bdc3c7;
        }
    }
    .border-checkbox-group-danger {
        .border-checkbox-label:before {
            border: 2px solid #e74c3c;
        }
        .border-checkbox:checked+.border-checkbox-label:after {
            border-color: #e74c3c;
        }
    }
    .border-checkbox-group-success {
        .border-checkbox-label:before {
            border: 2px solid #2ecc71;
        }
        .border-checkbox:checked+.border-checkbox-label:after {
            border-color: #2ecc71;
        }
    }
    .border-checkbox-group-inverse {
        .border-checkbox-label:before {
            border: 2px solid #34495e;
        }
        .border-checkbox:checked+.border-checkbox-label:after {
            border-color: #34495e;
        }
    }
    .border-checkbox-group-info {
        .border-checkbox-label:before {
            border: 2px solid #3498DB;
        }
        .border-checkbox:checked+.border-checkbox-label:after {
            border-color: #3498DB;
        }
    }
}

@-webkit-keyframes check {
    0% {
        height: 0;
        width: 0;
    }
    25% {
        height: 0;
        width: 5px;
    }
    50% {
        height: 15px;
        width: 5px;
    }
}

@keyframes check {
    0% {
        height: 0;
        width: 0;
    }
    25% {
        height: 0;
        width: 5px;
    }
    50% {
        height: 15px;
        width: 5px;
    }
}


/*===== Fade-in-checkbox css start =====*/

.checkbox-fade {
    display: inline-block;
    margin-right: 15px;
    label {
        line-height: 25px;
        input {
            &[type="checkbox"],
            &[type="radio"] {
                display: none;
            }
            &[type="checkbox"]:checked+.cr>.cr-icon,
            &[type="radio"]:checked+.cr>.cr-icon {
                transform: scale(1) rotateZ(0deg);
                opacity: 1;
            }
            &[type="checkbox"]+.cr>.cr-icon,
            &[type="radio"]+.cr>.cr-icon {
                transform: scale(3) rotateZ(-20deg);
                opacity: 0;
                transition: all 0.3s ease-in;
            }
        }
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    &.fade-in-disable {
        label,
        .cr {
            color: #ccc;
            cursor: no-drop;
        }
    }
    .cr {
        border-radius: 0px;
        border: 2px solid #0073aa;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 25px;
        margin-right: 0.5em;
        position: relative;
        width: 25px;
        .cr-icon {
            color: #0073aa;
            font-size: 0.8em;
            left: 0;
            line-height: 0;
            position: absolute;
            right: 0;
            text-align: center;
            top: 50%;
        }
    }
    &.fade-in-primary .cr {
        border: 2px solid #0073aa;
        .cr-icon {
            color: #0073aa;
        }
    }
    &.fade-in-warning .cr {
        border: 2px solid #f1c40f;
        .cr-icon {
            color: #f1c40f;
        }
    }
    &.fade-in-default .cr {
        border: 2px solid #bdc3c7;
        .cr-icon {
            color: #bdc3c7;
        }
    }
    &.fade-in-danger .cr {
        border: 2px solid #e74c3c;
        .cr-icon {
            color: #e74c3c;
        }
    }
    &.fade-in-success .cr {
        border: 2px solid #2ecc71;
        .cr-icon {
            color: #2ecc71;
        }
    }
    &.fade-in-inverse .cr {
        border: 2px solid #34495e;
        .cr-icon {
            color: #34495e;
        }
    }
    &.fade-in-info .cr {
        border: 2px solid #3498DB;
        .cr-icon {
            color: #3498DB;
        }
    }
}


/*===== Color-checkbox css start =====*/

.checkbox-color {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    label {
        display: inline-block;
        position: relative;
        padding-left: 10px;
        line-height: 25px;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 3px;
            width: 25px;
            height: 25px;
            left: 0;
            right: 0;
            text-align: center;
            margin-left: -20px;
            border: 1px solid #ccc;
            border-radius: 0;
            background-color: #fff;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        }
        &::after {
            display: inline-block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 2px;
            margin-left: -17px;
            padding-left: 3px;
            padding-top: 1px;
            font-size: 11px;
            color: #fff;
        }
    }
    input[type="checkbox"] {
        opacity: 0;
        &:focus+label::before {
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }
        &:checked+label::after {
            font-family: 'FontAwesome';
            content: "\f00c";
        }
        &:disabled+label {
            opacity: 0.65;
            &::before {
                background-color: #eee;
                cursor: not-allowed;
            }
        }
    }
    &.checkbox-circle label::before {
        border-radius: 50%;
    }
    &.checkbox-inline {
        margin-top: 0;
    }
}

.checkbox-primary input[type="checkbox"]:checked+label::after,
.checkbox-danger input[type="checkbox"]:checked+label::after,
.checkbox-info input[type="checkbox"]:checked+label::after,
.checkbox-warning input[type="checkbox"]:checked+label::after,
.checkbox-success input[type="checkbox"]:checked+label::after {
    color: #fff;
}

.checkbox-primary input[type="checkbox"]:checked+label::before {
    background-color: #2196f3;
    border-color: #2196f3;
    background-color: #0073aa;
}

.checkbox-warning input[type="checkbox"]:checked+label::before {
    background-color: #f1c40f;
}

.checkbox-default input[type="checkbox"]:checked+label::before {
    background-color: #bdc3c7;
}

.checkbox-danger input[type="checkbox"]:checked+label::before {
    background-color: #e74c3c;
}

.checkbox-success input[type="checkbox"]:checked+label::before {
    background-color: #2ecc71;
}

.checkbox-inverse input[type="checkbox"]:checked+label::before {
    background-color: #34495e;
}

.checkbox-info input[type="checkbox"]:checked+label::before {
    background-color: #3498DB;
}


/*===== Tag input css start =====*/

.bootstrap-tagsinput {
    border: 1px solid #0073aa;
    line-height: 30px;
    border-radius: 2px;
    .tag {
        padding: 6px;
        border-radius: 2px;
    }
}


/**  ===================== Select-2 css start
==========================  **/

.select2-container--default {
    &:focus {
        border-color: #0073aa;
    }
    &.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: transparent transparent #fff transparent;
    }
    .select2-search__field:focus {
        border: 1px solid #0073aa;
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: #0073aa;
    }
    .select2-selection--multiple {
        padding: 3px 30px 4px 10px;
        .select2-selection__choice {
            background-color: #0073aa;
            border: 1px solid #0073aa;
            padding: 5px 15px;
            color: #fff;
            span {
                color: #fff;
            }
        }
        .select2-search__field {
            border: none;
        }
    }
    &.select2-container--focus .select2-selection--multiple {
        border: 1px solid #0073aa;
    }
    .select2-selection--single {
        color: #fff;
        height: auto;
        .select2-selection__rendered {
            background-color: #0073aa;
            color: #fff;
            padding: 8px 30px 8px 20px;
        }
        .select2-selection__arrow {
            top: 10px;
            right: 15px;
            b {
                border-color: #fff transparent transparent transparent;
            }
        }
    }
}


/*===== Select-2 css ends =====*/


/**  ===================== Multi-select css start
==========================  **/

.ms-container {
    .ms-list.ms-focus {
        border-color: #0073aa;
        box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49), 0 0 8px rgba(26, 188, 156, 0.5);
    }
    .ms-selectable li.ms-hover,
    .ms-selection li.ms-hover {
        background-color: #0073aa;
    }
}

.ms-selectable .custom-header,
.ms-selection .custom-header {
    background-color: #0073aa;
    color: #fff;
    text-align: center;
}

.ms-list {
    display: block !important;
    li {
        width: 100%;
    }
}


/*===== Multi-select css ends =====*/


/**  ===================== Validation-forms css start
==========================  **/

.form-group {
    .messages p {
        margin-bottom: 0;
        transition: all ease-in .3s;
    }
    .popover-valid {
        position: absolute;
        right: 40px;
        top: 7px;
    }
}


/*===== Validation-forms css end =====*/


/**  ===================== Form-wizards css start
==========================  **/

.wizard>.steps .current a {
    background: #0073aa;
    transition: all ease-in .3s;
    cursor: pointer;
    border-radius: 2px;
    outline: 1px solid #fff;
    outline-offset: -7px;
    &:hover {
        background: #0084c4;
        outline-offset: 0;
    }
}

#design-wizard .steps li {
    position: relative;
    z-index: 99;
    margin-bottom: 20px;
    &:after {
        content: "";
        position: absolute;
        height: 2px;
        background: #0073aa;
        width: 100%;
        top: 30px;
        z-index: -1;
    }
    a {
        width: 20%;
        margin: 0 auto;
        text-align: center;
        border-radius: 2px;
    }
}

.wizard>.steps .done a {
    background: #bdc3c7;
}

.card .card-body .wizard-form {
    .steps ul {
        display: block;
    }
    li {
        margin-right: 0;
    }
}

.wizard>.content {
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #fff;
}


/*===== Form-wizard forms css end =====*/


/**  ===================== Label-Badges css start
==========================  **/

.label {
    border-radius: 4px;
    font-size: 75%;
    padding: 4px 7px;
    margin-right: 5px;
    font-weight: 700;
    color: #fff !important;
}

.label-main {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 5px;
}

.badge {
    border-radius: 10px;
    padding: 3px 7px;
}

.label {
    &.label-lg {
        padding: 8px 21px;
    }
    &.label-md {
        padding: 6px 14px;
    }
}

.badge-lg {
    padding: 5px 9px;
    font-size: 14px;
}

.badge-md {
    padding: 4px 8px;
    font-size: 14px;
}

span.badge {
    display: inline-block !important;
}

.label-default {
    background-color: #bdc3c7 !important;
    border-color: #bdc3c7;
    box-shadow: none;
    color: #fff;
}

.label-inverse-default {
    border: 1px solid;
    border-color: #bdc3c7;
    color: #bdc3c7 !important;
}

.label-inverse-primary {
    border: 1px solid;
    border-color: #0073aa;
    color: #0073aa !important;
}

.label-inverse-success {
    border: 1px solid;
    border-color: #2ecc71;
    color: #2ecc71 !important;
}

.label-inverse-warning {
    border: 1px solid;
    border-color: #f1c40f;
    color: #f1c40f !important;
}

.label-inverse-danger {
    border: 1px solid;
    border-color: #e74c3c;
    color: #e74c3c !important;
}

.label-inverse-info {
    border: 1px solid;
    border-color: #3498DB;
    color: #3498DB !important;
}

.label-inverse-info-border {
    border: 1px solid;
    border-color: #34495e;
    color: #34495e !important;
}

.badge-inverse-default {
    border: 1px solid;
    border-color: #bdc3c7;
    color: #bdc3c7 !important;
}

.badge-inverse-primary {
    border: 1px solid;
    border-color: #0073aa;
    color: #0073aa !important;
}

.badge-inverse-success {
    border: 1px solid;
    border-color: #2ecc71;
    color: #2ecc71 !important;
}

.badge-inverse-warning {
    border: 1px solid;
    border-color: #f1c40f;
    color: #f1c40f !important;
}

.badge-inverse-danger {
    border: 1px solid;
    border-color: #e74c3c;
    color: #e74c3c !important;
}

.badge-inverse-info {
    border: 1px solid;
    border-color: #34495e;
    color: #34495e !important;
}

.label-icon label {
    position: absolute;
}

.icofont.icofont-envelope {
    font-size: 20px;
}


/*===== Label and Badges css end =====*/


/* ====================== All Icon Common Page css
======================== */

.data-table-main {
    &.icon-list-demo [class*=col-],
    &.icon-svg-demo [class*=col-] {
        margin-bottom: 10px;
    }
}

.icon-list-demo {
    i {
        border: 1px solid #eceeef;
        border-radius: 3px;
        color: rgba(43, 61, 81, 0.7);
        display: inline-block;
        font-size: 24px;
        height: 50px;
        line-height: 50px;
        margin: 0 12px 0 0;
        text-align: center;
        vertical-align: middle;
        width: 50px;
    }
    div {
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: 10px;
    }
    i:hover {
        color: #64b0f2;
    }
}


/**====== Flag-icon css start ======**/

.flags .f-item {
    padding: 12px;
    border: 1px solid #ddd;
    margin-right: 15px;
    display: inline-block;
}

.data-table-main.flags [class*=col-] {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.content-flag {
    label {
        margin-bottom: 0;
        cursor: pointer;
    }
    .txt-ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 120px;
        overflow: hidden;
    }
}

.outer-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: 250px;
}

label.txt-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: 150px;
}

.flags .f-item {
    .name,
    .capital {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 0;
    }
    .code {
        display: block;
        font-size: 90%;
        color: #999;
        padding-bottom: 0;
    }
}


/**====== All icon common page css end ======**/


/**  ===================== Ready-to-use css start
==========================  **/

.bg-pic {
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
}

.j-pro {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    .j-header {
        background-color: #0073aa;
        border-top: 1px solid #0073aa;
        box-shadow: none;
    }
    .j-icon-right {
        border-left: 1px solid rgba(0, 0, 0, 0.32);
    }
    .j-icon-left {
        border-right: 1px solid rgba(0, 0, 0, 0.32);
    }
    .j-footer {
        background-color: #fff;
        border-top: 1px dashed #0073aa;
        padding: 20px 25px;
        button {
            margin-bottom: 0;
            float: right;
        }
    }
    .j-divider-text span {
        color: #222;
    }
    .btn-primary.disabled,
    .sweet-alert button.disabled.confirm {
        background-color: #2ecc71;
        border-color: #2ecc71;
    }
}

.sweet-alert .j-pro button.disabled.confirm,
.j-pro .wizard>.actions a.disabled,
.wizard>.actions .j-pro a.disabled {
    background-color: #2ecc71;
    border-color: #2ecc71;
}

.j-pro {
    .btn-primary:disabled,
    .sweet-alert button.confirm:disabled {
        background-color: #2ecc71;
        border-color: #2ecc71;
    }
}

.sweet-alert .j-pro button.confirm:disabled,
.j-pro .wizard>.actions a:disabled,
.wizard>.actions .j-pro a:disabled {
    background-color: #2ecc71;
    border-color: #2ecc71;
}

.j-pro {
    input {
        &[type="text"],
        &[type="password"],
        &[type="email"],
        &[type="search"],
        &[type="url"] {
            border: 1px solid rgba(0, 0, 0, 0.15);
        }
    }
    textarea,
    select {
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
}

.j-forms {
    input {
        &[type="text"],
        &[type="password"],
        &[type="email"],
        &[type="search"],
        &[type="url"] {
            border: 1px solid rgba(0, 0, 0, 0.15);
        }
    }
    textarea,
    select {
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
}

.j-pro {
    input {
        &[type="text"]:hover,
        &[type="password"]:hover,
        &[type="email"]:hover,
        &[type="search"]:hover,
        &[type="url"]:hover {
            border: 1px solid #0073aa;
        }
    }
    textarea:hover,
    select:hover {
        border: 1px solid #0073aa;
    }
    input {
        &[type="text"]:focus,
        &[type="password"]:focus,
        &[type="email"]:focus,
        &[type="search"]:focus,
        &[type="url"]:focus {
            border: 1px solid #0073aa;
        }
    }
    textarea:focus,
    select:focus,
    .j-file-button:hover+input {
        border: 1px solid #0073aa;
    }
}

.j-forms {
    input {
        &[type="text"]:hover,
        &[type="password"]:hover,
        &[type="email"]:hover,
        &[type="search"]:hover,
        &[type="url"]:hover {
            border: 1px solid #0073aa;
        }
    }
    textarea:hover,
    select:hover {
        border: 1px solid #0073aa;
    }
    input {
        &[type="text"]:focus,
        &[type="password"]:focus,
        &[type="email"]:focus,
        &[type="search"]:focus,
        &[type="url"]:focus {
            border: 1px solid #0073aa;
        }
    }
    textarea:focus,
    select:focus {
        border: 1px solid #0073aa;
    }
}

.j-pro {
    .j-radio-toggle,
    .j-checkbox-toggle {
        padding: 9px 0 18px 0;
    }
    .j-inline-group {
        .j-radio-toggle,
        .j-checkbox-toggle {
            padding: 9px 0 18px 0;
        }
    }
    .j-tooltip,
    .j-tooltip-image {
        background-color: #0073aa;
    }
    .j-tooltip:before,
    .j-tooltip-image:before {
        border-color: #0073aa transparent;
    }
    .j-primary-btn,
    .j-file-button,
    .j-secondary-btn,
    .j-widget .j-addon-btn {
        background-color: #0073aa;
    }
    .j-primary-btn:hover,
    .j-file-button:hover,
    .j-secondary-btn:hover,
    .j-widget .j-addon-btn:hover {
        background-color: #0084c4;
    }
    .j-ratings input {
        +label:hover,
        &:checked+label {
            color: #0073aa;
            ~label {
                color: #0073aa;
            }
        }
    }
}

.j-unit .checkbox-fade {
    display: block;
    margin-top: 20px;
}

.j-forms {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    button i {
        margin-right: 0;
    }
    .checkbox-fade {
        margin-top: 10px;
        .disabled-view {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .link {
        color: #0073aa;
        border-bottom: none;
        text-transform: capitalize;
        margin-bottom: 15px;
    }
    .label {
        margin-bottom: 15px;
    }
    .btn-primary:disabled,
    .sweet-alert button.confirm:disabled {
        background-color: #bdc3c7;
        border-color: #bdc3c7;
    }
}

.sweet-alert .j-forms button.confirm:disabled,
.j-forms .wizard>.actions a:disabled,
.wizard>.actions .j-forms a:disabled {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
}

.j-forms {
    .radio-toggle,
    .checkbox-toggle {
        padding: 9px 0 8px 0px;
    }
    .inline-group {
        .radio-toggle,
        .checkbox-toggle {
            padding: 9px 0 8px 0px;
        }
    }
    .header {
        background-color: #0073aa;
        border-top: 1px solid #0073aa;
        box-shadow: none;
    }
    .divider-text span {
        color: #222;
    }
    .widget {
        .addon,
        .addon-btn {
            background-color: #0073aa;
            color: #fff;
        }
        .addon {
            &:hover,
            &:focus {
                background-color: #0084c4;
            }
        }
        .addon-btn {
            &:hover,
            &:focus {
                background-color: #0084c4;
            }
        }
        .addon {
            &:hover i,
            &:focus i {
                color: #fff;
            }
        }
        .addon-btn {
            &:hover i,
            &:focus i {
                color: #fff;
            }
        }
        .addon i,
        .addon-btn i {
            color: #fff;
        }
    }
    .footer {
        background-color: #fff;
        border-top: 1px dashed #1abc9c;
        padding: 20px 25px;
        button {
            float: right;
            margin-bottom: 0;
        }
    }
    .stepper .stepper-arrow {
        background-color: #0073aa;
        &:hover {
            background-color: #0084c4;
        }
        &.up:after {
            border-bottom: 7px solid #fff;
        }
        &.down:after {
            border-top: 7px solid #fff;
        }
    }
}

.popup-menu {
    padding: 0;
    .popup-list {
        background-color: #2C3E50;
        border-radius: 0;
        >ul>li {
            transition: all ease-in .3s;
            color: #fff;
            border-left: none;
            cursor: pointer;
            &:hover {
                background-color: #384c5f;
                color: #fff;
            }
        }
    }
}

.j-tabs-container {
    .j-tabs-label,
    input[type="radio"]:checked+.j-tabs-label {
        border-top: 4px solid #0073aa;
    }
}

.pop-up-logo img {
    margin-top: 10px;
    margin-left: 80px;
}

.popup-list-open .popup-list-wrapper {
    z-index: 99;
}

.span4 label {
    color: #222 !important;
}

.pop-up-wrapper {
    margin-left: 0 !important;
}


/**====== Ready-to-use css end ======**/


/**  ===================== Box-shadow css start
==========================  **/

.box-list {
    div div div {
        margin-bottom: 20px;
    }
    padding-bottom: 0;
    p {
        margin-bottom: 0 !important;
    }
}


/**====== z-depth-top css start ======**/

.z-depth-top-0 {
    box-shadow: 0 -5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-top-1 {
    box-shadow: 0 -7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-top-2 {
    box-shadow: 0 -9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-top-3 {
    box-shadow: 0 -10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-top-4 {
    box-shadow: 0 -11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-top-5 {
    box-shadow: 0 -12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}


/**====== z-depth-top css end ======**/


/**====== z-depth-bottom css start ======**/

.z-depth-bottom-0 {
    box-shadow: 0 5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-bottom-1 {
    box-shadow: 0 7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-bottom-2 {
    box-shadow: 0 9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-bottom-3 {
    box-shadow: 0 10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-bottom-4 {
    box-shadow: 0 11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-bottom-5 {
    box-shadow: 0 12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}


/**====== z-depth-bottom css end ======**/


/**====== z-depth-left css start ======**/

.z-depth-left-0 {
    box-shadow: -5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-left-1 {
    box-shadow: -7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-left-2 {
    box-shadow: -9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-left-3 {
    box-shadow: -10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-left-4 {
    box-shadow: -11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-left-5 {
    box-shadow: -12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}


/**====== z-depth-left css end ======**/


/**====== z-depth-right css start ======**/

.z-depth-right-0 {
    box-shadow: 5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-right-1 {
    box-shadow: 7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-right-2 {
    box-shadow: 9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-right-3 {
    box-shadow: 10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-right-4 {
    box-shadow: 11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-right-5 {
    box-shadow: 12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}


/**====== z-depth-right css end ======**/


/**====== z-depth-all-side css start ======**/

.z-depth-0 {
    box-shadow: 0 0 25px 5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-1 {
    box-shadow: 0 0 25px 7px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-2 {
    box-shadow: 0 0 25px 9px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-3 {
    box-shadow: 0 0 25px 10px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-4 {
    box-shadow: 0 0 25px 11px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}

.z-depth-5 {
    box-shadow: 0 0 25px 12px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 transparent;
}


/**====== z-depth-all-side css end ======**/


/**====== Box-shadow css end ======**/


/**  ===================== Data-table css start
==========================  **/

table.table-bordered.dataTable tbody {
    th:focus,
    td:focus {
        outline: none;
    }
}

.card .card-body ul.pagination li {
    margin-right: 0;
}

.page-item.active .page-link {
    background-color: #0073aa;
    border-color: #0073aa;
}

.page-link {
    color: #222;
}

td.highlight {
    font-weight: 700;
    color: #3498DB;
    background-color: #f5f5f5;
}

.table.compact {
    td,
    th {
        padding: .45rem;
    }
}

.dataTables_paginate .pagination {
    float: right;
}

#multi-table_wrapper .dataTables_paginate .pagination {
    float: none;
}

#footer-search tfoot .form-control,
#footer-select tfoot .form-control,
#form-input-table .form-control,
.search-api .form-control,
#dt-live-dom .form-control {
    width: 90%;
}

.search-api {
    .global_filter,
    .column_filter {
        margin: 0 auto;
    }
    .checkbox-fade {
        display: block;
        text-align: center;
    }
}

td.details-control {
    background: url("assets/images/details_open.png") no-repeat center center;
    cursor: pointer;
}

tr.shown td.details-control {
    background: url("assets/images/details_close.png") no-repeat center center;
}

#row-select .selected,
#row-delete .selected {
    background-color: #0073aa;
    color: #fff;
}


/*===== Autofill data-table ===== */

div.dt-autofill-list div.dt-autofill-button button {
    background-color: #0073aa;
    border-color: #0073aa;
}

table.dataTable {
    border-collapse: collapse !important;
    tbody>tr {
        &.selected,
        >.selected {
            background-color: #0073aa;
            color: #fff;
        }
    }
    td.select-checkbox:before,
    th.select-checkbox:before {
        margin-top: 0;
    }
    tr.selected {
        td.select-checkbox:after,
        th.select-checkbox:after {
            margin-top: -6px;
            margin-left: -5px;
        }
    }
}


/*===== Button data-table ===== */

button.dt-button,
div.dt-button,
a.dt-button,
button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled) {
    background-color: #0073aa;
    border-color: #0073aa;
    border-radius: 2px;
    color: #fff;
    background-image: none;
    font-size: 14px;
}

button.dt-button {
    &:active:not(.disabled),
    &.active:not(.disabled) {
        background-color: #0073aa;
        border-color: #0073aa;
        border-radius: 2px;
        color: #fff;
        background-image: none;
        font-size: 14px;
    }
}

div.dt-button {
    &:active:not(.disabled),
    &.active:not(.disabled) {
        background-color: #0073aa;
        border-color: #0073aa;
        border-radius: 2px;
        color: #fff;
        background-image: none;
        font-size: 14px;
    }
}

a.dt-button {
    &:active:not(.disabled),
    &.active:not(.disabled) {
        background-color: #0073aa;
        border-color: #0073aa;
        border-radius: 2px;
        color: #fff;
        background-image: none;
        font-size: 14px;
    }
}

button.dt-button.btn-warning,
div.dt-button.btn-warning,
a.dt-button.btn-warning {
    background-color: #f1c40f;
    border-color: #f1c40f;
    border-radius: 2px;
    color: #fff;
    background-image: none;
}

button.dt-button.btn-danger,
div.dt-button.btn-danger,
a.dt-button.btn-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
    border-radius: 2px;
    color: #fff;
    background-image: none;
}

button.dt-button.btn-inverse,
div.dt-button.btn-inverse,
a.dt-button.btn-inverse {
    background-color: #34495e;
    border-color: #34495e;
    border-radius: 2px;
    color: #fff;
    background-image: none;
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
    background-image: none;
    background-color: #0084c4;
    border-color: #0073aa;
}

button.dt-button.btn-warning:hover:not(.disabled),
div.dt-button.btn-warning:hover:not(.disabled),
a.dt-button.btn-warning:hover:not(.disabled) {
    background-image: none;
    background-color: #f4d03f;
    border-color: #f1c40f;
}

button.dt-button.btn-danger:hover:not(.disabled),
div.dt-button.btn-danger:hover:not(.disabled),
a.dt-button.btn-danger:hover:not(.disabled) {
    background-image: none;
    background-color: #ed7669;
    border-color: #e74c3c;
}

button.dt-button.btn-inverse:hover:not(.disabled),
div.dt-button.btn-inverse:hover:not(.disabled),
a.dt-button.btn-inverse:hover:not(.disabled) {
    background-image: none;
    background-color: #46637f;
    border-color: #34495e;
}

div {
    &.dt-button-collection {
        button.dt-button {
            &:active:not(.disabled),
            &.active:not(.disabled) {
                background-color: #0073aa;
                border-color: #0073aa;
                background-image: none;
            }
        }
        div.dt-button {
            &:active:not(.disabled),
            &.active:not(.disabled) {
                background-color: #0073aa;
                border-color: #0073aa;
                background-image: none;
            }
        }
        a.dt-button {
            &:active:not(.disabled),
            &.active:not(.disabled) {
                background-color: #0073aa;
                border-color: #0073aa;
                background-image: none;
            }
        }
    }
    &.dt-buttons {
        clear: both;
    }
}

.card .table-card-header {
    b {
        display: block;
        color: #0073aa;
        margin-top: 15px;
    }
    span {
        color: #005177;
        display: inline-block;
        margin-top: 0;
    }
}

div.dataTables_wrapper div.dataTables_info {
    display: inline-block;
}


/*===== Colreorder data-table ===== */

table.DTCR_clonedTable.dataTable {
    position: absolute !important;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 202;
}

div.DTCR_pointer {
    width: 1px;
    background-color: #0259C4;
    z-index: 201;
}


/*===== Fixed-column data-table ===== */

table.DTFC_Cloned {
    thead,
    tfoot {
        background-color: white;
    }
}

div {
    &.DTFC_Blocker {
        background-color: white;
    }
    &.DTFC_LeftWrapper table.dataTable,
    &.DTFC_RightWrapper table.dataTable {
        margin-bottom: 0;
        z-index: 2;
    }
    &.DTFC_LeftWrapper table.dataTable.no-footer,
    &.DTFC_RightWrapper table.dataTable.no-footer {
        border-bottom: none;
    }
}

th,
td {
    white-space: nowrap;
}


/*===== Fixed-header data-table ===== */

table {
    &.fixedHeader-floating {
        position: fixed !important;
        background-color: white;
        &.no-footer {
            border-bottom-width: 0;
        }
    }
    &.fixedHeader-locked {
        position: absolute !important;
        background-color: white;
    }
    &.dataTable {
        th.focus {
            outline: 3px solid #0073aa;
            outline-offset: -1px;
        }
        td.focus {
            outline: 3px solid #0073aa;
            outline-offset: -1px;
            outline: 1px solid #e74c3c;
            outline-offset: -3px;
            background-color: #f8e6e6 !important;
        }
    }
}

@media print {
    table.fixedHeader-floating {
        display: none;
    }
}


/*===== Key-table data-table ===== */

#events {
    margin-bottom: 1em;
    padding: 1em;
    background-color: #f6f6f6;
    border: 1px solid #999;
    border-radius: 3px;
    height: 100px;
    overflow: auto;
}


/*===== Row-Re-order data-table ===== */

table.dt-rowReorder-float {
    position: absolute !important;
    opacity: 0.8;
    table-layout: fixed;
    outline: 2px solid #888;
    outline-offset: -2px;
    z-index: 2001;
}

tr.dt-rowReorder-moving {
    outline: 2px solid #555;
    outline-offset: -2px;
}

body.dt-rowReorder-noOverflow {
    overflow-x: hidden;
}

table.dataTable td.reorder {
    text-align: center;
    cursor: move;
}

#result {
    border: 1px solid #888;
    background: #f7f7f7;
    padding: 1em;
    margin-bottom: 1em;
}


/*===== Scroller data-table ===== */

div.DTS {
    display: block !important;
    tbody {
        th,
        td {
            white-space: nowrap;
        }
    }
    div {
        &.DTS_Loading {
            z-index: 1;
        }
        &.dataTables_scrollBody table {
            z-index: 2;
        }
        &.dataTables_paginate,
        &.dataTables_length {
            display: none;
        }
    }
}


/**====== Data-table css end ======**/


/**  ===================== Foo-table css start
==========================  **/

.footable .pagination> {
    .active> {
        a {
            background-color: #0073aa;
            border-color: #0073aa;
            &:focus,
            &:hover {
                background-color: #0073aa;
                border-color: #0073aa;
            }
        }
        span {
            background-color: #0073aa;
            border-color: #0073aa;
            &:focus,
            &:hover {
                background-color: #0073aa;
                border-color: #0073aa;
            }
        }
    }
    li> {
        a,
        span {
            color: #222;
        }
    }
}

.footable-details.table,
.footable.table {
    margin-bottom: 0;
}

table {
    &.footable>tfoot>tr.footable-paging>td>span.label {
        margin-bottom: 0;
    }
    &.footable-paging-center>tfoot>tr.footable-paging>td {
        padding-bottom: 0;
    }
}


/**====== Foo-table css end ======**/


/**  ===================== Handson css start
==========================  **/

.make-me-red {
    color: red;
}

.scroll-container {
    .wtHolder {
        height: 350px !important;
    }
    #highlighting .wtHolder,
    #populating .wtHolder,
    #paginating .wtHolder,
    #searching .wtHolder,
    #drag .wtHolder,
    #validation .wtHolder,
    #readOnly .wtHolder,
    #nonEditable .wtHolder,
    #numericData .wtHolder,
    #dateDate .wtHolder,
    #timeData .wtHolder,
    #checkbox .wtHolder,
    #select .wtHolder,
    #dropdown .wtHolder,
    #autocomplete .wtHolder,
    #jQuery .wtHolder,
    #chromaJS .wtHolder,
    #context .wtHolder,
    #configuration .wtHolder,
    #copyPaste .wtHolder,
    #buttons .wtHolder,
    #comments .wtHolder {
        height: auto !important;
    }
}

.handson-pagination {
    margin-top: 30px;
}

.currentRow {
    background-color: #F9F9FB !important;
}

.currentCol {
    background-color: #E7E8EF !important;
}

.contact-table tr td {
    &:nth-child(n-2) {
        text-align: center;
    }
    &:last-child {
        position: relative;
        .dropdown-menu {
            top: 52px;
        }
    }
}


/**====== Handson css end ======**/

.card-body-big {
    padding: 2em;
}

.widget-row-set {
    margin-left: 0;
    margin-right: 0;
}

.borderless-card {
    border-top: none;
}

.widget-main-card {
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.11);
}

.counter-card-1 h3,
.counter-card-2 h3,
.counter-card-3 h3 {
    margin-bottom: 16px;
    font-weight: 600;
    color: #222;
}

.counter-card-1 [class*="card-"]>i,
.counter-card-2 [class*="card-"]>i,
.counter-card-3 [class*="card-"]>i {
    position: relative;
    font-size: 40px;
    border: 4px solid #2ecc71;
    border-left: 4px solid #ccc;
    border-radius: 50%;
    padding: 21px 24px;
    color: #2ecc71;
    top: 20px;
    left: 5px;
}

.counter-card-1 .counter-card-text,
.counter-card-2 .counter-card-text,
.counter-card-3 .counter-card-text {
    display: inline-block;
    text-align: center;
}

.counter-card-2 [class*="card-"]>i {
    border: 4px solid #e74c3c;
    border-left: 4px solid #ccc;
    color: #e74c3c;
}

.counter-card-3 [class*="card-"]>i {
    border: 4px solid #f1c40f;
    border-left: 4px solid #ccc;
    color: #f1c40f;
}

.turnover-graph {
    .card-body {
        .turnover-price {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 40px;
            font-weight: 600;
        }
        .turnover-rate {
            position: absolute;
            left: 20px;
            top: 60px;
            background-color: #0073aa;
            color: #fff;
            border-radius: 20px;
            padding: 4px 15px;
        }
    }
    #turnoverareachart {
        margin-bottom: -5px;
        min-height: 200px;
    }
}

.sale-report-card {
    border-top: none !important;
    .sale-rate {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .progress {
        height: 0.5rem;
    }
    p {
        margin-top: 30px;
        font-size: 15px;
    }
    h5,
    p {
        color: #fff;
    }
    &:nth-child(1) {
        background-color: #2ecc71;
        .progress-bar-success {
            background-color: #25a25a;
        }
    }
    &:nth-child(2) {
        background-color: #f1c40f;
        .progress-bar-warning {
            background-color: #c29d0b;
        }
    }
}

.week-status-card {
    text-align: center;
    .card-footer {
        i {
            font-size: 20px;
            vertical-align: middle;
        }
        span {
            margin: 0 10px;
        }
    }
    h2 {
        padding: 18px 0;
    }
}

.review-card .card-body {
    .review-duration {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: #0073aa;
        color: #fff;
        padding: 4px 10px;
        border-radius: 20px;
    }
    .review-social-link li {
        display: inline-block;
        .btn {
            margin: 5px 12px 5px 0;
            i {
                border-radius: 50%;
                width: 40px;
                line-height: 20px;
                height: 40px;
                padding: 9px;
                margin-right: -12px;
            }
        }
    }
}

.user-card-body .card-body {
    padding: 30px;
    .card-contain h5 {
        font-size: 18px;
        font-weight: 600;
        color: #222;
        margin: 0;
    }
    .card-data {
        p {
            margin-bottom: 5px;
        }
        span {
            font-size: 20px;
            font-weight: 600;
            color: #359de1;
        }
    }
}

.chart-stat-card-1 {
    .chart-stat-cont,
    .chart-stat-graph {
        float: left;
    }
}

.chart-stat-card-2 {
    .chart-stat-cont,
    .chart-stat-graph {
        float: left;
    }
}

.chart-stat-card-3 {
    .chart-stat-cont,
    .chart-stat-graph {
        float: left;
    }
}

.chart-stat-card-1 .chart-stat-cont h6,
.chart-stat-card-2 .chart-stat-cont h6,
.chart-stat-card-3 .chart-stat-cont h6 {
    color: #fff;
    text-transform: uppercase;
}

.chart-stat-card-1 .chart-stat-cont span,
.chart-stat-card-2 .chart-stat-cont span,
.chart-stat-card-3 .chart-stat-cont span {
    font-size: 30px;
    color: #fff;
    line-height: 1.27;
}

.chart-stat-card-1 {
    .chart-stat-cont {
        background-color: #25a25a;
    }
    .chart-stat-graph {
        background-color: #1b7943;
    }
}

.chart-stat-card-2 {
    .chart-stat-cont {
        background-color: #f1c40f;
    }
    .chart-stat-graph {
        background-color: #c29d0b;
    }
}

.chart-stat-card-3 {
    .chart-stat-cont {
        background-color: #0073aa;
    }
    .chart-stat-graph {
        background-color: #005177;
    }
}

.card-body .new-task .fade-in-primary {
    .cr {
        border: 2px solid #ccc;
        margin-top: 12px;
        margin-right: 20px;
    }
    p {
        display: block;
        margin-left: 35px;
    }
}

.client-card .table-responsive .table {
    th {
        border-top: none;
        border-bottom: none;
        vertical-align: middle;
    }
    tbody {
        tr:first-child td {
            border-top: none;
        }
        td {
            vertical-align: middle;
            p {
                display: inline-block;
                margin-left: 30px;
            }
        }
    }
}

.card-task {
    span {
        padding: 5px 12px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .btn-default {
        background-color: transparent;
        color: #bdc3c7;
        border-color: #bdc3c7;
        cursor: pointer;
        transition: all ease-in 0.3s;
    }
}

.news-card {
    h6,
    p {
        color: #fff;
    }
    span {
        color: #fff;
        font-style: italic;
    }
}

.reset-table tr {
    td {
        vertical-align: middle;
        img {
            width: 40px;
            display: inline-block;
        }
        .btn {
            padding: 6px 19px;
        }
    }
    &:first-child {
        td,
        th {
            border-top: none;
        }
    }
}

.card-visitor-block .card-visitor-button {
    display: flex;
    align-items: center;
    .btn-icon {
        border-radius: 50%;
        width: 70px;
        line-height: 30px;
        height: 70px;
        padding: 3px;
        text-align: center;
        font-size: 30px;
        i {
            margin: 0;
        }
    }
    .card-contain {
        display: inline-block;
        margin-left: 20px;
        text-align: left;
        h6 {
            font-size: 18px;
            font-weight: 600;
            color: #222;
        }
    }
}

.weather-card-1 {
    color: #fff;
    padding-right: 0;
    .mob-bg-calender {
        height: 100%;
        width: 100%;
        h3 {
            padding: 20px 0;
        }
        .weather-temp {
            font-size: 80px;
        }
    }
    h5 {
        color: #fff;
    }
    #sun {
        width: 130px;
        height: 130px;
        .climacon_component-stroke {
            fill: #fff;
        }
    }
}

#w-svg.climacon {
    width: 85px;
    height: 85px;
    display: block;
    margin: 25px auto;
}

.weather-calender {
    .pignose-calendar {
        width: auto;
        max-width: initial;
        box-shadow: none;
        border: none;
        border-left: 1px solid #ddd;
        .pignose-calendar-top {
            box-shadow: none;
        }
    }
    .pignose-calendar-unit.pignose-calendar-unit-active a {
        background-color: #0073aa;
        box-shadow: none;
    }
}

.card-notification {
    position: relative;
    &:before {
        content: "";
        width: 15px;
        height: 15px;
        border: 2px solid #0073aa;
        border-radius: 50%;
        position: absolute;
    }
    &:after {
        content: "";
        background: #bdc3c7;
        height: 55px;
        width: 2px;
        position: absolute;
        top: 20px;
        left: 7px;
    }
    .card-noti-conatin {
        margin-left: 30px;
        span {
            color: #2ecc71;
        }
    }
}

.card-status p {
    display: inline-block;
}

.card-comment-icon {
    .btn-primary,
    .sweet-alert button.confirm {
        background-color: #005177;
        border: 3px solid #0096dd;
    }
}

.sweet-alert .card-comment-icon button.confirm,
.card-comment-icon .wizard>.actions a,
.wizard>.actions .card-comment-icon a {
    background-color: #005177;
    border: 3px solid #0096dd;
}

.card-comment-icon {
    .btn-warning {
        background-color: #c29d0b;
        border: 3px solid #f4d03f;
    }
    .btn-success {
        background-color: #25a25a;
        border: 3px solid #54d98c;
    }
    .btn-icon {
        border-radius: 50%;
        width: 80px;
        line-height: 30px;
        height: 80px;
        padding: 3px;
        text-align: center;
        font-size: 25px;
        i {
            margin: 0;
        }
    }
}

.card-msg {
    position: relative;
    &:first-child .card-msg-img:after {
        background-color: #2ecc71;
    }
    &:nth-child(2) .card-msg-img:after {
        background-color: #f1c40f;
    }
    &:nth-child(3) .card-msg-img:after {
        background-color: #0073aa;
    }
    &:last-child .card-msg-img:after {
        background-color: #2ecc71;
    }
    .card-msg-img {
        position: relative;
        img {
            width: 50px;
            position: absolute;
        }
        &:after {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50px;
            left: 40px;
            top: 5px;
            border: 1px solid #fff;
        }
    }
    .card-msg-contain {
        display: inline-block;
        margin-left: 70px;
        span {
            color: #222;
            font-size: 15px;
        }
    }
}

.card-contact {
    .widget-profile-card-1 {
        position: relative;
        margin-bottom: 70px;
        .bg-layer {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
        img {
            width: 100%;
        }
        .btn-icon {
            position: absolute;
            top: 30px;
            &.b-lft {
                left: 30px;
            }
            &.b-rgt {
                right: 30px;
            }
            i {
                margin: 0;
            }
        }
    }
    .middle-user img {
        width: 115px;
        border-radius: 50%;
        height: 100%;
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
    }
    h5 {
        color: #222;
    }
}

.customtext {
    border: 2px solid #bdc3c7;
    padding: 5px 20px;
    position: absolute;
    span {
        display: block;
        font-size: 16px;
        color: #222;
    }
}

.product-table .table {
    td {
        vertical-align: middle;
        .btn {
            padding: 5px 18px;
        }
    }
    tr:first-child {
        td,
        th {
            border-top: none;
        }
    }
}

.order-task .table {
    td {
        vertical-align: middle;
        img {
            width: 50px;
            display: inline-block;
        }
        p {
            margin: 0;
        }
        .btn {
            padding: 6px 19px;
        }
    }
    tbody tr:first-child td {
        border-top: none;
    }
}

.warning-border {
    border-top-color: #f1c40f;
}

.success-border {
    border-top-color: #2ecc71;
}

.social-widget-card {
    border-top: none;
    color: #fff;
    &:hover i {
        opacity: 1;
        transform: scale(1.1);
    }
    i {
        position: absolute;
        right: 30px;
        top: 50px;
        font-size: 48px;
        opacity: 0.4;
        transition: all ease-in 0.3s;
    }
}

.bg-facebook {
    background-color: #3B5998;
}

.bg-dribbble {
    background-color: #EA4C89;
}

.bg-twitter {
    background-color: #4099FF;
}

.bg-linkein {
    background-color: #0077B5;
}

.bg-google-plus {
    background-color: #d34836;
}

.txt-facebook {
    color: #3B5998;
}

.txt-dribbble {
    color: #EA4C89;
}

.txt-twitter {
    color: #4099FF;
}

.txt-linkein {
    color: #0077B5;
}

.txt-google-plus {
    color: #d34836;
}

.widget-profile-card-1 {
    position: relative;
    margin-bottom: 50px;
    .middle-user {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        bottom: -60px;
        img {
            width: 115px;
            border-radius: 50%;
        }
    }
    ~.card-footer [class*=col-] {
        border-right: 1px solid #fff;
        &:last-child {
            border: none;
        }
    }
}

.weather-bg-card {
    position: relative;
    img {
        width: 100%;
    }
    .card-body {
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
    }
    h5 {
        color: #fff;
    }
    .card-body-big {
        position: absolute;
        top: 0;
        span {
            color: #fff;
        }
    }
    .weather-temp {
        font-size: 70px;
        color: #fff;
    }
    ~.card-footer {
        border-top: none;
    }
}

.table-1-card {
    .table th {
        border-top: none;
    }
    .card-body {
        padding-top: 10px;
        padding-bottom: 0;
    }
}

.widget-content-footer {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 16px;
    background-color: #e6e9ea;
}

.tab-card .media {
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.social-card {
    min-height: 170px;
    position: relative;
    .social-bg-card {
        position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
    }
    .social-cont {
        position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 10;
        display: flex;
        align-items: center;
        overflow: hidden;
        .s-main-cont {
            margin: 0 auto;
        }
        &:after {
            content: "";
            border: 8px solid transparent;
            position: absolute;
            transform: rotate(45deg);
            bottom: -8px;
            left: calc(50% - 10px);
        }
        &.fb-card:after {
            border-top: 8px solid #3B5998;
            border-left: 8px solid #3B5998;
        }
        &.db-card:after {
            border-top: 8px solid #EA4C89;
            border-left: 8px solid #EA4C89;
        }
        .bg-icon {
            font-size: 150px;
            position: absolute;
            right: -50px;
            bottom: -50px;
            opacity: 0.1;
        }
        i {
            font-size: 35px;
        }
    }
    .social-bg-card {
        bottom: 0;
        right: 0;
        z-index: 0;
        span {
            position: absolute;
            right: -24px;
            transform: rotate(90deg);
            bottom: 50px;
            font-weight: 600;
            color: #fff;
        }
    }
}

@media only screen and (max-width: 768px) {
    .weather-card-1 {
        .mob-bg-calender .weather-temp {
            font-size: 50px;
        }
        #sun {
            width: 100px;
            height: 100px;
        }
    }
    #w-svg.climacon {
        width: 60px;
        height: 60px;
    }
    .form-control {
        margin-bottom: 5px;
    }
    #vmap {
        height: 300px !important;
    }
}

@media only screen and (max-width: 576px) {
    .weather-card-1 {
        padding-right: 15px;
    }
    .bar-chart canvas,
    .tri-state canvas {
        width: 100% !important;
    }
    .datatable-footer-inner {
        height: auto !important;
        display: block !important;
    }
    .switch {
        margin-bottom: 5px !important;
    }
    .modal-footer {
        display: block;
    }
    .weather-card-1 .mob-bg-calender .weather-temp {
        font-size: 60px;
    }
    .daily-whether div {
        margin: 10px 0;
    }
    #w-svg.climacon {
        margin: 0 auto;
    }
    .p-l-0 {
        padding-left: 15px;
    }
}

@media only screen and (max-width: 420px) {
    .chart-stat-card-1 .chart-stat-graph span,
    .chart-stat-card-2 .chart-stat-graph span,
    .chart-stat-card-3 .chart-stat-graph span {
        margin-left: -15px;
    }
    .user-card-body .card-body .card-data div>div {
        max-width: 50%;
        width: 50%;
        flex: 0 0 50%;
        &:nth-child(even) {
            border-right: none;
        }
    }
    .card-contact .widget-profile-card-1 {
        .middle-user {
            bottom: -40px;
            img {
                width: 80px;
            }
        }
        .btn-icon {
            top: 15px;
            &.b-lft {
                left: 15px;
            }
            &.b-rgt {
                right: 15px;
            }
        }
    }
    .card-visitor-block>div>div,
    .card-task>div>div>div {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
    }
}


/**  ===================== Theme-dashboard css start
==========================  **/

.d-timeline-btn .f-right {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding: 0;
    i {
        margin-right: 0;
    }
}

#datebasechart {
    height: 590px;
    width: 100%;
}

.bullet {
    font: 10px sans-serif;
    .marker {
        stroke: #000;
        stroke-width: 2px;
    }
    .tick line {
        stroke: #666;
        stroke-width: .5px;
    }
    .range {
        &.s0 {
            fill: #eee;
        }
        &.s1 {
            fill: #ddd;
        }
        &.s2 {
            fill: #ccc;
        }
    }
    .measure {
        &.s0 {
            fill: lightsteelblue;
        }
        &.s1 {
            fill: steelblue;
        }
    }
    .title {
        font-size: 14px;
        font-weight: bold;
    }
    .subtitle {
        fill: #999;
    }
}

.section-graph {
    position: relative;
    height: 151px;
    color: #fff;
    background: #55bc75;
    background-image: linear-gradient(#55bc75, #55bc75 50%, #4daf7c 50%);
    .graph-info {
        z-index: 99;
        position: absolute;
        font-weight: bold;
        margin-top: 18px;
        margin-left: 21px;
        width: 100px;
        .graph-arrow {
            width: 0;
            height: 0;
            margin-top: 18px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid white;
            float: left;
        }
        .graph-info-big {
            font-size: 24px;
            float: left;
            margin-left: 3px;
        }
        .graph-info-small {
            margin-left: 3px;
            font-size: 12px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.5);
            clear: left;
            margin-left: 8px;
        }
    }
}


/* Info Section */

.section-info {
    padding: 21px 21px 21px;
    color: #8a786a;
    overflow: hidden;
}

.info-time {
    font-size: 12px;
}

.info-block {
    background-color: #fff;
    border: 1px solid #ccc;
    text-align: center;
    width: 120px;
    border-radius: 3px;
    float: left;
    padding: 4px 0;
    dt,
    dd {
        margin: 0;
        padding: 0;
    }
    dt {
        font-weight: bold;
    }
    dd {
        font-size: 12px;
    }
}

.info-aapl {
    float: left;
    margin-top: 20px;
    h4 {
        font-size: 13px;
        font-weight: normal;
        margin-bottom: 10px;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        margin: 0;
        display: block;
        width: 9px;
        height: 40px;
        margin-right: 6px;
        background-color: #f5f0ec;
        float: left;
        position: relative;
        span {
            display: block;
            width: 9px;
            height: 40px;
            position: absolute;
            bottom: 0;
            &.orange {
                background-color: #f1c40f;
            }
            &.green {
                background-color: #2ecc71;
            }
        }
    }
}

.yearly-change {
    width: 94px;
    float: right;
    margin-top: 20px;
    h4 {
        font-size: 13px;
        font-weight: normal;
        margin-bottom: 10px;
    }
    span {
        display: block;
        height: 40px;
        line-height: 40px;
        color: white;
        background-color: #0073aa;
        text-align: center;
        border-radius: 3px;
        font-weight: bold;
        em {
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

.analytic-table {
    tr td {
        &:first-child {
            padding-left: 20px;
        }
        &:last-child {
            padding-right: 30px;
            text-align: right;
            font-size: 16px;
        }
    }
    span {
        display: block;
    }
    .count {
        font-size: 18px;
        font-weight: 600;
    }
    .table-msg {
        font-size: 12px;
        margin-top: 5px;
    }
}

.analytic-user {
    i {
        font-size: 52px;
        color: #0073aa;
    }
    .card-footer {
        padding-top: 25px;
        padding-bottom: 25px;
        font-size: 16px;
        background-color: #e6e9ea;
    }
    h1 {
        margin: 25px 0;
        font-weight: 300;
    }
    h4 {
        font-size: 20px;
        color: #3498DB;
        font-weight: 600;
    }
}


/**====== Theme-dashboard css end ======**/


/**  ===================== Alert css start
==========================  **/

.alert {
    font-size: 15px;
    margin-bottom: 2rem;
    &.icons-alert {
        border-left-width: 48px;
        .alert-icon {
            left: 15px;
            position: relative;
            text-align: center;
            top: 0;
            z-index: 3;
        }
        p {
            line-height: 21px;
            margin-bottom: 0;
        }
    }
}

.close {
    font-size: 16px;
    margin-top: 5px;
}


/*===== Primary alert ===== */

.alert-default {
    background-color: #fff;
    border-color: #bdc3c7;
    color: #bdc3c7;
}

.alert-primary {
    background-color: #fff;
    border-color: #0073aa;
    color: #0073aa;
}

.alert-success {
    background-color: #fff;
    border-color: #2ecc71;
    color: #2ecc71;
}

.alert-info {
    background-color: #fff;
    border-color: #3498DB;
    color: #3498DB;
}

.alert-warning {
    background-color: #fff;
    border-color: #f1c40f;
    color: #f1c40f;
}

.alert-danger {
    background-color: #fff;
    border-color: #e74c3c;
    color: #e74c3c;
}


/*===== Border alert ===== */

.border-default {
    border-color: transparent;
    border-left: 3px solid #bdc3c7;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #bdc3c7;
}

.border-primary {
    border-color: transparent;
    border-left: 3px solid #0073aa;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #0073aa;
}

.border-success {
    border-color: transparent;
    border-left: 3px solid #2ecc71;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #2ecc71;
}

.border-info {
    border-color: transparent;
    border-left: 3px solid #3498DB;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #3498DB;
}

.border-warning {
    border-color: transparent;
    border-left: 3px solid #f1c40f;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #f1c40f;
}

.border-danger {
    border-color: transparent;
    border-left: 3px solid #e74c3c;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #e74c3c;
}


/*===== Solid-background alert ===== */

.background-default {
    background-color: #bdc3c7;
    color: #fff;
}

.background-primary {
    background-color: #0073aa;
    color: #fff;
}

.background-success {
    background-color: #2ecc71;
    color: #fff;
}

.background-info {
    background-color: #3498DB;
    color: #fff;
}

.background-warning {
    background-color: #f1c40f;
    color: #fff;
}

.background-danger {
    background-color: #e74c3c;
    color: #fff;
}

.icons-alert {
    position: relative;
    &:before {
        color: #fff;
        content: '\f027';
        font-family: 'IcoFont' !important;
        font-size: 16px;
        left: -30px;
        position: absolute;
    }
}

[class*="alert-"] code {
    margin-left: 10px;
}


/*======= Alert css ends ===========*/


/* ======================================= Authentication-page css start ========================================= */

.common-img-bg {
    background-size: cover;
    background: url('assets/images/bg.jpg') no-repeat center center fixed;
    height: 100%;
}

.auth-body {
    min-width: 450px;
}

.login-card {
    border-radius: 30px;
    position: relative;
    display: block;
    h1 {
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
    }
}

.login {
    text-align: left;
    ul li label {
        color: #757575;
        font-weight: 600;
        letter-spacing: 1px;
    }
    h3 {
        color: #666666;
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
    }
    &.header {
        align-items: flex-start;
    }
}

.header .login-card {
    margin-top: 30px;
}

.social-login {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 30px;
    .btn {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.login-card {
    .card-footer {
        background: #fff;
    }
    h1 {
        color: #1b8bf9;
    }
    .forget {
        border-bottom: 1px solid #fff;
        font-size: 24px;
        line-height: 0.6;
    }
    h3 {
        color: #666666;
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
    }
}

.btn-signup .btn-md,
.btn-singin1 .btn-md,
.btn-singin2 .btn-md {
    font-size: 22px;
    font-weight: 400;
    width: 400px;
}

.form-group-main .form-group {
    margin: 30px 0;
}

.login-card {
    .unlock,
    .btn-forgot {
        text-align: center;
    }
}

.imgs-screen .img-circle {
    padding: 4px;
    border: 1px solid #E6E6E4;
}

.sign-in-with {
    font-weight: 600;
    margin: 24px auto 16px;
    position: relative;
    text-align: center;
    .text {
        display: inline-block;
        padding: 0 8px;
        position: relative;
        vertical-align: baseline;
        z-index: 9999;
        &:after {
            left: 100%;
        }
        &:before {
            right: 100%;
        }
        &:after,
        &:before {
            border-top: 1px solid rgba(0, 0, 0, 0.28);
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            width: 60px;
        }
    }
}

.error-500,
.error-503,
.error-404,
.error-400,
.error-403,
.login {
    align-items: center;
    background-color: #2196F3;
    display: flex;
    height: 100%;
    position: fixed;
    text-align: center;
    width: 100%;
}

.auth-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 20px 0 0 0;
    padding: 20px;
    .confirm h3 {
        color: #0073aa;
        font-size: 34px;
    }
    i.icofont-check-circled {
        font-size: 42px;
    }
}

.btn-facebook {
    background-color: #3c5a99;
    border-color: #3c5a99;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-twitter {
    background-color: #41abe1;
    border-color: #41abe1;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
}

.btn-google-plus,
.btn-linkedin,
.btn-pinterest {
    border-radius: 2px;
}

.footer {
    bottom: 0;
    position: absolute;
    width: 100%;
    p {
        margin-bottom: 0;
        padding: 18px;
        width: 100%;
    }
}

.login-card-modal {
    margin: 0 auto;
}

.login.login-modal-main {
    background-color: #fff;
}

.mixed-form {
    .tabbed-modal .nav-link {
        h6 {
            padding: 10px 0;
        }
        background-color: #f5f5f5;
        border-radius: none;
        color: #888;
        &.active {
            background-color: transparent;
            border-color: transparent;
            color: #34495e;
        }
    }
    .modal-body {
        padding: 0;
    }
    .auth-box {
        background-color: #fff;
        border-radius: 8px;
        margin: 10px;
        padding: 10px;
        h3 {
            color: #666666;
            font-size: 22px;
            font-weight: 600;
            margin-top: 20px;
        }
        i.icofont-check-circled {
            font-size: 42px;
        }
    }
}

.auth-box-main .navbar-nav li {
    color: #fff;
    line-height: 40px;
}

.offline-404 {
    background-color: #f3f3f3;
    .auth-body {
        margin-top: -100px;
    }
    h1 {
        color: #2c3e50;
        font-size: 160px;
        font-weight: 600;
        letter-spacing: 5px;
        text-shadow: 3px -2px 4px rgba(128, 128, 128, 0.57);
    }
    h2 {
        color: #aaa;
        font-weight: 600;
        margin-bottom: 40px;
        margin-top: 30px;
    }
}


/**====== Authentication-page css end ======**/


/**  ===================== Breadcrumb css start
==========================  **/

.caption-breadcrumb .breadcrumb-header,
.primary-breadcrumb .breadcrumb-header,
.inverse-breadcrumb .breadcrumb-header,
.danger-breadcrumb .breadcrumb-header,
.info-breadcrumb .breadcrumb-header,
.warning-breadcrumb .breadcrumb-header,
.success-breadcrumb .breadcrumb-header {
    display: inline-block;
}

.caption-breadcrumb .breadcrumb-header span,
.primary-breadcrumb .breadcrumb-header span,
.inverse-breadcrumb .breadcrumb-header span,
.danger-breadcrumb .breadcrumb-header span,
.info-breadcrumb .breadcrumb-header span,
.warning-breadcrumb .breadcrumb-header span,
.success-breadcrumb .breadcrumb-header span {
    display: block;
    font-size: 13px;
    margin-top: 5px;
}

.front-icon-breadcrumb {
    .breadcrumb-header {
        display: inline-block;
    }
    .big-icon {
        display: inline-block;
        i {
            font-size: 64px;
            color: #0073aa;
        }
    }
    .d-inline-block span {
        display: block;
        font-size: 13px;
        margin-top: 5px;
    }
}

.primary-breadcrumb,
.inverse-breadcrumb,
.danger-breadcrumb,
.info-breadcrumb,
.warning-breadcrumb,
.success-breadcrumb {
    background-color: #0073aa;
    color: #fff;
}

.primary-breadcrumb h5,
.inverse-breadcrumb h5,
.danger-breadcrumb h5,
.info-breadcrumb h5,
.warning-breadcrumb h5,
.success-breadcrumb h5,
.primary-breadcrumb a,
.inverse-breadcrumb a,
.danger-breadcrumb a,
.info-breadcrumb a,
.warning-breadcrumb a,
.success-breadcrumb a,
.primary-breadcrumb .breadcrumb-title li:last-child a,
.inverse-breadcrumb .breadcrumb-title li:last-child a,
.danger-breadcrumb .breadcrumb-title li:last-child a,
.info-breadcrumb .breadcrumb-title li:last-child a,
.warning-breadcrumb .breadcrumb-title li:last-child a,
.success-breadcrumb .breadcrumb-title li:last-child a,
.primary-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.inverse-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.danger-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.info-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.warning-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.success-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
}

.inverse-breadcrumb {
    background-color: #34495e;
}

.danger-breadcrumb {
    background-color: #e74c3c;
}

.info-breadcrumb {
    background-color: #3498DB;
}

.warning-breadcrumb {
    background-color: #f1c40f;
}

.success-breadcrumb {
    background-color: #2ecc71;
}


/**====== Breadcrumb css end ======**/


/**  ===================== Chart css start
==========================  **/

.nvd-chart {
    height: 400px;
}

.peity-chart .peity {
    width: 100%;
    height: 250px;
}

#areachart .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke: #187c68;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.morris-hover {
    position: absolute;
    left: auto !important;
    top: -70px !important;
    right: 0;
    background: #fff;
    padding: 20px;
    border: 1px solid #ccc;
}


/**====== Chart css end ======**/


/**  ===================== Chatting css start
==========================  **/

.chat-bg {
    background-image: url("assets/images/chat-bg.jpg");
    background-size: cover;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.45);
}

#main-chat {
    .chat-single-box {
        &.active .chat-header {
            background-color: #0073aa;
            a {
                color: #fff;
            }
        }
        .chat-header a {
            color: #fff;
        }
    }
    .chat-box ul.boxs li.chat-single-box .chat-header.custom-collapsed {
        position: absolute;
        bottom: 0;
        width: 300px;
    }
    .minimized {
        background-color: #1e2730;
    }
    .user-groups {
        padding-left: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
        .userlist-box {
            border-bottom: none;
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        .media {
            padding-left: 0;
        }
        h6 {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        li {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            &.work,
            &.frnds {
                position: relative;
                padding-left: 20px;
            }
            &.work:before {
                content: '';
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: #2ecc71;
                border-radius: 50px;
                left: 0;
                top: 6px;
            }
            &.frnds:before {
                content: '';
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: #2ecc71;
                border-radius: 50px;
                left: 0;
                top: 6px;
                background-color: #e74c3c;
            }
        }
    }
}

.user-box {
    height: 100%;
}

.close {
    opacity: 1;
}

.write-msg {
    padding: 12px;
    bottom: 10px;
    border-top: 1px solid #ddd;
    #paper-btn i {
        font-size: 18px;
        margin-right: 0;
        cursor: pointer;
    }
}

.chat-box .secondary:active:hover {
    background-color: #fff;
}

#main-chat .chat-box ul.boxs li {
    &.minimized {
        height: 30px;
        width: 30px;
        bottom: 0;
        position: absolute;
        left: -50px;
        -webkit-border-radius: 5px 5px 0 0;
        -moz-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
        .count {
            color: #fff;
            text-align: center;
            margin: 5px;
        }
        .chat-dropdown {
            list-style: none;
            display: none;
            position: absolute;
            background-color: #f5f5f5;
            -webkit-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
            -moz-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
            box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
            width: 150px;
            z-index: 100;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            padding: 5px 0;
            li {
                padding: 2px 5px;
                div {
                    display: inline-block;
                }
                .username {
                    width: 85%;
                    height: 22px;
                }
                .remove {
                    width: 13%;
                    padding: 2PX 4PX;
                    float: right;
                    &:hover {
                        background-color: #c0c0c0 !important;
                    }
                }
                &:hover {
                    color: black;
                }
            }
        }
    }
    &.hidden {
        display: none;
    }
}

.chat-single-box {
    height: 440px;
    float: right;
    width: 300px;
    margin-right: 15px;
    direction: ltr;
    z-index: 999;
    bottom: 0;
    .chat-header {
        background-color: #bdc3c7;
        color: #fff;
    }
}

.box-live-status {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    border: 1px solid;
}

.chat-msg-img {
    height: 50px;
    width: 50px;
}

.msg-reply {
    padding: 5px;
    position: relative;
    right: 10px;
    border-radius: 2px;
    top: 6px;
    margin-bottom: 10px;
}

.header-users,
.users {
    right: 0;
    top: 103px;
    width: 300px;
    height: 100%;
}

.p-chat-user {
    position: fixed;
    top: 56px;
    z-index: 1000;
    border-left: 1px solid #ccc;
}

.users-main {
    height: 100%;
    width: 300px;
    position: fixed;
    border-radius: 0px;
}

.userlist-box {
    cursor: pointer;
    border-bottom: 1px solid #efefef;
    .media-left {
        padding-right: 10px;
    }
}

.users-main .media {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}

.user-box .media-object {
    height: 45px;
    width: 45px;
    display: inline-block;
}

.chat-menu-content,
.chat-menu-reply {
    background: #f3f3f3;
    position: relative;
    overflow: visible;
    border-radius: 4px;
}

.chat-menu-content:before {
    position: absolute;
    top: 20px;
    left: -14px;
    width: 0;
    height: 0;
    content: '';
    border: 8px solid transparent;
    border-right-color: #f3f3f3;
}

.chat-menu-reply:before {
    position: absolute;
    top: 20px;
    right: -14px;
    width: 0;
    height: 0;
    content: '';
    border: 8px solid transparent;
    border-left-color: #0073aa;
}

.chat-reply-box {
    background-color: #fff;
    position: absolute;
    bottom: 43px;
    width: 100%;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-top: 1px solid #ccc;
}

.chat-inner-header {
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding: 20px;
}

.chat-send {
    position: absolute;
    bottom: 5px;
    right: 0;
    border: none;
    background-color: transparent;
    color: #0073aa;
    transform: rotate(45deg);
}

.showChat_inner .chat-messages {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.chat-menu-content .chat-cont,
.chat-menu-reply .chat-cont {
    padding: 10px;
}

.chat-menu-content .chat-time,
.chat-menu-reply .chat-time {
    padding-left: 10px;
    padding-bottom: 10px;
}

.back_chatBox {
    margin: 0 auto;
    font-weight: 600;
    i {
        float: left;
        position: absolute;
        margin-top: 5px;
        left: 20px;
        cursor: pointer;
    }
}

.designation {
    cursor: pointer;
}

.extra-profile-list {
    display: none;
}

.chat-menu-reply {
    .chat-cont,
    .chat-time {
        color: #fff;
    }
    background: #0073aa;
    margin-right: 10px;
}

.users-main {
    .media .chat-header {
        font-size: 14px;
        font-weight: 600;
    }
    .media-body div+div {
        font-size: 12px;
    }
}

.chat-body {
    overflow-y: auto;
    height: 340px;
}

.users-main-fix {
    height: auto;
    position: fixed;
    bottom: 0px;
    top: 151px;
}

.chat-footer,
.chat-link {
    border-color: #0073aa;
}

.chat-footer .input-group-addon {
    padding: 7px .75rem;
}

.form-control:focus~.chat-send {
    border-color: #0073aa;
}

.minimized-box {
    padding: 5px 10px;
}

.write-msg {
    input {
        border-radius: 0;
        border-right: 0;
    }
    .btn {
        box-shadow: none;
        border-left: 0;
        font-size: 14px;
    }
    .btn-secondary:hover {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-left: 0;
    }
}

.form-control:focus~.input-group-btn .btn-secondary {
    border-color: #66afe9;
    color: #0073aa;
}

.img-chat-profile {
    height: 45px;
    width: 45px;
}

.chat-minimize {
    .chat-body,
    .chat-footer {
        display: none;
    }
}

.chat-box {
    margin-right: 290px;
    height: 440px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999;
}

.user-box .media-object {
    height: 45px;
    width: 45px;
    display: inline-block;
}

.friend-box {
    .media-object {
        height: 45px;
        width: 45px;
        display: inline-block;
    }
    img {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.chat-header {
    color: #222222;
}

.live-status {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 100%;
    border: 1px solid;
}

.showChat {
    display: none;
}

.showChat_inner {
    position: fixed;
    top: 56px;
    background-color: white;
    right: 0;
    width: 300px;
    height: 100%;
    z-index: 99999;
    border-left: 1px solid #ccc;
}


/**====== Chatting css end ======**/


/* ======================= Start Color-page Css
=========================== */

.amber-colors ul li p,
.bg-danger li p,
.bg-default li p,
.bg-info li p,
.bg-primary li p,
.bg-success li p,
.bg-warning li p,
.blue-colors ul li p,
.blue-grey-colors ul li p,
.brown-colors ul li p,
.cyan-colors ul li p,
.deep-orange-colors ul li p,
.deep-purple-colors ul li p,
.green-colors ul li p,
.grey-colors ul li p,
.indigo-colors ul li p,
.light-blue-colors ul li p,
.light-green-colors ul li p,
.lime-colors ul li p,
.orange-colors ul li p,
.pink-colors ul li p,
.purple-colors ul li p,
.red-colors ul li p,
.teal-colors ul li p,
.yellow-colors ul li p,
.primary-colorr ul li p,
.success-colorr ul li p,
.info-colorr ul li p,
.warning-colorr ul li p,
.danger-colorr ul li p {
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 0;
}


/**====== Accent-red-color css start ======**/

.red-colors ul li {
    &:nth-child(14) {
        background-color: #e40505;
        p:before {
            content: "#e40505";
        }
    }
    &:nth-child(13) {
        background-color: #f30606;
        p:before {
            content: "#f30606";
        }
    }
    &:nth-child(12) {
        background-color: #f90f0f;
        p:before {
            content: "#f90f0f";
        }
    }
    &:nth-child(11) {
        background-color: #fa1d1d;
        p:before {
            content: "#fa1d1d";
        }
    }
    &:nth-child(10) {
        background-color: #fa2c2c;
        p:before {
            content: "#fa2c2c";
        }
    }
    &:nth-child(9) {
        background-color: #fa3b3b;
        p:before {
            content: "#fa3b3b";
        }
    }
    &:nth-child(8) {
        background-color: #fb4a4a;
        p:before {
            content: "#fb4a4a";
        }
    }
    &:nth-child(7) {
        background-color: #fb5959;
        p:before {
            content: "#fb5959";
        }
    }
    &:nth-child(6) {
        background-color: #fb6868;
        p:before {
            content: "#fb6868";
        }
    }
    &:nth-child(5) {
        background-color: #fc7777;
        p:before {
            content: "#fc7777";
        }
    }
    &:nth-child(4) {
        background-color: #fc8686;
        p:before {
            content: "#fc8686";
        }
    }
    &:nth-child(3) {
        background-color: #fd9595;
        p:before {
            content: "#fd9595";
        }
    }
    &:nth-child(2) {
        background-color: #fda4a4;
        p:before {
            content: "#fda4a4";
        }
    }
    &:nth-child(1) {
        background-color: #fdb3b3;
        p:before {
            content: "#fdb3b3";
        }
    }
}


/**====== Accent-red-color css end ======**/


/**====== Accent-pink-color css start ======**/

.pink-colors ul li {
    &:nth-child(14) {
        background-color: #d4166c;
        p:before {
            content: "#d4166c";
        }
    }
    &:nth-child(13) {
        background-color: #e21873;
        p:before {
            content: "#e21873";
        }
    }
    &:nth-child(12) {
        background-color: #e7217b;
        p:before {
            content: "#e7217b";
        }
    }
    &:nth-child(11) {
        background-color: #e92f83;
        p:before {
            content: "#e92f83";
        }
    }
    &:nth-child(10) {
        background-color: #ea3d8b;
        p:before {
            content: "#ea3d8b";
        }
    }
    &:nth-child(9) {
        background-color: #ec4b94;
        p:before {
            content: "#ec4b94";
        }
    }
    &:nth-child(8) {
        background-color: #ed599c;
        p:before {
            content: "#ed599c";
        }
    }
    &:nth-child(7) {
        background-color: #ef67a4;
        p:before {
            content: "#ef67a4";
        }
    }
    &:nth-child(6) {
        background-color: #f074ac;
        p:before {
            content: "#f074ac";
        }
    }
    &:nth-child(5) {
        background-color: #f282b5;
        p:before {
            content: "#f282b5";
        }
    }
    &:nth-child(4) {
        background-color: #f390bd;
        p:before {
            content: "#f390bd";
        }
    }
    &:nth-child(3) {
        background-color: #f59ec5;
        p:before {
            content: "#f59ec5";
        }
    }
    &:nth-child(2) {
        background-color: #f6accd;
        p:before {
            content: "#f6accd";
        }
    }
    &:nth-child(1) {
        background-color: #f8bad6;
        p:before {
            content: "#f8bad6";
        }
    }
}


/**====== Accent-pink-color css end ======**/


/**====== Accent-purple-color css start ======**/

.purple-colors ul li {
    &:nth-child(14) {
        background-color: #b014ff;
        p:before {
            content: "#b014ff";
        }
    }
    &:nth-child(13) {
        background-color: #b524ff;
        p:before {
            content: "#b524ff";
        }
    }
    &:nth-child(12) {
        background-color: #ba33ff;
        p:before {
            content: "#ba33ff";
        }
    }
    &:nth-child(11) {
        background-color: #c042ff;
        p:before {
            content: "#c042ff";
        }
    }
    &:nth-child(10) {
        background-color: #c552ff;
        p:before {
            content: "#c552ff";
        }
    }
    &:nth-child(9) {
        background-color: #ca61ff;
        p:before {
            content: "#ca61ff";
        }
    }
    &:nth-child(8) {
        background-color: #cf70ff;
        p:before {
            content: "#cf70ff";
        }
    }
    &:nth-child(7) {
        background-color: #d47fff;
        p:before {
            content: "#d47fff";
        }
    }
    &:nth-child(6) {
        background-color: #d98fff;
        p:before {
            content: "#d98fff";
        }
    }
    &:nth-child(5) {
        background-color: #de9eff;
        p:before {
            content: "#de9eff";
        }
    }
    &:nth-child(4) {
        background-color: #e4adff;
        p:before {
            content: "#e4adff";
        }
    }
    &:nth-child(3) {
        background-color: #e9bdff;
        p:before {
            content: "#e9bdff";
        }
    }
    &:nth-child(2) {
        background-color: #eeccff;
        p:before {
            content: "#eeccff";
        }
    }
    &:nth-child(1) {
        background-color: #f3dbff;
        p:before {
            content: "#f3dbff";
        }
    }
}


/**====== Accent-purple-color css end ======**/


/**====== Deep-purple-color css start ======**/

.deep-purple-colors ul li {
    &:nth-child(14) {
        background-color: #6b05f9;
        p:before {
            content: "#6b05f9";
        }
    }
    &:nth-child(13) {
        background-color: #7414fa;
        p:before {
            content: "#7414fa";
        }
    }
    &:nth-child(12) {
        background-color: #7d23fa;
        p:before {
            content: "#7d23fa";
        }
    }
    &:nth-child(11) {
        background-color: #8632fb;
        p:before {
            content: "#8632fb";
        }
    }
    &:nth-child(10) {
        background-color: #8f41fb;
        p:before {
            content: "#8f41fb";
        }
    }
    &:nth-child(9) {
        background-color: #9850fb;
        p:before {
            content: "#9850fb";
        }
    }
    &:nth-child(8) {
        background-color: #a05ffc;
        p:before {
            content: "#a05ffc";
        }
    }
    &:nth-child(7) {
        background-color: #a96efc;
        p:before {
            content: "#a96efc";
        }
    }
    &:nth-child(6) {
        background-color: #b27cfc;
        p:before {
            content: "#b27cfc";
        }
    }
    &:nth-child(5) {
        background-color: #bb8bfd;
        p:before {
            content: "#bb8bfd";
        }
    }
    &:nth-child(4) {
        background-color: #c49afd;
        p:before {
            content: "#c49afd";
        }
    }
    &:nth-child(3) {
        background-color: #cda9fd;
        p:before {
            content: "#cda9fd";
        }
    }
    &:nth-child(2) {
        background-color: #d5b8fd;
        p:before {
            content: "#d5b8fd";
        }
    }
    &:nth-child(1) {
        background-color: #dec7fe;
        p:before {
            content: "#dec7fe";
        }
    }
}


/**====== Deep-purple-color css end ======**/


/**====== Indigo-color css start ======**/

.indigo-colors ul li {
    &:nth-child(14) {
        background-color: #415dfe;
        p:before {
            content: "#415dfe";
        }
    }
    &:nth-child(13) {
        background-color: #4d68fe;
        p:before {
            content: "#4d68fe";
        }
    }
    &:nth-child(12) {
        background-color: #5a72fe;
        p:before {
            content: "#5a72fe";
        }
    }
    &:nth-child(11) {
        background-color: #677dfe;
        p:before {
            content: "#677dfe";
        }
    }
    &:nth-child(10) {
        background-color: #7388fe;
        p:before {
            content: "#7388fe";
        }
    }
    &:nth-child(9) {
        background-color: #8093fe;
        p:before {
            content: "#8093fe";
        }
    }
    &:nth-child(8) {
        background-color: #8d9efe;
        p:before {
            content: "#8d9efe";
        }
    }
    &:nth-child(7) {
        background-color: #9aa9ff;
        p:before {
            content: "#9aa9ff";
        }
    }
    &:nth-child(6) {
        background-color: #a6b3ff;
        p:before {
            content: "#a6b3ff";
        }
    }
    &:nth-child(5) {
        background-color: #b3beff;
        p:before {
            content: "#b3beff";
        }
    }
    &:nth-child(4) {
        background-color: #c0c9ff;
        p:before {
            content: "#c0c9ff";
        }
    }
    &:nth-child(3) {
        background-color: #ccd4ff;
        p:before {
            content: "#ccd4ff";
        }
    }
    &:nth-child(2) {
        background-color: #d9dfff;
        p:before {
            content: "#d9dfff";
        }
    }
    &:nth-child(1) {
        background-color: #e6e9ff;
        p:before {
            content: "#e6e9ff";
        }
    }
}


/**====== Indigo-color css end ======**/


/**====== Blue-accent-color css start ======**/

.blue-colors ul li {
    &:nth-child(14) {
        background-color: #3a6eff;
        p:before {
            content: "#3a6eff";
        }
    }
    &:nth-child(13) {
        background-color: #4778ff;
        p:before {
            content: "#4778ff";
        }
    }
    &:nth-child(12) {
        background-color: #5381ff;
        p:before {
            content: "#5381ff";
        }
    }
    &:nth-child(11) {
        background-color: #608aff;
        p:before {
            content: "#608aff";
        }
    }
    &:nth-child(10) {
        background-color: #6d94ff;
        p:before {
            content: "#6d94ff";
        }
    }
    &:nth-child(9) {
        background-color: #7a9dff;
        p:before {
            content: "#7a9dff";
        }
    }
    &:nth-child(8) {
        background-color: #86a6ff;
        p:before {
            content: "#86a6ff";
        }
    }
    &:nth-child(7) {
        background-color: #93b0ff;
        p:before {
            content: "#93b0ff";
        }
    }
    &:nth-child(6) {
        background-color: #a0b9ff;
        p:before {
            content: "#a0b9ff";
        }
    }
    &:nth-child(5) {
        background-color: #adc3ff;
        p:before {
            content: "#adc3ff";
        }
    }
    &:nth-child(4) {
        background-color: #b9ccff;
        p:before {
            content: "#b9ccff";
        }
    }
    &:nth-child(3) {
        background-color: #c6d5ff;
        p:before {
            content: "#c6d5ff";
        }
    }
    &:nth-child(2) {
        background-color: #d3dfff;
        p:before {
            content: "#d3dfff";
        }
    }
    &:nth-child(1) {
        background-color: #e0e8ff;
        p:before {
            content: "#e0e8ff";
        }
    }
}


/**====== Blue-accent-color css end ======**/


/**====== Light-blue-color css start ======**/

.light-blue-colors ul li {
    &:nth-child(14) {
        background-color: #059cf9;
        p:before {
            content: "#059cf9";
        }
    }
    &:nth-child(13) {
        background-color: #14a2fa;
        p:before {
            content: "#14a2fa";
        }
    }
    &:nth-child(12) {
        background-color: #23a8fa;
        p:before {
            content: "#23a8fa";
        }
    }
    &:nth-child(11) {
        background-color: #32aefb;
        p:before {
            content: "#32aefb";
        }
    }
    &:nth-child(10) {
        background-color: #41b4fb;
        p:before {
            content: "#41b4fb";
        }
    }
    &:nth-child(9) {
        background-color: #50bafb;
        p:before {
            content: "#50bafb";
        }
    }
    &:nth-child(8) {
        background-color: #5fc0fc;
        p:before {
            content: "#5fc0fc";
        }
    }
    &:nth-child(7) {
        background-color: #6ec6fc;
        p:before {
            content: "#6ec6fc";
        }
    }
    &:nth-child(6) {
        background-color: #7cccfc;
        p:before {
            content: "#7cccfc";
        }
    }
    &:nth-child(5) {
        background-color: #8bd2fd;
        p:before {
            content: "#8bd2fd";
        }
    }
    &:nth-child(4) {
        background-color: #9ad7fd;
        p:before {
            content: "#9ad7fd";
        }
    }
    &:nth-child(3) {
        background-color: #a9ddfd;
        p:before {
            content: "#a9ddfd";
        }
    }
    &:nth-child(2) {
        background-color: #b8e3fd;
        p:before {
            content: "#b8e3fd";
        }
    }
    &:nth-child(1) {
        background-color: #c7e9fe;
        p:before {
            content: "#c7e9fe";
        }
    }
}


/**====== Light-blue-color css end ======**/


/**====== Cyan-color css start ======**/

.cyan-colors ul li {
    &:nth-child(14) {
        background-color: #05c6e3;
        p:before {
            content: "#05c6e3";
        }
    }
    &:nth-child(13) {
        background-color: #06d3f2;
        p:before {
            content: "#06d3f2";
        }
    }
    &:nth-child(12) {
        background-color: #0edbf9;
        p:before {
            content: "#0edbf9";
        }
    }
    &:nth-child(11) {
        background-color: #1dddfa;
        p:before {
            content: "#1dddfa";
        }
    }
    &:nth-child(10) {
        background-color: #2bdffa;
        p:before {
            content: "#2bdffa";
        }
    }
    &:nth-child(9) {
        background-color: #3ae1fa;
        p:before {
            content: "#3ae1fa";
        }
    }
    &:nth-child(8) {
        background-color: #49e4fb;
        p:before {
            content: "#49e4fb";
        }
    }
    &:nth-child(7) {
        background-color: #58e6fb;
        p:before {
            content: "#58e6fb";
        }
    }
    &:nth-child(6) {
        background-color: #67e8fb;
        p:before {
            content: "#67e8fb";
        }
    }
    &:nth-child(5) {
        background-color: #76eafc;
        p:before {
            content: "#76eafc";
        }
    }
    &:nth-child(4) {
        background-color: #85edfc;
        p:before {
            content: "#85edfc";
        }
    }
    &:nth-child(3) {
        background-color: #94effc;
        p:before {
            content: "#94effc";
        }
    }
    &:nth-child(2) {
        background-color: #a3f1fd;
        p:before {
            content: "#a3f1fd";
        }
    }
    &:nth-child(1) {
        background-color: #b2f3fd;
        p:before {
            content: "#b2f3fd";
        }
    }
}


/**====== Cyan-color css start ======**/


/**====== Teal-color css start ======**/

.teal-colors ul li {
    &:nth-child(14) {
        background-color: #05cfb3;
        p:before {
            content: "#05cfb3";
        }
    }
    &:nth-child(13) {
        background-color: #06dec0;
        p:before {
            content: "#06dec0";
        }
    }
    &:nth-child(12) {
        background-color: #06edcd;
        p:before {
            content: "#06edcd";
        }
    }
    &:nth-child(11) {
        background-color: #0af9d7;
        p:before {
            content: "#0af9d7";
        }
    }
    &:nth-child(10) {
        background-color: #19f9da;
        p:before {
            content: "#19f9da";
        }
    }
    &:nth-child(9) {
        background-color: #27f9dc;
        p:before {
            content: "#27f9dc";
        }
    }
    &:nth-child(8) {
        background-color: #36fadf;
        p:before {
            content: "#36fadf";
        }
    }
    &:nth-child(7) {
        background-color: #45fae1;
        p:before {
            content: "#45fae1";
        }
    }
    &:nth-child(6) {
        background-color: #54fbe3;
        p:before {
            content: "#54fbe3";
        }
    }
    &:nth-child(5) {
        background-color: #63fbe6;
        p:before {
            content: "#63fbe6";
        }
    }
    &:nth-child(4) {
        background-color: #72fbe8;
        p:before {
            content: "#72fbe8";
        }
    }
    &:nth-child(3) {
        background-color: #81fceb;
        p:before {
            content: "#81fceb";
        }
    }
    &:nth-child(2) {
        background-color: #90fced;
        p:before {
            content: "#90fced";
        }
    }
    &:nth-child(1) {
        background-color: #9ffcef;
        p:before {
            content: "#9ffcef";
        }
    }
}


/**====== Teal-color css end ======**/


/**====== Green-color css start ======**/

.green-colors ul li {
    &:nth-child(14) {
        background-color: #05d85c;
        p:before {
            content: "#05d85c";
        }
    }
    &:nth-child(13) {
        background-color: #06e763;
        p:before {
            content: "#06e763";
        }
    }
    &:nth-child(12) {
        background-color: #06f669;
        p:before {
            content: "#06f669";
        }
    }
    &:nth-child(11) {
        background-color: #12f972;
        p:before {
            content: "#12f972";
        }
    }
    &:nth-child(10) {
        background-color: #21f97a;
        p:before {
            content: "#21f97a";
        }
    }
    &:nth-child(9) {
        background-color: #30fa83;
        p:before {
            content: "#30fa83";
        }
    }
    &:nth-child(8) {
        background-color: #3ffa8c;
        p:before {
            content: "#3ffa8c";
        }
    }
    &:nth-child(7) {
        background-color: #4efb95;
        p:before {
            content: "#4efb95";
        }
    }
    &:nth-child(6) {
        background-color: #5dfb9e;
        p:before {
            content: "#5dfb9e";
        }
    }
    &:nth-child(5) {
        background-color: #6cfba7;
        p:before {
            content: "#6cfba7";
        }
    }
    &:nth-child(4) {
        background-color: #7bfcb0;
        p:before {
            content: "#7bfcb0";
        }
    }
    &:nth-child(3) {
        background-color: #8afcb9;
        p:before {
            content: "#8afcb9";
        }
    }
    &:nth-child(2) {
        background-color: #98fcc2;
        p:before {
            content: "#98fcc2";
        }
    }
    &:nth-child(1) {
        background-color: #a7fdcb;
        p:before {
            content: "#a7fdcb";
        }
    }
}


/**====== Green-color css end ======**/


/**====== Light-Green-color css start ======**/

.light-green-colors ul li {
    &:nth-child(14) {
        background-color: #6fe423;
        p:before {
            content: "#6fe423";
        }
    }
    &:nth-child(13) {
        background-color: #78e631;
        p:before {
            content: "#78e631";
        }
    }
    &:nth-child(12) {
        background-color: #81e73e;
        p:before {
            content: "#81e73e";
        }
    }
    &:nth-child(11) {
        background-color: #8ae94c;
        p:before {
            content: "#8ae94c";
        }
    }
    &:nth-child(10) {
        background-color: #93eb5a;
        p:before {
            content: "#93eb5a";
        }
    }
    &:nth-child(9) {
        background-color: #9bec67;
        p:before {
            content: "#9bec67";
        }
    }
    &:nth-child(8) {
        background-color: #a4ee75;
        p:before {
            content: "#a4ee75";
        }
    }
    &:nth-child(7) {
        background-color: #adf083;
        p:before {
            content: "#adf083";
        }
    }
    &:nth-child(6) {
        background-color: #b6f190;
        p:before {
            content: "#b6f190";
        }
    }
    &:nth-child(5) {
        background-color: #bff39e;
        p:before {
            content: "#bff39e";
        }
    }
    &:nth-child(4) {
        background-color: #c8f5ac;
        p:before {
            content: "#c8f5ac";
        }
    }
    &:nth-child(3) {
        background-color: #d1f6b9;
        p:before {
            content: "#d1f6b9";
        }
    }
    &:nth-child(2) {
        background-color: #daf8c7;
        p:before {
            content: "#daf8c7";
        }
    }
    &:nth-child(1) {
        background-color: #e3fad4;
        p:before {
            content: "#e3fad4";
        }
    }
}


/**====== Light-Green-color css end ======**/


/**====== Lime-color css start ======**/

.lime-colors ul li {
    &:nth-child(14) {
        background-color: #baf905;
        p:before {
            content: "#baf905";
        }
    }
    &:nth-child(13) {
        background-color: #bffa14;
        p:before {
            content: "#bffa14";
        }
    }
    &:nth-child(12) {
        background-color: #c3fa23;
        p:before {
            content: "#c3fa23";
        }
    }
    &:nth-child(11) {
        background-color: #c7fb32;
        p:before {
            content: "#c7fb32";
        }
    }
    &:nth-child(10) {
        background-color: #cbfb41;
        p:before {
            content: "#cbfb41";
        }
    }
    &:nth-child(9) {
        background-color: #cffb50;
        p:before {
            content: "#cffb50";
        }
    }
    &:nth-child(8) {
        background-color: #d3fc5f;
        p:before {
            content: "#d3fc5f";
        }
    }
    &:nth-child(7) {
        background-color: #d7fc6e;
        p:before {
            content: "#d7fc6e";
        }
    }
    &:nth-child(6) {
        background-color: #dbfc7c;
        p:before {
            content: "#dbfc7c";
        }
    }
    &:nth-child(5) {
        background-color: #dffd8b;
        p:before {
            content: "#dffd8b";
        }
    }
    &:nth-child(4) {
        background-color: #e3fd9a;
        p:before {
            content: "#e3fd9a";
        }
    }
    &:nth-child(3) {
        background-color: #e8fda9;
        p:before {
            content: "#e8fda9";
        }
    }
    &:nth-child(2) {
        background-color: #ecfdb8;
        p:before {
            content: "#ecfdb8";
        }
    }
    &:nth-child(1) {
        background-color: #f0fec7;
        p:before {
            content: "#f0fec7";
        }
    }
}


/**====== Lime-color css end ======**/


/**====== Yellow-color css start ======**/

.yellow-colors ul li {
    &:nth-child(14) {
        background-color: #ffd812;
        p:before {
            content: "#ffd812";
        }
    }
    &:nth-child(13) {
        background-color: #ffda1f;
        p:before {
            content: "#ffda1f";
        }
    }
    &:nth-child(12) {
        background-color: #ffdc2b;
        p:before {
            content: "#ffdc2b";
        }
    }
    &:nth-child(11) {
        background-color: #ffde38;
        p:before {
            content: "#ffde38";
        }
    }
    &:nth-child(10) {
        background-color: #ffe045;
        p:before {
            content: "#ffe045";
        }
    }
    &:nth-child(9) {
        background-color: #ffe352;
        p:before {
            content: "#ffe352";
        }
    }
    &:nth-child(8) {
        background-color: #ffe55e;
        p:before {
            content: "#ffe55e";
        }
    }
    &:nth-child(7) {
        background-color: #ffe76b;
        p:before {
            content: "#ffe76b";
        }
    }
    &:nth-child(6) {
        background-color: #ffe978;
        p:before {
            content: "#ffe978";
        }
    }
    &:nth-child(5) {
        background-color: #ffeb85;
        p:before {
            content: "#ffeb85";
        }
    }
    &:nth-child(4) {
        background-color: #ffed91;
        p:before {
            content: "#ffed91";
        }
    }
    &:nth-child(3) {
        background-color: #ffef9e;
        p:before {
            content: "#ffef9e";
        }
    }
    &:nth-child(2) {
        background-color: #fff1ab;
        p:before {
            content: "#fff1ab";
        }
    }
    &:nth-child(1) {
        background-color: #fff3b8;
        p:before {
            content: "#fff3b8";
        }
    }
}


/**====== Yellow-color css end ======**/


/**====== Amber-color css start ======**/

.amber-colors ul li {
    &:nth-child(14) {
        background-color: #ffb012;
        p:before {
            content: "#ffb012";
        }
    }
    &:nth-child(13) {
        background-color: #ffb41f;
        p:before {
            content: "#ffb41f";
        }
    }
    &:nth-child(12) {
        background-color: #ffb92b;
        p:before {
            content: "#ffb92b";
        }
    }
    &:nth-child(11) {
        background-color: #ffbd38;
        p:before {
            content: "#ffbd38";
        }
    }
    &:nth-child(10) {
        background-color: #ffc145;
        p:before {
            content: "#ffc145";
        }
    }
    &:nth-child(9) {
        background-color: #ffc552;
        p:before {
            content: "#ffc552";
        }
    }
    &:nth-child(8) {
        background-color: #ffca5e;
        p:before {
            content: "#ffca5e";
        }
    }
    &:nth-child(7) {
        background-color: #ffce6b;
        p:before {
            content: "#ffce6b";
        }
    }
    &:nth-child(6) {
        background-color: #ffd278;
        p:before {
            content: "#ffd278";
        }
    }
    &:nth-child(5) {
        background-color: #ffd685;
        p:before {
            content: "#ffd685";
        }
    }
    &:nth-child(4) {
        background-color: #ffdb91;
        p:before {
            content: "#ffdb91";
        }
    }
    &:nth-child(3) {
        background-color: #ffdf9e;
        p:before {
            content: "#ffdf9e";
        }
    }
    &:nth-child(2) {
        background-color: #ffe3ab;
        p:before {
            content: "#ffe3ab";
        }
    }
    &:nth-child(1) {
        background-color: #ffe7b8;
        p:before {
            content: "#ffe7b8";
        }
    }
}


/**====== Amber-color css end ======**/


/**====== Orange-color css start ======**/

.orange-colors ul li {
    &:nth-child(14) {
        background-color: #ff7814;
        p:before {
            content: "#ff7814";
        }
    }
    &:nth-child(13) {
        background-color: #ff8124;
        p:before {
            content: "#ff8124";
        }
    }
    &:nth-child(12) {
        background-color: #ff8933;
        p:before {
            content: "#ff8933";
        }
    }
    &:nth-child(11) {
        background-color: #ff9242;
        p:before {
            content: "#ff9242";
        }
    }
    &:nth-child(10) {
        background-color: #ff9b52;
        p:before {
            content: "#ff9b52";
        }
    }
    &:nth-child(9) {
        background-color: #ffa461;
        p:before {
            content: "#ffa461";
        }
    }
    &:nth-child(8) {
        background-color: #ffad70;
        p:before {
            content: "#ffad70";
        }
    }
    &:nth-child(7) {
        background-color: #ffb67f;
        p:before {
            content: "#ffb67f";
        }
    }
    &:nth-child(6) {
        background-color: #ffbe8f;
        p:before {
            content: "#ffbe8f";
        }
    }
    &:nth-child(5) {
        background-color: #ffc79e;
        p:before {
            content: "#ffc79e";
        }
    }
    &:nth-child(4) {
        background-color: #ffd0ad;
        p:before {
            content: "#ffd0ad";
        }
    }
    &:nth-child(3) {
        background-color: #ffd9bd;
        p:before {
            content: "#ffd9bd";
        }
    }
    &:nth-child(2) {
        background-color: #ffe2cc;
        p:before {
            content: "#ffe2cc";
        }
    }
    &:nth-child(1) {
        background-color: #ffeadb;
        p:before {
            content: "#ffeadb";
        }
    }
}


/**====== Orange-color css start ======**/


/**====== Deep-Orange-color css start ======**/

.deep-orange-colors ul li {
    &:nth-child(14) {
        background-color: #ec3305;
        p:before {
            content: "#ec3305";
        }
    }
    &:nth-child(13) {
        background-color: #f93707;
        p:before {
            content: "#f93707";
        }
    }
    &:nth-child(12) {
        background-color: #fa4316;
        p:before {
            content: "#fa4316";
        }
    }
    &:nth-child(11) {
        background-color: #fa5025;
        p:before {
            content: "#fa5025";
        }
    }
    &:nth-child(10) {
        background-color: #fa5c34;
        p:before {
            content: "#fa5c34";
        }
    }
    &:nth-child(9) {
        background-color: #fb6843;
        p:before {
            content: "#fb6843";
        }
    }
    &:nth-child(8) {
        background-color: #fb7452;
        p:before {
            content: "#fb7452";
        }
    }
    &:nth-child(7) {
        background-color: #fb8061;
        p:before {
            content: "#fb8061";
        }
    }
    &:nth-child(6) {
        background-color: #fc8c70;
        p:before {
            content: "#fc8c70";
        }
    }
    &:nth-child(5) {
        background-color: #fc987f;
        p:before {
            content: "#fc987f";
        }
    }
    &:nth-child(4) {
        background-color: #fca48e;
        p:before {
            content: "#fca48e";
        }
    }
    &:nth-child(3) {
        background-color: #fdb09d;
        p:before {
            content: "#fdb09d";
        }
    }
    &:nth-child(2) {
        background-color: #fdbcac;
        p:before {
            content: "#fdbcac";
        }
    }
    &:nth-child(1) {
        background-color: #fdc8bb;
        p:before {
            content: "#fdc8bb";
        }
    }
}


/**====== Deep-Orange-color css end ======**/


/**====== Brown-color css start ======**/

.brown-colors ul li {
    &:nth-child(14) {
        background-color: #513631;
        p:before {
            content: "#513631";
        }
    }
    &:nth-child(13) {
        background-color: #61403a;
        p:before {
            content: "#61403a";
        }
    }
    &:nth-child(12) {
        background-color: #714b44;
        p:before {
            content: "#714b44";
        }
    }
    &:nth-child(11) {
        background-color: #81554d;
        p:before {
            content: "#81554d";
        }
    }
    &:nth-child(10) {
        background-color: #916057;
        p:before {
            content: "#916057";
        }
    }
    &:nth-child(9) {
        background-color: #a06b61;
        p:before {
            content: "#a06b61";
        }
    }
    &:nth-child(8) {
        background-color: #aa7a71;
        p:before {
            content: "#aa7a71";
        }
    }
    &:nth-child(7) {
        background-color: #b38981;
        p:before {
            content: "#b38981";
        }
    }
    &:nth-child(6) {
        background-color: #bd9791;
        p:before {
            content: "#bd9791";
        }
    }
    &:nth-child(5) {
        background-color: #c6a6a1;
        p:before {
            content: "#c6a6a1";
        }
    }
    &:nth-child(4) {
        background-color: #d0b5b1;
        p:before {
            content: "#d0b5b1";
        }
    }
    &:nth-child(3) {
        background-color: #dac4c1;
        p:before {
            content: "#dac4c1";
        }
    }
    &:nth-child(2) {
        background-color: #e3d3d0;
        p:before {
            content: "#e3d3d0";
        }
    }
    &:nth-child(1) {
        background-color: #ede2e0;
        p:before {
            content: "#ede2e0";
        }
    }
}


/**====== Brown-color css end ======**/


/**====== Grey-color css start ======**/

.grey-colors ul li {
    &:nth-child(14) {
        background-color: #323232;
        p:before {
            content: "#323232";
        }
    }
    &:nth-child(13) {
        background-color: #3f3f3f;
        p:before {
            content: "#3f3f3f";
        }
    }
    &:nth-child(12) {
        background-color: #4b4b4b;
        p:before {
            content: "#4b4b4b";
        }
    }
    &:nth-child(11) {
        background-color: #585858;
        p:before {
            content: "#585858";
        }
    }
    &:nth-child(10) {
        background-color: #656565;
        p:before {
            content: "#656565";
        }
    }
    &:nth-child(9) {
        background-color: #727272;
        p:before {
            content: "#727272";
        }
    }
    &:nth-child(8) {
        background-color: #7e7e7e;
        p:before {
            content: "#7e7e7e";
        }
    }
    &:nth-child(7) {
        background-color: #8b8b8b;
        p:before {
            content: "#8b8b8b";
        }
    }
    &:nth-child(6) {
        background-color: #989898;
        p:before {
            content: "#989898";
        }
    }
    &:nth-child(5) {
        background-color: #a5a5a5;
        p:before {
            content: "#a5a5a5";
        }
    }
    &:nth-child(4) {
        background-color: #b1b1b1;
        p:before {
            content: "#b1b1b1";
        }
    }
    &:nth-child(3) {
        background-color: #bebebe;
        p:before {
            content: "#bebebe";
        }
    }
    &:nth-child(2) {
        background-color: #cbcbcb;
        p:before {
            content: "#cbcbcb";
        }
    }
    &:nth-child(1) {
        background-color: #d8d8d8;
        p:before {
            content: "#d8d8d8";
        }
    }
}


/**====== Grey-color css end ======**/


/**====== Blue-color css start ======**/

.blue-grey-colors ul li {
    &:nth-child(14) {
        background-color: #35444a;
        p:before {
            content: "#35444a";
        }
    }
    &:nth-child(13) {
        background-color: #3f5159;
        p:before {
            content: "#3f5159";
        }
    }
    &:nth-child(12) {
        background-color: #4a5f68;
        p:before {
            content: "#4a5f68";
        }
    }
    &:nth-child(11) {
        background-color: #546d77;
        p:before {
            content: "#546d77";
        }
    }
    &:nth-child(10) {
        background-color: #5f7a85;
        p:before {
            content: "#5f7a85";
        }
    }
    &:nth-child(9) {
        background-color: #6a8894;
        p:before {
            content: "#6a8894";
        }
    }
    &:nth-child(8) {
        background-color: #78949f;
        p:before {
            content: "#78949f";
        }
    }
    &:nth-child(7) {
        background-color: #87a0aa;
        p:before {
            content: "#87a0aa";
        }
    }
    &:nth-child(6) {
        background-color: #96abb4;
        p:before {
            content: "#96abb4";
        }
    }
    &:nth-child(5) {
        background-color: #a5b7bf;
        p:before {
            content: "#a5b7bf";
        }
    }
    &:nth-child(4) {
        background-color: #b4c3ca;
        p:before {
            content: "#b4c3ca";
        }
    }
    &:nth-child(3) {
        background-color: #c3cfd4;
        p:before {
            content: "#c3cfd4";
        }
    }
    &:nth-child(2) {
        background-color: #d2dbdf;
        p:before {
            content: "#d2dbdf";
        }
    }
    &:nth-child(1) {
        background-color: #e1e7e9;
        p:before {
            content: "#e1e7e9";
        }
    }
}


/**====== Blue-color css end ======**/


/**====== Primary-color css start ======**/

.primary-colorr ul li {
    &:nth-child(14) {
        background-color: #1cc9a7;
        p:before {
            content: "#1cc9a7";
        }
    }
    &:nth-child(13) {
        background-color: #1ed7b2;
        p:before {
            content: "#1ed7b2";
        }
    }
    &:nth-child(12) {
        background-color: #23e1bb;
        p:before {
            content: "#23e1bb";
        }
    }
    &:nth-child(11) {
        background-color: #31e2bf;
        p:before {
            content: "#31e2bf";
        }
    }
    &:nth-child(10) {
        background-color: #3ee4c4;
        p:before {
            content: "#3ee4c4";
        }
    }
    &:nth-child(9) {
        background-color: #4ce6c8;
        p:before {
            content: "#4ce6c8";
        }
    }
    &:nth-child(8) {
        background-color: #59e8cc;
        p:before {
            content: "#59e8cc";
        }
    }
    &:nth-child(7) {
        background-color: #66ead0;
        p:before {
            content: "#66ead0";
        }
    }
    &:nth-child(6) {
        background-color: #74ecd4;
        p:before {
            content: "#74ecd4";
        }
    }
    &:nth-child(5) {
        background-color: #81eed8;
        p:before {
            content: "#81eed8";
        }
    }
    &:nth-child(4) {
        background-color: #8fefdc;
        p:before {
            content: "#8fefdc";
        }
    }
    &:nth-child(3) {
        background-color: #9cf1e1;
        p:before {
            content: "#9cf1e1";
        }
    }
    &:nth-child(2) {
        background-color: #aaf3e5;
        p:before {
            content: "#aaf3e5";
        }
    }
    &:nth-child(1) {
        background-color: #b7f5e9;
        p:before {
            content: "#b7f5e9";
        }
    }
}


/**====== Primary-color css end ======**/


/**====== Success-color css start ======**/

.success-colorr ul li {
    &:nth-child(14) {
        background-color: #33d176;
        p:before {
            content: "#33d176";
        }
    }
    &:nth-child(13) {
        background-color: #3bd37c;
        p:before {
            content: "#3bd37c";
        }
    }
    &:nth-child(12) {
        background-color: #44d581;
        p:before {
            content: "#44d581";
        }
    }
    &:nth-child(11) {
        background-color: #4cd787;
        p:before {
            content: "#4cd787";
        }
    }
    &:nth-child(10) {
        background-color: #54d98c;
        p:before {
            content: "#54d98c";
        }
    }
    &:nth-child(9) {
        background-color: #5dda92;
        p:before {
            content: "#5dda92";
        }
    }
    &:nth-child(8) {
        background-color: #65dc98;
        p:before {
            content: "#65dc98";
        }
    }
    &:nth-child(7) {
        background-color: #6dde9d;
        p:before {
            content: "#6dde9d";
        }
    }
    &:nth-child(6) {
        background-color: #76e0a3;
        p:before {
            content: "#76e0a3";
        }
    }
    &:nth-child(5) {
        background-color: #7ee2a8;
        p:before {
            content: "#7ee2a8";
        }
    }
    &:nth-child(4) {
        background-color: #86e4ae;
        p:before {
            content: "#86e4ae";
        }
    }
    &:nth-child(3) {
        background-color: #8fe6b4;
        p:before {
            content: "#8fe6b4";
        }
    }
    &:nth-child(2) {
        background-color: #97e8b9;
        p:before {
            content: "#97e8b9";
        }
    }
    &:nth-child(1) {
        background-color: #9fe9bf;
        p:before {
            content: "#9fe9bf";
        }
    }
}


/**====== Success-color css end ======**/


/**====== Info-color css start ======**/

.info-colorr ul li {
    &:nth-child(14) {
        background-color: #3d9cdd;
        p:before {
            content: "#3d9cdd";
        }
    }
    &:nth-child(13) {
        background-color: #45a1de;
        p:before {
            content: "#45a1de";
        }
    }
    &:nth-child(12) {
        background-color: #4ea5e0;
        p:before {
            content: "#4ea5e0";
        }
    }
    &:nth-child(11) {
        background-color: #57aae1;
        p:before {
            content: "#57aae1";
        }
    }
    &:nth-child(10) {
        background-color: #5faee3;
        p:before {
            content: "#5faee3";
        }
    }
    &:nth-child(9) {
        background-color: #68b2e4;
        p:before {
            content: "#68b2e4";
        }
    }
    &:nth-child(8) {
        background-color: #71b7e6;
        p:before {
            content: "#71b7e6";
        }
    }
    &:nth-child(7) {
        background-color: #79bbe7;
        p:before {
            content: "#79bbe7";
        }
    }
    &:nth-child(6) {
        background-color: #82c0e9;
        p:before {
            content: "#82c0e9";
        }
    }
    &:nth-child(5) {
        background-color: #8bc4ea;
        p:before {
            content: "#8bc4ea";
        }
    }
    &:nth-child(4) {
        background-color: #93c8ec;
        p:before {
            content: "#93c8ec";
        }
    }
    &:nth-child(3) {
        background-color: #9ccded;
        p:before {
            content: "#9ccded";
        }
    }
    &:nth-child(2) {
        background-color: #a5d1ef;
        p:before {
            content: "#a5d1ef";
        }
    }
    &:nth-child(1) {
        background-color: #add6f1;
        p:before {
            content: "#add6f1";
        }
    }
}


/**====== Info-color css end ======**/


/**====== Warning-color css start ======**/

.warning-colorr ul li {
    &:nth-child(14) {
        background-color: #f2c619;
        p:before {
            content: "#f2c619";
        }
    }
    &:nth-child(13) {
        background-color: #f2c922;
        p:before {
            content: "#f2c922";
        }
    }
    &:nth-child(12) {
        background-color: #f3cb2c;
        p:before {
            content: "#f3cb2c";
        }
    }
    &:nth-child(11) {
        background-color: #f3cd36;
        p:before {
            content: "#f3cd36";
        }
    }
    &:nth-child(10) {
        background-color: #f4d03f;
        p:before {
            content: "#f4d03f";
        }
    }
    &:nth-child(9) {
        background-color: #f4d249;
        p:before {
            content: "#f4d249";
        }
    }
    &:nth-child(8) {
        background-color: #f5d552;
        p:before {
            content: "#f5d552";
        }
    }
    &:nth-child(7) {
        background-color: #f5d75c;
        p:before {
            content: "#f5d75c";
        }
    }
    &:nth-child(6) {
        background-color: #f6d966;
        p:before {
            content: "#f6d966";
        }
    }
    &:nth-child(5) {
        background-color: #f7dc6f;
        p:before {
            content: "#f7dc6f";
        }
    }
    &:nth-child(4) {
        background-color: #f7de79;
        p:before {
            content: "#f7de79";
        }
    }
    &:nth-child(3) {
        background-color: #f8e083;
        p:before {
            content: "#f8e083";
        }
    }
    &:nth-child(2) {
        background-color: #f8e38c;
        p:before {
            content: "#f8e38c";
        }
    }
    &:nth-child(1) {
        background-color: #f9e596;
        p:before {
            content: "#f9e596";
        }
    }
}


/**====== Warning-color css end ======**/


/**====== Danger-color css start ======**/

.danger-colorr ul li {
    &:nth-child(14) {
        background-color: #e85445;
        p:before {
            content: "#e85445";
        }
    }
    &:nth-child(13) {
        background-color: #e95d4e;
        p:before {
            content: "#e95d4e";
        }
    }
    &:nth-child(12) {
        background-color: #ea6557;
        p:before {
            content: "#ea6557";
        }
    }
    &:nth-child(11) {
        background-color: #eb6d60;
        p:before {
            content: "#eb6d60";
        }
    }
    &:nth-child(10) {
        background-color: #ed7669;
        p:before {
            content: "#ed7669";
        }
    }
    &:nth-child(9) {
        background-color: #ee7e72;
        p:before {
            content: "#ee7e72";
        }
    }
    &:nth-child(8) {
        background-color: #ef867c;
        p:before {
            content: "#ef867c";
        }
    }
    &:nth-child(7) {
        background-color: #f08f85;
        p:before {
            content: "#f08f85";
        }
    }
    &:nth-child(6) {
        background-color: #f1978e;
        p:before {
            content: "#f1978e";
        }
    }
    &:nth-child(5) {
        background-color: #f29f97;
        p:before {
            content: "#f29f97";
        }
    }
    &:nth-child(4) {
        background-color: #f3a8a0;
        p:before {
            content: "#f3a8a0";
        }
    }
    &:nth-child(3) {
        background-color: #f4b0a9;
        p:before {
            content: "#f4b0a9";
        }
    }
    &:nth-child(2) {
        background-color: #f6b8b2;
        p:before {
            content: "#f6b8b2";
        }
    }
    &:nth-child(1) {
        background-color: #f7c1bb;
        p:before {
            content: "#f7c1bb";
        }
    }
}


/**====== Danger-color css end ======**/


/**====== Color-color css end ======**/


/**  ===================== Draggable css start
==========================  **/

#draggableMultiple .sortable-moves {
    cursor: move;
    margin-bottom: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 20px;
    padding: 15px 0 15px 60px;
}

.sortable-moves {
    font-size: 14px;
    line-height: 1.55556em;
    list-style-type: none;
    margin-bottom: 15px;
    min-height: 3.55556em;
    padding-left: 5.11111em;
    position: relative;
    cursor: move;
    img {
        position: absolute;
        height: 40px;
        left: 10px;
        border-radius: 50px;
        top: 15px;
    }
    h6 {
        margin-bottom: 0;
        font-weight: bold;
    }
}

.card-sub {
    cursor: move;
    border: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
    &:hover {
        box-shadow: 0 0 25px -5px #9e9c9e;
        transition: all 180ms linear;
    }
}


/**====== Draggable css end ======**/


/**  ===================== Payment-card css start
==========================  **/

.payment-card {
    .icofont-paypal-alt,
    .icofont-visa-alt,
    .icofont-mastercard {
        display: block;
        font-size: 60px;
        color: #ed5565;
    }
    .icofont-visa-alt {
        color: #1c84c6;
    }
    .icofont-mastercard {
        color: #f8ac59;
    }
}

.payment-tabs {
    .nav-tabs .slide {
        width: calc(100% / 5);
    }
    .md-tabs {
        .nav-item+.nav-item,
        .main-menu .main-menu-content .nav-item .tree-1 a+.nav-item {
            width: calc(100% / 5);
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .nav-item+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+a {
    width: calc(100% / 5);
}

.main-menu .main-menu-content .nav-item {
    .tree-1 .payment-tabs .md-tabs a+a,
    .tree-2 .payment-tabs .md-tabs .tree-1 a+a,
    .tree-3 .payment-tabs .md-tabs .tree-1 a+a,
    .tree-4 .payment-tabs .md-tabs .tree-1 a+a {
        width: calc(100% / 5);
    }
}

.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 .nav-item+a {
    width: calc(100% / 5);
}

.main-menu .main-menu-content .nav-item {
    .tree-2 .payment-tabs .md-tabs .nav-item+a,
    .tree-1 .payment-tabs .md-tabs .tree-2 a+a {
        width: calc(100% / 5);
    }
}

.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+a {
    width: calc(100% / 5);
}

.main-menu .main-menu-content .nav-item {
    .tree-2 .payment-tabs .md-tabs a+a,
    .tree-3 .payment-tabs .md-tabs .tree-2 a+a,
    .tree-4 .payment-tabs .md-tabs .tree-2 a+a {
        width: calc(100% / 5);
    }
}

.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 .nav-item+a {
    width: calc(100% / 5);
}

.main-menu .main-menu-content .nav-item {
    .tree-3 .payment-tabs .md-tabs .nav-item+a,
    .tree-1 .payment-tabs .md-tabs .tree-3 a+a,
    .tree-2 .payment-tabs .md-tabs .tree-3 a+a {
        width: calc(100% / 5);
    }
}

.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+a {
    width: calc(100% / 5);
}

.main-menu .main-menu-content .nav-item {
    .tree-3 .payment-tabs .md-tabs a+a,
    .tree-4 .payment-tabs .md-tabs .tree-3 a+a {
        width: calc(100% / 5);
    }
}

.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 .nav-item+a {
    width: calc(100% / 5);
}

.main-menu .main-menu-content .nav-item {
    .tree-4 .payment-tabs .md-tabs .nav-item+a,
    .tree-1 .payment-tabs .md-tabs .tree-4 a+a,
    .tree-2 .payment-tabs .md-tabs .tree-4 a+a,
    .tree-3 .payment-tabs .md-tabs .tree-4 a+a {
        width: calc(100% / 5);
    }
}

.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+a {
    width: calc(100% / 5);
}

.demo-container {
    padding-bottom: 0;
}

.jp-card {
    .jp-card-front,
    .jp-card-back {
        background: #0073aa !important;
    }
}

.payment-form {
    max-width: 550px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 0;
}


/**====== Payment-card css end ======**/


/**  ===================== Editor css start
==========================  **/

.ace_editor {
    width: 100% !important;
    position: relative !important;
    margin-top: 20px;
}

.long-press:focus {
    outline-color: #0073aa;
}

.inputor:focus {
    border-color: #0073aa;
}

#edui1 {
    width: auto !important;
}


/**====== Editor css end ======**/


/**  ===================== FB-wall css start
==========================  **/

.tab-pane form .md-add-on i {
    font-size: 20px;
}

.wall-elips {
    position: absolute;
    right: 15px;
}

.social-wallpaper {
    position: relative;
}

.social-profile {
    position: relative;
    padding-top: 15px;
}

.timeline-btn {
    position: absolute;
    bottom: 0;
    right: 30px;
}

.nav-tabs.md-tabs.tab-timeline li a {
    padding: 20px 0 10px;
    color: #666666;
    font-size: 18px;
}

.social-timeline-left {
    position: absolute;
    top: -200px;
    margin-right: 15px;
}

.post-input {
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    resize: none;
}

.user-box .media-object {
    height: 45px;
    width: 45px;
    display: inline-block;
}

.friend-box {
    .media-object {
        height: 45px;
        width: 45px;
        display: inline-block;
    }
    img {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.chat-header {
    color: #222222;
}

.live-status {
    height: 9px;
    width: 9px;
    position: absolute;
    bottom: 0;
    right: 17px;
    border-radius: 100%;
    border: 1px solid;
}

.tab-timeline .slide {
    bottom: -1px;
}

.image-upload input {
    visibility: hidden;
    max-width: 0;
    max-height: 0;
}

.file-upload-lbl {
    max-width: 15px;
    padding: 5px 0 0;
}

.ellipsis::after {
    top: 15px;
    border: none;
    position: absolute;
    content: '\f142';
    font-family: FontAwesome;
    right: 30px;
}

.elipsis-box {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
    top: 40px;
    right: -10px;
    &:after {
        content: '';
        height: 13px;
        width: 13px;
        background: #fff;
        position: absolute;
        top: -5px;
        right: 10px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
        box-shadow: -3px -3px 11px 1px rgba(170, 170, 170, 0.22);
    }
}

.friend-elipsis {
    left: -10px;
    top: -10px;
}

.social-profile:hover .profile-hvr,
.social-wallpaper:hover .profile-hvr {
    opacity: 1;
    transition: all ease-in-out 0.3s;
}

.profile-hvr {
    opacity: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 20px;
    padding: 10px;
    top: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.61);
    transition: all ease-in-out 0.3s;
}

.social-profile {
    margin: 0 15px;
}

.social-follower {
    text-align: center;
    h4 {
        font-size: 18px;
        margin-bottom: 10px;
        font-style: normal;
    }
    h5 {
        font-size: 14px;
    }
    .follower-counter {
        text-align: center;
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 13px;
        .txt-primary {
            font-size: 24px;
        }
    }
}

.timeline-icon {
    height: 45px;
    width: 45px;
    display: block;
    margin: 0 auto;
    border: 4px #fff solid;
}

.social-timelines-left:after {
    height: 3px;
    width: 25%;
    position: absolute;
    background: #cccccc;
    top: 20px;
    content: "";
    right: 0;
    z-index: 0;
}

.social-timelines:before {
    position: absolute;
    content: ' ';
    width: 3px;
    background: #cccccc;
    left: 4%;
    z-index: 0;
    height: 100%;
    top: 0;
    z-index: -1;
}

.timeline-dot {
    &:after,
    &:before {
        content: "";
        position: absolute;
        height: 9px;
        width: 9px;
        background-color: #cccccc;
        left: 3.8%;
        border-radius: 100%;
    }
}

.user-box .social-designation,
.post-timelines .social-time {
    font-size: 13px;
}

.user-box .f-right {
    text-transform: capitalize;
}

.social-msg {
    span {
        color: #666;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 10px;
    }
    a {
        display: inline-block;
    }
}

.view-info .social-label,
.contact-info .social-label,
.work-info .social-label {
    font-size: 15px;
    padding-left: 0;
    padding-top: 0;
}

.view-info .social-user-name,
.contact-info .social-user-name,
.work-info .social-user-name {
    font-size: 14px;
    padding-left: 0;
}

.friend-elipsis .social-designation {
    font-size: 13px;
}

.timeline-details {
    p {
        padding-top: 10px;
        margin-bottom: 0;
    }
    .chat-header {
        font-size: 15px;
    }
}

.post-timelines .chat-header {
    font-size: 15px;
}

.social-client-description {
    padding-bottom: 20px;
    margin-bottom: 20px;
    p {
        margin-top: 5px;
        margin-bottom: 0;
    }
    span {
        font-size: 12px;
        margin-left: 10px;
    }
    .chat-header {
        font-size: 13px;
    }
}

.social-tabs a {
    font-size: 18px;
}

.timeline-btn a {
    margin-bottom: 20px;
}

.profile-hvr i {
    cursor: pointer;
}


/*====== FB-wall css End ======*/


/**  ===================== Full-calender css start
==========================  **/

.fc-left {
    margin-bottom: 10px;
}

#external-events {
    h4 {
        font-size: 16px;
        margin-top: 0;
        padding-top: 1em;
    }
    .fc-event {
        background: #fff;
        color: #000;
        cursor: move;
        border-left: none;
        padding: 5px 18px;
        margin-bottom: 5px;
    }
    p {
        margin: 1.5em 0;
        font-size: 11px;
        color: #666;
        input {
            margin: 0;
            vertical-align: middle;
        }
    }
}

.fc {
    th,
    td {
        line-height: 2.5;
    }
}

.fc-state-default {
    background-color: #fff;
    color: #272727;
}

tr:first-child>td>.fc-day-grid-event {
    color: #000;
    padding-left: 10px;
    background: #f3f3f3;
}

.fc-nonbusiness {
    background-color: #fff;
}

.fc-state-active {
    background-color: #0073aa;
    color: #fff;
}

.fc-toolbar {
    margin-bottom: 8px;
    .fc-center {
        display: block;
    }
}

.fc-center h2 {
    font-weight: 300;
    font-size: 25px;
    margin-top: 10px;
}

.fc button {
    height: auto;
    padding: 10px;
}

.fc-toolbar .fc-left {
    float: right;
}

.fc-button-group {
    margin-right: 10px;
}

.fc .fc-toolbar>*>* {
    margin-left: 0;
}

.fc-event,
.fc-event-dot {
    background-color: transparent;
}

.fc-day-grid-event:hover {
    color: #000 !important;
    opacity: 0.8;
}

.fc-time-grid-event .fc-content {
    color: #fff;
}

.fc-bgevent {
    /* default look for background events */
    background: #d8d6d6 !important;
}

.fc-state-default {
    background-image: none !important;
    border-color: transparent !important;
    text-shadow: none !important;
    box-shadow: none !important;
}

.fc-state-down,
.fc-state-active {
    background-color: transparent !important;
    background-image: none !important;
    box-shadow: none !important;
}

.fc th {
    background: #0073aa;
    color: #fff;
    border-color: #0073aa;
}

.fc-event {
    line-height: 1.3;
    border-radius: 2px;
    border-left: 4px solid #0073aa !important;
}

tr:first-child>td>.fc-day-grid-event {
    margin-left: 0;
}

.clndr {
    width: 100%;
    background-color: #F3F3F3;
    box-shadow: 0 4px 0 #272727;
    .clndr-controls {
        padding: 14px;
        background-color: #37BC9B;
        color: #fff;
        text-align: center;
    }
    &.clndr-controls {
        &.clndr-previous-button {
            float: left;
            text-align: left;
        }
        &.clndr-next-button {
            float: right;
            text-align: right;
            width: 30px;
            cursor: pointer;
        }
        &.clndr-previous-button {
            width: 30px;
            cursor: pointer;
        }
        &.clndr-next-button:hover,
        &.clndr-previous-button:hover {
            opacity: 0.5;
        }
    }
    &.clndr-grid {
        float: left;
        width: 65%;
        background-color: #FFF;
        &.days-of-the-week {
            width: 100%;
            background-color: #F6BB42;
            &.header-day {
                float: left;
                width: 14.2857%;
                padding: 14px;
                text-align: center;
                color: #fff;
            }
        }
        &.days {
            width: 100%;
            &.day,
            &.empty {
                float: left;
                width: 14.2857%;
                padding: 12px 0;
                text-align: center;
                color: #4f4f4f;
            }
        }
    }
    .clndr-grid .days {
        .day.event .day-number,
        .empty.event .day-number {
            border: 1px solid #F6BB42;
            border-radius: 50%;
            padding: 6px 9px;
        }
    }
    &.clndr-grid.days {
        &.day {
            &.adjacent-month.day-number,
            &.inactive.day-number {
                opacity: .3;
                cursor: pointer;
            }
        }
        &.empty.adjacent-month.day-number {
            opacity: .3;
            cursor: pointer;
        }
        &.day.selected {
            background-color: #DA4453;
            color: #fff;
        }
        &.today {
            background-color: #37BC9B;
            color: #fff;
            background-image: none;
        }
    }
    &.event-listing {
        float: left;
        width: 35%;
        &.event-listing-title {
            padding: 15.5px;
            font-size: .88rem;
            background-color: #f4a911;
            text-align: center;
            color: #fff;
            letter-spacing: 1px;
        }
        &.event-item {
            padding: 6px 14px;
            color: #4f4f4f;
        }
    }
    .event-listing .event-item-location {
        font-weight: 400;
    }
}

.fc-event {
    border-color: #0073aa;
}


/**====== Full-calender css end ======**/


/**  ===================== Job-pages css start
==========================  **/

.job-right-header {
    .card-header .card-header-right {
        top: 7px;
        right: 15px;
    }
    .radio-inline {
        display: block;
    }
    .checkbox-fade {
        display: block;
        label {
            position: absolute;
            z-index: 99;
        }
        div {
            position: relative;
            padding-left: 40px;
            margin-bottom: 20px;
        }
    }
    a {
        color: #0073aa;
        font-weight: 600;
    }
}

.job-details-list {
    padding-left: inherit;
    list-style-type: square;
    margin-bottom: 40px;
    li {
        margin-bottom: 10px;
    }
}

.company-name p {
    font-weight: 600;
    color: #0073aa;
    font-size: 20px;
    margin-bottom: 5px;
}

.job-cards {
    .media {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #ddd;
    }
    &:last-child .media {
        border-bottom: none;
        padding-bottom: 0;
    }
    img {
        width: 60px;
    }
    .media-right {
        position: absolute;
        top: 20px;
        right: 0;
    }
}

.job-badge {
    position: absolute;
    top: 20px;
    right: 10px;
}

.job-lable .label {
    background-color: #0073aa;
    color: #fff;
    margin: 5px 10px;
    position: relative;
    z-index: 9;
    display: inline-block;
    padding: 5px 10px 5px 12px;
    &:before {
        content: "";
        background-color: #0073aa;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        position: absolute;
        left: -7px;
        top: 3px;
        z-index: -1;
    }
    &:after {
        content: '\ed55';
        font-family: 'IcoFont' !important;
        position: absolute;
        left: 0;
        font-size: 5px;
        top: 8px;
    }
}

.job-card-desc {
    font-weight: 600;
}

.job-meta-data {
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 10px;
    i {
        margin-right: 5px;
        color: #0073aa;
    }
}


/**====== Job-pages css end ======**/


/**  ===================== Mail-pages css start
==========================  **/

.email-card {
    padding: 0 15px;
    .user-head {
        background-color: #0073aa;
        padding: 10px;
        min-height: 80px;
        display: flex;
        align-items: center;
        .user-name {
            margin-left: 10px;
            margin-bottom: 10px;
            span {
                display: block;
                color: #fff;
            }
        }
    }
    .user-body {
        margin-left: -15px;
        margin-right: -15px;
        background-color: #fff;
        border-right: 1px solid #ddd;
        .page-list li {
            text-transform: capitalize;
            border-bottom: 1px solid #ddd;
            transition: all ease-in .3s;
            cursor: pointer;
            .mail-section {
                padding: 15px 20px;
                display: block;
                color: #6a6a6a;
                a {
                    color: #6a6a6a;
                }
            }
            i {
                margin-right: 10px;
            }
            &.active,
            &:hover {
                background-color: #e5e5e5;
            }
        }
        .label-list a {
            padding: 10px 20px;
            display: block;
            color: #6a6a6a;
            position: relative;
            padding-left: 25px;
            &:before {
                content: '';
                position: absolute;
                height: 10px;
                width: 10px;
                border-radius: 50px;
                left: 0;
                top: 16px;
            }
            &.mail-work:before {
                background-color: #0073aa;
            }
            &.mail-design:before {
                background-color: #3498DB;
            }
            &.mail-family:before {
                background-color: #2ecc71;
            }
            &.mail-friends:before {
                background-color: #f1c40f;
            }
            &.mail-office:before {
                background-color: #e74c3c;
            }
        }
    }
    .mail-box-head {
        background-color: #bdc3c7;
        min-height: 80px;
        padding: 20px 0;
        h3 {
            color: #fff;
        }
        .input-group {
            margin-bottom: 0;
        }
    }
    .mail-body {
        padding: 20px 0;
        .mail-body-header {
            margin-bottom: 20px;
            .btn-group {
                height: 42px;
            }
        }
    }
    .mail-body-content {
        .check-star {
            display: flex;
            align-items: center;
            .icofont-star {
                margin-top: -8px;
            }
        }
        tr {
            transition: all ease-in .3s;
            &:hover {
                background-color: #e5e5e5;
                cursor: pointer;
            }
        }
        .unread a {
            color: #222;
            font-weight: 600;
        }
        .read a {
            color: #222;
            font-weight: 400;
        }
    }
}

.email-summernote~.note-frame {
    border: 1px solid #ddd;
    border-radius: 2px;
}

.email-read {
    .photo-table {
        padding-right: 10px;
    }
    .user-name {
        margin-bottom: 10px;
    }
}

.mail-body-content.email-read {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    .card {
        border-top: none;
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: 0 0 25px -5px #9e9c9e;
        transition: all 150ms linear;
    }
}

.user-name {
    margin-bottom: 20px;
}

.email-card .user-mail h6 {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 20px;
    color: #292b2c;
}

.email-content {
    line-height: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}

.mail-img {
    margin-top: 20px;
}

.email-welcome-txt {
    font-weight: 600;
}


/**====== Mail-pages css end ======**/

.set-map {
    height: 400px;
}

#mapGeo {
    height: 345px;
}

#address-search .input-group {
    margin-bottom: 0;
}


/**  ===================== Navbar-varients css start
==========================  **/

.light-nav-border,
.primary-nav,
.warning-nav,
.success-nav,
.info-nav,
.danger-nav {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
}

.light-nav-border a,
.primary-nav a,
.warning-nav a,
.success-nav a,
.info-nav a,
.danger-nav a {
    color: #222;
}

.light-nav-border li.active a,
.primary-nav li.active a,
.warning-nav li.active a,
.success-nav li.active a,
.info-nav li.active a,
.danger-nav li.active a {
    color: #0073aa;
    font-weight: 600;
}

.light-nav-border .nav-item,
.primary-nav .nav-item,
.warning-nav .nav-item,
.success-nav .nav-item,
.info-nav .nav-item,
.danger-nav .nav-item,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav a,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav a,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav a {
    margin-left: 15px;
}

.light-nav-border .nav-item:hover .navbar-varient-submenu,
.primary-nav .nav-item:hover .navbar-varient-submenu,
.warning-nav .nav-item:hover .navbar-varient-submenu,
.success-nav .nav-item:hover .navbar-varient-submenu,
.info-nav .nav-item:hover .navbar-varient-submenu,
.danger-nav .nav-item:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav a:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav a:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.light-nav-border .navbar-varient-submenu,
.primary-nav .navbar-varient-submenu,
.warning-nav .navbar-varient-submenu,
.success-nav .navbar-varient-submenu,
.info-nav .navbar-varient-submenu,
.danger-nav .navbar-varient-submenu {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.03), 0 -2px 1px -2px rgba(0, 0, 0, 0.02), 0 0 0 -1px rgba(0, 0, 0, 0.03);
    border: 1px solid #ddd;
    position: absolute;
    width: 16em;
    background-color: #fff;
    transition: all linear 0.3s;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}

.light-nav-border .navbar-varient-submenu a,
.primary-nav .navbar-varient-submenu a,
.warning-nav .navbar-varient-submenu a,
.success-nav .navbar-varient-submenu a,
.info-nav .navbar-varient-submenu a,
.danger-nav .navbar-varient-submenu a {
    padding: 10px;
    display: block;
    transition: background-color linear 0.3s;
}

.light-nav-border .navbar-varient-submenu a:hover,
.primary-nav .navbar-varient-submenu a:hover,
.warning-nav .navbar-varient-submenu a:hover,
.success-nav .navbar-varient-submenu a:hover,
.info-nav .navbar-varient-submenu a:hover,
.danger-nav .navbar-varient-submenu a:hover {
    background-color: #f1f1f1;
}

.light-nav-border .profile-sub-menu,
.primary-nav .profile-sub-menu,
.warning-nav .profile-sub-menu,
.success-nav .profile-sub-menu,
.info-nav .profile-sub-menu,
.danger-nav .profile-sub-menu {
    right: 10px;
}

.light-nav-border .profile-sub-menu i,
.primary-nav .profile-sub-menu i,
.warning-nav .profile-sub-menu i,
.success-nav .profile-sub-menu i,
.info-nav .profile-sub-menu i,
.danger-nav .profile-sub-menu i {
    margin-right: 10px;
}

.primary-nav {
    background-color: #11b2ff;
    a {
        color: #666;
    }
    li.active a {
        color: #666;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #222;
    }
}

.warning-nav {
    background-color: #f7dc6f;
    a {
        color: #666;
    }
    li.active a {
        color: #666;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #222;
    }
}

.success-nav {
    background-color: #7ee2a8;
    a {
        color: #666;
    }
    li.active a {
        color: #666;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #222;
    }
}

.info-nav {
    background-color: #8bc4ea;
    a {
        color: #666;
    }
    li.active a {
        color: #666;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #222;
    }
}

.danger-nav {
    background-color: #f5b4ae;
    a {
        color: #666;
    }
    li.active a {
        color: #666;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #222;
    }
}

.bg-dark {
    background-color: #2C3E50;
    color: #fff;
}

.navbar-dark {
    .navbar-brand,
    .navbar-toggler {
        color: rgba(255, 255, 255, 0.9);
    }
    .navbar-nav {
        .active>.nav-link {
            color: rgba(255, 255, 255, 0.9);
        }
        .navbar-dark .navbar-nav .nav-link {
            &.active,
            &.open {
                color: rgba(255, 255, 255, 0.9);
            }
        }
        .navbar-light .navbar-nav .open>.nav-link {
            color: rgba(255, 255, 255, 0.9);
        }
        .nav-link {
            color: rgba(255, 255, 255, 0.5);
            &.disabled {
                color: rgba(255, 255, 255, 0.3);
            }
        }
    }
    button.btn {
        color: #fff;
        border-color: #fff;
    }
}

.dark-nav-border,
.primary-nav-dark,
.warning-nav-dark,
.success-nav-dark,
.info-nav-dark,
.danger-nav-dark {
    border: 1px solid #2C3E50;
    padding: 15px;
}

.primary-nav,
.warning-nav,
.success-nav,
.info-nav,
.danger-nav {
    border: none;
    padding: 15px;
}

.dark-nav-border a,
.primary-nav-dark a,
.warning-nav-dark a,
.success-nav-dark a,
.info-nav-dark a,
.danger-nav-dark a,
.primary-nav a,
.warning-nav a,
.success-nav a,
.info-nav a,
.danger-nav a {
    color: rgba(0, 0, 0, 0.9);
}

.dark-nav-border .navbar-varient-submenu,
.primary-nav-dark .navbar-varient-submenu,
.warning-nav-dark .navbar-varient-submenu,
.success-nav-dark .navbar-varient-submenu,
.info-nav-dark .navbar-varient-submenu,
.danger-nav-dark .navbar-varient-submenu,
.primary-nav .navbar-varient-submenu,
.warning-nav .navbar-varient-submenu,
.success-nav .navbar-varient-submenu,
.info-nav .navbar-varient-submenu,
.danger-nav .navbar-varient-submenu {
    box-shadow: -2 0 1px 2px rgba(0, 0, 0, 0.03), 0 -2px 1px -2px rgba(0, 0, 0, 0.02), 0 0 0 -1px rgba(0, 0, 0, 0.03);
    border: 1px solid #ddd;
    position: absolute;
    width: 16em;
    background-color: #fff;
    transition: all linear 0.3s;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}

.dark-nav-border .nav-item:hover .navbar-varient-submenu,
.primary-nav-dark .nav-item:hover .navbar-varient-submenu,
.warning-nav-dark .nav-item:hover .navbar-varient-submenu,
.success-nav-dark .nav-item:hover .navbar-varient-submenu,
.info-nav-dark .nav-item:hover .navbar-varient-submenu,
.danger-nav-dark .nav-item:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.primary-nav {
    .nav-item:hover .navbar-varient-submenu,
    .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .primary-nav a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.warning-nav {
    .nav-item:hover .navbar-varient-submenu,
    .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .warning-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.success-nav {
    .nav-item:hover .navbar-varient-submenu,
    .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .success-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.info-nav {
    .nav-item:hover .navbar-varient-submenu,
    .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .info-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.danger-nav {
    .nav-item:hover .navbar-varient-submenu,
    .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 .danger-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav-dark .main-menu .main-menu-content .nav-item {
    .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 {
        .dark-nav-border a:hover .navbar-varient-submenu,
        a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu {
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
            opacity: 1;
            visibility: visible;
        }
    }
    .tree-2 a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-2 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-2 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-2 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
    .tree-1 {
        .tree-4 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
        .primary-nav-dark a:hover .navbar-varient-submenu,
        a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu {
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
            opacity: 1;
            visibility: visible;
        }
    }
    .tree-2 a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
    .tree-1 {
        .tree-4 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
        .warning-nav-dark a:hover .navbar-varient-submenu,
        a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu {
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
            opacity: 1;
            visibility: visible;
        }
    }
    .tree-2 a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
    .tree-1 {
        .tree-4 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
        .success-nav-dark a:hover .navbar-varient-submenu,
        a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu {
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
            opacity: 1;
            visibility: visible;
        }
    }
    .tree-2 a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
    .tree-1 {
        .tree-4 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
        .info-nav-dark a:hover .navbar-varient-submenu,
        a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu {
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
            opacity: 1;
            visibility: visible;
        }
    }
    .tree-2 a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
    .tree-1 {
        .tree-4 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
        .danger-nav-dark a:hover .navbar-varient-submenu,
        a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu {
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
            opacity: 1;
            visibility: visible;
        }
    }
    .tree-2 a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-2 .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-2 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-3 .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-3 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-4 .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
    .tree-1 .tree-4 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav .main-menu .main-menu-content .nav-item {
    .tree-1 a:hover .navbar-varient-submenu,
    .tree-2 a:hover .navbar-varient-submenu,
    .tree-3 a:hover .navbar-varient-submenu,
    .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 .primary-nav a:hover .navbar-varient-submenu,
    .tree-2 .primary-nav a:hover .navbar-varient-submenu,
    .tree-3 .primary-nav a:hover .navbar-varient-submenu,
    .tree-4 .primary-nav a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.warning-nav .main-menu .main-menu-content .nav-item {
    .tree-1 a:hover .navbar-varient-submenu,
    .tree-2 a:hover .navbar-varient-submenu,
    .tree-3 a:hover .navbar-varient-submenu,
    .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 .warning-nav a:hover .navbar-varient-submenu,
    .tree-2 .warning-nav a:hover .navbar-varient-submenu,
    .tree-3 .warning-nav a:hover .navbar-varient-submenu,
    .tree-4 .warning-nav a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.success-nav .main-menu .main-menu-content .nav-item {
    .tree-1 a:hover .navbar-varient-submenu,
    .tree-2 a:hover .navbar-varient-submenu,
    .tree-3 a:hover .navbar-varient-submenu,
    .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 .success-nav a:hover .navbar-varient-submenu,
    .tree-2 .success-nav a:hover .navbar-varient-submenu,
    .tree-3 .success-nav a:hover .navbar-varient-submenu,
    .tree-4 .success-nav a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.info-nav .main-menu .main-menu-content .nav-item {
    .tree-1 a:hover .navbar-varient-submenu,
    .tree-2 a:hover .navbar-varient-submenu,
    .tree-3 a:hover .navbar-varient-submenu,
    .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 .info-nav a:hover .navbar-varient-submenu,
    .tree-2 .info-nav a:hover .navbar-varient-submenu,
    .tree-3 .info-nav a:hover .navbar-varient-submenu,
    .tree-4 .info-nav a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.danger-nav .main-menu .main-menu-content .nav-item {
    .tree-1 a:hover .navbar-varient-submenu,
    .tree-2 a:hover .navbar-varient-submenu,
    .tree-3 a:hover .navbar-varient-submenu,
    .tree-4 a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.main-menu .main-menu-content .nav-item {
    .tree-1 .danger-nav a:hover .navbar-varient-submenu,
    .tree-2 .danger-nav a:hover .navbar-varient-submenu,
    .tree-3 .danger-nav a:hover .navbar-varient-submenu,
    .tree-4 .danger-nav a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.main-menu .main-menu-content .nav-item .tree-2 {
    .dark-nav-border a:hover .navbar-varient-submenu,
    .primary-nav-dark a:hover .navbar-varient-submenu,
    .warning-nav-dark a:hover .navbar-varient-submenu,
    .success-nav-dark a:hover .navbar-varient-submenu,
    .info-nav-dark a:hover .navbar-varient-submenu,
    .danger-nav-dark a:hover .navbar-varient-submenu {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
        opacity: 1;
        visibility: visible;
    }
}

.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible;
}

.dark-nav-border .navbar-varient-submenu a:hover,
.primary-nav-dark .navbar-varient-submenu a:hover,
.warning-nav-dark .navbar-varient-submenu a:hover,
.success-nav-dark .navbar-varient-submenu a:hover,
.info-nav-dark .navbar-varient-submenu a:hover,
.danger-nav-dark .navbar-varient-submenu a:hover,
.primary-nav .navbar-varient-submenu a:hover,
.warning-nav .navbar-varient-submenu a:hover,
.success-nav .navbar-varient-submenu a:hover,
.info-nav .navbar-varient-submenu a:hover,
.danger-nav .navbar-varient-submenu a:hover {
    background-color: #f1f1f1;
}

.dark-nav-border .navbar-varient-submenu a,
.primary-nav-dark .navbar-varient-submenu a,
.warning-nav-dark .navbar-varient-submenu a,
.success-nav-dark .navbar-varient-submenu a,
.info-nav-dark .navbar-varient-submenu a,
.danger-nav-dark .navbar-varient-submenu a,
.primary-nav .navbar-varient-submenu a,
.warning-nav .navbar-varient-submenu a,
.success-nav .navbar-varient-submenu a,
.info-nav .navbar-varient-submenu a,
.danger-nav .navbar-varient-submenu a {
    padding: 10px;
    display: block;
    transition: background-color linear 0.3s;
}

.dark-nav-border li.active a,
.primary-nav-dark li.active a,
.warning-nav-dark li.active a,
.success-nav-dark li.active a,
.info-nav-dark li.active a,
.danger-nav-dark li.active a,
.primary-nav li.active a,
.warning-nav li.active a,
.success-nav li.active a,
.info-nav li.active a,
.danger-nav li.active a {
    color: #666;
    font-weight: 600;
}

.dark-nav-border .profile-sub-menu,
.primary-nav-dark .profile-sub-menu,
.warning-nav-dark .profile-sub-menu,
.success-nav-dark .profile-sub-menu,
.info-nav-dark .profile-sub-menu,
.danger-nav-dark .profile-sub-menu,
.primary-nav .profile-sub-menu,
.warning-nav .profile-sub-menu,
.success-nav .profile-sub-menu,
.info-nav .profile-sub-menu,
.danger-nav .profile-sub-menu {
    right: 10px;
}

.primary-nav-dark {
    background-color: #005177;
    border: none;
    a {
        color: #fff;
    }
    li.active a {
        color: #fff;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #fff;
    }
    .nav-link.disabled {
        color: rgba(255, 255, 255, 0.5);
    }
    .navbar-varient-submenu {
        background-color: #005177;
        border-color: #003f5e;
        a:hover {
            background-color: #003f5e;
        }
    }
}

.warning-nav-dark {
    background-color: #c29d0b;
    border: none;
    a {
        color: #fff;
    }
    li.active a {
        color: #fff;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #fff;
    }
    .nav-link.disabled {
        color: rgba(255, 255, 255, 0.5);
    }
    .navbar-varient-submenu {
        background-color: #c29d0b;
        border-color: #aa8a0a;
        a:hover {
            background-color: #aa8a0a;
        }
    }
}

.success-nav-dark {
    background-color: #25a25a;
    border: none;
    a {
        color: #fff;
    }
    li.active a {
        color: #fff;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #fff;
    }
    .nav-link.disabled {
        color: rgba(255, 255, 255, 0.5);
    }
    .navbar-varient-submenu {
        background-color: #25a25a;
        border-color: #208e4e;
        a:hover {
            background-color: #208e4e;
        }
    }
}

.info-nav-dark {
    background-color: #217dbb;
    border: none;
    a {
        color: #fff;
    }
    li.active a {
        color: #fff;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #fff;
    }
    .nav-link.disabled {
        color: rgba(255, 255, 255, 0.5);
    }
    .navbar-varient-submenu {
        background-color: #217dbb;
        border-color: #1d6fa5;
        a:hover {
            background-color: #1d6fa5;
        }
    }
}

.danger-nav-dark {
    background-color: #d62c1a;
    border: none;
    a {
        color: #fff;
    }
    li.active a {
        color: #fff;
        font-weight: 600;
    }
    .navbar-varient-submenu a {
        color: #fff;
    }
    .nav-link.disabled {
        color: rgba(255, 255, 255, 0.5);
    }
    .navbar-varient-submenu {
        background-color: #d62c1a;
        border-color: #bf2718;
        a:hover {
            background-color: #bf2718;
        }
    }
}

.nav-item label.badge-top-left {
    right: 4.4%;
    bottom: 38px;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 a label.badge-top-left,
    .tree-2 a label.badge-top-left,
    .tree-3 a label.badge-top-left,
    .tree-4 a label.badge-top-left {
        right: 4.4%;
        bottom: 38px;
    }
}

.nav-item .radio-inline {
    margin-top: 7px;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 a .radio-inline,
    .tree-2 a .radio-inline,
    .tree-3 a .radio-inline,
    .tree-4 a .radio-inline {
        margin-top: 7px;
    }
}

.primary-nav-dark button,
.warning-nav-dark button,
.success-nav-dark button,
.info-nav-dark button,
.danger-nav-dark button {
    border-color: #fff;
}

.primary-nav-dark i,
.warning-nav-dark i,
.success-nav-dark i,
.info-nav-dark i,
.danger-nav-dark i {
    color: #fff;
}

.primary-nav button,
.warning-nav button,
.success-nav button,
.info-nav button,
.danger-nav button {
    border-color: rgba(0, 0, 0, 0.6) !important;
}

.primary-nav i,
.warning-nav i,
.success-nav i,
.info-nav i,
.danger-nav i {
    color: rgba(0, 0, 0, 0.6) !important;
}

.light-nav-border button,
.primary-nav button,
.warning-nav button,
.success-nav button,
.info-nav button,
.danger-nav button {
    border-color: rgba(0, 0, 0, 0.1);
}

.light-nav-border i,
.primary-nav i,
.warning-nav i,
.success-nav i,
.info-nav i,
.danger-nav i {
    color: rgba(0, 0, 0, 0.4);
}


/**====== Navbar-varients css end ======**/


/**  ===================== Note css start
==========================  **/

.note-card {
    padding-top: 0;
    padding-bottom: 0;
    .note-box-aside {
        border-right: 1px solid #ddd;
        .row {
            padding: 20px 0;
            display: flex;
            align-items: center;
        }
    }
    .Note-header {
        padding: 20px 0;
    }
    .note-write {
        &:before {
            content: '';
            position: absolute;
            width: 0px;
            top: 0;
            left: 32px;
            bottom: 0;
            border-left: 1px solid #dacaaa;
        }
        &:after {
            content: '';
            position: absolute;
            width: 0px;
            top: 0;
            left: 34px;
            bottom: 0;
            border-left: 1px solid #dacaaa;
        }
    }
    #Note-pad {
        border: none;
        resize: none;
        background: transparent;
        padding: 0px 20px 0 50px;
        line-height: 35px;
    }
    .Note-created__on {
        display: block;
    }
}


/*.note-card .note-write {
    position: relative;
    background: -webkit-linear-gradient(top, #f0f0f0 0%, #ffffec 5%) 0 0;
    background: -moz-linear-gradient(top, #f0f0f0 0%, #ffffec 5%) 0 0;
    background: linear-gradient(top, #f0f0f0 0%, #ffffec 5%) 0 0;
    -webkit-background-size: 100% 35px;
    -moz-background-size: 100% 35px;
    background-size: 100% 35px;
}*/

.summernote-page .modal-footer {
    margin: 0 auto;
}


/**====== Note css end ======**/


/**  ===================== Prism css start
==========================  **/

pre {
    &[class*="language-"] {
        background-color: #fff;
        border: 1px solid #ddd;
        code {
            background-color: #fff !important;
        }
    }
    &.line-numbers {
        position: relative;
        padding-left: 3.8em;
        counter-reset: linenumber;
        >code {
            position: relative;
        }
    }
}

.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em;
    /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    border-right: 1px solid #999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.line-numbers-rows>span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber;
    &:before {
        content: counter(linenumber);
        color: #999;
        display: block;
        padding-right: 0.8em;
        text-align: right;
    }
}


/**====== Prism css end ======**/


/**  ===================== Progress-bar css start
==========================  **/

.progress-xl {
    height: 30px;
}

.progress-lg {
    height: 24px;
}

.progres-md {
    height: 18px;
}

.progress-sm {
    height: 12px;
}

.progress-xs {
    height: 6px;
}

.progress-bar-page .progress {
    margin-bottom: 2rem;
}

.progress-bar-default {
    background-color: #bdc3c7;
}

.progress-bar-primary {
    background-color: #0073aa;
}

.progress-bar-success {
    background-color: #2ecc71;
}

.progress-bar-info {
    background-color: #3498DB;
}

.progress-bar-warning {
    background-color: #f1c40f;
}

.progress-bar-danger {
    background-color: #e74c3c;
}

.progress-bar-pink {
    background-color: #ff7aa3;
}

.progress-bar-purple {
    background-color: #9261c6;
}

.progress-bar-orange {
    background-color: #e67e22;
}

.progress-bar-yellow {
    background-color: #f1c40f;
}

.progress-bar-emrald {
    background-color: #2ecc71;
}


/**====== Progress-bar css end ======**/


/**  ===================== Pre-loader css start
==========================  **/


/*===== Loader1 =====*/

.loader {
    margin: 18px auto;
    top: 65px;
    left: 65px;
    right: 0;
    width: 100px;
    height: 100px;
    position: relative;
    transform: translate(-50%, -50%);
    &.animation-start .circle {
        animation: rotating 2s ease-in-out infinite;
    }
    &.animation-stop .circle {
        animation-iteration-count: 1;
    }
    .circle {
        width: 50px;
        height: 50px;
        display: block;
        position: absolute;
        transform-origin: 50% 50%;
        animation-iteration-count: 0;
        &:after {
            content: '';
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            display: block;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            background-color: #1abc9c;
        }
        &.delay-1 {
            animation-delay: 0.125s;
        }
        &.size-1:after {
            width: 11px;
            height: 11px;
        }
        &.delay-2 {
            animation-delay: 0.25s;
        }
        &.size-2:after {
            width: 12px;
            height: 12px;
        }
        &.delay-3 {
            animation-delay: 0.375s;
        }
        &.size-3:after {
            width: 13px;
            height: 13px;
        }
        &.delay-4 {
            animation-delay: 0.5s;
        }
        &.size-4:after {
            width: 14px;
            height: 14px;
        }
        &.delay-5 {
            animation-delay: 0.625s;
        }
        &.size-5:after {
            width: 15px;
            height: 15px;
        }
        &.delay-6 {
            animation-delay: 0.75s;
        }
        &.size-6:after {
            width: 16px;
            height: 16px;
        }
        &.delay-7 {
            animation-delay: 0.875s;
        }
        &.size-7:after {
            width: 17px;
            height: 17px;
        }
        &.delay-8 {
            animation-delay: 1s;
        }
        &.size-8:after {
            width: 18px;
            height: 18px;
        }
        &.delay-9 {
            animation-delay: 1.125s;
        }
        &.size-9:after {
            width: 19px;
            height: 19px;
        }
        &.delay-10 {
            animation-delay: 1.25s;
        }
        &.size-10:after {
            width: 20px;
            height: 20px;
        }
        &.delay-11 {
            animation-delay: 1.375s;
        }
        &.size-11:after {
            width: 21px;
            height: 21px;
        }
        &.delay-12 {
            animation-delay: 1.5s;
        }
        &.size-12:after {
            width: 22px;
            height: 22px;
        }
    }
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/*===== Loader2 =====*/

#loader2 {
    width: 80px;
    height: 130px;
    display: block;
    transform-origin: 50% 50%;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

#circle-loader2 {
    fill: none;
    stroke: #0073aa;
    stroke-width: 4;
    animation: draw 3s ease-in-out infinite;
}

@keyframes draw {
    0% {
        stroke-dasharray: 20, 282.6;
    }
    50% {
        stroke-dasharray: 200, 282.6;
    }
    100% {
        stroke-dasharray: 20, 282.6;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


/*==== Loader3 ====*/

.preloader3 {
    width: auto;
    height: 130px;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    >div {
        margin: 2px;
        background-color: #0073aa;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
        animation: stretchdelay 0.7s infinite ease-in-out;
    }
    .circ2 {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }
    .circ3 {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }
    .circ4 {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }
    .circ5 {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
    }
    .loader-md {
        height: 13px;
        width: 13px;
    }
    .loader-lg {
        height: 18px;
        width: 18px;
    }
}

@-webkit-keyframes stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: translateY(-10px);
    }
    20% {
        -webkit-transform: translateY(-20px);
    }
}

@keyframes stretchdelay {
    0%,
    40%,
    100% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
    }
    20% {
        transform: translateY(-20px);
        -webkit-transform: translateY(-20px);
    }
}

@keyframes rotateColor {
    0% {
        border-top-color: #1b8bf9;
    }
    12.5% {
        border-bottom-color: rgba(27, 139, 249, 0.8);
    }
    25% {
        border-bottom-color: rgba(27, 139, 249, 0.6);
    }
    37.5% {
        border-bottom-color: rgba(27, 139, 249, 0.4);
    }
    50% {
        border-bottom-color: rgba(27, 139, 249, 0.6);
    }
    50.00001% {
        border-bottom-color: #1b8bf9;
    }
    62.5% {
        border-bottom-color: rgba(27, 139, 249, 0.8);
    }
    75% {
        border-bottom-color: rgba(27, 139, 249, 0.6);
    }
    87.5% {
        border-bottom-color: rgba(27, 139, 249, 0.4);
    }
}


/*===== Loader4 =====*/

.preloader4 {
    width: 30px;
    height: 30px;
    position: relative;
    margin: 55px auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #0073aa;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: bounce 2.0s infinite ease-in-out;
    animation: bounce 2.0s infinite ease-in-out;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}


/*===== Loader5 =====*/

.preloader5 {
    margin: 55px 0;
    text-align: center;
    .circle-5 {
        display: inline-block;
        padding: 11px;
        margin: 0 0.6em;
        background: #0073aa;
        border-radius: 100%;
    }
    .l {
        -webkit-animation: pulse 2s infinite linear;
        animation: pulse 2s infinite linear;
    }
    .m {
        -webkit-animation: pulse 2s infinite linear;
        animation: pulse 2s infinite linear;
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    .r {
        -webkit-animation: pulse 2s infinite linear;
        animation: pulse 2s infinite linear;
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
}

@-webkit-keyframes pulse {
    10% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    30% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
    }
    50% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
    }
    70% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
    }
    80% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulse {
    10% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    30% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
    }
    50% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
    }
    70% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7);
    }
    80% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


/*===== Loader6 =====*/

.preloader6 {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: inherit;
    margin: 18px 0;
    hr {
        border: 0;
        background: inherit;
        width: 80%;
        height: 80%;
        margin: 10%;
        border-radius: 100%;
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        z-index: -1;
        left: 33px;
        border: 17px solid transparent;
        border-bottom: 50px solid #0073aa;
        border-top: 50px solid #0073aa;
        animation: rotateColor 1000ms infinite;
    }
    hr {
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            z-index: -1;
            left: 33px;
            border: 17px solid transparent;
            border-bottom: 50px solid #0073aa;
            border-top: 50px solid #0073aa;
            animation: rotateColor 1000ms infinite;
        }
    }
    &::after {
        transform: rotate(45deg);
        animation-delay: 125ms;
    }
    hr {
        &::before {
            transform: rotate(90deg);
            animation-delay: 250ms;
        }
        &::after {
            transform: rotate(135deg);
            animation-delay: 375ms;
        }
    }
}

.loader-block,
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes rotateColor {
    0% {
        border-top-color: #1abc9c;
    }
    12.5% {
        border-bottom-color: rgba(26, 188, 156, 0.8);
    }
    25% {
        border-bottom-color: rgba(26, 188, 156, 0.6);
    }
    37.5% {
        border-bottom-color: rgba(26, 188, 156, 0.4);
    }
    50% {
        border-bottom-color: rgba(26, 188, 156, 0.6);
    }
    50.00001% {
        border-bottom-color: #1abc9c;
    }
    62.5% {
        border-bottom-color: rgba(26, 188, 156, 0.8);
    }
    75% {
        border-bottom-color: rgba(26, 188, 156, 0.6);
    }
    87.5% {
        border-bottom-color: rgba(26, 188, 156, 0.4);
    }
}


/*========= Pre-loader css end ============*/


/*range slider start */

.range-slider {
    min-height: 200px;
    display: flex;
    align-items: center;
    margin-left: 15px;
}

#RGB {
    height: 10px;
    background: gray;
}

#RC {
    .slider-selection {
        background: #FF8282;
    }
    .slider-handle {
        background: red;
    }
}

#GC {
    .slider-selection {
        background: #428041;
    }
    .slider-handle {
        background: green;
    }
}

#BC {
    .slider-selection {
        background: #8283FF;
    }
    .slider-handle {
        border-bottom-color: blue;
    }
}

#R,
#G,
#B {
    width: 300px;
}

.range-slider-contain {
    position: absolute;
    bottom: 12%;
}

.slider-handle {
    top: -5px;
    background-image: linear-gradient(to bottom, #1abc9c 0%, #1abc9c 100%);
    box-shadow: none;
}

.slider-tick,
.slider-track {
    background-image: linear-gradient(to bottom, #BDC3C7 0%, #BDC3C7 100%);
}

.slider-handle.custom::before {
    line-height: 15px;
    font-size: 35px;
    color: #1abc9c;
}

#slider12a .slider-track-high,
#slider12c .slider-track-high {
    background: green;
}

#slider12b .slider-track-low {
    background: red;
}

#slider12c {
    .slider-track-low {
        background: red;
    }
    .slider-selection {
        background: yellow;
    }
}

.slider-selection.tick-slider-selection,
.slider-tick.in-selection {
    background-image: linear-gradient(to bottom, #28E1BD 0%, #28E1BD 100%);
}

.slider.slider-horizontal {
    .slider-track {
        height: 2px;
    }
    .slider-tick,
    .slider-handle {
        top: -5px;
        cursor: pointer;
    }
}


/*range slider end */


/**  ===================== Rating css start
==========================  **/

.br-wrapper {
    margin: 20px 0 50px;
}

.br-theme-bars-1to10 .br-widget .br-current-rating {
    clear: both;
    padding: 0;
    font-size: 16px;
    color: #757575;
    font-weight: 600;
}

.br-theme-bars-reversed .br-widget .br-current-rating {
    clear: both;
    padding: 0;
    line-height: 2;
    font-size: 16px;
}

.br-theme-bars-movie .br-widget .br-current-rating {
    color: #757575;
    font-weight: 600;
    text-align: left;
}

.br-theme-bars-horizontal .br-widget .br-current-rating,
.br-theme-bars-reversed .br-widget .br-current-rating {
    color: #757575;
    font-weight: 600;
}

span.value {
    display: inline;
}

.current-rating,
.your-rating {
    position: relative;
    top: -50px;
    &.hidden {
        display: none;
    }
}

.br-theme-bars-1to10 .br-widget a,
.br-theme-bars-movie .br-widget a,
.br-theme-bars-reversed .br-widget a,
.br-theme-bars-horizontal .br-widget a {
    background-color: #8CDDCD;
}

.br-theme-bars-1to10 .br-widget a {
    &.br-active,
    &.br-selected {
        background-color: #1ABC9C;
    }
}

.br-theme-bars-movie .br-widget a {
    &.br-active,
    &.br-selected {
        background-color: #1ABC9C;
    }
}

.br-theme-bars-reversed .br-widget a {
    &.br-active,
    &.br-selected {
        background-color: #1ABC9C;
    }
}

.br-theme-bars-horizontal .br-widget a {
    &.br-active,
    &.br-selected {
        background-color: #1ABC9C;
    }
}

.br-theme-bars-pill .br-widget a {
    background-color: #8CDDCD;
    color: #1ABC9C;
    &.br-active,
    &.br-selected {
        background-color: #1ABC9C;
    }
}

.br-theme-fontawesome-stars .br-widget a {
    &.br-active:after,
    &.br-selected:after {
        color: #1ABC9C;
    }
}

.br-theme-css-stars .br-widget a {
    &.br-active:after,
    &.br-selected:after {
        color: #1ABC9C;
    }
}

.br-theme-bars-square .br-widget a {
    border: 2px solid #8CDDCD;
    color: #8CDDCD;
    &.br-active,
    &.br-selected {
        border: 2px solid #1ABC9C;
        color: #1ABC9C;
    }
}

.br-theme-fontawesome-stars-o .br-widget a {
    &.br-selected:after,
    &:after,
    &.br-active:after,
    &.br-fractional:after {
        color: #1ABC9C;
    }
}


/**====== Rating css end ======**/


/**  ===================== Todo css start
==========================  **/

#task-container {
    ul {
        overflow: hidden;
    }
    .task-headline {
        display: none;
        color: #666666;
        border-bottom: 1px solid #C8C7BB;
        padding-bottom: 20px;
        margin-bottom: 20px;
        font-size: 1.6em;
        position: relative;
        &:before {
            height: 1px;
            width: 100%;
            background: #FFF;
            position: absolute;
            content: " ";
            bottom: 0;
            left: 0;
        }
    }
    .nothing-message {
        height: 160px;
        color: #666;
        background-size: 15%;
    }
    li {
        float: left;
        width: 49%;
        overflow: auto;
        height: auto;
        min-height: 10px;
        background: #FFF;
        display: inline-block;
        padding: 20px;
        border: 1px solid #CCC;
        color: #666;
        border-top: 9px solid #0073aa;
        cursor: pointer;
        margin-bottom: 20px;
        margin-right: 2%;
        transition: all 0.3s;
        position: relative;
        &:nth-child(even) {
            margin-right: 0;
        }
        &:hover {
            opacity: 1;
            border-top: 9px solid #bdc3c7;
        }
        &.complete {
            opacity: 1;
            border-top: 9px solid #e74c3c;
            transition: all ease-in 0.3s;
            &:before {
                background: url("assets/images/complete.png") no-repeat;
                position: absolute;
                top: 5px;
                right: 5px;
                content: "";
                width: 55px;
                height: 55px;
                background-size: 100%;
            }
            &:hover {
                border-top: 9px solid #bdc3c7;
                opacity: 1;
            }
            p {
                text-decoration: line-through;
            }
        }
    }
    p {
        line-height: 1.6em;
        text-align: left;
    }
}

.add-line.complete {
    text-decoration: line-through;
}

.task-panel .to-do-label {
    padding-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding: 0;
    }
}

.to-do-list {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    i {
        color: #ccc;
        font-size: 24px;
        opacity: 0;
    }
    &:last-child {
        border: none;
        padding: 0;
        margin: 0;
    }
    &:hover i {
        opacity: 1;
        transition: opacity ease-in 0.3s;
    }
    p {
        display: inline-block;
    }
}

.to-do-label {
    .checkbox-fade {
        display: block;
        .delete_todo {
            float: right;
            font-size: 24px;
            color: #ccc;
        }
    }
    .check-task {
        display: block;
    }
    i {
        cursor: pointer;
    }
}

.done-task {
    span,
    .captions {
        text-decoration: line-through;
    }
}


/**====== Todo css end ======**/

@media only screen and (max-width: 1199px) {
    .blog-page-card {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    #draggablePanelList [class*="col-"] {
        .card-sub {
            margin-bottom: 30px;
        }
        &:last-child .card-sub {
            margin-bottom: 0px;
        }
    }
    .user-head {
        justify-content: center;
    }
    .user-body {
        min-height: auto !important;
    }
    .mail-box-head {
        text-align: center;
        form {
            float: inherit;
        }
    }
    #external-events h6 {
        margin-top: 0;
        margin-bottom: 20px;
    }
    .just_edit .input-group {
        width: 100% !important;
    }
    .dotted-line-theme .ibtn_container {
        top: 10px;
        position: inherit;
    }
    .users-card [class*="col-"] {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .timeline-btn {
        text-align: center;
        width: 100%;
        right: 0;
    }
    .social-timeline-left {
        position: inherit;
        top: 0;
        margin-right: 0;
    }
    .timeline-icon {
        position: absolute;
        margin-left: 0;
    }
    .timeline-right .card {
        margin-left: 70px !important;
    }
    .social-timelines:before {
        left: 35px;
    }
    .timeline-dot {
        &:after,
        &:before {
            left: 32px;
        }
    }
    .user-box {
        .social-client-description {
            padding-bottom: 20px;
            margin-bottom: 0;
            border-bottom: 1px solid #ddd;
        }
        .f-right {
            float: none;
            display: block;
        }
    }
    .main-timeline {
        padding: 10px;
        &:before,
        &:after {
            margin-left: -94px;
            margin-top: 25px;
            display: none;
        }
    }
    .cd-timeline-content::before {
        top: 15px;
    }
    .cd-date {
        display: block;
        padding-left: 20px !important;
        padding-top: 0 !important;
    }
    .cd-details {
        display: block;
        padding-left: 20px !important;
        padding-top: 0 !important;
        margin-bottom: 20px;
    }
    .cd-timeline-content p {
        margin-bottom: 0;
    }
    .clock-widget .basic-alarm {
        display: none;
    }
    .weather-card-2 {
        .weather-card-temp {
            padding: 0;
        }
        .card-footer {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    .user-activity-card .card-body-big {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media only screen and (min-width: 992px) {
    .header-navbar .navbar-wrapper .navbar-container .nav-right {
        overflow: visible !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 1024px) {
    .main-body .page-wrapper {
        .page-header-title {
            display: block;
        }
        .page-header {
            margin-bottom: 10px;
        }
        .page-header-breadcrumb {
            float: inherit;
            display: inline-block;
            margin-top: 10px;
        }
    }
    .ngb-dp-day,
    .custom-range,
    .ngb-dp-day .btn-secondary {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
    .ngb-dp-weekday,
    .ngb-dp-week-number {
        width: 1.5rem !important;
    }
    .custom-select {
        display: block;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 992px) {
    #footer-search tfoot .form-control,
    #footer-select tfoot .form-control,
    #form-input-table .form-control,
    .search-api .form-control,
    #dt-live-dom .form-control {
        width: 80%;
    }
    .pcoded-main-container {
        margin-top: 56px !important;
    }
    .card-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .header-navbar {
        position: fixed !important;
        z-index: 1209;
        &[pcoded-header-position="relative"] {
            position: fixed !important;
            top: 0;
        }
        .navbar-wrapper {
            .navbar-container {
                margin-left: 0;
            }
            .navbar-logo {
                width: 100%;
                .mobile-menu {
                    display: block;
                    position: absolute;
                    left: 30px;
                    top: 18px;
                }
                .mobile-search {
                    display: block;
                    position: absolute;
                    left: 70px;
                    top: 18px;
                }
                .mobile-options {
                    display: block;
                    position: absolute;
                    right: 30px;
                }
            }
        }
        .navbar-container .nav-left {
            display: none;
        }
    }
    .btn-blog {
        text-align: center;
    }
    .animation-image img {
        margin: 20px auto 0;
    }
    .animation-type {
        text-align: center;
    }
    .nav-tabs.md-tabs.tab-timeline li a {
        font-size: 16px;
    }
}

@media only screen and (min-width: 992px) {
    .btn-blog {
        text-align: right;
    }
}

@media only screen and (max-width: 768px) {
    .card-body.list-tag ul li:last-child {
        margin-bottom: 20px;
    }
    .inline-order-list {
        margin-top: 0;
    }
    h4 {
        font-size: 1rem;
        font-weight: 600;
    }
    .mail-body-content .form-group:nth-child(2) {
        .form-control {
            margin-bottom: 20px;
        }
        .col-md-6:last-child .form-control {
            margin-bottom: 0;
        }
    }
    .card h5 {
        font-size: 16px;
    }
    .header-navbar .navbar-wrapper .navbar-logo {
        display: inherit;
        padding-top: 13px;
        a {
            display: inline-block;
        }
        .mobile-options {
            top: 18px;
        }
    }
    .main-body .page-wrapper .page-header-title {
        display: block;
    }
    body.header-fixed .main-body .page-wrapper {
        padding-bottom: 0;
        padding-top: 15px;
    }
    .card-header-right i:nth-child(n+2) {
        display: none;
    }
    .dataTables_paginate .pagination {
        float: inherit;
        text-align: center;
        display: inline-block;
        margin-top: 10px !important;
    }
    .card .card-body ul.pagination li {
        float: left;
    }
    div.dataTables_wrapper div.dataTables_info {
        display: inherit;
    }
    .wizard>.steps>ul>li {
        float: inherit;
        width: 100%;
    }
    .gallery-page .grid {
        padding: 0;
    }
    figure.effect-steve h2 {
        margin-top: 0;
    }
    .cover-profile .profile-bg-img {
        margin-bottom: 0;
    }
    .card-body.user-info {
        position: inherit;
        text-align: center;
        background-color: #bdc3c7;
        margin-bottom: 20px;
    }
    .user-info {
        .media-left,
        .media-body {
            display: block;
        }
    }
    .cover-btn {
        position: inherit;
        float: none;
        .btn:first-child {
            margin-right: 10px;
        }
    }
    .user-info .user-title {
        position: initial;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .nav-tabs.md-tabs.tab-timeline li a {
        font-size: 14px;
    }
    .user-content {
        h4 {
            font-size: 1rem;
        }
        h5 {
            margin-right: 0;
            font-size: 14px;
        }
    }
    .top-cap-text p {
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .user-card .btn span {
        font-size: 13px;
    }
    .toolbar-page [class*="col-"] {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .offline-404 {
        h1 {
            font-size: 80px;
        }
        h2 {
            font-size: 25px;
        }
    }
    .invoice-contact {
        margin-top: 20px;
        margin-bottom: 0;
    }
    .lng-dropdown {
        display: none;
    }
    .group-widget .card-body-big {
        height: 110px;
    }
    .client-blocks ul li {
        font-size: 30px;
    }
    .widget-profile-card-1~.card-footer [class*=col-] {
        border: none;
        margin: 0 auto;
    }
    .large-widget-card i {
        top: 15px;
    }
    .user-activity-card {
        .user-number {
            padding: 15px 0;
        }
        .m-b-40 {
            margin-bottom: 10px;
        }
    }
    .blur-user-card .blur-footer [class*="col-"] {
        margin: 0 auto;
        border: none;
    }
    .switchery {
        margin-bottom: 20px;
    }
    .switchery-large {
        margin-bottom: 0;
        ~.switchery-default {
            margin-bottom: 0;
        }
    }
    .switchery-small {
        margin-bottom: 0;
    }
    .js-dynamic-disable,
    .js-dynamic-enable {
        display: block;
        width: 100%;
    }
    .js-dynamic-disable {
        margin-bottom: 20px;
    }
    .radio-inline,
    .border-checkbox-section .border-checkbox-group,
    .checkbox-color {
        display: block;
    }
    .checkbox-fade {
        display: inline-block;
    }
}

@media only screen and (max-width: 640px) {
    .dt-button {
        margin-bottom: 5px;
    }
    .email-read {
        .card-header h6 {
            float: none;
            margin-top: 10px;
        }
        .media {
            display: inline-block;
            &.m-b-20 {
                margin-bottom: 0;
            }
        }
        .mail-img [class*="col-"] {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: 573px) {
    .md-tabs .nav.nav-tabs,
    .nav.nav-tabs {
        display: block;
    }
    .below-tabs .nav-tabs .nav-link.active {
        border-color: #fff #fff #fff !important;
    }
    .md-tabs {
        .nav-item,
        .main-menu .main-menu-content .nav-item .tree-1 a {
            width: calc(100% / 1);
        }
    }
    .main-menu .main-menu-content .nav-item .tree-1 .md-tabs a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a,
    .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a,
    .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a,
    .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a,
    .nav-tabs .slide {
        width: calc(100% / 1);
    }
    .toolbar-page [class*="col-"] .sub-title {
        text-align: center;
    }
    #task-container li {
        width: 100%;
    }
    .nav-tabs {
        &.tabs {
            display: inherit;
        }
        .nav-item.show .nav-link,
        .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link {
            border: none;
        }
    }
    .main-menu .main-menu-content .nav-item .tree-1 .nav-tabs a.show .nav-link,
    .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-2 .nav-tabs a.show .nav-link,
    .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-3 .nav-tabs a.show .nav-link,
    .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-4 .nav-tabs a.show .nav-link {
        border: none;
    }
    .nav-tabs .nav-link {
        &.active {
            border: none;
        }
        text-align: center;
    }
    .search-content {
        display: block;
        text-align: center;
        img {
            margin-top: 20px;
        }
    }
    .seacrh-header {
        margin-top: 0;
        .input-group {
            margin-bottom: 0;
        }
    }
    .payment-card .text-right {
        text-align: left !important;
    }
    .payment-tabs .md-tabs {
        .nav-item+.nav-item,
        .main-menu .main-menu-content .nav-item .tree-1 a+.nav-item {
            width: calc(100% / 1);
        }
    }
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .nav-item+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+a {
        width: calc(100% / 1);
    }
    .main-menu .main-menu-content .nav-item {
        .tree-1 .payment-tabs .md-tabs a+a,
        .tree-2 .payment-tabs .md-tabs .tree-1 a+a,
        .tree-3 .payment-tabs .md-tabs .tree-1 a+a,
        .tree-4 .payment-tabs .md-tabs .tree-1 a+a {
            width: calc(100% / 1);
        }
    }
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 .nav-item+a {
        width: calc(100% / 1);
    }
    .main-menu .main-menu-content .nav-item {
        .tree-2 .payment-tabs .md-tabs .nav-item+a,
        .tree-1 .payment-tabs .md-tabs .tree-2 a+a {
            width: calc(100% / 1);
        }
    }
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+a {
        width: calc(100% / 1);
    }
    .main-menu .main-menu-content .nav-item {
        .tree-2 .payment-tabs .md-tabs a+a,
        .tree-3 .payment-tabs .md-tabs .tree-2 a+a,
        .tree-4 .payment-tabs .md-tabs .tree-2 a+a {
            width: calc(100% / 1);
        }
    }
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 .nav-item+a {
        width: calc(100% / 1);
    }
    .main-menu .main-menu-content .nav-item {
        .tree-3 .payment-tabs .md-tabs .nav-item+a,
        .tree-1 .payment-tabs .md-tabs .tree-3 a+a,
        .tree-2 .payment-tabs .md-tabs .tree-3 a+a {
            width: calc(100% / 1);
        }
    }
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+a {
        width: calc(100% / 1);
    }
    .main-menu .main-menu-content .nav-item {
        .tree-3 .payment-tabs .md-tabs a+a,
        .tree-4 .payment-tabs .md-tabs .tree-3 a+a {
            width: calc(100% / 1);
        }
    }
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 .nav-item+a {
        width: calc(100% / 1);
    }
    .main-menu .main-menu-content .nav-item {
        .tree-4 .payment-tabs .md-tabs .nav-item+a,
        .tree-1 .payment-tabs .md-tabs .tree-4 a+a,
        .tree-2 .payment-tabs .md-tabs .tree-4 a+a,
        .tree-3 .payment-tabs .md-tabs .tree-4 a+a {
            width: calc(100% / 1);
        }
    }
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+a,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+a {
        width: calc(100% / 1);
    }
    .jp-card {
        min-width: 225px !important;
    }
    .payment-form {
        padding: 0;
        padding-top: 20px;
    }
    .modal-mob-btn [class*="col-"]:first-child {
        margin-bottom: 20px;
    }
    .location-mob-btn .btn {
        margin-bottom: 20px;
        margin-right: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .job-cards .media {
        display: inline-block;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        p {
            margin-bottom: 0;
        }
    }
    .invoice-btn-group .m-r-20 {
        margin-right: 0;
    }
    .table-card .row-table {
        display: block;
        text-align: center;
        i {
            font-size: 28px;
            margin-bottom: 10px;
            display: inline-block;
        }
        .br {
            border: none;
            border-bottom: 1px solid #ccc;
        }
        >[class*=col-] {
            display: block;
        }
    }
    .card-body-big {
        padding: 1em;
        #barchart,
        #barchart2 {
            display: inline-block;
            margin-bottom: 10px;
        }
    }
    .widget-primary-card [class*="col-"] {
        padding: 1em;
        h6 {
            margin-bottom: 0;
        }
    }
    .cd-horizontal-timeline .events-content em {
        text-align: left;
    }
    .events-content h2 {
        font-size: 20px;
    }
    .cd-horizontal-timeline {
        margin: 0 auto;
    }
    .social-widget-card i {
        top: 25px;
        font-size: 38px;
    }
    .widget-chat-box .card-header i {
        display: none;
    }
    .mobile-inputs [class*="col-"]:first-child {
        margin-bottom: 20px;
    }
    .pagination-lg .page-link {
        padding: .75rem .65rem;
    }
    .mobtn {
        padding: 10px;
    }
}

@media only screen and (max-width: 460px) {
    .fc-toolbar .fc-right .fc-button-group {
        margin-right: 0;
    }
    .social-msg a {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 480px) {
    [class*="alert-"] code {
        margin-left: 0px;
    }
    .auth-body {
        min-width: auto;
    }
    .breadcrumb-page .card {
        text-align: center;
    }
    div.dataTables_wrapper div.dataTables_filter input {
        width: 150px;
    }
    #footer-search tfoot .form-control,
    #footer-select tfoot .form-control,
    #form-input-table .form-control,
    .search-api .form-control,
    #dt-live-dom .form-control {
        width: 70%;
    }
    .grid figure {
        min-width: auto;
    }
    .mytooltip .tooltip-content {
        width: 310px;
        margin: 0 0 20px -90px;
        &:after {
            margin-left: -90px;
        }
    }
    .cd-timeline-content .media {
        display: inherit !important;
        padding-left: 20px;
        .media-left {
            text-align: center;
            margin: 10px auto;
        }
    }
    .summernote-page .modal-footer {
        display: inline-block;
        text-align: center;
        > :not(:first-child) {
            margin-top: 10px;
            margin-left: 0;
        }
    }
    .product-detail .txt-muted.d-inline-block {
        margin-top: 10px;
        ~.f-right {
            float: none;
            display: block;
            margin-top: 10px;
        }
    }
    .mob-product-btn {
        text-align: center;
        .btn-primary,
        .sweet-alert button.confirm {
            margin-right: 0;
            display: block;
            margin: 10px auto;
        }
    }
    .sweet-alert .mob-product-btn button.confirm,
    .mob-product-btn .wizard>.actions a,
    .wizard>.actions .mob-product-btn a {
        margin-right: 0;
        display: block;
        margin: 10px auto;
    }
}

@media only screen and (max-width: 576px) {
    .header-navbar .navbar-wrapper .navbar-container {
        .header-notification .show-notification,
        .mega-menu-top .show-notification,
        .header-notification .profile-notification,
        .mega-menu-top .profile-notification {
            min-width: 280px;
            left: 0;
            right: auto;
            width: auto;
        }
        .header-notification .profile-notification,
        .mega-menu-top .profile-notification {
            left: auto;
            right: 0;
        }
    }
    .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
    .main-menu .main-menu-content .nav-item .tree-1 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
    .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
    .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
    .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a {
        box-shadow: 0 0 0 1px #d2cece;
        margin-bottom: 5px;
    }
}

@media only screen and (min-width: 801px) and (max-width: 915px) {
    .grid figure {
        min-width: auto;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1258px) {
    .user-head {
        text-align: center;
        .user-face {
            margin: 0 auto;
        }
        .user-name {
            display: none;
        }
    }
}

@media only screen and (min-width: 1279px) and (max-width: 1500px) {
    .cd-horizontal-timeline {
        margin: 0 auto;
    }
    #chartdiv {
        height: 440px;
    }
    .social-card-1 .card-body-big {
        padding: 1.3em;
    }
    #morris-extra-area {
        height: 400px !important;
    }
    .widget-chat-box {
        .send-chat,
        .receive-chat {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}


/**  ===================== Dark-Layout css start
==========================  **/

body.dark-layout {
    background-color: #333;
    transition: all linear .3s;
    .header-navbar .navbar-wrapper,
    .jstree-default .jstree-clicked,
    .prod-item .prod-info,
    .filter-bar>.navbar,
    #task-container li {
        background-color: #1b1b1b;
    }
    .main-menu {
        background: #242424;
        .main-menu-header {
            background: #000;
        }
    }
    .nav-tabs .nav-link.active,
    .page-link:hover,
    .location-selector .bit:hover {
        background: #000;
    }
    .ms-container {
        .ms-selectable li.disabled,
        .ms-selection li.disabled {
            background: #000;
        }
    }
    .j-forms .divider-text span,
    .j-pro .j-divider-text span,
    .handsontable th,
    .cd-timeline-content {
        background: #000;
    }
    .email-card {
        .user-body .page-list li {
            &.active,
            &:hover {
                background: #000;
            }
        }
        .mail-body-content tr:hover {
            background: #000;
        }
    }
    #external-events .fc-event {
        background: #000;
    }
    .card {
        background: #292929;
        border-top-color: #555;
        color: #fff;
        h5 {
            color: #a3a3a3;
        }
    }
    .well,
    .page-link {
        border-color: #555;
    }
    .table-bordered {
        td,
        th {
            border-color: #555;
        }
    }
    .grid-layout span,
    .vertical-alignment .row,
    .location-selector,
    pre[class*="language-"],
    .icon-list-demo i {
        border-color: #555;
    }
    .ms-container {
        .ms-list,
        .ms-selectable li.ms-elem-selectable,
        .ms-selection li.ms-elem-selection {
            border-color: #555;
        }
    }
    .wizard>.content,
    .j-forms,
    .j-pro,
    .table-bordered {
        border-color: #555;
    }
    table.dataTable {
        td.select-checkbox:before,
        th.select-checkbox:before {
            border-color: #555;
        }
    }
    .email-summernote~.note-frame,
    .thumb-block,
    #task-container li {
        border-color: #555;
    }
    .cd-timeline::before,
    .cd-timeline-content::before {
        background: #555;
    }
    .main-timeline {
        &:before,
        &:after {
            background: #555;
        }
    }
    .card-footer {
        background: #292929;
        color: #fff;
    }
    .header-navbar .navbar-wrapper .navbar-container {
        .header-notification .show-notification,
        .mega-menu-top .show-notification,
        .header-notification .profile-notification {
            background: #292929;
            color: #fff;
        }
        .mega-menu-top {
            .profile-notification,
            .show-notification,
            .profile-notification {
                background: #292929;
                color: #fff;
            }
        }
        .header-notification .profile-notification,
        .mega-menu-top .profile-notification {
            background: #292929;
            color: #fff;
        }
    }
    .table-card .row-table .br,
    .social-card-1 .card-footer [class*="col-"],
    .email-card .user-body,
    .messages-content,
    .note-card .note-box-aside {
        border-right-color: #555;
    }
    .table-card .row-table:first-child,
    .to-do-list,
    .modal-header,
    .email-card .user-body .page-list li,
    .search-content {
        border-bottom-color: #555;
    }
    .table {
        td,
        th {
            border-top-color: #555;
        }
    }
    .card-footer,
    .tab-below,
    .chat-reply-box,
    .panel-footer,
    .modal-footer {
        border-top-color: #555;
    }
    .j-forms {
        .footer,
        .divider,
        .divider-text {
            border-top-color: #555;
        }
    }
    .j-pro .j-footer {
        border-top-color: #555;
    }
    .footable-details.table> {
        tbody>tr> {
            td,
            th {
                border-top-color: #555;
            }
        }
        tfoot>tr> {
            td,
            th {
                border-top-color: #555;
            }
        }
        thead>tr> {
            td,
            th {
                border-top-color: #555;
            }
        }
    }
    .footable.table> {
        tbody>tr> {
            td,
            th {
                border-top-color: #555;
            }
        }
        tfoot>tr> {
            td,
            th {
                border-top-color: #555;
            }
        }
        thead>tr> {
            td,
            th {
                border-top-color: #555;
            }
        }
    }
    .write-msg,
    .version .nav li:first-child {
        border-top-color: #555;
    }
    .p-chat-user,
    .showChat_inner,
    .invoice-table {
        border-left-color: #555;
    }
    .table>thead>tr>th,
    .userlist-box,
    .card-header,
    .nav-tabs,
    .chat-inner-header {
        border-bottom-color: #555;
    }
    .widget-chat-box {
        .send-chat,
        .receive-chat {
            background: #333;
        }
        .send-chat:before,
        .receive-chat:before {
            border-top-color: #333;
            border-right-color: #333;
        }
    }
    .nav-tabs {
        .nav-item.show .nav-link,
        .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link {
            border-color: #555 #555 #555;
            color: #fff;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a.show .nav-link {
    border-color: #555 #555 #555;
    color: #fff;
}

body.dark-layout {
    .nav-tabs .nav-link.active {
        border-color: #555 #555 #555;
        color: #fff;
    }
    .tab-below .active {
        border-top: none;
    }
    text {
        fill: #fff;
    }
    .main-menu .main-menu-content .nav-item {
        &.has-class>a,
        .tree-1 a.has-class>a,
        .tree-2 a.has-class>a,
        .tree-3 a.has-class>a,
        .tree-4 a.has-class>a {
            background: #1b1b1b;
        }
        .tree-1 a {
            &.has-class>a,
            .tree-1 a.has-class>a {
                background: #1b1b1b;
            }
        }
        .tree-2 a {
            &.has-class>a,
            .tree-2 a.has-class>a {
                background: #1b1b1b;
            }
        }
        .tree-3 a {
            &.has-class>a,
            .tree-3 a.has-class>a {
                background: #1b1b1b;
            }
        }
        .tree-4 a {
            &.has-class>a,
            .tree-4 a.has-class>a {
                background: #1b1b1b;
            }
        }
        .tree-2 a {
            &.has-class>a,
            .tree-2 a.has-class>a {
                background: #1b1b1b;
            }
        }
        .tree-3 a {
            &.has-class>a,
            .tree-3 a.has-class>a {
                background: #1b1b1b;
            }
        }
        .tree-4 a {
            &.has-class>a,
            .tree-4 a.has-class>a {
                background: #1b1b1b;
            }
        }
    }
    .card .card-header .card-header-right,
    code,
    .card .card-body code,
    .fb-timeliner,
    .version .nav li:hover {
        background: #1b1b1b;
    }
    .main-menu .main-menu-content .nav-item {
        a,
        .tree-1 a a,
        .tree-2 a a,
        .tree-3 a a,
        .tree-4 a a,
        .tree-2 a a,
        .tree-3 a a,
        .tree-4 a a {
            color: #a3a3a3;
        }
    }
    .sub-title,
    .breadcrumb-title a,
    h4.m-b-30,
    code,
    .card .card-body code {
        color: #a3a3a3;
    }
    .clock-widget {
        .bg-alarm,
        .basic-alarm {
            color: #a3a3a3;
        }
    }
    .nav-tabs {
        .nav-item a,
        .main-menu .main-menu-content .nav-item .tree-1 a a {
            color: #a3a3a3;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a a,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a a,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a a,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a a {
    color: #a3a3a3;
}

body.dark-layout {
    .dark-nav-border a,
    .primary-nav-dark a,
    .warning-nav-dark a,
    .success-nav-dark a,
    .info-nav-dark a,
    .danger-nav-dark a,
    .page-link,
    .main-body .page-wrapper .page-header-title h4,
    .modal-title,
    .close,
    .icon-list-demo i,
    .form-radio label {
        color: #a3a3a3;
    }
    .ms-container {
        .ms-selectable li.ms-elem-selectable,
        .ms-selection li.ms-elem-selection {
            color: #a3a3a3;
        }
    }
    .j-forms .divider-text span {
        color: #a3a3a3;
    }
    .j-pro {
        .j-label,
        .j-divider-text span,
        .j-icon-left,
        .j-icon-right,
        .j-radio,
        .j-checkbox,
        .j-radio-toggle,
        .j-checkbox-toggle {
            color: #a3a3a3;
        }
    }
    .handsontable th,
    .left-icon-control .form-icon,
    .offline-404 h1,
    .cd-timeline-content .cd-date,
    ul.list-contacts .list-group-item a,
    .port_detail_next_search a {
        color: #a3a3a3;
    }
    .email-card {
        .mail-body-content .unread a,
        .user-mail h6 {
            color: #a3a3a3;
        }
    }
    .navbar-light .navbar-nav .nav-link,
    h4.m-b-30,
    .thead-default th,
    .fc-state-default,
    .light-nav-border a,
    .primary-nav a,
    .warning-nav a,
    .success-nav a,
    .info-nav a,
    .danger-nav a,
    .primary-nav a,
    .warning-nav a,
    .success-nav a,
    .info-nav a,
    .danger-nav a {
        color: #a3a3a3;
    }
    .nav-tabs .nav-link {
        &:focus,
        &:hover {
            border-color: #555 #555 #555;
        }
    }
    .widget-chat-box .card-header i,
    .card .card-header span {
        color: #fff;
    }
    .main-menu .main-menu-content {
        .tree-1.open .has-class>a {
            color: #fff;
        }
        .nav-item .open {
            &.tree-2 .has-class>a,
            &.tree-3 .has-class>a,
            &.tree-4 .has-class>a,
            &.tree-2 .has-class>a,
            &.tree-3 .has-class>a,
            &.tree-4 .has-class>a {
                color: #fff;
            }
        }
        .tree-2.open .has-class>a,
        .tree-3.open .has-class>a,
        .tree-4.open .has-class>a,
        .nav-title {
            color: #fff;
        }
    }
    .header-navbar .navbar-wrapper .navbar-container {
        .header-notification .show-notification a,
        .mega-menu-top .show-notification a,
        .header-notification .profile-notification a {
            color: #fff;
        }
        .mega-menu-top {
            .profile-notification a,
            .show-notification a,
            .profile-notification a {
                color: #fff;
            }
        }
        .header-notification .profile-notification a,
        .mega-menu-top .profile-notification a {
            color: #fff;
        }
    }
    .mega-menu-title,
    .form-group label,
    .chat-header,
    .cd-horizontal-timeline .events a {
        color: #fff;
    }
    .borderless-card [class*="breadcrumb-"] {
        h5,
        a {
            color: #fff;
        }
    }
    .form-control,
    .dropdown-item,
    .pignose-calendar .pignose-calendar-top .pignose-calendar-top-year {
        color: #fff;
    }
    .nav-tabs {
        .nav-item.show .nav-link,
        .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link {
            color: #fff;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a.show .nav-link {
    color: #fff;
}

body.dark-layout {
    .primary-nav-dark a,
    .warning-nav-dark a,
    .success-nav-dark a,
    .info-nav-dark a,
    .danger-nav-dark a,
    .primary-nav a,
    .warning-nav a,
    .success-nav a,
    .info-nav a,
    .danger-nav a,
    .accordion-msg,
    .card .card-body p,
    .chat-inner-header,
    .right-icon-control .form-icon,
    .jstree-default .jstree-clicked,
    .close:hover,
    .modal-body {
        color: #fff;
    }
    .j-forms {
        input {
            &[type="text"],
            &[type="password"],
            &[type="email"],
            &[type="search"],
            &[type="url"] {
                color: #fff;
            }
        }
        textarea,
        select {
            color: #fff;
        }
    }
    .j-pro {
        input {
            &[type="text"],
            &[type="password"],
            &[type="email"],
            &[type="search"],
            &[type="url"] {
                color: #fff;
            }
        }
        textarea,
        select {
            color: #fff;
        }
    }
    .note-editor.note-frame .note-editing-area .note-editable,
    .email-card .mail-body-content .read a,
    #external-events .fc-event,
    #ta {
        color: #fff;
    }
    .main-menu .main-menu-content {
        .tree-1.open .nav-sub-item>a {
            background: #2d2d2d;
        }
        .nav-item .open {
            &.tree-2 .nav-sub-item>a,
            &.tree-3 .nav-sub-item>a,
            &.tree-4 .nav-sub-item>a,
            &.tree-2 .nav-sub-item>a,
            &.tree-3 .nav-sub-item>a,
            &.tree-4 .nav-sub-item>a {
                background: #2d2d2d;
            }
        }
    }
    .morphsearch.open .morphsearch-form,
    .dummy-media-object,
    .card .card-body .dropdown-menu,
    .clock-widget {
        background: #292929;
    }
    .pignose-calendar {
        background: #292929;
        .pignose-calendar-top {
            background: #292929;
        }
    }
    .nav-tabs {
        .nav-item.show .nav-link,
        .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link {
            background: #292929;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a.show .nav-link {
    background: #292929;
}

body.dark-layout {
    .alert-danger,
    .alert-warning,
    .alert-info,
    .alert-success,
    .alert-primary,
    .alert-default,
    .showChat_inner,
    .chat-reply-box,
    .modal-content,
    .location-selector .bit {
        background: #292929;
    }
    .j-forms {
        background: #292929;
        .footer {
            background: #292929;
        }
    }
    .j-pro {
        background: #292929;
        .j-footer {
            background: #292929;
        }
    }
    .handsontable {
        th,
        td {
            background: #292929;
        }
    }
    .offline-404,
    .dropdown-menu,
    .email-card .user-body {
        background: #292929;
    }
    .morphsearch-close {
        &::before,
        &::after {
            background: #999;
        }
    }
    .morphsearch.open,
    .morphsearch-content,
    .theme-loader,
    .form-control {
        background: #333;
    }
    .md-tabs {
        .nav-item a,
        .main-menu .main-menu-content .nav-item .tree-1 a a {
            background: #333;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .md-tabs a a {
    background: #333;
}

body.dark-layout {
    .md-tabs .main-menu .main-menu-content .nav-item {
        .tree-1 a a,
        .tree-2 a a,
        .tree-3 a a,
        .tree-4 a a {
            background: #333;
        }
    }
    .main-menu .main-menu-content .nav-item {
        .tree-1 .md-tabs a a,
        .tree-2 .md-tabs a a,
        .tree-3 .md-tabs a a,
        .tree-4 .md-tabs a a {
            background: #333;
        }
    }
    .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
    .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
    .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
    .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a,
    .well,
    .panel-footer,
    .panel,
    .page-link,
    .location-selector,
    pre[class*="language-"],
    .bootstrap-tagsinput,
    .select2-container--default .select2-selection--multiple {
        background: #333;
    }
    .ms-container {
        .ms-selectable,
        .ms-selection {
            background: #333;
        }
    }
    .wizard> {
        .steps .disabled a {
            background: #333;
            &:hover,
            &:active {
                background: #333;
            }
        }
        .content {
            background: #333;
        }
    }
    .j-forms {
        input {
            &[type="text"],
            &[type="password"],
            &[type="email"],
            &[type="search"],
            &[type="url"] {
                background: #333;
            }
        }
        textarea,
        select {
            background: #333;
        }
    }
    .j-pro {
        input {
            &[type="text"],
            &[type="password"],
            &[type="email"],
            &[type="search"],
            &[type="url"] {
                background: #333;
            }
        }
        textarea,
        select {
            background: #333;
        }
    }
    .footable-details.table-striped>tbody>tr:nth-child(odd),
    .footable.table-striped>tbody>tr:nth-child(odd),
    .main-timeline {
        background: #333;
    }
    .dropdown-item {
        &:focus,
        &:hover {
            background: #333;
        }
    }
    .list-group-item,
    .note-editor.note-frame .note-editing-area .note-editable,
    #main-chat .chat-single-box.active .chat-header,
    .post-input,
    .msg-send,
    .note-editor.note-frame .note-statusbar,
    #ta,
    .thead-default th,
    .invoice-total,
    .fc-state-default,
    .jFiler-input-dragDrop,
    .jFiler-theme-default .jFiler-input,
    .light-nav-border,
    .primary-nav,
    .warning-nav,
    .success-nav,
    .info-nav,
    .danger-nav,
    .primary-nav,
    .warning-nav,
    .success-nav,
    .info-nav,
    .danger-nav {
        background: #333;
    }
    .md-tabs {
        .nav-item a,
        .main-menu .main-menu-content .nav-item .tree-1 a a {
            border-radius: 0;
        }
    }
}

.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .md-tabs a a {
    border-radius: 0;
}

body.dark-layout {
    .md-tabs .main-menu .main-menu-content .nav-item {
        .tree-1 a a,
        .tree-2 a a,
        .tree-3 a a,
        .tree-4 a a {
            border-radius: 0;
        }
    }
    .main-menu .main-menu-content .nav-item {
        .tree-1 .md-tabs a a,
        .tree-2 .md-tabs a a,
        .tree-3 .md-tabs a a,
        .tree-4 .md-tabs a a {
            border-radius: 0;
        }
    }
    .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
    .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
    .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
    .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a {
        border-radius: 0;
    }
    .ball-scale>div {
        background: #fff;
    }
    .tree-1,
    .main-menu .main-menu-content .nav-item .tree-2 {
        background-color: #2d2d2d !important;
    }
}

.main-menu .main-menu-content .nav-item body.dark-layout .tree-2,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item body.dark-layout .tree-3,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item body.dark-layout .tree-4 {
    background-color: #2d2d2d !important;
}

body.dark-layout {
    .tree-2,
    .tree-3,
    .tree-4 {
        background-color: #2d2d2d !important;
    }
    .page-item.disabled .page-link {
        color: #a3a3a3;
        background-color: #333;
        border-color: #555;
    }
    .ct-grid {
        stroke: rgba(255, 255, 255, 0.2);
    }
    .ct-label {
        &.ct-vertical.ct-start,
        &.ct-horizontal.ct-end {
            color: rgba(255, 255, 255, 0.38);
        }
    }
    .img-tabs a {
        opacity: 1;
    }
    pre[class*="language-"] code,
    .bg-white {
        background-color: #000 !important;
    }
    code[class*="language-"] {
        text-shadow: none;
    }
    #reportrange {
        background: #333 !important;
    }
    .chat-bg {
        background: rgba(1, 1, 1, 0.69);
    }
}


/**====== Dark-Layout css end ======**/

.pcoded {
    .pcoded-container {
        position: relative;
        background: #d5dae6;
    }
    .pcoded-header {
        position: relative;
        display: block;
    }
    .pcoded-navbar {
        display: block;
    }
}

.pcoded-main-container {
    display: block;
    position: relative;
    background: #e9e9e9;
    min-height: calc(100vh - 56px);
}

.pcoded .pcoded-content {
    position: relative;
    display: block;
}

.pcoded-inner-navbar {
    display: block;
    position: relative;
}

.pcoded-inner-content {
    padding: 10px;
}

.pcoded .pcoded-navbar .pcoded-item {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    >li {
        display: block;
        list-style: outside none none;
        margin: 0;
        padding: 0;
        position: relative;
        >a {
            display: block;
            font-size: 14px;
            padding: 0 15px;
            text-decoration: none;
            position: relative;
        }
    }
    .pcoded-hasmenu .pcoded-submenu {
        list-style: outside none none;
        margin: 0;
        padding: 0;
        li {
            display: block;
            list-style: outside none none;
            margin: 0;
            padding: 0;
            position: relative;
            >a {
                display: block;
                font-size: 14px;
                font-weight: 400;
                padding: 0 15px;
                text-decoration: none;
                position: relative;
                .pcoded-micon {
                    font-size: 10px;
                    padding-right: 5px;
                }
            }
        }
    }
    >li>a>.pcoded-micon {
        font-size: 15px;
        padding-right: 10px;
    }
}

.pcoded-inner-navbar {
    height: 100%;
}

.pcoded[theme-layout="vertical"] {
    .pcoded-header {
        width: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        z-index: 1029;
        .pcoded-left-header {
            display: block;
            z-index: 1028;
            .pcoded-logo {
                opacity: 1;
                text-align: center;
                visibility: visible;
                height: 50px;
                white-space: nowrap;
                a {
                    display: block;
                    text-decoration: none;
                    padding: 9px 0;
                }
                .logo-icon {
                    bottom: -21px;
                    font-size: 60px;
                    left: 8px;
                    position: absolute;
                    z-index: -1;
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    -ms-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear;
                    -o-transition: all 0.3s linear;
                }
            }
        }
    }
    &[vertical-nav-type="collapsed"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-icon {
        font-size: 45px;
        bottom: -7px;
        left: 5px;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
    }
    &[pcoded-device-type="desktop"] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text,
    &[pcoded-device-type="phone"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text,
    &[pcoded-device-type="tablet"] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text {
        font-family: open sans;
        font-size: 16px;
        font-weight: 600;
        left: 60px;
        margin: 0 auto !important;
        opacity: 1;
        position: absolute;
        text-transform: uppercase;
        top: 25px;
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        visibility: visible;
    }
    &[vertical-nav-type="compact"] .pcoded-header {
        &.iscollapsed .pcoded-left-header .pcoded-logo .logo-text,
        .pcoded-left-header .pcoded-logo .logo-text {
            left: 15px;
            top: 13px;
        }
    }
    &[pcoded-device-type="phone"] .pcoded-header.nocollapsed .pcoded-left-header .pcoded-logo .logo-text {
        font-family: open sans;
        font-size: 16px;
        font-weight: 400;
        margin: 0 auto !important;
        opacity: 1;
        visibility: visible;
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        position: relative;
        top: 5px;
    }
    &[vertical-nav-type="collapsed"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text {
        opacity: 0;
        position: absolute;
        transform: rotateX(90deg);
        -webkit-transform: rotateX(0deg);
        -ms-transform: rotateX(90deg);
        -moz-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        visibility: hidden;
    }
    &[vertical-nav-type="offcanvas"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo {
        opacity: 0;
        visibility: hidden;
        transform: rotateX(90deg);
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -moz-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
    }
    &[vertical-nav-type="compact"] .pcoded-header .pcoded-left-header .pcoded-logo a .hide-in-smallsize {
        display: none;
    }
    .pcoded-header .sidebar_toggle a {
        border-radius: 4px;
        float: left;
        font-size: 18px;
        height: 35px;
        margin-right: 5px;
        position: relative;
        text-align: center;
        top: 7px;
        width: 40px;
        border-width: 0;
        border-style: solid;
        i {
            position: relative;
            top: -7px;
        }
        &:hover {
            text-decoration: none;
        }
    }
}

.sidebar_toggle a:focus {
    text-decoration: none;
}

.pcoded[theme-layout="vertical"] {
    .pcoded-header .pcoded-right-header {
        line-height: 50px;
        padding: 0 15px;
        .pcoded-rl-header {
            float: left;
        }
        .pcoded-rr-header {
            float: right;
        }
        .pcoded-rl-header {
            padding-left: 20px;
        }
    }
    &[pcoded-device-type="phone"] .pcoded-header .pcoded-right-header .pcoded-rl-header {
        padding-left: 0;
    }
    .pcoded-header .pcoded-right-header .pcoded-rl-header ul {
        list-style: outside none none;
        margin: 0;
        padding: 0;
    }
}

.pcoded-right-header .pcoded-rr-header ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.pcoded[theme-layout="vertical"] {
    .pcoded-header .pcoded-right-header {
        .pcoded-rl-header ul>li,
        .pcoded-rr-header ul>li {
            float: left;
            list-style: outside none none;
            margin: 0;
            padding: 0 5px;
            position: relative;
        }
        .pcoded-rl-header ul>li>a,
        .pcoded-rr-header ul>li>a {
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
        }
        .pcoded-rl-header li.icons>a>i,
        .pcoded-rr-header li.icons>a>i {
            font-size: 18px;
            position: relative;
            top: 3px;
        }
        .pcoded-rl-header li.icons>a,
        .pcoded-rr-header li.icons>a {
            padding: 0 5px;
        }
    }
    .pcoded-right-header .pcoded-badge {
        background-color: #777;
        border-radius: 10px;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
        min-height: 17px;
        min-width: 10px;
        opacity: 1;
        padding: 4px 7px;
        position: absolute;
        right: -4px;
        text-align: center;
        top: 9px;
        transition: opacity 0.3s linear 0s;
        -webkit-transition: opacity 0.3s linear 0s;
        -ms-transition: opacity 0.3s linear 0s;
        -moz-transition: opacity 0.3s linear 0s;
        -o-transition: opacity 0.3s linear 0s;
        vertical-align: middle;
        visibility: visible;
        white-space: nowrap;
    }
    .pcoded-badge {
        &.badge-success {
            background-color: #70ca63;
            color: #fff;
        }
        &.badge-warning {
            background-color: #fecd33;
            color: #fff;
        }
        &.badge-danger {
            background-color: #f13b48;
            color: #fff;
        }
    }
    &[pcoded-device-type="phone"] .hide-small-device {
        display: none;
    }
    .pcoded-navbar {
        z-index: 1027;
        height: 100%;
    }
    .pcoded-content {
        position: relative;
        display: block;
    }
    .pcoded-container {
        overflow: hidden;
        position: relative;
        margin: 0 auto;
    }
    &.pcoded-wrapper,
    .pcoded-main-container {
        position: relative;
        margin: 0 auto;
    }
    .pcoded-navbar .pcoded-search {
        border-top-width: 1px;
        border-top-style: solid;
        padding: 20px 10px;
        position: relative;
    }
    &[vnavigation-view="view2"] .pcoded-navbar .pcoded-search {
        border-radius: 4px 4px 0 0;
    }
}

.pcoded-search input[type="text"] {
    display: block;
    margin: 0;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: none;
    -o-border-radius: none;
    border-radius: 0;
    background: transparent;
    padding: 6px 22px 6px 3px;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    -webkit-transition: border 0.3s;
    -moz-transition: border 0.3s;
    -o-transition: border 0.3s;
    transition: border 0.3s;
    &:focus {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }
}

.style-4 input[type="text"].focus {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.pcoded-search .search-icon {
    cursor: pointer;
    margin-top: -33px;
    padding: 4px;
    position: absolute;
    right: 10px;
}

.pcoded[vertical-nav-type="collapsed"] {
    .pcoded-search-box {
        visibility: hidden;
        opacity: 0;
        transform: rotateX(90deg);
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -moz-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        position: absolute;
    }
    .pcoded-search input[type="text"] {
        padding: 10px 22px 10px 3px;
    }
}

.searchbar-toggle:before {
    content: "\f002";
    cursor: pointer;
    float: right;
    font-family: FontAwesome;
    left: 0;
    position: absolute;
    top: 10px;
    visibility: hidden;
    opacity: 0;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
}

.pcoded {
    &[vertical-nav-type="collapsed"] {
        .searchbar-toggle:before {
            left: 15px;
            top: 10px;
            visibility: visible;
            opacity: 1;
            transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
        }
        .pcoded-search {
            &.open {
                .searchbar-toggle:before {
                    content: "\f05c";
                }
                .pcoded-search-box {
                    margin-left: 35px;
                    opacity: 1;
                    position: absolute;
                    top: 0;
                    transform: rotateX(0deg);
                    -webkit-transform: rotateX(0deg);
                    -ms-transform: rotateX(0deg);
                    -moz-transform: rotateX(0deg);
                    -o-transform: rotateX(0deg);
                    visibility: visible;
                    width: 200px;
                    padding-right: 10px;
                }
            }
            .search-icon {
                margin-top: -35px;
            }
        }
    }
    &[theme-layout="vertical"] {
        .pcoded-navbar .pcoded-item {
            >li>a {
                text-align: left;
                padding: 10px 15px;
            }
            .pcoded-hasmenu .pcoded-submenu {
                position: relative;
                width: 100%;
                li>a {
                    text-align: left;
                    padding: 8.7px 10px;
                }
            }
        }
        &[vertical-nav-type="expanded"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
            padding-left: 30px;
        }
        .pcoded-navbar .pcoded-item {
            .pcoded-hasmenu {
                .pcoded-submenu {
                    width: 100%;
                    list-style: outside none none;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                }
                &.pcoded-trigger .pcoded-submenu {
                    -webkit-transform-origin: 0 0;
                    transform-origin: 10% 10%;
                    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
                    transition: transform 0.5s, opacity 0.5s;
                }
                .pcoded-submenu {
                    transform-style: preserve-3d;
                    -webkit-transform-style: preserve-3d;
                    transform: rotateX(-90deg);
                    -webkit-transform: rotateX(-90deg);
                }
                &.pcoded-trigger>.pcoded-submenu {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                    transform: rotateX(0deg);
                    -webkit-transform: rotateX(0deg);
                }
            }
            &[item-border="true"] {
                &[item-border-style="dashed"] li>a {
                    border-bottom-style: dashed;
                }
                &[item-border-style="dotted"] li>a {
                    border-bottom-style: dotted;
                }
                &[item-border-style="solid"] li>a {
                    border-bottom-style: solid;
                }
                &[item-border-style="none"] li>a {
                    border: none;
                }
            }
            &[item-border="false"]>li>a {
                border-bottom-width: 0;
            }
            &[item-border="true"]>li>a {
                border-bottom-width: 1px;
            }
            &[subitem-border="false"] .pcoded-hasmenu .pcoded-submenu li>a {
                border-bottom-width: 0;
            }
            &[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li>a {
                border-bottom-width: 1px;
            }
            >li.pcoded-hasmenu {
                >a:after {
                    float: right;
                    font-style: normal;
                    margin-left: 3px;
                    font-size: 10px;
                    position: absolute;
                    right: 15px;
                    top: 0;
                    padding-top: 10px;
                    transition: opacity 0.3s linear;
                    -webkit-transition: opacity 0.3s linear;
                    -ms-transition: opacity 0.3s linear;
                    -moz-transition: opacity 0.3s linear;
                    -o-transition: opacity 0.3s linear;
                }
                .pcoded-submenu li.pcoded-hasmenu>a:after {
                    float: right;
                    font-style: normal;
                    margin-left: 3px;
                    font-size: 10px;
                    position: absolute;
                    right: 15px;
                    top: 0;
                    padding-top: 13px;
                }
                &.pcoded-trigger {
                    >a:after {
                        float: right;
                        font-style: normal;
                        margin-left: 3px;
                        position: absolute;
                        right: 15px;
                        font-size: 12px;
                        top: 0;
                        padding-top: 10px;
                    }
                    .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                        float: right;
                        font-style: normal;
                        margin-left: 3px;
                        position: absolute;
                        right: 15px;
                        font-size: 12px;
                        top: 0;
                        padding-top: 13px;
                        transition: opacity 0.3s linear;
                        -webkit-transition: opacity 0.3s linear;
                        -ms-transition: opacity 0.3s linear;
                        -moz-transition: opacity 0.3s linear;
                        -o-transition: opacity 0.3s linear;
                    }
                }
            }
            li.pcoded-hasmenu {
                &[dropdown-icon="style1"]>a:after {
                    content: "\e606";
                    font-family: simple-line-icons;
                }
                &.pcoded-trigger[dropdown-icon="style1"]>a:after {
                    content: "\e604";
                    font-family: simple-line-icons;
                }
                &[dropdown-icon="style2"]>a:after {
                    content: "\e095";
                    font-family: simple-line-icons;
                }
                &.pcoded-trigger[dropdown-icon="style2"]>a:after {
                    content: "\e615";
                    font-family: simple-line-icons;
                }
                &[dropdown-icon="style3"]>a:after {
                    content: '\e61a';
                    font-family: 'themify';
                    font-size: 14px;
                }
                &.pcoded-trigger[dropdown-icon="style3"]>a:after {
                    content: '\e622';
                    font-family: 'themify';
                    font-size: 14px;
                }
            }
        }
        .pcoded-header .pcoded-left-header {
            &[pcoded-lheader-position="relative"] {
                position: relative;
            }
            &[pcoded-lheader-position="fixed"] {
                position: fixed;
            }
            &[pcoded-lheader-position="absolute"] {
                position: absolute;
            }
        }
        .pcoded-navbar {
            &[pcoded-navbar-position="relative"] {
                position: relative;
            }
            &[pcoded-navbar-position="fixed"] {
                position: fixed;
            }
            &[pcoded-navbar-position="absolute"] {
                position: absolute;
            }
        }
        .pcoded-header {
            &[pcoded-header-position="relative"] {
                position: relative;
            }
            &[pcoded-header-position="fixed"] {
                position: fixed;
                z-index: 1028;
            }
            &[pcoded-header-position="absolute"] {
                position: absolute;
            }
        }
        &[vertical-layout="wide"] .pcoded-container {
            width: 100%;
            margin: 0 auto;
        }
        &[pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container {
            max-width: 1200px;
            width: 100%;
            margin: 0 auto;
        }
        &[pcoded-device-type="tablet"][vertical-layout="box"] .pcoded-container {
            max-width: 900px;
            width: 100%;
            margin: 0 auto;
        }
        &[pcoded-device-type="phone"][vertical-layout="box"] .pcoded-container {
            max-width: 100%;
            width: 100%;
            margin: 0 auto;
        }
        &[vertical-layout="widebox"] .pcoded-container {
            width: 100%;
            margin: 0 auto;
        }
        &[pcoded-device-type="desktop"][vertical-layout="widebox"] {
            .pcoded-main-container,
            .pcoded-wrapper {
                max-width: 1200px;
                width: 100%;
                margin: 0 auto;
            }
        }
        &[pcoded-device-type="tablet"][vertical-layout="widebox"] {
            .pcoded-main-container,
            .pcoded-wrapper {
                max-width: 900px;
                width: 100%;
                margin: 0 auto;
            }
        }
        &[pcoded-device-type="phone"][vertical-layout="widebox"] {
            .pcoded-main-container,
            .pcoded-wrapper {
                max-width: 100%;
                width: 100%;
                margin: 0 auto;
            }
        }
        &[vertical-layout="widebox"] .pcoded-wrapper {
            overflow: hidden;
        }
    }
    .pcoded-overlay-box {
        background: #000 none repeat scroll 0 0;
        bottom: 0;
        height: 100%;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s linear;
        -webkit-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        -moz-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
        overflow: hidden;
    }
    &[vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-overlay-box,
    &[vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-overlay-box,
    &[vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-overlay-box,
    &[vertical-nav-type="fullsub-collapsed"][vertical-effect="overlay"] .pcoded-overlay-box,
    &[vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-overlay-box {
        visibility: visible;
        z-index: 1026;
        opacity: 0.3;
        transition: opacity 0.3s linear;
        -webkit-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        -moz-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
        overflow: hidden;
    }
    .pcoded-navbar .pcoded-item .pcoded-hasmenu {
        .pcoded-submenu li>a {
            .pcoded-micon {
                display: none;
                font-size: 10px;
                padding-right: 5px;
            }
            .pcoded-mtext:before {
                font-style: normal;
                padding-right: 4px;
                font-size: 10px;
                position: relative;
                left: 0;
                top: 0;
                opacity: 1;
                visibility: visible;
            }
        }
        &[subitem-icon="style1"] .pcoded-submenu li>a .pcoded-mtext:before {
            content: "\f105";
            font-family: FontAwesome;
            padding-right: 5px;
            font-size: 12px;
        }
        &[subitem-icon="style2"] .pcoded-submenu li>a .pcoded-mtext:before {
            content: "\f22d";
            font-family: FontAwesome;
            padding-right: 5px;
            font-size: 12px;
        }
        &[subitem-icon="style3"] .pcoded-submenu li>a .pcoded-mtext:before {
            content: "\e08d";
            font-family: simple-line-icons;
        }
        &[subitem-icon="style4"] .pcoded-submenu li>a .pcoded-mtext:before {
            content: "\f124";
            font-family: FontAwesome;
            padding-right: 10px;
            font-size: 12px;
        }
        &[subitem-icon="style5"] .pcoded-submenu li>a .pcoded-mtext:before {
            content: "\f0da";
            font-family: FontAwesome;
            padding-right: 10px;
            font-size: 12px;
        }
        &[subitem-icon="style6"] .pcoded-submenu li>a .pcoded-mtext:before {
            content: '\e649';
            font-family: 'themify';
            padding-right: 10px;
            font-size: 12px;
        }
    }
    &[theme-layout="vertical"] .pcoded-navbar .pcoded-item>li {
        &.active>a>.pcoded-mcaret {
            background: transparent none repeat scroll 0 0;
            border-bottom: 10px solid transparent;
            border-right: 8px solid #e9e9e9;
            border-top: 10px solid transparent;
            display: block;
            float: right;
            height: 0;
            position: absolute;
            right: 0;
            top: calc(50% - 10px);
            width: 0;
        }
        &:hover>a:before {
            border-left-style: solid;
            border-left-width: 5px;
            content: " ";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
    }
    .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
        border-left-style: solid;
        border-left-width: 5px;
        content: " ";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
    }
    &[theme-layout="vertical"] {
        .pcoded-navbar .pcoded-item>li.active>a:before {
            border-left-style: solid;
            border-left-width: 5px;
            content: " ";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
        &[vertical-placement="right"] {
            .pcoded-navbar {
                float: right;
                right: 0;
            }
            .pcoded-content {
                float: unset;
                left: 0;
            }
        }
        &[vertical-placement="left"] {
            .pcoded-navbar {
                float: left;
            }
            .pcoded-content {
                float: unset;
                right: 0;
            }
        }
        &[vertical-placement="right"] .pcoded-header {
            .pcoded-left-header {
                float: right;
                right: 0;
            }
            .pcoded-right-header {
                float: unset;
                left: unset;
            }
        }
        &[vertical-placement="left"] .pcoded-header {
            .pcoded-left-header {
                float: left;
            }
            .pcoded-right-header {
                float: unset;
                right: unset;
            }
        }
        &[vertical-nav-type="expanded"] {
            .pcoded-header .pcoded-left-header,
            .pcoded-navbar {
                width: 235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-left: 235px;
                    margin-right: -235px;
                }
            }
            .pcoded-content {
                margin-left: 235px;
                margin-right: -235px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-right: 0;
                    margin-left: -235px;
                }
            }
            .pcoded-content {
                margin-right: 235px;
                margin-left: -235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed .pcoded-right-header,
                .pcoded-right-header {
                    margin-left: 235px;
                }
            }
            .pcoded-content {
                margin-left: 235px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed .pcoded-right-header,
                .pcoded-right-header {
                    margin-right: 235px;
                }
            }
            .pcoded-content {
                margin-right: 235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-left: 0;
                }
            }
            .pcoded-content {
                margin-left: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-right: 0;
                }
            }
            .pcoded-content {
                margin-right: 0;
            }
        }
        &[vertical-effect="overlay"] .pcoded-navbar .pcoded-item>li.active>a>.pcoded-mcaret {
            background: transparent !important;
            border-bottom: transparent !important;
            border-right: transparent !important;
            border-top: 15px solid transparent;
        }
        &[vertical-nav-type="expanded"] {
            &[pcoded-device-type="desktop"] .pcoded-navbar {
                .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
                &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    left: 100%;
                    position: absolute;
                    width: 230px;
                    z-index: 1024;
                    top: 0;
                }
            }
            &[pcoded-device-type="tablet"] .pcoded-navbar {
                .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
                &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    left: 100%;
                    position: absolute;
                    width: 230px;
                    z-index: 1024;
                    top: 0;
                }
            }
        }
        &[vertical-nav-type="collapsed"] {
            .pcoded-header .pcoded-left-header {
                width: 235px;
            }
            .pcoded-navbar {
                width: 45px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 45px;
                    }
                    .pcoded-right-header {
                        margin-left: 45px;
                        margin-right: 0;
                    }
                }
            }
            .pcoded-content {
                margin-left: 45px;
                margin-right: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 45px;
                    }
                    .pcoded-right-header {
                        margin-right: 45px;
                        margin-left: 0;
                    }
                }
            }
            .pcoded-content {
                margin-right: 45px;
                margin-left: 0;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="shrink"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 45px;
                    }
                    .pcoded-right-header {
                        margin-left: 45px;
                    }
                }
            }
            .pcoded-content {
                margin-left: 45px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="shrink"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 45px;
                    }
                    .pcoded-right-header {
                        margin-right: 45px;
                    }
                }
            }
            .pcoded-content {
                margin-right: 45px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 45px;
                    }
                    .pcoded-right-header {
                        margin-left: 45px;
                    }
                }
            }
            .pcoded-content {
                margin-left: 45px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 45px;
                    }
                    .pcoded-right-header {
                        margin-right: 45px;
                    }
                }
            }
            .pcoded-content {
                margin-right: 45px;
            }
        }
        .pcoded-navbar .pcoded-item {
            >li>a .pcoded-mtext {
                opacity: 1;
                visibility: visible;
                position: absolute;
            }
            li.pcoded-hasmenu>a:after {
                opacity: 1;
                visibility: visible;
            }
        }
        &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item {
            >li {
                >a .pcoded-mtext {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                }
                &.pcoded-trigger>a .pcoded-mtext {
                    opacity: 1;
                    visibility: visible;
                    position: absolute;
                    padding-left: 22px;
                }
            }
            li.pcoded-hasmenu {
                >a:after {
                    opacity: 0;
                    visibility: hidden;
                }
                &.pcoded-trigger a:after {
                    opacity: 1;
                    visibility: visible;
                }
            }
            > {
                li.pcoded-trigger {
                    display: block;
                    position: relative;
                    width: 255px;
                    z-index: 1024;
                }
                .pcoded-hasmenu {
                    &.pcoded-trigger>.pcoded-submenu {
                        left: 45px;
                        position: absolute;
                        width: 210px;
                        z-index: 1024;
                        border-left-style: solid;
                        border-left-width: 1px;
                        .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                            position: relative;
                        }
                    }
                    >.pcoded-submenu .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                        position: absolute;
                    }
                }
                li.pcoded-trigger.active>a>.pcoded-mcaret {
                    display: none;
                }
            }
        }
        &[vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-left-header {
                    width: 235px;
                }
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 0;
                    }
                    .pcoded-right-header {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
            .pcoded-navbar {
                width: 235px;
                margin-left: -235px;
            }
            .pcoded-content {
                margin-left: 0;
                margin-right: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-left-header {
                    width: 235px;
                }
                .pcoded-right-header {
                    margin-right: 0;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 0;
                    }
                    .pcoded-right-header {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }
            .pcoded-navbar {
                width: 235px;
                margin-right: -235px;
            }
            .pcoded-content {
                margin-right: 0;
                margin-left: 0;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] {
            .pcoded-header {
                .pcoded-left-header {
                    width: 235px;
                }
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 0;
                    }
                    .pcoded-right-header {
                        margin-left: 0;
                    }
                }
            }
            .pcoded-navbar {
                width: 235px;
                margin-left: -235px;
            }
            .pcoded-content {
                margin-left: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] {
            .pcoded-header {
                .pcoded-left-header {
                    width: 235px;
                }
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 0;
                    }
                    .pcoded-right-header {
                        margin-right: 0;
                    }
                }
            }
            .pcoded-navbar {
                width: 235px;
                margin-right: -235px;
            }
            .pcoded-content {
                margin-right: 0;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-left-header {
                    width: 235px;
                }
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 0;
                    }
                    .pcoded-right-header {
                        margin-left: 0;
                    }
                }
            }
            .pcoded-navbar {
                width: 235px;
                margin-left: -235px;
            }
            .pcoded-content {
                margin-left: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-left-header {
                    width: 235px;
                }
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 0;
                    }
                    .pcoded-right-header {
                        margin-right: 0;
                    }
                }
            }
            .pcoded-navbar {
                width: 235px;
                margin-right: -235px;
            }
            .pcoded-content {
                margin-right: 0;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] {
            .pcoded-header {
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 190px;
                    }
                    .pcoded-right-header {
                        margin-left: 190px;
                        margin-right: -190px;
                    }
                }
                .pcoded-left-header {
                    width: 190px;
                }
                .pcoded-right-header {
                    margin-left: 190px;
                }
            }
            .pcoded-navbar {
                width: 190px;
            }
            .pcoded-content {
                margin-left: 190px;
                margin-right: -190px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] {
            .pcoded-header {
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 190px;
                    }
                    .pcoded-right-header {
                        margin-right: 190px;
                        margin-left: -190px;
                    }
                }
                .pcoded-left-header {
                    width: 190px;
                }
                .pcoded-right-header {
                    margin-right: 190px;
                }
            }
            .pcoded-navbar {
                width: 190px;
            }
            .pcoded-content {
                margin-right: 190px;
                margin-left: -190px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 190px;
                    }
                    .pcoded-right-header {
                        margin-left: 190px;
                    }
                }
                .pcoded-left-header {
                    width: 190px;
                }
                .pcoded-right-header {
                    margin-left: 190px;
                }
            }
            .pcoded-navbar {
                width: 190px;
            }
            .pcoded-content {
                margin-left: 190px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 190px;
                    }
                    .pcoded-right-header {
                        margin-right: 190px;
                    }
                }
                .pcoded-left-header {
                    width: 190px;
                }
                .pcoded-right-header {
                    margin-right: 190px;
                }
            }
            .pcoded-navbar {
                width: 190px;
            }
            .pcoded-content {
                margin-right: 190px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] {
            .pcoded-header {
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 190px;
                    }
                    .pcoded-right-header {
                        margin-left: 0;
                    }
                }
                .pcoded-left-header {
                    width: 190px;
                }
                .pcoded-right-header {
                    margin-left: 190px;
                }
            }
            .pcoded-navbar {
                width: 190px;
            }
            .pcoded-content {
                margin-left: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-left-header {
                    width: 190px;
                }
                .pcoded-right-header {
                    margin-right: 190px;
                }
                &.iscollapsed {
                    .pcoded-left-header {
                        width: 190px;
                    }
                    .pcoded-right-header {
                        margin-right: 0;
                    }
                }
            }
            .pcoded-navbar {
                width: 190px;
            }
            .pcoded-content {
                margin-right: 0;
            }
        }
        &[vertical-nav-type="compact"] {
            .pcoded-navbar .pcoded-item>li {
                >a {
                    line-height: unset;
                    text-align: center;
                    .pcoded-mtext {
                        opacity: 1;
                        position: relative;
                        text-align: center;
                        visibility: visible;
                    }
                    >.pcoded-micon {
                        display: block;
                        font-size: 18px;
                        padding: 5px;
                    }
                }
                &.active>a {
                    >.pcoded-mcaret {
                        background: transparent none repeat scroll 0 0;
                        border-bottom: 18px solid transparent;
                        border-right: 12px solid #e9e9e9;
                        border-top: 18px solid transparent;
                        top: 18px;
                    }
                    &:before {
                        height: 100%;
                    }
                }
                &:hover>a:before {
                    height: 100%;
                }
                &.pcoded-hasmenu {
                    &.pcoded-trigger[dropdown-icon="style1"]>a:after,
                    &[dropdown-icon="style1"]>a:after {
                        top: 18px;
                    }
                }
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar {
                .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
                &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    left: 100%;
                    position: absolute;
                    width: 210px;
                    z-index: 1024;
                    top: 0;
                }
            }
            &[pcoded-device-type="tablet"] .pcoded-navbar {
                .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
                &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    left: 100%;
                    position: absolute;
                    width: 210px;
                    z-index: 1024;
                    top: 0;
                }
            }
        }
        &[vertical-nav-type="sub-expanded"] {
            .pcoded-header .pcoded-left-header,
            .pcoded-navbar {
                width: 235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-left: 235px;
                    margin-right: -235px;
                }
            }
            .pcoded-content {
                margin-left: 235px;
                margin-right: -235px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-right: 0;
                    margin-left: -235px;
                }
            }
            .pcoded-content {
                margin-right: 235px;
                margin-left: -235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed .pcoded-right-header,
                .pcoded-right-header {
                    margin-left: 235px;
                }
            }
            .pcoded-content {
                margin-left: 235px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed .pcoded-right-header,
                .pcoded-right-header {
                    margin-right: 235px;
                }
            }
            .pcoded-navbar {
                width: 235px;
            }
            .pcoded-content {
                margin-right: 235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-left: 0;
                }
            }
            .pcoded-navbar {
                width: 235px;
            }
            .pcoded-content {
                margin-left: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-right: 0;
                }
            }
            .pcoded-content {
                margin-right: 0;
            }
        }
        &[vertical-nav-type="sub-expanded"] {
            &[pcoded-device-type="desktop"],
            &[pcoded-device-type="tablet"] {
                .pcoded-inner-navbar {
                    position: unset;
                }
                .pcoded-navbar .pcoded-item {
                    position: unset;
                    >li {
                        position: unset;
                    }
                }
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                left: 100%;
                position: absolute;
                width: 230px;
                z-index: 1024;
                top: 0;
                bottom: 0;
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
                position: unset;
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
                left: 100%;
                position: absolute;
                width: 230px;
                z-index: 1024;
                top: 0;
                bottom: 0;
            }
            .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-style: solid;
                border-left-width: 1px;
            }
        }
        &[vertical-nav-type="ex-popover"] {
            .pcoded-header {
                &.iscollapsed .pcoded-left-header,
                .pcoded-left-header {
                    width: 235px;
                }
            }
            .pcoded-navbar {
                width: 235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-left: 235px;
                    margin-right: -235px;
                }
            }
            .pcoded-content {
                margin-left: 235px;
                margin-right: -235px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="push"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-right: 0;
                    margin-left: -235px;
                }
            }
            .pcoded-content {
                margin-right: 235px;
                margin-left: -235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed .pcoded-right-header,
                .pcoded-right-header {
                    margin-left: 235px;
                }
            }
            .pcoded-content {
                margin-left: 235px;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] {
            .pcoded-header {
                &.iscollapsed .pcoded-right-header,
                .pcoded-right-header {
                    margin-right: 235px;
                }
            }
            .pcoded-content {
                margin-right: 235px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-left: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-left: 0;
                }
            }
            .pcoded-content {
                margin-left: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] {
            .pcoded-header {
                .pcoded-right-header {
                    margin-right: 235px;
                }
                &.iscollapsed .pcoded-right-header {
                    margin-right: 0;
                }
            }
            .pcoded-content {
                margin-right: 0;
            }
        }
        &[vertical-nav-type="ex-popover"] {
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>li,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>li {
                position: relative;
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                left: 100%;
                position: absolute;
                width: 230px;
                z-index: 1024;
                top: 0;
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
                position: relative;
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
                left: 100%;
                position: absolute;
                width: 230px;
                z-index: 1024;
                top: 0;
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret,
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
                background: transparent none repeat scroll 0 0;
                border-bottom: 19px solid transparent;
                border-right: 12px solid;
                border-top: 19px solid transparent;
                display: block;
                float: right;
                height: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
                z-index: 1025;
            }
            .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-style: solid;
                border-left-width: 1px;
            }
        }
        &[vertical-placement="left"][vertical-nav-type="fullpage"] .pcoded-header {
            .pcoded-left-header {
                width: 235px;
            }
            .pcoded-right-header {
                margin-left: 235px;
            }
            &.iscollapsed .pcoded-left-header {
                width: 235px;
            }
        }
        &[vertical-nav-type="fullpage"] {
            .pcoded-navbar {
                width: 100%;
            }
            .pcoded-content {
                margin-left: 0;
                margin-right: 0;
            }
        }
        &[vertical-placement="right"][vertical-nav-type="fullpage"] .pcoded-header {
            .pcoded-left-header {
                width: 235px;
            }
            .pcoded-right-header {
                margin-right: 235px;
            }
            &.iscollapsed .pcoded-left-header {
                width: 235px;
            }
        }
        &[vertical-nav-type="fullpage"] {
            .pcoded-navbar {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1030;
                opacity: 1;
                visibility: visible;
                width: 100%;
                .pcoded-item {
                    >li.active>a {
                        background: transparent !important;
                    }
                    &[item-border="true"]>li>a {
                        border-bottom-width: 0 !important;
                    }
                }
                &[pcoded-navbar-position="absolute"] {
                    position: absolute;
                }
                &[pcoded-navbar-position="fixed"] {
                    position: fixed;
                }
                .pcoded-inner-navbar {
                    margin-top: 40px;
                }
            }
            .pcoded-main-container {
                display: block;
                position: unset;
            }
            .pcoded-navbar {
                .pcoded-item>li>a {
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    font-size: 24px;
                    font-weight: 500;
                    .pcoded-mtext {
                        opacity: 1;
                        position: relative;
                        visibility: visible;
                    }
                    >.pcoded-micon {
                        display: none;
                    }
                }
                .pcoded-navigatio-lavel {
                    display: none !important;
                }
                .pcoded-item>li {
                    &.active>a:before,
                    a .pcoded-badge {
                        display: none;
                    }
                    &:hover>a {
                        background: transparent !important;
                        &:before {
                            display: none;
                        }
                    }
                    &.active>a>.pcoded-mcaret {
                        display: none;
                    }
                    >a {
                        padding: 0;
                    }
                }
                .sidebar_toggle {
                    height: 30px;
                }
            }
        }
    }
}

.pcoded-navbar .sidebar_toggle a {
    opacity: 1;
    visibility: visible;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    position: absolute;
}

.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .sidebar_toggle a {
    color: #f1f1f1;
    float: right;
    font-size: 36px;
    opacity: 1;
    position: relative;
    right: 10px;
    text-decoration: none;
    top: 0;
    transition: opacity 0.8s linear 0s;
    -webkit-transition: opacity 0.8s linear 0s;
    -ms-transition: opacity 0.8s linear 0s;
    -moz-transition: opacity 0.8s linear 0s;
    -o-transition: opacity 0.8s linear 0s;
    visibility: visible;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
}

.pcoded-navbar .sidebar_toggle a:hover {
    color: #fff;
}

.pcoded {
    &[theme-layout="vertical"] {
        &[vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item {
            >li {
                &.pcoded-hasmenu>a:after {
                    display: none;
                }
                &.pcoded-trigger>a {
                    background: transparent !important;
                    border-bottom-color: transparent !important;
                }
            }
            .pcoded-hasmenu .pcoded-submenu {
                background: transparent !important;
            }
            &[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li>a {
                border-bottom-width: 0;
            }
            .pcoded-hasmenu .pcoded-submenu li {
                >a {
                    text-align: center;
                    font-size: 24px;
                    font-weight: 500;
                    .pcoded-mtext:before {
                        display: none;
                    }
                }
                &:hover>a {
                    background: transparent !important;
                    &:before {
                        border-left-width: 0;
                    }
                }
            }
            .pcoded-submenu>li.active>a {
                background: transparent !important;
                color: #FFF !important;
            }
        }
        &[pcoded-device-type="phone"] {
            .pcoded-header.nocollapsed .pcoded-left-header {
                width: 100% !important;
                padding-left: 235px;
            }
            &[vertical-nav-type="collapsed"] .pcoded-header.nocollapsed .pcoded-left-header {
                padding-left: 45px;
            }
            &[vertical-effect="overlay"] .pcoded-header.nocollapsed .pcoded-left-header,
            &[vertical-nav-type="fullpage"] .pcoded-header.nocollapsed .pcoded-left-header,
            &[vertical-nav-type="offcanvas"] .pcoded-header.nocollapsed .pcoded-left-header {
                padding-left: 0;
            }
            .pcoded-header.nocollapsed {
                height: 100px !important;
                .pcoded-right-header {
                    padding: 50px 5px 0 !important;
                }
            }
            &[vertical-effect="overlay"] .pcoded-header.nocollapsed .pcoded-right-header,
            &[vertical-nav-type="offcanvas"] .pcoded-header.nocollapsed .pcoded-right-header {
                margin: 0 !important;
            }
            .pcoded-header.nocollapsed .sidebar_toggle a {
                background: transparent none repeat scroll 0 0;
                border-radius: 4px;
                color: #fff;
                float: left;
                font-size: 18px;
                height: 35px;
                margin-right: 5px;
                position: fixed;
                right: 0;
                text-align: center;
                top: 6px;
                width: 40px;
                z-index: 1028;
            }
        }
    }
    &.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] {
        .pcoded-navbar {
            top: 0;
            z-index: 1030;
        }
        .pcoded-main-container {
            position: unset;
        }
        #styleSelector {
            top: 100px;
        }
    }
    .pcoded-navbar .pcoded-navigatio-lavel {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        opacity: 1;
        padding: 10px 5px 2px;
        text-transform: uppercase;
        visibility: visible;
        width: 100%;
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transition: opacity 0.3s linear;
        -webkit-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        -moz-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
        border-bottom: 2px solid;
        &:before {
            content: "\e6de";
            font-family: 'themify';
            margin-right: 10px;
        }
        &[menu-title-theme="theme1"] {
            color: #2a394f !important;
        }
        &[menu-title-theme="theme2"] {
            color: #624e89 !important;
        }
        &[menu-title-theme="theme3"] {
            color: #3c4fb1 !important;
        }
        &[menu-title-theme="theme4"] {
            color: #2C3E50 !important;
        }
        &[menu-title-theme="theme5"] {
            color: #0073aa !important;
        }
        &[menu-title-theme="theme6"] {
            color: #fff !important;
        }
    }
    &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-navigatio-lavel {
        opacity: 0;
        visibility: hidden;
        /* position:absolute; */
        transform: rotateX(90deg);
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -moz-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transition: opacity 0.3s linear;
        -webkit-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        -moz-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
    }
    &[vertical-nav-type="compact"] .pcoded-navbar .pcoded-navigatio-lavel {
        text-align: center;
    }
    .pcoded-navbar .pcoded-item li a .pcoded-badge {
        position: absolute;
        right: 30px;
        text-align: center;
        top: 12px;
        vertical-align: middle;
        white-space: nowrap;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s linear;
    }
    &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item>li {
        >a .pcoded-badge {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s linear;
            -webkit-transition: opacity 0.3s linear;
            -ms-transition: opacity 0.3s linear;
            -moz-transition: opacity 0.3s linear;
            -o-transition: opacity 0.3s linear;
        }
        &.pcoded-trigger>a .pcoded-badge {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s linear;
            -webkit-transition: opacity 0.3s linear;
            -ms-transition: opacity 0.3s linear;
            -moz-transition: opacity 0.3s linear;
            -o-transition: opacity 0.3s linear;
        }
    }
    .pcoded-navbar .pcoded-item li a .pcoded-badge {
        &.badge-success {
            color: #fff;
            background-color: #70ca63;
        }
        &.badge-danger {
            color: #fff;
            background-color: #f13b48;
        }
        &.badge-warning {
            color: #000;
            background-color: #fecd33;
        }
    }
    &[theme-layout="vertical"][vertical-placement="right"] {
        .pcoded-navbar {
            .pcoded-item {
                >li {
                    &.active>a {
                        &:before {
                            left: auto;
                            right: 0;
                        }
                        >.pcoded-mcaret {
                            background: transparent none repeat scroll 0 0;
                            border-bottom: 15px solid transparent;
                            border-left: 10px solid #e9e9e9;
                            border-top: 15px solid transparent;
                            left: 0;
                            right: auto;
                            top: 4px;
                            border-right: 0;
                        }
                    }
                    >a .pcoded-micon i {
                        float: right;
                        position: absolute;
                        right: 0;
                        top: 11px;
                    }
                    &.pcoded-hasmenu>a:after {
                        left: 8px;
                        right: auto;
                        top: -1px;
                    }
                    >a .pcoded-mtext {
                        left: auto;
                        right: 0;
                        padding-right: 45px;
                    }
                }
                li a .pcoded-badge {
                    position: relative;
                    right: -1px;
                    top: -2px;
                }
                >li:hover>a:before {
                    left: auto;
                    right: 0;
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a:before {
                        left: auto;
                        right: 0;
                    }
                    >a {
                        text-align: right;
                        .pcoded-mtext:before {
                            float: right;
                            left: 6px;
                            top: 3px;
                            padding-right: 10px;
                        }
                    }
                }
                li .pcoded-submenu li>a .pcoded-badge {
                    left: 23px;
                    position: absolute;
                    right: auto;
                    top: 11px;
                }
            }
            .pcoded-navigatio-lavel {
                text-align: right;
            }
            .pcoded-item li.pcoded-hasmenu {
                &[dropdown-icon="style1"]>a:after {
                    content: "\e605";
                    font-family: simple-line-icons;
                }
                &.pcoded-trigger[dropdown-icon="style1"]>a:after {
                    content: "\e604";
                    font-family: simple-line-icons;
                }
            }
        }
        &[vertical-nav-type="expanded"] {
            &[pcoded-device-type="desktop"] .pcoded-navbar {
                .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
                &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    left: auto;
                    right: 100%;
                }
            }
            &[pcoded-device-type="tablet"] .pcoded-navbar {
                .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
                &.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    left: auto;
                    right: 100%;
                }
            }
        }
        .pcoded-navbar .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu>a:after {
            left: 7px;
            right: auto;
            top: 0;
        }
        &[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item {
            > {
                li.pcoded-trigger {
                    left: auto;
                    right: 210px;
                }
                .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    left: auto;
                }
            }
            li a .pcoded-badge {
                position: absolute;
                right: auto;
                top: 10px;
            }
        }
        .pcoded-header {
            .pcoded-right-header {
                .pcoded-rl-header {
                    float: right;
                }
                .pcoded-rr-header {
                    float: left;
                }
            }
            .sidebar_toggle {
                float: right;
            }
        }
        &[vertical-nav-type="collapsed"] .pcoded-search.open .pcoded-search-box {
            right: 0;
        }
    }
}


/* Theme Patteren CSS Start */

body {
    &[themebg-pattern="pattern1"] {
        background-image: url("assets/images/pattern1.png");
    }
    &[themebg-pattern="pattern2"] {
        background-image: url("assets/images/pattern2.png");
    }
    &[themebg-pattern="pattern3"] {
        background-image: url("assets/images/pattern3.png");
    }
    &[themebg-pattern="pattern4"] {
        background-image: url("assets/images/pattern4.png");
    }
    &[themebg-pattern="pattern5"] {
        background-image: url("assets/images/pattern5.png");
    }
    &[themebg-pattern="pattern6"] {
        background-image: url("assets/images/pattern6.png");
    }
    &[themebg-pattern="pattern7"] {
        background-image: url("assets/images/pattern7.png");
    }
    &[themebg-pattern="pattern8"] {
        background-image: url("assets/images/pattern8.png");
    }
    &[themebg-pattern="pattern9"] {
        background-image: url("assets/images/pattern9.png");
    }
}


/* Theme Patteren CSS Close */


/* ########### Theme1 Color Combation CSS Style Start Here ##################### */

.pcoded {
    .pcoded-navbar {
        &[active-item-theme="theme1"] .pcoded-item {
            >li.active>a {
                background: #ff5e3a !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #ff5e3a !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #ff5e3a;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #ff5e3a !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        &[active-item-theme="theme2"] .pcoded-item {
            >li.active>a {
                background: #0096b1 !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #0096b1 !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #0096b1;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #0096b1 !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        &[active-item-theme="theme3"] .pcoded-item {
            >li.active>a {
                background: #e64056 !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #e64056 !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #e64056;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #e64056 !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        &[active-item-theme="theme4"] .pcoded-item {
            >li.active>a {
                background: #0073aa !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #0073aa !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #0073aa;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #0073aa !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        &[active-item-theme="theme5"] .pcoded-item {
            >li.active>a {
                background: #354a5f !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #354a5f !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #354a5f;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #354a5f !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        &[active-item-theme="theme6"] .pcoded-item {
            >li.active>a {
                background: #967adc !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #967adc !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #967adc;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #967adc !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        &[active-item-theme="theme7"] .pcoded-item {
            >li.active>a {
                background: #fe9375 !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #fe9375 !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #fe9375;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #fe9375 !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        &[active-item-theme="theme8"] .pcoded-item {
            >li.active>a {
                background: #565a90 !important;
            }
            li {
                .pcoded-submenu li.active>a {
                    color: #fff !important;
                    font-weight: 600;
                }
                &.pcoded-hasmenu {
                    &.active>a {
                        background: #565a90 !important;
                    }
                    &:hover>a {
                        color: #FFF !important;
                    }
                }
                &:hover>a {
                    color: #fff !important;
                    background: #565a90;
                    &:before {
                        border-left-color: transparent !important;
                    }
                }
            }
            >li.active>a {
                &:before {
                    border-left-color: #565a90 !important;
                }
                &:after {
                    color: #FFF !important;
                }
            }
        }
        .pcoded-header {
            &[header-theme="theme1"] {
                color: #fff;
                .sidebar_toggle a {
                    background: transparent;
                    color: #fff;
                    border-color: #fff;
                }
                .pcoded-right-header {
                    .pcoded-rl-header ul>li>a,
                    .pcoded-rr-header ul>li>a {
                        color: #fff;
                    }
                }
            }
            &.nocollapsed .pcoded-left-header[lheader-theme="theme1"] {
                background: transparent;
            }
            &.iscollapsed .pcoded-left-header[lheader-theme="theme1"] {
                background: #000;
            }
        }
        &[navbar-theme="theme1"] {
            background: #000;
            .main-menu {
                background-color: #000;
                .main-menu-header {
                    background-color: #1a1a1a;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #fff;
                    }
                    &.active>a {
                        background: #000;
                        color: #FFF;
                        border-bottom-color: #1a1a1a;
                        &:before {
                            border-left-color: #000;
                        }
                    }
                    >a {
                        border-bottom-color: #1a1a1a;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #000;
                    li>a {
                        border-bottom-color: #000;
                        color: #fff;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #121212;
                            li.pcoded-hasmenu>a:after {
                                color: #fff;
                            }
                        }
                        >a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #fff;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #000;
                        color: #FFF;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #121212;
                        color: #FFF;
                        &:before {
                            border-left-color: #000;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #121212;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #000;
                            }
                            &:after {
                                border-left-color: #000;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #000;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #fff;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #000;
                            border-bottom-color: #1a1a1a;
                        }
                        &.active>a {
                            background: #000;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #000;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #000;
                border-bottom-color: transparent;
            }
        }
        &[theme-layout="vertical"] {
            &[vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-color: transparent;
            }
            &[vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item {
                li.pcoded-trigger>a>.pcoded-mcaret {
                    border-right-color: #000 !important;
                }
                >.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    border-left-color: transparent;
                }
            }
            &[vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] {
                background-color: #000;
                .pcoded-item>li:hover>a {
                    color: #FFF;
                }
                .sidebar_toggle a {
                    color: #fff;
                }
            }
        }
        .pcoded-header {
            &[header-theme="theme2"] {
                color: #fff;
                .sidebar_toggle a {
                    background: transparent;
                    color: #fff;
                    border-color: #fff;
                }
                .pcoded-right-header {
                    .pcoded-rl-header ul>li>a,
                    .pcoded-rr-header ul>li>a {
                        color: #fff;
                    }
                }
            }
            &.nocollapsed .pcoded-left-header[lheader-theme="theme2"] {
                background: transparent;
            }
            &.iscollapsed .pcoded-left-header[lheader-theme="theme2"] {
                background: #5e5e5e;
            }
        }
        &[navbar-theme="theme2"] {
            background: #5e5e5e;
            .main-menu {
                background-color: #5e5e5e;
                .main-menu-header {
                    background-color: #787878;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #fff;
                    }
                    &.active>a {
                        background: #5e5e5e;
                        color: #FFF;
                        border-bottom-color: #787878;
                        &:before {
                            border-left-color: #5e5e5e;
                        }
                    }
                    >a {
                        border-bottom-color: #787878;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #5e5e5e;
                    li>a {
                        border-bottom-color: #5e5e5e;
                        color: #fff;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #707070;
                            li.pcoded-hasmenu>a:after {
                                color: #fff;
                            }
                        }
                        >a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #fff;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #5e5e5e;
                        color: #FFF;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #707070;
                        color: #FFF;
                        &:before {
                            border-left-color: #5e5e5e;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #707070;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #5e5e5e;
                            }
                            &:after {
                                border-left-color: #5e5e5e;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #5e5e5e;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #fff;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #5e5e5e;
                            border-bottom-color: #787878;
                        }
                        &.active>a {
                            background: #5e5e5e;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #5e5e5e;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #5e5e5e;
                border-bottom-color: transparent;
            }
        }
        &[theme-layout="vertical"] {
            &[vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme2"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme2"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-color: transparent;
            }
            &[vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme2"] .pcoded-item {
                li.pcoded-trigger>a>.pcoded-mcaret {
                    border-right-color: #5e5e5e !important;
                }
                >.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    border-left-color: transparent;
                }
            }
            &[vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme2"] {
                background-color: #5e5e5e;
                .pcoded-item>li:hover>a {
                    color: #FFF;
                }
                .sidebar_toggle a {
                    color: #fff;
                }
            }
        }
        .pcoded-header {
            &[header-theme="theme3"] {
                color: #fff;
                .sidebar_toggle a {
                    background: transparent;
                    color: #fff;
                    border-color: #fff;
                }
                .pcoded-right-header {
                    .pcoded-rl-header ul>li>a,
                    .pcoded-rr-header ul>li>a {
                        color: #fff;
                    }
                }
            }
            &.nocollapsed .pcoded-left-header[lheader-theme="theme3"] {
                background: transparent;
            }
            &.iscollapsed .pcoded-left-header[lheader-theme="theme3"] {
                background: #2c3e50;
            }
        }
        &[navbar-theme="theme3"] {
            background: #2c3e50;
            .main-menu {
                background-color: #2c3e50;
                .main-menu-header {
                    background-color: #3e5871;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #fff;
                    }
                    &.active>a {
                        background: #2c3e50;
                        color: #FFF;
                        border-bottom-color: #3e5871;
                        &:before {
                            border-left-color: #2c3e50;
                        }
                    }
                    >a {
                        border-bottom-color: #3e5871;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #2c3e50;
                    li>a {
                        border-bottom-color: #2c3e50;
                        color: #fff;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #395067;
                            li.pcoded-hasmenu>a:after {
                                color: #fff;
                            }
                        }
                        >a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #fff;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #2c3e50;
                        color: #FFF;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #395067;
                        color: #FFF;
                        &:before {
                            border-left-color: #2c3e50;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #395067;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #2c3e50;
                            }
                            &:after {
                                border-left-color: #2c3e50;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #2c3e50;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #fff;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #2c3e50;
                            border-bottom-color: #3e5871;
                        }
                        &.active>a {
                            background: #2c3e50;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #2c3e50;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #2c3e50;
                border-bottom-color: transparent;
            }
        }
        &[theme-layout="vertical"] {
            &[vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme3"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme3"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-color: transparent;
            }
            &[vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme3"] .pcoded-item {
                li.pcoded-trigger>a>.pcoded-mcaret {
                    border-right-color: #2c3e50 !important;
                }
                >.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    border-left-color: transparent;
                }
            }
            &[vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme3"] {
                background-color: #2c3e50;
                .pcoded-item>li:hover>a {
                    color: #FFF;
                }
                .sidebar_toggle a {
                    color: #fff;
                }
            }
        }
        .pcoded-header {
            &[header-theme="theme4"] {
                color: #fff;
                .sidebar_toggle a {
                    background: transparent;
                    color: #fff;
                    border-color: #fff;
                }
                .pcoded-right-header {
                    .pcoded-rl-header ul>li>a,
                    .pcoded-rr-header ul>li>a {
                        color: #fff;
                    }
                }
            }
            &.nocollapsed .pcoded-left-header[lheader-theme="theme4"] {
                background: transparent;
            }
            &.iscollapsed .pcoded-left-header[lheader-theme="theme4"] {
                background: #23282d;
            }
        }
        &[navbar-theme="theme4"] {
            background: #23282d;
            .main-menu {
                background-color: #23282d;
                .main-menu-header {
                    background-color: #39424a;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #fff;
                    }
                    &.active>a {
                        background: #23282d;
                        color: #FFF;
                        border-bottom-color: #39424a;
                        &:before {
                            border-left-color: #23282d;
                        }
                    }
                    >a {
                        border-bottom-color: #39424a;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #23282d;
                    li>a {
                        border-bottom-color: #23282d;
                        color: #fff;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #333a41;
                            li.pcoded-hasmenu>a:after {
                                color: #fff;
                            }
                        }
                        >a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #fff;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #23282d;
                        color: #FFF;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #333a41;
                        color: #FFF;
                        &:before {
                            border-left-color: #23282d;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #333a41;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #23282d;
                            }
                            &:after {
                                border-left-color: #23282d;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #23282d;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #fff;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #23282d;
                            border-bottom-color: #39424a;
                        }
                        &.active>a {
                            background: #23282d;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #23282d;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #23282d;
                border-bottom-color: transparent;
            }
        }
        &[theme-layout="vertical"] {
            &[vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme4"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme4"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-color: transparent;
            }
            &[vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme4"] .pcoded-item {
                li.pcoded-trigger>a>.pcoded-mcaret {
                    border-right-color: #23282d !important;
                }
                >.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    border-left-color: transparent;
                }
            }
            &[vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme4"] {
                background-color: #23282d;
                .pcoded-item>li:hover>a {
                    color: #FFF;
                }
                .sidebar_toggle a {
                    color: #fff;
                }
            }
        }
        .pcoded-header {
            &[header-theme="theme5"] {
                color: #fff;
                .sidebar_toggle a {
                    background: transparent;
                    color: #fff;
                    border-color: #fff;
                }
                .pcoded-right-header {
                    .pcoded-rl-header ul>li>a,
                    .pcoded-rr-header ul>li>a {
                        color: #fff;
                    }
                }
            }
            &.nocollapsed .pcoded-left-header[lheader-theme="theme5"] {
                background: transparent;
            }
            &.iscollapsed .pcoded-left-header[lheader-theme="theme5"] {
                background: #0073aa;
            }
        }
        &[navbar-theme="theme5"] {
            background: #0073aa;
            .main-menu {
                background-color: #0073aa;
                .main-menu-header {
                    background-color: #0096dd;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #fff;
                    }
                    &.active>a {
                        background: #0073aa;
                        color: #FFF;
                        border-bottom-color: #0096dd;
                        &:before {
                            border-left-color: #0073aa;
                        }
                    }
                    >a {
                        border-bottom-color: #0096dd;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #0073aa;
                    li>a {
                        border-bottom-color: #0073aa;
                        color: #fff;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #008bce;
                            li.pcoded-hasmenu>a:after {
                                color: #fff;
                            }
                        }
                        >a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #fff;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #0073aa;
                        color: #FFF;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #008bce;
                        color: #FFF;
                        &:before {
                            border-left-color: #0073aa;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #008bce;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #0073aa;
                            }
                            &:after {
                                border-left-color: #0073aa;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #0073aa;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #fff;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #0073aa;
                            border-bottom-color: #0096dd;
                        }
                        &.active>a {
                            background: #0073aa;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #0073aa;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #0073aa;
                border-bottom-color: transparent;
            }
        }
        &[theme-layout="vertical"] {
            &[vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme5"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme5"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-color: transparent;
            }
            &[vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme5"] .pcoded-item {
                li.pcoded-trigger>a>.pcoded-mcaret {
                    border-right-color: #0073aa !important;
                }
                >.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    border-left-color: transparent;
                }
            }
            &[vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme5"] {
                background-color: #0073aa;
                .pcoded-item>li:hover>a {
                    color: #FFF;
                }
                .sidebar_toggle a {
                    color: #fff;
                }
            }
        }
        .pcoded-header {
            &[header-theme="theme6"] {
                color: #fff;
                .sidebar_toggle a {
                    background: transparent;
                    color: #fff;
                    border-color: #fff;
                }
                .pcoded-right-header {
                    .pcoded-rl-header ul>li>a,
                    .pcoded-rr-header ul>li>a {
                        color: #fff;
                    }
                }
            }
            &.nocollapsed .pcoded-left-header[lheader-theme="theme6"] {
                background: transparent;
            }
            &.iscollapsed .pcoded-left-header[lheader-theme="theme6"] {
                background: #3c4fb1;
            }
        }
        &[navbar-theme="theme6"] {
            background: #3c4fb1;
            .main-menu {
                background-color: #3c4fb1;
                .main-menu-header {
                    background-color: #596bc7;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #fff;
                    }
                    &.active>a {
                        background: #3c4fb1;
                        color: #FFF;
                        border-bottom-color: #596bc7;
                        &:before {
                            border-left-color: #3c4fb1;
                        }
                    }
                    >a {
                        border-bottom-color: #596bc7;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #3c4fb1;
                    li>a {
                        border-bottom-color: #3c4fb1;
                        color: #fff;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #4e61c3;
                            li.pcoded-hasmenu>a:after {
                                color: #fff;
                            }
                        }
                        >a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #fff;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #3c4fb1;
                        color: #FFF;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #4e61c3;
                        color: #FFF;
                        &:before {
                            border-left-color: #3c4fb1;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #4e61c3;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #3c4fb1;
                            }
                            &:after {
                                border-left-color: #3c4fb1;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #3c4fb1;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #fff;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #3c4fb1;
                            border-bottom-color: #596bc7;
                        }
                        &.active>a {
                            background: #3c4fb1;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #3c4fb1;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #3c4fb1;
                border-bottom-color: transparent;
            }
        }
        &[theme-layout="vertical"] {
            &[vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme6"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
            &[vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme6"] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                border-left-color: transparent;
            }
            &[vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme6"] .pcoded-item {
                li.pcoded-trigger>a>.pcoded-mcaret {
                    border-right-color: #3c4fb1 !important;
                }
                >.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
                    border-left-color: transparent;
                }
            }
            &[vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme6"] {
                background-color: #3c4fb1;
                .pcoded-item>li:hover>a {
                    color: #FFF;
                }
                .sidebar_toggle a {
                    color: #fff;
                }
            }
        }
        &[navbar-theme="themelight1"] {
            .pcoded-item {
                li {
                    .pcoded-submenu li.active>a {
                        color: #000 !important;
                        font-weight: 600;
                    }
                    &.pcoded-hasmenu:hover>a {
                        color: #000 !important;
                    }
                    &:hover>a {
                        color: #000 !important;
                        background: #fff;
                        &:before {
                            border-left-color: transparent !important;
                        }
                    }
                }
                >li.active>a:after {
                    color: #FFF !important;
                }
            }
            background: #fff;
            .main-menu {
                background-color: #fff;
                .main-menu-header {
                    background-color: #e6e6e6;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #000 !important;
                    }
                    &.active>a {
                        background: #fff !important;
                        color: #FFF;
                        &:before {
                            border-left-color: #fff;
                        }
                    }
                    >a {
                        border-bottom-color: #e6e6e6;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #fff;
                    li>a {
                        border-bottom-color: #fff;
                        color: #000;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #ededed;
                            li.pcoded-hasmenu>a:after {
                                color: #000;
                            }
                        }
                        >a:after {
                            color: #000;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #000;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #fff;
                        color: #000;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #ededed;
                        color: #000;
                        &:before {
                            border-left-color: #fff;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #ededed;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #fff;
                            }
                            &:after {
                                border-left-color: #fff;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #fff;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #000;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #fff;
                            border-bottom-color: #fff;
                        }
                        &.active>a {
                            background: #fff;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after,
                        &.pcoded-trigger.active>a:after {
                            color: #fff;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #fff;
                border-bottom-color: transparent;
            }
        }
        &[navbar-theme="themelight2"] {
            .pcoded-item {
                li {
                    .pcoded-submenu li.active>a {
                        color: #000 !important;
                        font-weight: 600;
                    }
                    &.pcoded-hasmenu:hover>a {
                        color: #000 !important;
                    }
                    &:hover>a {
                        color: #000 !important;
                        background: #bde9ff;
                        &:before {
                            border-left-color: transparent !important;
                        }
                    }
                }
                >li.active>a:after {
                    color: #FFF !important;
                }
            }
            background: #bde9ff;
            .main-menu {
                background-color: #bde9ff;
                .main-menu-header {
                    background-color: #8ad8ff;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #000 !important;
                    }
                    &.active>a {
                        background: #bde9ff !important;
                        color: #FFF;
                        &:before {
                            border-left-color: #bde9ff;
                        }
                    }
                    >a {
                        border-bottom-color: #8ad8ff;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #bde9ff;
                    li>a {
                        border-bottom-color: #bde9ff;
                        color: #000;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #99ddff;
                            li.pcoded-hasmenu>a:after {
                                color: #000;
                            }
                        }
                        >a:after {
                            color: #000;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #000;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #bde9ff;
                        color: #000;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #99ddff;
                        color: #000;
                        &:before {
                            border-left-color: #bde9ff;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #99ddff;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #bde9ff;
                            }
                            &:after {
                                border-left-color: #bde9ff;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #bde9ff;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #000;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #bde9ff;
                            border-bottom-color: #bde9ff;
                        }
                        &.active>a {
                            background: #bde9ff;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #bde9ff;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #bde9ff;
                border-bottom-color: transparent;
            }
        }
        &[navbar-theme="themelight3"] {
            .pcoded-item {
                li {
                    .pcoded-submenu li.active>a {
                        color: #000 !important;
                        font-weight: 600;
                    }
                    &.pcoded-hasmenu:hover>a {
                        color: #000 !important;
                    }
                    &:hover>a {
                        color: #000 !important;
                        background: #edcbc3;
                        &:before {
                            border-left-color: transparent !important;
                        }
                    }
                }
                >li.active>a:after {
                    color: #FFF !important;
                }
            }
            background: #edcbc3;
            .main-menu {
                background-color: #edcbc3;
                .main-menu-header {
                    background-color: #e1a99c;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #000 !important;
                    }
                    &.active>a {
                        background: #edcbc3 !important;
                        color: #FFF;
                        &:before {
                            border-left-color: #edcbc3;
                        }
                    }
                    >a {
                        border-bottom-color: #e1a99c;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #edcbc3;
                    li>a {
                        border-bottom-color: #edcbc3;
                        color: #000;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #e5b3a8;
                            li.pcoded-hasmenu>a:after {
                                color: #000;
                            }
                        }
                        >a:after {
                            color: #000;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #000;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #edcbc3;
                        color: #000;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #e5b3a8;
                        color: #000;
                        &:before {
                            border-left-color: #edcbc3;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #e5b3a8;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #edcbc3;
                            }
                            &:after {
                                border-left-color: #edcbc3;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #edcbc3;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #000;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #edcbc3;
                            border-bottom-color: #edcbc3;
                        }
                        &.active>a {
                            background: #edcbc3;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #edcbc3;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #edcbc3;
                border-bottom-color: transparent;
            }
        }
        &[navbar-theme="themelight4"] {
            .pcoded-item {
                li {
                    .pcoded-submenu li.active>a {
                        color: #000 !important;
                        font-weight: 600;
                    }
                    &.pcoded-hasmenu:hover>a {
                        color: #000 !important;
                    }
                    &:hover>a {
                        color: #000 !important;
                        background: #d7c7ff;
                        &:before {
                            border-left-color: transparent !important;
                        }
                    }
                }
                >li.active>a:after {
                    color: #FFF !important;
                }
            }
            background: #d7c7ff;
            .main-menu {
                background-color: #d7c7ff;
                .main-menu-header {
                    background-color: #b394ff;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #000 !important;
                    }
                    &.active>a {
                        background: #d7c7ff !important;
                        color: #FFF;
                        &:before {
                            border-left-color: #d7c7ff;
                        }
                    }
                    >a {
                        border-bottom-color: #b394ff;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #d7c7ff;
                    li>a {
                        border-bottom-color: #d7c7ff;
                        color: #000;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #bea3ff;
                            li.pcoded-hasmenu>a:after {
                                color: #000;
                            }
                        }
                        >a:after {
                            color: #000;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #000;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #d7c7ff;
                        color: #000;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #bea3ff;
                        color: #000;
                        &:before {
                            border-left-color: #d7c7ff;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #bea3ff;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #d7c7ff;
                            }
                            &:after {
                                border-left-color: #d7c7ff;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #d7c7ff;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #000;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #d7c7ff;
                            border-bottom-color: #d7c7ff;
                        }
                        &.active>a {
                            background: #d7c7ff;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #d7c7ff;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #d7c7ff;
                border-bottom-color: transparent;
            }
        }
        &[navbar-theme="themelight5"] {
            .pcoded-item {
                li {
                    .pcoded-submenu li.active>a {
                        color: #000 !important;
                        font-weight: 600;
                    }
                    &.pcoded-hasmenu:hover>a {
                        color: #000 !important;
                    }
                    &:hover>a {
                        color: #000 !important;
                        background: #f1f1f1;
                        &:before {
                            border-left-color: transparent !important;
                        }
                    }
                }
                >li.active>a:after {
                    color: #FFF !important;
                }
            }
            background: #f1f1f1;
            .main-menu {
                background-color: #f1f1f1;
                .main-menu-header {
                    background-color: #d8d8d8;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #000 !important;
                    }
                    &.active>a {
                        background: #f1f1f1 !important;
                        color: #FFF;
                        &:before {
                            border-left-color: #f1f1f1;
                        }
                    }
                    >a {
                        border-bottom-color: #d8d8d8;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #f1f1f1;
                    li>a {
                        border-bottom-color: #f1f1f1;
                        color: #000;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #dfdfdf;
                            li.pcoded-hasmenu>a:after {
                                color: #000;
                            }
                        }
                        >a:after {
                            color: #000;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #000;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #f1f1f1;
                        color: #000;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #dfdfdf;
                        color: #000;
                        &:before {
                            border-left-color: #f1f1f1;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #dfdfdf;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #f1f1f1;
                            }
                            &:after {
                                border-left-color: #f1f1f1;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #f1f1f1;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #000;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #f1f1f1;
                            border-bottom-color: #f1f1f1;
                        }
                        &.active>a {
                            background: #f1f1f1;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #f1f1f1;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #f1f1f1;
                border-bottom-color: transparent;
            }
        }
        &[navbar-theme="themelight6"] {
            .pcoded-item {
                li {
                    .pcoded-submenu li.active>a {
                        color: #000 !important;
                        font-weight: 600;
                    }
                    &.pcoded-hasmenu:hover>a {
                        color: #000 !important;
                    }
                    &:hover>a {
                        color: #000 !important;
                        background: #a3f4c5;
                        &:before {
                            border-left-color: transparent !important;
                        }
                    }
                }
                >li.active>a:after {
                    color: #FFF !important;
                }
            }
            background: #a3f4c5;
            .main-menu {
                background-color: #a3f4c5;
                .main-menu-header {
                    background-color: #75efa8;
                }
            }
            .pcoded-item {
                >li {
                    >a {
                        color: #000 !important;
                    }
                    &.active>a {
                        background: #a3f4c5 !important;
                        color: #FFF;
                        &:before {
                            border-left-color: #a3f4c5;
                        }
                    }
                    >a {
                        border-bottom-color: #75efa8;
                    }
                }
                li.pcoded-hasmenu .pcoded-submenu {
                    background: #a3f4c5;
                    li>a {
                        border-bottom-color: #a3f4c5;
                        color: #000;
                    }
                }
                >li {
                    &.pcoded-hasmenu {
                        .pcoded-submenu {
                            background-color: #83f0b1;
                            li.pcoded-hasmenu>a:after {
                                color: #000;
                            }
                        }
                        >a:after {
                            color: #000;
                        }
                        &.pcoded-trigger {
                            >a:after,
                            .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
                                color: #000;
                            }
                        }
                    }
                    &.active:hover>a,
                    &:hover>a {
                        background: #a3f4c5;
                        color: #000;
                    }
                }
                .pcoded-hasmenu .pcoded-submenu li {
                    &:hover>a {
                        background-color: #83f0b1;
                        color: #000;
                        &:before {
                            border-left-color: #a3f4c5;
                        }
                    }
                    &.active>a {
                        color: #FFF;
                        background-color: #83f0b1;
                        .pcoded-mtext {
                            &:before {
                                border-bottom-color: #a3f4c5;
                            }
                            &:after {
                                border-left-color: #a3f4c5;
                            }
                        }
                    }
                    >a .pcoded-mtext {
                        &:before {
                            border-bottom-color: #ffff;
                        }
                        &:after {
                            border-left-color: #fff;
                        }
                    }
                }
                >li {
                    &:hover>a:before {
                        border-left-color: #a3f4c5;
                    }
                    &.pcoded-hasmenu:hover>a:after {
                        color: #000;
                    }
                    &.pcoded-trigger {
                        >a {
                            background: #a3f4c5;
                            border-bottom-color: #a3f4c5;
                        }
                        &.active>a {
                            background: #a3f4c5;
                        }
                    }
                    &.pcoded-hasmenu {
                        .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
                            color: #fff;
                        }
                        &.pcoded-trigger.active>a:after {
                            color: #a3f4c5;
                        }
                    }
                }
            }
            .pcoded-navigatio-lavel {
                color: #a3f4c5;
                border-bottom-color: transparent;
            }
        }
    }
    .pcoded-header .navbar-logo {
        &[navbar-theme="theme1"] {
            background-color: #000;
        }
        &[navbar-theme="theme2"] {
            background-color: #5e5e5e;
        }
        &[navbar-theme="theme3"] {
            background-color: #2c3e50;
        }
        &[navbar-theme="theme4"] {
            background-color: #23282d;
        }
        &[navbar-theme="theme5"] {
            background-color: #0073aa;
        }
        &[navbar-theme="theme6"] {
            background-color: #3c4fb1;
        }
        &[navbar-theme="themelight1"],
        &[navbar-theme="themelight2"],
        &[navbar-theme="themelight3"],
        &[navbar-theme="themelight4"],
        &[navbar-theme="themelight5"],
        &[navbar-theme="themelight6"] {
            background-color: #23282D;
        }
    }
    &[theme-layout="vertical"] {
        &[vnavigation-view="view2"] {
            &[pcoded-device-type="desktop"] .pcoded-navbar,
            &[pcoded-device-type="tablet"] .pcoded-navbar {
                margin-top: 10px;
                border-radius: 4px 4px 0 0;
            }
            &[pcoded-device-type="desktop"] .pcoded-navbar .profile-box,
            &[pcoded-device-type="tablet"] .pcoded-navbar .profile-box {
                border-radius: 4px 4px 0 0;
            }
            &[pcoded-device-type="desktop"] {
                .pcoded-header,
                .pcoded-main-container {
                    padding-left: 10px;
                }
            }
            &[pcoded-device-type="tablet"] {
                .pcoded-header,
                .pcoded-main-container {
                    padding-left: 10px;
                }
            }
            &[vertical-layout="widebox"] {
                &[pcoded-device-type="desktop"] .pcoded-header .pcoded-wrapper,
                &[pcoded-device-type="tablet"] .pcoded-header .pcoded-wrapper {
                    padding-left: 5px;
                }
            }
        }
        &[vnavigation-view="view3"] {
            .pcoded-navbar {
                top: 0;
            }
            .pcoded-main-container {
                position: unset;
            }
            .pcoded-header .pcoded-left-header {
                display: none;
            }
        }
    }
    #styleSelector {
        min-height: 300px;
        height: 80vh;
        border: 1px solid #e9e9e9;
        background: #FFF;
        position: fixed;
        margin: 0;
        padding: 20px;
        width: 300px;
        top: 10vh;
        z-index: 100;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -ms-transition: 0.5s;
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
        &.open {
            background: #FFF none repeat scroll 0 0;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        }
    }
    &[theme-layout="horizontal"] #styleSelector,
    &[vertical-placement="left"] #styleSelector {
        right: -300px;
    }
    &[theme-layout="horizontal"] #styleSelector.open,
    &[vertical-placement="left"] #styleSelector.open {
        right: 0;
    }
    &[vertical-placement="right"] #styleSelector {
        left: -300px;
        &.open {
            left: 0;
        }
    }
}

.selector-toggle {
    position: relative;
}

.pcoded {
    .selector-toggle>a {
        border: 2px solid #0073aa;
        position: absolute;
        top: 200px;
        width: 60px;
        height: 66px;
        display: block;
        cursor: pointer;
        text-align: center;
        background: #23282D;
        color: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        padding: 14px 4px;
    }
    &[theme-layout="horizontal"] .selector-toggle>a,
    &[vertical-placement="left"] .selector-toggle>a {
        left: -80px;
        border-right: 0;
        border-radius: 4px 0 0 4px;
    }
    &[vertical-placement="right"] .selector-toggle>a {
        right: -80px;
        border-left: 0;
        border-radius: 0 4px 4px 0;
    }
}

.selector-toggle>a:before {
    font-family: 'themify';
    font-size: 25px;
    content: "\e60f";
    position: relative;
    float: left;
    left: 13px;
    line-height: 35px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    animation: mymove 1.3s infinite linear;
}

.open .selector-toggle>a:before {
    font-family: 'themify';
    font-size: 25px;
    content: "\e646";
    position: relative;
    float: left;
    left: 13px;
    line-height: 35px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    animation: mymove 0s infinite linear;
}

#styleSelector .nav-tabs {
    margin: 20px 0;
    .nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 a {
        width: calc(100% / 3);
        margin: 0;
        padding: 0;
        float: left;
    }
}

.main-menu .main-menu-content .nav-item .tree-1 #styleSelector .nav-tabs a,
#styleSelector .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 #styleSelector .nav-tabs a,
#styleSelector .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 #styleSelector .nav-tabs a,
#styleSelector .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 #styleSelector .nav-tabs a {
    width: calc(100% / 3);
    margin: 0;
    padding: 0;
    float: left;
}

#styleSelector .nav-tabs .nav-item a {
    color: #0073aa;
    text-align: center;
    border-radius: 0;
    background-color: #fff;
    border: none;
    transition: all 0.3s ease-in-out;
    border-bottom: 3px solid #0073aa;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 #styleSelector .nav-tabs a a,
    .tree-2 #styleSelector .nav-tabs a a,
    .tree-3 #styleSelector .nav-tabs a a,
    .tree-4 #styleSelector .nav-tabs a a {
        color: #0073aa;
        text-align: center;
        border-radius: 0;
        background-color: #fff;
        border: none;
        transition: all 0.3s ease-in-out;
        border-bottom: 3px solid #0073aa;
    }
}

#styleSelector .nav-tabs .nav-item a.active {
    color: #fff;
    background-color: #23282d;
}

.main-menu .main-menu-content .nav-item {
    .tree-1 #styleSelector .nav-tabs a a.active,
    .tree-2 #styleSelector .nav-tabs a a.active,
    .tree-3 #styleSelector .nav-tabs a a.active,
    .tree-4 #styleSelector .nav-tabs a a.active {
        color: #fff;
        background-color: #23282d;
    }
}

#styleSelector {
    .form-control {
        border: none;
        box-shadow: none;
        background-color: #f1f1f1;
        margin-bottom: 10px;
        &:focus {
            border: none;
            background-color: #f1f1f1;
        }
    }
    ul {
        border: 0 none;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            border-bottom: 0 solid #e9e9e9;
            display: block;
            margin: 0;
            padding: 0;
            text-align: left;
            width: 100%;
            p.selector-title,
            span.selector-title {
                color: #444;
                font-size: 14px;
                margin: 0 0 20px;
                padding: 10px 0;
                text-align: left;
            }
            p {
                &.selector-title {
                    font-weight: 400;
                    border-bottom: 1px solid #e9e9e9;
                    &.main-title {
                        font-weight: 600;
                    }
                }
                &.sub-title.drp-title {
                    border-bottom: 0 solid transparent;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
            }
        }
    }
    >ul>li>.sub-title {
        color: #9c9c9c;
        display: block;
        font-size: 13px;
        margin: 0;
        padding: 5px 0;
        position: relative;
        text-align: left;
    }
    li {
        padding: 5px;
    }
}

.theme-option select {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 3px 10px;
}

@-moz-document url-prefix() {
    .theme-option select {
        background-color: #f1f1f1;
        border: 1px solid #ccc;
        border-radius: 2px;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 3px 10px;
        margin: 0;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        &.minimal {
            background-image: -webkit-linear-gradient(45deg, transparent 50%, gray 50%), -webkit-linear-gradient(315deg, gray 50%, transparent 50%), -webkit-linear-gradient(left, #ccc, #ccc);
            background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
            background-position: calc(100% - 18px) calc(1em + -1px), calc(100% - 13px) calc(1em + -1px), calc(100% - 2.5em) 4px;
            background-size: 5px 5px, 5px 5px, 1px 1.5em;
            background-repeat: no-repeat;
            &:active,
            &:focus {
                background-image: -webkit-linear-gradient(45deg, gray 50%, transparent 50%), -webkit-linear-gradient(315deg, transparent 50%, gray 50%), -webkit-linear-gradient(left, gray, gray);
                background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%), linear-gradient(to right, gray, gray);
                background-position: calc(100% - 15px) 13px, calc(100% - 20px) 13px, calc(100% - 2.5em) 0.3em;
                background-size: 5px 5px, 5px 5px, 1px 1.5em;
                background-repeat: no-repeat;
                border-color: #66afe9;
                outline: 0;
            }
        }
    }
}

.theme-color {
    padding: 0;
    width: 100%;
    a {
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 5px 10px;
        box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
        &:first-child {
            margin-left: 0;
        }
        &:focus,
        &:hover {
            text-decoration: none;
        }
        &[lheader-theme="theme1"] {
            background: #2a394f;
        }
        &[lheader-theme="theme2"] {
            background: #624e89;
        }
        &[lheader-theme="theme3"] {
            background: #3c4fb1;
        }
        &[lheader-theme="theme4"] {
            background: #2C3E50;
        }
        &[lheader-theme="theme5"] {
            background: #0073aa;
        }
        &[lheader-theme="theme6"] {
            background: #fff;
        }
        &[header-theme="theme1"] {
            background: #bde9ff;
        }
        &[header-theme="theme2"] {
            background: #edcbc3;
        }
        &[header-theme="theme3"] {
            background: #d7c7ff;
        }
        &[header-theme="theme4"] {
            background: #fff;
        }
        &[header-theme="theme5"] {
            background: #f1f1f1;
        }
        &[header-theme="theme6"] {
            background: #a3f4c5;
        }
        &[navbar-theme="theme1"] {
            background: #000;
        }
        &[navbar-theme="theme2"] {
            background: #5e5e5e;
        }
        &[navbar-theme="theme3"] {
            background: #2c3e50;
        }
        &[navbar-theme="theme4"] {
            background: #23282d;
        }
        &[navbar-theme="theme5"] {
            background: #0073aa;
        }
        &[navbar-theme="theme6"] {
            background: #3c4fb1;
        }
        &[navbar-theme="themelight1"] {
            background: #fff;
        }
        &[navbar-theme="themelight2"] {
            background: #bde9ff;
        }
        &[navbar-theme="themelight3"] {
            background: #edcbc3;
        }
        &[navbar-theme="themelight4"] {
            background: #d7c7ff;
        }
        &[navbar-theme="themelight5"] {
            background: #f1f1f1;
        }
        &[navbar-theme="themelight6"] {
            background: #a3f4c5;
        }
        &[active-item-theme="theme1"] {
            background: #ff5e3a;
        }
        &[active-item-theme="theme2"] {
            background: #0096b1;
        }
        &[active-item-theme="theme3"] {
            background: #e64056;
        }
        &[active-item-theme="theme4"] {
            background: #0073aa;
        }
        &[active-item-theme="theme5"] {
            background: #354a5f;
        }
        &[active-item-theme="theme6"] {
            background: #967adc;
        }
        &[active-item-theme="theme7"] {
            background: #fe9375;
        }
        &[active-item-theme="theme8"] {
            background: #565a90;
        }
        &[sub-item-theme="theme1"] {
            background: #70ca63;
        }
        &[sub-item-theme="theme2"] {
            background: #3498DB;
        }
        &[sub-item-theme="theme3"] {
            background: #485BBD;
        }
        &[sub-item-theme="theme4"] {
            background: #e7604a;
        }
        &[sub-item-theme="theme5"] {
            background: #333843;
        }
        &[sub-item-theme="theme6"] {
            background: #624e89;
        }
        &[sub-item-theme="theme7"] {
            background: #FFF;
        }
        &[sub-item-theme="theme8"] {
            background: #384b5f;
        }
        &[sub-item-theme="theme9"] {
            background: #578ebe;
        }
        &[themebg-pattern="pattern1"] {
            background-image: url("assets/images/pattern1.png");
        }
        &[themebg-pattern="pattern2"] {
            background-image: url("assets/images/pattern2.png");
        }
        &[themebg-pattern="pattern3"] {
            background-image: url("assets/images/pattern3.png");
        }
        &[themebg-pattern="pattern4"] {
            background-image: url("assets/images/pattern4.png");
        }
        &[themebg-pattern="pattern5"] {
            background-image: url("assets/images/pattern5.png");
        }
        &[themebg-pattern="pattern6"] {
            background-image: url("assets/images/pattern6.png");
        }
        &[themebg-pattern="pattern7"] {
            background-image: url("assets/images/pattern7.png");
        }
        &[themebg-pattern="pattern8"] {
            background-image: url("assets/images/pattern8.png");
        }
        &[themebg-pattern="pattern9"] {
            background-image: url("assets/images/pattern9.png");
        }
    }
}

.sparkline-chart {
    text-align: center;
}

.pcoded[theme-layout="vertical"] {
    &[pcoded-device-type="phone"] .spark-chart,
    &[pcoded-device-type="tablet"] .spark-chart {
        display: none;
    }
}

.spark-chart-title {
    text-align: center;
}

.content-title {
    margin-bottom: 10px;
}

.main-title {
    color: #424242;
    font-size: 22px;
    font-weight: 400;
    margin: 0;
}

.small-text {
    font-size: 14px;
}

.info-widget {
    .info-box-stats {
        float: left;
        margin-top: 5px;
        p {
            font-size: 28px;
            /* margin-bottom: 14px; */
            color: #666;
            font-weight: 300;
        }
        span.info-box-title {
            display: block;
            font-size: 13px;
            margin-bottom: 10px;
            color: #a4a4a4;
        }
    }
    .info-box-icon {
        position: absolute;
        right: 30px;
        i {
            font-size: 45px;
            color: #FFF;
        }
    }
    .info-box-progress {
        clear: both;
    }
    .progress {
        margin: 0;
    }
}

.progress-xs {
    height: 5px;
}

.progress-squared {
    border-radius: 0;
}

.progress-bar {
    background-color: #7a6fbe;
}

.progress-bar-success {
    background-color: #22BAA0;
}

.progress-bar-info {
    background-color: #12AFCB;
}

.progress-bar-warning {
    background-color: #f6d433;
}

.progress-bar-danger {
    background-color: #f25656;
}

.info-widget {
    .earnings-panel-theme .info-box-icon i {
        color: #6a7bdb;
    }
    .balance-panel-theme .info-box-icon i {
        color: #f7786c;
    }
    .total-earnings-panel-theme .info-box-icon i {
        color: #36bcf8;
    }
    .item-sales-panel-theme .info-box-icon i {
        color: #65d5a4;
    }
}

.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    padding: 8px 0;
    margin-bottom: 10px;
}

.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .breadcrumb {
    display: none;
}

.top-country-panel {
    .country-name {
        color: #7e7e7e;
        font-size: 14px;
        font-weight: 400;
    }
    .earning {
        color: #7e7e7e;
        float: right;
        font-size: 15px;
    }
    .list-group {
        border: 1px solid #d1d1d1;
        border-radius: 4px;
    }
    .list-group-item {
        background-color: #fff;
        border: 0 solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        display: block;
        margin-bottom: 0;
        padding: 7px 15px;
        position: relative;
        &:last-child {
            border-bottom: 0 solid #f1f1f1;
        }
    }
    .top-country-title {
        font-size: 16px;
        margin: 0;
        padding: 3px 0;
    }
    .img-thumbnail {
        background-color: #fff;
        border: 0 solid #ddd;
        border-radius: 0;
        margin-right: 5px;
        padding: 6px 8px 8px;
        position: relative;
        top: 3px;
    }
}

.table-container .table {
    border: 0 solid #f1f1f1;
    margin-bottom: 0;
    tr th {
        border-bottom: 1px solid #f1f1f1;
        font-size: 13px;
        font-weight: 600;
        background: #00bcd4;
        color: #FFF;
    }
    > {
        tbody>tr> {
            td,
            th {
                border-top: 1px solid #f1f1f1;
                line-height: 1.42857;
                padding: 8px;
                vertical-align: top;
            }
        }
        tfoot>tr> {
            td,
            th {
                border-top: 1px solid #f1f1f1;
                line-height: 1.42857;
                padding: 8px;
                vertical-align: top;
            }
        }
        thead>tr> {
            td,
            th {
                border-top: 1px solid #f1f1f1;
                line-height: 1.42857;
                padding: 8px;
                vertical-align: top;
            }
        }
        tbody>tr>td,
        tfoot>tr>td,
        thead>tr>td {
            font-family: arial;
            font-size: 13px;
            font-weight: 300;
        }
        tbody>tr.total>td {
            border-bottom: 1px solid #DDD;
            font-size: 14px;
            font-weight: 600;
            background: #FFF;
        }
    }
}

.pageview-statistics-panel {
    .statistics-name {
        font-size: 14px;
    }
    .value {
        float: right;
        font-size: 15px;
    }
    .list-group-item {
        background-color: #00bcd4;
        border: 1px solid #1dcde4;
        display: block;
        margin-bottom: -1px;
        padding: 7px 15px;
        position: relative;
        color: #FFF;
    }
    .pageview-statistics-title {
        font-size: 18px;
        margin: 0;
        padding: 3px 0;
    }
}

.traffic-source-panel {
    .traffic-source-name {
        font-size: 14px;
    }
    .value {
        float: right;
        font-size: 18px;
        position: relative;
        top: -4px;
        small {
            font-size: 13px;
            padding-left: 10px;
            color: #f1f1f1;
        }
    }
    .list-group-item {
        background-color: #46be8a;
        border: 1px solid #65d5a4;
        color: #fff;
        display: block;
        margin-bottom: -1px;
        padding: 10px 15px;
        position: relative;
    }
    .traffic-source-title {
        font-size: 18px;
        margin: 0;
        padding: 3px 0;
    }
}

.review-panel {
    .review-title {
        color: #666;
        font-size: 14px;
        font-weight: 700;
    }
    .user-by {
        float: right;
        font-size: 14px;
        position: absolute;
        right: 12px;
        top: 6px;
        a {
            color: #4d5ec1;
        }
        .time-ago {
            font-size: 13px;
            padding-left: 2px;
            color: #999;
        }
    }
    .list-group {
        border: 1px solid #d1d1d1;
        border-radius: 4px;
    }
    .list-group-item {
        background-color: #FFF;
        border: 0 solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        color: #333;
        display: block;
        margin-bottom: 0;
        padding: 10px 15px;
        position: relative;
        &:last-child {
            border-bottom: 0 solid #f1f1f1;
        }
    }
    .review-heading {
        font-size: 18px;
        margin: 0;
        padding: 3px 0;
    }
}

.rate {
    color: #f9ab49;
}

.review-panel .review-text {
    color: #7e7e7e;
    font-size: 13px;
    font-weight: 400;
    padding-top: 2px;
}

.up-arrow {
    color: #46be8a;
}

.down-arrow {
    color: #eb6357;
}

.up-arrow i {
    color: #46be8a;
    padding-left: 5px;
}

.down-arrow i {
    color: #eb6357;
    padding-left: 5px;
}

.comment-panel {
    .user-by {
        color: #666;
        font-size: 14px;
        font-weight: 700;
    }
    .time-ago {
        float: right;
        font-size: 13px;
        position: absolute;
        right: 12px;
        top: 6px;
        color: #999;
        a {
            color: #4d5ec1;
        }
    }
    .list-group {
        border: 1px solid #d1d1d1;
        border-radius: 4px;
    }
    .list-group-item {
        background-color: #FFF;
        border: 0 solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        color: #333;
        display: block;
        margin-bottom: 0;
        padding: 10px 15px;
        position: relative;
        &:last-child {
            border-bottom: 0 solid #f1f1f1;
        }
    }
    .comment-heading {
        font-size: 18px;
        margin: 0;
        padding: 3px 0;
    }
    .comment-text {
        color: #7e7e7e;
        font-size: 13px;
        font-weight: 400;
        padding-top: 2px;
    }
    .user-img img {
        border-radius: 50%;
        height: 35px;
        width: 35px;
    }
    .item-purchase-status {
        background-color: #00bcd4;
        border-radius: 10px;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
        min-width: 10px;
        opacity: 1;
        padding: 3px 7px;
        position: relative;
        text-align: center;
        transition: opacity 0.3s linear 0s;
        -webkit-transition: opacity 0.3s linear 0s;
        -ms-transition: opacity 0.3s linear 0s;
        -moz-transition: opacity 0.3s linear 0s;
        -o-transition: opacity 0.3s linear 0s;
        vertical-align: middle;
        visibility: visible;
        white-space: nowrap;
    }
    .comment-item-status {
        padding: 4px 0;
        text-align: right;
    }
    .comment-reply a {
        color: #4285f4;
        font-size: 13px;
        padding-left: 10px;
    }
    p {
        margin: 0 0 2px;
    }
}

.item-download-box .progress {
    background-color: #f5f5f5;
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    height: 8px;
    margin-bottom: 10px;
    overflow: hidden;
}

.progress-bar {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1) inset;
    color: #fff;
    float: left;
    font-size: 9px;
    height: 100%;
    line-height: 10px;
    text-align: center;
}

.item-download-box {
    margin-bottom: 10px;
    .item-name {
        color: #767676;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
        img {
            border-radius: 0;
            height: 25px;
            margin-right: 5px;
            width: 25px;
            display: none;
        }
    }
    .count {
        color: #666;
        float: right;
        font-size: 14px;
        font-weight: 500;
    }
}

.message-panel {
    .user-by {
        color: #666;
        font-size: 14px;
        font-weight: 600;
    }
    .time-ago {
        color: #999;
        float: right;
        font-size: 11px;
        position: absolute;
        right: 8px;
        top: 6px;
        a {
            color: #4d5ec1;
        }
    }
    .list-group {
        border: 1px solid #d1d1d1;
        border-radius: 4px;
    }
    .list-group-item {
        background-color: #FFF;
        border: 0 solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        color: #333;
        display: block;
        margin-bottom: 0;
        padding: 10px 15px;
        position: relative;
        &:last-child {
            border-bottom: 0 solid #f1f1f1;
        }
    }
    .message-heading {
        font-size: 18px;
        margin: 0;
        padding: 3px 0;
    }
    .message-text {
        color: #7e7e7e;
        font-size: 13px;
        font-weight: 400;
        padding-top: 2px;
    }
    .user-img img {
        border-radius: 4px;
        height: 30px;
        width: 30px;
    }
    .item-purchase-status {
        background-color: #00bcd4;
        border-radius: 10px;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
        min-width: 10px;
        opacity: 1;
        padding: 3px 7px;
        position: relative;
        text-align: center;
        transition: opacity 0.3s linear 0s;
        -o-transition: opacity 0.3s linear 0s;
        vertical-align: middle;
        visibility: visible;
        white-space: nowrap;
    }
    .message-item-status {
        padding: 4px 0;
        text-align: right;
    }
    .message-reply a {
        color: #4285f4;
        font-size: 13px;
        padding-left: 10px;
    }
    p {
        margin: 0 0 2px;
    }
}

.panel-body {
    overflow-x: hidden;
}

.pcoded-rr-header .btn {
    font-size: 12px;
    font-weight: 600;
}


/* fixed issue  20170319 */

.pcoded {
    &[theme-layout="vertical"] {
        &[vertical-placement="right"] .pcoded-navbar .pcoded-item>li>a .pcoded-micon i {
            float: none;
            position: relative;
            z-index: 1051;
            left: 93%;
            top: 1px;
        }
        &[vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item>li {
            >a .pcoded-micon i {
                left: 0;
            }
            &.pcoded-hasmenu.pcoded-trigger>a .pcoded-micon i,
            &.pcoded-trigger.active>a .pcoded-micon i {
                left: 94%;
            }
        }
        &[vertical-nav-type="sub-expanded"] {
            &[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item {
                position: static;
                .pcoded-hasmenu .pcoded-submenu li,
                >li {
                    position: static;
                }
            }
            &[pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item {
                position: static;
                .pcoded-hasmenu .pcoded-submenu li,
                >li {
                    position: static;
                }
            }
        }
    }
    &.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-main-container {
        position: static;
    }
    &[theme-layout="vertical"] {
        &[vertical-nav-type="fullpage"] .pcoded-main-container,
        &[vnavigation-view="view3"] .pcoded-main-container {
            position: static;
        }
    }
    .pcoded-header {
        &[header-theme="theme1"] {
            background: #bde9ff;
        }
        &[header-theme="theme2"] {
            background: #edcbc3;
        }
        &[header-theme="theme3"] {
            background: #d7c7ff;
        }
        &[header-theme="theme4"] {
            background: #fff;
        }
        &[header-theme="theme5"] {
            background: #f1f1f1;
        }
        &[header-theme="theme6"] {
            background: #a3f4c5;
        }
    }
}

body {
    .pcoded {
        &[vertical-placement="right"] {
            .users,
            .showChat_inner {
                right: auto;
                left: 0;
            }
            .main-body {
                direction: rtl;
                .page-wrapper .page-header-breadcrumb {
                    float: left;
                }
                .language-markup {
                    direction: ltr;
                }
            }
            .header-navbar .navbar-wrapper {
                .navbar-logo {
                    float: right !important;
                }
                .navbar-container {
                    margin-left: 0;
                    margin-right: 240px;
                    .nav-left li,
                    .nav-right li {
                        float: right !important;
                    }
                    .nav-left {
                        float: right;
                    }
                    .nav-right {
                        float: right;
                        float: left;
                    }
                    .header-notification .show-notification,
                    .mega-menu-top .show-notification,
                    .header-notification .profile-notification,
                    .mega-menu-top .profile-notification,
                    .header-notification .profile-notification {
                        right: auto;
                        left: 0;
                    }
                    .mega-menu-top {
                        .profile-notification,
                        .show-notification,
                        .profile-notification {
                            right: auto;
                            left: 0;
                        }
                    }
                }
            }
        }
        &[vertical-nav-type="compact"] .main-menu {
            width: 190px;
            .pcoded-badge {
                display: none;
            }
        }
    }
    &.menu-bottom .navbar-wrapper {
        z-index: 1050;
    }
}

#pcoded[vertical-nav-type="collapsed"] {
    .slimScrollDiv,
    .scroll-sidebar {
        overflow: visible !important;
    }
}

.md-tabs code {
    margin-bottom: 5px;
    display: inline-block;
}

.pagination {
    display: inline-block;
    li {
        float: left;
    }
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
